import Local from './Local'
import FTP from '@/modules/general/components/Pages/remoteservers/protocols/FTP'
import HTTP from '@/modules/general/components/Pages/remoteservers/protocols/HTTP'
import SFTP from '@/modules/general/components/Pages/remoteservers/protocols/SFTP'
import RemoteServer from './RemoteServer'

export {
  Local,
  FTP,
  HTTP,
  SFTP,
  RemoteServer
}
