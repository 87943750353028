import {noop, identity} from '../util'
import {pull} from '../pull'
import {infinite} from './infinite'
import {onEnd} from '../operators/onEnd'
import {map} from '../operators/map'
import {takeWhile} from '../operators/takeWhile'
import {flatten} from '../operators/flatten'

export const next = (nextFn, endFn = noop) => pull(
  infinite(nextFn),
  onEnd(endFn),
  flatten()
)
