<template>
    <section v-if="loading" class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>Licentie voor gebruik</h1></template>
                    <template slot="content">
                        <p v-if="loading">Bezig met laden...</p>
                    </template>
                </block>
            </div>
        </div>
    </section>
    <vue-form v-else tag="section" :state="form" ref="form" :schema="schema" v-model="settings" class="padding" @submit="doSubmit()">
        <div class="grid">
            <div class="column size--100">
                <block :table="true">
                    <template slot="title"><h1>Toegang tot modules</h1></template>
                    <template slot="content">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Module naam</th>
                                    <th colspan="2" class="align--center">Eigenaar</th>
                                    <th class="align--center">Klant</th>
                                </tr>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th v-for="(label, role) of systemRoles" :key="role" class="align--center">{{ label }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="module of tableModules" :key="module.moduleID">
                                    <td>{{ module.name }}</td>
                                    <td v-for="(roleLabel, role) of systemRoles" :key="role" class="align--center">
                                        <vue-form-field
                                            v-if="module.roles[role]"
                                            :field="`roles.${role}.$.enabled`"
                                            v-model="module.roles[role].enabled"
                                            :bare="true"
                                            :disabled="role === 'superadministrator'"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="content">
                        <button type="button" class="full" title="Opslaan" :disabled="saving" @click.prevent="$refs.form.submit()">
                            <template v-if="saving"><icon name="refresh" :spin="true" /></template>
                            <template v-else>Instellingen opslaan</template>
                        </button>
                        <ul class="errors" v-if="formErrors.length > 0">
                            <li v-for="error of formErrors">
                                <icon name="exclamation-circle"></icon> {{ error }}
                            </li>
                        </ul>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid" v-if="false">
            <div class="column size--100">
                <block>
                    <template slot="content">
                        <pre>{{ debugJSON }}</pre>
                    </template>
                </block>
            </div>
        </div>
    </vue-form>
</template>

<script>
    import pick from 'lodash/pick'
    import omit from 'lodash/omit'
    import {Settings} from '@qiri/models/qiri-settings'
    import {getSystemRoles, getSystemModules} from '@qiri/models/environment/Module'

    export default {
        name: 'page-ModuleAccess',
        data () {
            return {
                form: {},
                settings: null,
                loading: true,
                saving: false
            }
        },
        async mounted () {
            this.settings = await this.$model
                .get('/qiri-settings/Settings', 'global')
                .then(settings =>  settings ? pick(settings, 'settingsID', 'roles') : null)

            if (!this.settings) {
                this.settings = {
                    settingsID: 'global',
                    roles: {}
                }
            }

            // Finished loading.
            this.loading = false
        },
        computed: {
            systemRoles () {
                return getSystemRoles()
            },
            systemModules () {
                return getSystemModules()
            },
            tableModules () {
                // Map the storage data model to the UI's data model.
                return this.systemModules.map(systemModule => {
                    let tableModule = {
                        moduleID: systemModule.moduleID,
                        name: systemModule.name,
                        roles: {}
                    }
                    for (const roleName of Object.keys(getSystemRoles())) {
                        // Skip role if it's not allowed for the current module.
                        if (systemModule.roles.indexOf(roleName) === -1) {
                            continue
                        }

                        // Find the settings object for the role, or create it if necessary.
                        let role = this.settings.roles[roleName]
                        if (!role) {
                            role = []
                            this.settings.roles[roleName] = role
                        }

                        // Find the settings object for the module in the role, or create it if necessary.
                        let module = role.find(x => x.moduleID === systemModule.moduleID)
                        if (!module) {
                            module = {
                                moduleID: systemModule.moduleID,
                                enabled: roleName === 'superadministrator'
                            }
                            role.push(module)
                        }

                        tableModule.roles[roleName] = module
                    }
                    return tableModule
                })
            },
            schema () {
                return Settings.pick('settingsID', 'roles')
            },
            formErrors () {
                let messages = []
                if (this.form.errors && this.form.errors.length > 0) {
                    for (const error of this.form.errors) {
                        let message = `${error.message}.`
                        // Don't show duplicate messages.
                        const duplicateMessage = messages.find(x => x === message)
                        if (!duplicateMessage) {
                            messages.push(message)
                        }
                    }
                }
                return messages
            },
            debugJSON () {
                return JSON.stringify(this.settings, null, '  ')
            }
        },
        methods: {
            async doSubmit () {
                this.saving = true
                await this.$model.upsert('/qiri-settings/Settings', this.settings.settingsID, { $set: omit(this.settings, 'settingsID') })
                this.$router.push({ name: 'dashboard', query: this.$route.query })
            }
        }
    }
</script>
