<template>
    <vue-nested-form :schema="schema" field="format.settings">
        <vue-form-field field="delimiter" :options="delimiterOptions"></vue-form-field>
        <vue-form-field field="quote" :options="quoteOptions"></vue-form-field>
        <vue-form-field field="headerRow"></vue-form-field>
    </vue-nested-form>
</template>

<script>
    import CSV from '@qiri/models/formats/CSV'

    export default {
        name: 'page-feeds-format-CSV',
        computed: {
            schema () {
                return CSV
            },
            delimiterOptions () {
                return {
                    ';': 'Puntkomma',
                    ',': 'Komma',
                    '\t': 'Tab',
                    '|': 'Pipe'
                }
            },
            quoteOptions () {
                return [
                    [ null, 'Geen' ],
                    [ '\'', 'Enkele quotes' ],
                    [ '"', 'Dubbele quotes' ]
                ]
            }
        }
    }
</script>
