import SimpleSchema from '@qiri/simpl-schema'
import ProductModel from '../data/Product'
import OrderBy from './OrderBy'
import {applicableIf} from '../util'
import {renderProductName} from './util'

export const Configuration = new SimpleSchema({
  'thresholdFormula': {
    type: String,
    label: 'Formule',
    description: 'De formule die bepaalt of het percentage kopers in een bepaalde week aanzienlijk hoger is (standaard “Mediaan + Standaarddeviatie * deviatiefactor”).',
    defaultValue: 'MEDIAN+DEVIATION',
    allowedValues: {
      'Gemiddelde': 'AVERAGE',
      'Gemiddelde + Standaarddeviatie * deviatiefactor': 'AVERAGE+DEVIATION',
      'Mediaan': 'MEDIAN',
      'Mediaan + Standaarddeviatie * deviatiefactor': 'MEDIAN+DEVIATION'
    }
  },
  'deviationFactor': {
    type: Number,
    label: 'Deviatiefactor',
    description: 'Is onderdeel van de formule. Met deze factor (standaard 1) wordt de standaarddeviatie vermenigvuldigd. Het resultaat wordt opgeteld bij het gemiddelde of de mediaan.',
    min: 0,
    defaultValue: 1,
    render (value, record) {
      // TODO: Don't "abuse" render for this, but implement "applicable" below.
      return record && ['AVERAGE+DEVIATION', 'MEDIAN+DEVIATION'].indexOf(record.thresholdFormula) !== -1
    }
    //applicable: applicableIf('intervalFormula', v => ['AVERAGE+DEVIATION', 'MEDIAN+DEVIATION'].indexOf(v) !== -1)
  }
})

export const Input = {
  Order: ['personID', 'timestamp'],
  OrderLine: ['orderID', 'productID']
}

export const Output = ({model} = {}) => new SimpleSchema({
  'product': {
    type: model ? model('Product').type : ProductModel,
    label: 'Product',
    table: 'Producten'
  },
  'details': {
    type: Object,
    label: 'Seizoens- of momentgebonden attributen'
  },
  'details.seasonalWeeks': [SimpleSchema.Integer]
})

export const Preview = new SimpleSchema({
  'name': {
    type: String,
    label: 'Productnaam',
    render: renderProductName
  },
  'seasonalWeeks': {
    type: String,
    label: 'Typische aankoopmomenten (weeknummers)',
    render (record) {
      return record.details.seasonalWeeks.join(', ')
    }
  }
})

export const Request = new SimpleSchema({
  'person': {
    type: String,
    label: 'Persoon',
    description: 'De persoon voor wie de suggesties opgevraagd worden. Producten die al door deze persoon zijn gekocht tijdens het seizoens- of momentgebonden moment, worden niet in de lijst getoond.',
    optional: true
  },
  'product': {
    type: String,
    label: 'Product',
    description: 'Het product op basis waarvan suggesties worden opgevraagd. Alleen voor dit product worden de gegevens opgevraagd.',
    optional: true
  },
  'date': {
    type: Date,
    label: 'Datum',
    description: 'De datum waarvoor de voorspelling gemaakt moet worden. Indien leeg wordt ‘vandaag’ gebruikt.',
    optional: true
  },
  'week': {
    type: SimpleSchema.Integer,
    label: 'Weeknummer',
    description: 'Het weeknummer (tussen 1 en 53) waarnaar gekeken moet worden voor seizoens- of momentgebonden aankoopmomenten van producten. Indien leeg wordt de huidige week gebruikt.',
    min: 1,
    max: 53,
    optional: true
  },
  'includeProductPromotions': {
    type: Boolean,
    label: 'Productpromoties opzoeken',
    description: 'Indien het product in een productpromotie gevonden wordt, deze ook teruggeven in het resultaat?',
    optional: true,
    options: {
      'Ja': 'true',
      'Nee': 'false'
    }
  },
  'filter': {
    type: Array,
    label: 'Filters',
    optional: true
  },
  'filter.$': {
    type: String
  },
  'limit': {
    type: SimpleSchema.Integer,
    label: 'Limiet',
    description: 'Maximaal aantal suggesties dat wordt getoond.',
    min: 1,
    optional: true
  }/*,
  'orderBy': OrderBy.arraySchema({
    defaultValue: [{ field: 'product.name', order: 'asc' }]
  }),
  'orderBy.$': OrderBy.elementSchema({
    indexFields: Output
  })*/
})

export const RequestConfiguration = new SimpleSchema({
  'maxLimit': {
    type: SimpleSchema.Integer,
    label: 'Limiet',
    description: 'Maximaal aantal suggesties in een enkele API-response',
    min: 1,
    defaultValue: 50
  }
})
