import Vue from 'vue'
import App from './App.vue'
import Router from './router'
import 'whatwg-fetch'

// Disable production tip
Vue.config.productionTip = false

// Components: partials
import Button from '@/components/Partials/Button'
Vue.component('btn', Button)
import Icon from '@/components/Partials/Icon'
Vue.component('icon', Icon)
import Block from '@/components/Partials/Block'
Vue.component('block', Block)

// Filters
import moment from 'moment'
import 'moment/locale/nl'
moment.locale('nl')
Vue.filter('date', (value, format) => moment(value ? value : []).format(format))

import numeral from 'numeral'
import 'numeral/locales/nl-nl'
numeral.locale('nl-nl')
Vue.filter('numeral', (value, format = '0,0') => numeral(value).format(format))
Vue.filter('pricing', (value) => numeral(value / 100).format('0,0.00'))

// Plugins
import VueSecurity from '@qiri/vue-security'
Vue.use(VueSecurity)

import VueRPC from '@qiri/vue-rpc'
Vue.use(VueRPC)

import VueForm from '@qiri/vue-form'
Vue.use(VueForm)

import VueQuill from '@qiri/vue-quill'
Vue.use(VueQuill)

new Vue({
  el: '#app',
  router: Router,
  render: h => h(App),
})
