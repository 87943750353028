// Pages
import Overview from './components/Pages/Overview'
import Form from './components/Pages/Form'

export default {
    'api-script-snippets.snippets.overview': {
        path: '/api/script-snippets',
        component: Overview,
        meta: {
            audience: 'actor:/env/*/api-script-snippets/Snippet/list',
            overview: {
                mode: 'overview'
            }
        }
    },
    'api-script-snippets.snippets.add': {
        path: '/api/script-snippets/add',
        component: Form,
        meta: {
            audience: 'actor:/env/*/api-script-snippets/Snippet',
            title: 'API-script snippet toevoegen',
            form: {
                mode: 'create'
            }
        }
    },
    'api-script-snippets.snippets.update': {
        path: '/api/script-snippets/:snippetID/edit',
        component: Form,
        meta: {
            audience: 'actor:/env/*/api-script-snippets/Snippet',
            title: 'API-script snippet aanpassen',
            form: {
                mode: 'edit'
            }
        }
    }
}
