// Pages
import DocumentsOverview from './components/Overview'
import DocumentsForm from './components/Form'

export default {
    'documentation.overview': {
        path: '/documentation',
        component: DocumentsOverview,
        meta: {
            audience: 'actor:/documentation/Document/list'
        }
    },
    'documentation.add': {
        path: '/documentation/add',
        component: DocumentsForm,
        meta: {
            audience: 'actor:/documentation/Document/add',
            title: 'Documentatie toevoegen',
            form: {
                mode: 'create'
            }
        }
    },
    'documentation.update': {
        path: '/documentation/:documentID/edit',
        component: DocumentsForm,
        meta: {
            audience: 'actor:/documentation/Document/update',
            title: 'Documentatie wijzigen',
            form: {
                mode: 'edit'
            }
        }
    }
}
