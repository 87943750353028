<template>
    <transition name="modal">
        <div class="modal" :id="id" v-show="state" @mousedown.stop="toggle(false)">
            <div class="body grid" @mousedown.stop>
                <div class="column size--100">
                     <div class="box">
                        <div class="top">
                            <h2 v-if="title">{{ title }}</h2>
                            <a class="close" href="#" @click.prevent="toggle(false)"><icon name="close"></icon></a>
                        </div>
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'modal',
        props: {
            title: {
                type: String
            },
            name: {
                required: true,
                type: String
            }
        },
        data () {
            return {
                state: false,
                params: {},
                id: 'modal-' + this.name
            }
        },
        mounted () {
            this.$root.$on('modal', (name, state, params) => {
                if (this.name === name) {
                    this.toggle(state, params)
                }
            })
        },
        methods: {
            toggle (state, params) {
                this.params = params
                this.state = state
                if (state === false) {
                    this.$emit('close')
                }
            }
        }
    }
</script>
