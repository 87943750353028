import isPlainObject from 'lodash/isPlainObject'
import {pull} from '@qiri/stream/pull'
import {next} from '@qiri/stream/sources/next'
import {abortable} from '@qiri/stream/operators/abortable'

/**
 * @todo
 */
export default function promisifyObject (client, component, obj) {
  if (typeof obj === 'function') {
    return promisifyFunction(client, component, obj)
  } else if (isPlainObject(obj)) {
    let clone = {}
    for (const property in obj) {
      clone[property] = promisifyObject(client, component, obj[property])
    }
    if (obj.$type === 'Cursor') {
      clone = promisifyCursor(client, component, clone)
    }
    return clone
  } else {
    return obj
  }
}

/**
 * @private
 */
function promisifyFunction (client, component, fn) {
  return (...args) => new Promise((resolve, reject) => {
    fn(...args, (err, result) => {
      if (err) {
        reject(err)
      } else {
        resolve(promisifyObject(client, component, result))
      }
    })
  })
}

/**
 * @private
 */
function promisifyCursor (client, component, remoteCursor) {
  let ended = false
  const abortHandle = abortable()
  const cleanup = () => {
    //console.log(`Cursor "${remoteCursor.$label}": Cleanup`)
    client.removeListener('close', cleanup)
    component.$off('hook:beforeDestroy', cleanup)
    remoteCursor.close().catch(err => console.error(`Failed to close remote cursor: ${err.message}`))
    abortHandle.abort()
  }
  const cursor = pull(
    next(
      async () => {
        if (ended) {
          return
        }
        //console.log(`Cursor "${remoteCursor.$label}": Before request more`)
        const item = await remoteCursor.next(64)
        //console.log(`Cursor "${remoteCursor.$label}": After request more`, item)
        if (item.done) {
          ended = true
          return item.value
        } else {
          return item.value
        }
      },
      cleanup
    ),
    abortHandle
  )
  client.on('close', cleanup)
  component.$on('hook:beforeDestroy', cleanup)

  return cursor
}
