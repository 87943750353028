<template>
  <section v-if="loading" class="padding">
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h1>API-log logregels</h1></template>
          <template slot="content">
            <p>Bezig met laden...</p>
          </template>
        </block>
      </div>
    </div>
  </section>
  <section v-else class="padding">
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h1>API-log logregels</h1></template>
          <template slot="content">
            <p>Hier worden de laatste 50 API-requests getoond die gemaakt zijn met een API-token.</p>
          </template>
        </block>
      </div>
    </div>
    <div class="grid">
      <div class="column size--100">
        <block :table="true">
          <template slot="content">
            <table class="table">
              <thead>
                <tr>
                  <th>Omschrijving gebruik en gebruiker(s)</th>
                  <th>API-request</th>
                  <th>Timestamp API-request</th>
                  <th>API-response</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="entry of entries" :key="entry.eventID">
                  <td v-if="entry.description">{{ entry.description }}</td>
                  <td v-else><i>Ongeldige API-token</i></td>
                  <td>
                    <btn icon="eye" title="Tonen" class="icon" @click.prevent="view(entry, 'api-request')"></btn>
                    {{ entry.method }} {{ entry.url }}
                  </td>
                  <td>{{ formatDate(entry.requestTime) }}</td>
                  <td>
                    <btn icon="eye" title="Tonen" class="icon" @click.prevent="view(entry, 'api-response')"></btn>
                    {{ entry.statusCode }}
                    ({{ getStatusText(entry.statusCode) }})
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </block>
      </div>
    </div>
    <modal name="view-api-request" title="API-request">
      <template v-if="modalRecord && modalRecord.request && modalRecord.request.params">
        <p><strong>Parameters</strong></p>
        <pre>{{ formatJSON(modalRecord.request.params) }}</pre>
      </template>
      <template v-if="modalRecord && modalRecord.request && modalRecord.request.body">
        <p><strong>Body</strong></p>
        <pre>{{ formatJSON(modalRecord.request.body) }}</pre>
      </template>
      <button type="button" @click="$root.$emit('modal', 'view-api-request', false)">Sluiten</button>
    </modal>
    <modal name="view-api-response" title="API-response">
      <pre v-if="modalRecord && modalRecord.response && modalRecord.response.body">{{ formatJSON(modalRecord.response.body) }}</pre>
      <button type="button" @click="$root.$emit('modal', 'view-api-response', false)">Sluiten</button>
    </modal>
  </section>
</template>

<script>
  import moment from 'moment'
  import stringify from 'csv-stringify'
  import Modal from '@/components/Partials/Modal'
  import {pull} from '@qiri/stream/pull'
  import {fromStream} from '@qiri/stream/interop/fromStream'
  import {iterate} from '@qiri/stream/sources/iterate'
  import {map} from '@qiri/stream/operators/map'
  import {collect} from '@qiri/stream/sinks/collect'

  export default {
    name: 'page-api-log-Overview',
    components: {
      Modal
    },
    data () {
      return {
        entries: [],
        loading: true,
        downloading: false,
        modalRecord: null
      }
    },
    async mounted () {
      await this.refresh()
      this._refreshHandle = setInterval(() => this.refresh(), 1000 * 60)
      this.loading = false
    },
    beforeDestroy () {
      clearInterval(this._refreshHandle)
    },
    computed: {

    },
    methods: {
      async refresh () {
        this.entries = await this.$model.fetch(`/env/${this.$environment}/api-log/APILog/list`)
      },
      formatDate (d) {
        return d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : ''
      },
      formatJSON (obj) {
        return JSON.stringify(obj, null, '  ')
      },
      view (entry, type) {
        this.modalRecord = entry
        this.$root.$emit('modal', `view-${type}`, true)
      },
      getStatusText (statusCode) {
        return STATUS_CODES[statusCode]
      }
    }
  }

  const STATUS_CODES = {
    100: 'Continue',
    101: 'Switching Protocols',
    102: 'Processing',
    200: 'OK',
    201: 'Created',
    202: 'Accepted',
    203: 'Non-Authoritative Information',
    204: 'No Content',
    205: 'Reset Content',
    206: 'Partial Content',
    207: 'Multi-Status',
    208: 'Already Reported',
    226: 'IM Used',
    300: 'Multiple Choices',
    301: 'Moved Permanently',
    302: 'Found',
    303: 'See Other',
    304: 'Not Modified',
    305: 'Use Proxy',
    307: 'Temporary Redirect',
    308: 'Permanent Redirect',
    400: 'Bad Request',
    401: 'Unauthorized',
    402: 'Payment Required',
    403: 'Forbidden',
    404: 'Not Found',
    405: 'Method Not Allowed',
    406: 'Not Acceptable',
    407: 'Proxy Authentication Required',
    408: 'Request Timeout',
    409: 'Conflict',
    410: 'Gone',
    411: 'Length Required',
    412: 'Precondition Failed',
    413: 'Payload Too Large',
    414: 'URI Too Long',
    415: 'Unsupported Media Type',
    416: 'Range Not Satisfiable',
    417: 'Expectation Failed',
    418: 'I\'m a teapot',
    421: 'Misdirected Request',
    422: 'Unprocessable Entity',
    423: 'Locked',
    424: 'Failed Dependency',
    425: 'Unordered Collection',
    426: 'Upgrade Required',
    428: 'Precondition Required',
    429: 'Too Many Requests',
    431: 'Request Header Fields Too Large',
    451: 'Unavailable For Legal Reasons',
    500: 'Internal Server Error',
    501: 'Not Implemented',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
    504: 'Gateway Timeout',
    505: 'HTTP Version Not Supported',
    506: 'Variant Also Negotiates',
    507: 'Insufficient Storage',
    508: 'Loop Detected',
    509: 'Bandwidth Limit Exceeded',
    510: 'Not Extended',
    511: 'Network Authentication Required'
  }
</script>
