import Local from './Local'
import FTP from './FTP'
import HTTP from './HTTP'
import SFTP from './SFTP'
import RemoteServer from './RemoteServer'

export {
  Local,
  FTP,
  HTTP,
  SFTP,
  RemoteServer
}
