import SimpleSchema from '@qiri/simpl-schema'

import Document from './Document'

export {
  Document
}

export default new SimpleSchema({
  Document: {
    type: Document,
    label: 'Document',
    table: 'Documentatie'
  }
})
