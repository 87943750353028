import SimpleSchema from '@qiri/simpl-schema'
import Product from './Product'

/**
 * @todo
 */
export default new SimpleSchema({
  productPromotionID: {
    type: String,
    label: 'Qiri productpromotie ID',
    description: 'De unieke waarde waarmee de productpromotie geïdentificeerd kan worden.',
    primaryKey: true
  },
  products: {
    type: Array,
    label: 'Qiri product ID\'s',
    description: 'De producten waar de productpromotie op van toepassing is.'
  },
  'products.$': {
    type: String,
    label: 'Qiri product ID',
    description: 'Het product waar de productpromotie op van toepassing is.',
    foreignKey: 'Product'
  },
  startDate: {
    type: Date,
    label: 'Beschikbaar vanaf',
    description: 'Datum en tijd vanaf wanneer de productpromotie geldig is.'
  },
  endDate: {
    type: Date,
    label: 'Beschikbaar tot en met',
    description: 'Datum en tijd tot wanneer de productpromotie geldig is.'
  },
  attributes: {
    type: Product.omit('productID', 'abstractProduct', 'abstractProductGoogle', 'classification', 'nutritionalValue', 'availableFrom', 'availableUntil'),
    label: 'Product attributen',
    description: 'De attributen welke geldig zijn voor de producten tijdens de productpromotie.'
  }
})
