import SimpleSchema from '@qiri/simpl-schema'

import Address from './Address'
import Owner from './Owner'
import Customer from './Customer'
import IPAddress from './IPAddress'
import Module from './Module'
import ModuleSettings from './ModuleSettings'
import Pricing from './Pricing'
import User from './User'
import UserProfile from './UserProfile'
import APIToken from './APIToken'
import APIReport from './APIReport'
import Environment from './Environment'
import UsageOverview from './UsageOverview'

export {
  Address,
  Owner,
  Customer,
  IPAddress,
  Module,
  ModuleSettings,
  Pricing,
  User,
  UserProfile,
  APIToken,
  APIReport,
  Environment,
  UsageOverview
}

export default new SimpleSchema({
  Module: {
    type: Module,
    label: 'Module',
    table: 'Modules'
  },
  Owner: {
    type: Owner,
    label: 'Eigenaar',
    table: 'Eigenaar'
  },
  Customer: {
    type: Customer,
    label: 'Klant',
    table: 'Klanten'
  },
  User: {
    type: User,
    label: 'Gebruiker',
    table: 'Gebruikers'
  },
  UserProfile: {
    type: UserProfile,
    label: 'Gebruiker profiel',
    table: 'Gebruiker profielen'
  },
  Environment: {
    type: Environment,
    label: 'Omgeving',
    table: 'Omgevingen'
  }
})
