import SimpleSchema from '@qiri/simpl-schema'

export const Request = new SimpleSchema({
  'date': {
    type: Date
  },
  'method': {
    type: String
  },
  'uri': {
    type: String
  },
  'headers': {
    type: Object,
    blackbox: true,
    optional: true
  },
  'body': {
    type: String,
    optional: true
  }
})

export const Response = new SimpleSchema({
  'date': {
    type: Date
  },
  'statusCode': {
    type: SimpleSchema.Integer
  },
  'headers': {
    type: Object,
    blackbox: true,
    optional: true
  },
  'body': {
    type: String,
    optional: true
  }
})

export default new SimpleSchema({
  'eventID': {
    type: String,
    label: 'Event ID',
    description: 'De unieke waarde waarmee het event geïdentificeerd kan worden',
    primaryKey: true
  },
  'audience': {
    type: String
  },
  'token': {
    type: String
  },
  'request': {
    type: Request
  },
  'response': {
    type: Response,
    optional: true
  }
})
