<template>
    <section v-if="loading" class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>Suggesties configureren: {{ algorithm.name }}</h1></template>
                    <template slot="content">
                        <p v-if="loading">Bezig met laden...</p>
                    </template>
                </block>
            </div>
        </div>
    </section>
    <vue-form v-else tag="section" :state="form" ref="form" :schema="schema" v-model="configuration" class="padding" @submit="doSubmit()">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>Suggesties configureren: {{ algorithm.name }}</h1></template>
                    <template slot="content">
                        <vue-form-field field="suggestions.description" type="quill"></vue-form-field>
                        <label>Configuratieparameters:</label>
                        <div class="grid form-group" v-for="field of schemaFields(schema, 'suggestions.configuration')" v-if="field.readOnly" :key="field.name">
                            <div class="column size--100">
                                <label>
                                    {{ field.label }}: <em>{{ field.description }}</em>
                                </label>
                            </div>
                        </div>
                        <vue-form-field v-for="field of schemaFields(schema, 'suggestions.configuration')" v-if="!field.readOnly && isApplicable(field)" :key="field.name" :field="field.name">
                            {{ field.label }}: <em>{{ field.description }}</em>
                        </vue-form-field>
                        <template v-if="false && hasSchemaField(schema, 'suggestions.configuration.orderBy')">
                            <label>Sortering op basis van:</label>
                            <order-by-table field="suggestions.configuration.orderBy"></order-by-table>
                        </template>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="content">
                        <button type="button" class="full" title="Opslaan" :disabled="form.invalid || saving" @click.prevent="$refs.form.submit()">
                            <template v-if="saving"><icon name="refresh" :spin="true" /></template>
                            <template v-else>Configuratie opslaan</template>
                        </button>
                        <ul class="errors" v-if="formErrors.length > 0">
                            <li v-for="error of formErrors">
                                <icon name="exclamation-circle"></icon> {{ error }}
                            </li>
                        </ul>
                    </template>
                </block>
            </div>
        </div>
    </vue-form>
</template>

<script>
    import get from 'lodash/get'
    import OrderByTable from '@/components/Partials/OrderByTable'
    import SimpleSchema from '@qiri/simpl-schema'
    import {Algorithm, getAlgorithm} from '@qiri/models/algorithm'

    export default {
        name: 'page-suggestions-Configure',
        components: {
            OrderByTable
        },
        data () {
            return {
                form: {},
                configuration: null,
                loading: true,
                saving: false
            }
        },
        async mounted () {
            const view = await this.$api.call(`${this.uiActor}/view`, {
                route: {
                    params: this.$route.params,
                    query: this.$route.query
                }
            })
            this.configuration = view.data

            // Finished loading.
            this.loading = false
        },
        computed: {
            routePrefix () {
                return this.$route.meta.routePrefix ? `${this.$route.meta.routePrefix}.` : ''
            },
            uiActor () {
                return this.$route.meta.uiActor || `/env/${this.$environment}/suggestions/ui/Configure`
            },
            algorithmID () {
                return this.$route.params.algorithmID
            },
            algorithm () {
                return getAlgorithm(this.algorithmID)
            },
            schema () {
                const schema = Algorithm.getSchemaFor({
                    algorithmID: this.algorithmID
                })
                return schema.pick('suggestions')
            },
            formErrors () {
                let messages = []
                if (this.form.errors && this.form.errors.length > 0) {
                    for (const error of this.form.errors) {
                        let message = `${error.message}.`
                        // Don't show duplicate messages.
                        const duplicateMessage = messages.find(x => x === message)
                        if (!duplicateMessage) {
                            messages.push(message)
                        }
                    }
                }
                return messages
            }
        },
        methods: {
            hasSchemaField (schema, schemaProperty) {
                return schema.allowsKey(schemaProperty)
            },
            hasSchemaFields (schema, schemaProperty) {
                return schema.objectKeys(schemaProperty).length > 0
            },
            schemaFields (schema, schemaProperty) {
                if (!schema) {
                    return []
                }
                let prefix = ''
                if (schemaProperty) {
                    schema = schema.schema(schemaProperty).type.singleType
                    prefix = `${schemaProperty}.`
                }
                let fields = []
                for (const [propertyName, property] of Object.entries(schema.schema())) {
                    const readOnly = schemaProperty === 'suggestions.configuration' && ['person', 'product', 'productPromotion'].indexOf(propertyName) >= 0
                    fields.push({
                        name: `${prefix}${propertyName}`,
                        parent: prefix ? prefix.substr(0, prefix.length - 1) : null,
                        label: property.label,
                        description: property.description,
                        optional: property.optional,
                        readOnly,
                        isComplex: property.type.singleType === Array || SimpleSchema.isSimpleSchema(property.type.singleType),
                        render: property.render
                    })
                }
                return fields
            },
            isApplicable (field) {
                if (field.isComplex) {
                    return false
                } else if (field.render) {
                    const value = get(this.configuration, field.name)
                    const record = field.parent ? get(this.configuration, field.parent) : this.configuration
                    return field.render(value, record)
                } else {
                    return true
                }
            },
            async doSubmit () {
                this.saving = true
                await this.$api.dispatch(`${this.uiActor}/submit`, {
                    route: {
                        params: this.$route.params,
                        query: this.$route.query
                    },
                    data: this.configuration
                })
                this.$router.push({ name: `${this.routePrefix}suggestions.overview`, query: this.$route.query })
            }
        }
    }
</script>

<style scoped lang="scss">
    label em {
        font-style: normal;
        font-weight: normal;
    }
</style>
