import bytewise from 'bytewise'
import SimpleSchema from '@qiri/simpl-schema'

import Person from './Person'
import Store from './Store'
import Product from './Product'
import ProductCategory from './ProductCategory'
import ProductPromotion from './ProductPromotion'
import Order from './Order'
import OrderLine from './OrderLine'
import Event from './Event'
import LogEntry from './LogEntry'
import Export from './Export'
import ExportSchedule from './ExportSchedule'
import ExportTask from './ExportTask'
import Feed from './Feed'
import FeedLog from './FeedLog'
import QueryImport from './QueryImport'
import FeedMappingHistory from './FeedMappingHistory'
import CustomField from './CustomField'
import RemoteServer from './RemoteServer'
import StatusMessage from './StatusMessage'
import StatusConfig from './StatusConfig'
import DataRetention from './DataRetention'

const MODELS = ['Person', 'Store', 'Product', 'ProductCategory', 'ProductPromotion', 'Order', 'OrderLine', 'Event']
const MODELS_TO_INDEX = ['Person', 'Store', 'Product', 'ProductCategory', 'ProductPromotion', 'Order']

export {
  MODELS,
  MODELS_TO_INDEX,
  Person,
  Store,
  Product,
  ProductCategory,
  ProductPromotion,
  Order,
  OrderLine,
  Event,
  LogEntry,
  Export,
  ExportSchedule,
  ExportTask,
  Feed,
  FeedLog,
  FeedMappingHistory,
  QueryImport,
  CustomField,
  RemoteServer,
  StatusMessage,
  StatusConfig,
  DataRetention
}

export default new SimpleSchema({
  Person: {
    type: Person,
    label: 'Persoon',
    table: 'Personen'
  },
  Store: {
    type: Store,
    label: 'Winkel',
    table: 'Winkels'
  },
  Product: {
    type: Product,
    label: 'Product',
    table: 'Producten'
  },
  ProductCategory: {
    type: ProductCategory,
    algorithm: 'ProductCategories',
    label: 'Product categorie',
    table: 'Product categorieën'
  },
  ProductPromotion: {
    type: ProductPromotion,
    algorithm: 'ProductPromotions',
    label: 'Productpromotie',
    table: 'Productpromoties'
  },
  Order: {
    type: Order,
    algorithm: 'Orders',
    label: 'Order',
    table: 'Orders'
  },
  OrderLine: {
    type: OrderLine,
    algorithm: 'OrderLines',
    label: 'Orderregel',
    table: 'Orderregels'
  },
  Event: {
    type: Event,
    algorithm: 'Events',
    label: 'Event',
    table: 'Events'
  },
  LogEntry: {
    type: LogEntry,
    label: 'Logregel',
    table: 'Logboek'
  },
  Feed: {
    type: Feed,
    label: 'Data-import',
    table: 'Data-imports'
  },
  FeedLog: {
    type: FeedLog,
    label: 'Data-import logboek',
    table: 'Data-import logboek'
  },
  QueryImport: {
    type: QueryImport,
    label: 'Query-import'
  },
  CustomField: {
    type: CustomField,
    label: 'Gebruikersveld',
    table: 'Gebruikersvelden'
  },
  RemoteServer: {
    type: RemoteServer,
    label: 'Externe server',
    table: 'Externe servers'
  },
  StatusMessage: {
    type: StatusMessage,
    label: 'Status bericht',
    table: 'Status berichten'
  },
  StatusConfig: {
    type: StatusConfig,
    label: 'Status meldingen configuratie',
    table: 'Status meldingen configuratie'
  }
})
