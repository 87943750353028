<template>
    <div class="grid">
        <div class="column size--100 align--center environment--name" @click="toggleEnvironmentsMenu()">
            <h1 :style="'color:' + env.theme + ';'" :title="env.name">{{ env.name }}</h1>
        </div>
        <div class="column size--100 zero--padding">
            <nav>
                <ul v-if="showEnvironmentsMenu">
                    <li>
                        <span>
                            <icon name="globe"></icon>
                            Kies de omgeving van klant:
                        </span>
                    </li>
                    <li>
                        <a href="#" @click.prevent="switchEnvironment(null)">
                            <icon name="plus"></icon>
                            Qiri
                        </a>
                    </li>
                    <li v-for="environment in environments" :key="environment.environmentID">
                        <a href="#" @click.prevent="switchEnvironment(environment)">
                            <icon name="plus"></icon>
                            {{ environment.name }}
                        </a>
                    </li>
                </ul>
                <ul v-else>
                    <li v-for="(item, index) in nav" :key="index">
                        <span v-if="item.nav" @click="toggle(item)" class="has--sub" :class="{'sub--open': isOpen(item)}">
                            <icon v-if="item.icon" :name="item.icon"></icon>
                            <img v-if="item.avatar" class="avatar" :src="item.avatar" />
                            {{ item.label }}
                            <span v-if="item.sublabel">{{ item.sublabel }}</span>
                        </span>
                        <router-link v-else :to="{ name: item.route, query: $route.query }">
                            <icon v-if="item.icon" :name="item.icon"></icon>
                            <img v-if="item.avatar" class="avatar" :src="item.avatar" />
                            {{ item.label }}
                            <span v-if="item.sublabel">{{ item.sublabel }}</span>
                        </router-link>
                        <ul class="sub" v-show="isOpen(item)">
                            <li v-for="(item, index) in item.nav" :key="index">
                                <router-link :to="{ name: item.route, query: $route.query }">
                                    <icon v-if="item.icon" :name="item.icon"></icon>
                                    {{ item.label }}
                                </router-link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
    import extend from 'lodash/extend'
    import {getSystemRoles} from '@qiri/models/environment/Module'
    import {checkAudience} from '@qiri/jwt/util'

    const SystemRoles = getSystemRoles()

    export default {
        name: 'navigation',
        data () {
            return {
                showEnvironmentsMenu: false,
                environments: [],
                states: {}
            }
        },
        mounted () {
            this.refreshEnvironments()

            // Restore previous environment from local storage.
            const previousEnvironmentID = this.$userProfile.environment
            if (previousEnvironmentID && this.$route.query.environment !== `${previousEnvironmentID}`) {
                this.$router.replace({
                    query: {
                        ...this.$route.query,
                        environment: previousEnvironmentID
                    }
                })
                this.$environment = previousEnvironmentID
            }

            // Keep environment from query in sync.
            this.$watch(
                '$route.query.environment',
                (environment) => {
                    const environmentID = parseInt(environment, 10)
                    if (isNaN(environmentID)) {
                        this.$environment = null
                        this.$set(this.$userProfile, 'environment', null)
                    } else {
                        this.$environment = environmentID
                        this.$set(this.$userProfile, 'environment', environmentID)
                    }
                },
                { immediate: true }
            )
        },
        computed: {
            nav () {
                return this.filterByAccess([
                    {
                        avatar: this.user.avatar,
                        label: this.user.name,
                        sublabel: this.user.role,
                        name: 'profile',
                        role: ['superadministrator', 'administrator', 'user'],
                        nav: [
                            {
                                icon: 'lock',
                                route: 'auth.change-password',
                                label: 'Wachtwoord wijzigen'
                            },
                            {
                                icon: 'sign-out',
                                route: 'auth.logout',
                                label: 'Uitloggen'
                            }
                      ]
                    },
                    {
                        icon: 'th-large',
                        route: 'dashboard',
                        label: 'Dashboard',
                        role: ['superadministrator', 'administrator', 'user']
                    },
                    {
                        icon: 'book',
                        route: 'documentation.overview',
                        label: 'Documentatie',
                        role: ['superadministrator', 'administrator', 'user']
                    },
                    {
                        icon: 'cog',
                        label: 'Basisinstellingen',
                        name: 'qiri-settings',
                        role: ['superadministrator'],
                        nav: [
                            {
                                icon: 'lock',
                                route: 'qiri-settings.module-access',
                                label: 'Toegang tot modules'
                            },
                            {
                                icon: 'lock',
                                route: 'qiri-settings.algorithm-access',
                                label: 'Toegang tot algoritmes'
                            },
                            {
                                icon: 'calculator',
                                route: 'qiri-settings.algorithms.overview',
                                label: 'Algoritmes configureren'
                            },
                            {
                                icon: 'shopping-cart',
                                route: 'qiri-settings.suggestions.overview',
                                label: 'Suggesties configureren'
                            },
                            {
                                icon: 'eur',
                                route: 'qiri-settings.module-pricing',
                                label: 'Licenties voor gebruik'
                            },
                            {
                                icon: 'eur',
                                route: 'qiri-settings.data-retention',
                                label: 'Data bewaartermijn'
                            }
                        ]
                    },
                    {
                        icon: 'user',
                        label: 'Eigenaar',
                        name: 'owner',
                        role: ['superadministrator'],
                        nav: [
                            {
                                icon: 'table',
                                route: 'owner.overview',
                                label: 'Overzicht'
                            }
                        ]
                    },
                    {
                        icon: 'group',
                        label: 'Klanten',
                        name: 'customer',
                        role: ['superadministrator', 'administrator'],
                        nav: [
                            {
                                icon: 'table',
                                route: 'customers.overview',
                                label: 'Overzicht'
                            }
                        ]
                    },
                    {
                        icon: 'database',
                        label: 'Data',
                        name: 'data',
                        role: ['user'],
                        nav: [
                            {
                                icon: 'table',
                                route: 'data.model',
                                label: 'Tabellen & velden'
                            },
                            {
                                icon: 'cloud-upload',
                                route: 'data.feeds',
                                label: 'Data-imports'
                            },
                            {
                                icon: 'file-text-o',
                                route: 'data.query-imports',
                                label: 'Query-imports'
                            },
                            {
                                icon: 'cloud-download',
                                route: 'data.exports',
                                label: 'Data-exports'
                            },
                            {
                                icon: 'server',
                                route: 'data.remoteservers.overview',
                                label: 'Externe servers'
                            },
                            {
                                icon: 'envelope-o',
                                route: 'data.status',
                                label: 'Foutmeldingen'
                            }
                        ]
                    },
                    {
                        icon: 'calculator',
                        label: 'Algoritmes',
                        name: 'algorithms',
                        role: ['user'],
                        nav: [
                            {
                                icon: 'cog',
                                route: 'algorithms.overview',
                                label: 'Algoritmes configureren'
                            }
                        ]
                    },
                    {
                        icon: 'shopping-cart',
                        label: 'Suggesties',
                        name: 'suggestions',
                        role: ['user'],
                        nav: [
                            {
                                icon: 'cog',
                                route: 'suggestions.overview',
                                label: 'Suggesties configureren'
                            },
                            {
                                icon: 'comment',
                                route: 'suggestions.query',
                                label: 'Suggesties bekijken'
                            }
                        ]
                    },
                    {
                        icon: 'desktop',
                        label: 'Monitoring',
                        name: 'monitoring',
                        role: ['user'],
                        nav: [
                            {
                                icon: 'line-chart',
                                route: 'monitoring.value-development.between-customer',
                                label: 'Klantwaarde'
                            }/*,
                            {
                                icon: 'line-chart',
                                route: 'monitoring.value-development.within-customer',
                                label: 'Within customer'
                            }*/
                        ]
                    },
                    {
                        icon: 'code-fork',
                        label: 'API',
                        name: 'api',
                        role: ['user'],
                        nav: [
                            {
                                icon: 'lock',
                                route: 'api.tokens.overview',
                                label: 'API-tokens'
                            },
                            {
                                icon: 'code',
                                route: 'api-template.scripts.overview',
                                label: 'API-scripts'
                            },
                            {
                                icon: 'tags',
                                route: 'api-script-snippets.snippets.overview',
                                label: 'API-script snippets'
                            },
                            {
                                icon: 'bar-chart',
                                route: 'api-reporting.overview',
                                label: 'API-log rapportage'
                            },
                            {
                                icon: 'book',
                                route: 'api-log.overview',
                                label: 'API-log logregels'
                            },
                            {
                                icon: 'cloud-download',
                                route: 'api-log.exports',
                                label: 'API-log exports'
                            },
                            {
                                icon: 'server',
                                route: 'api-log.remoteservers.overview',
                                label: 'Externe servers'
                            }
                        ]
                    },
                    {
                        icon: 'bar-chart',
                        label: 'Verbruik',
                        name: 'usage',
                        role: ['user'],
                        nav: [
                            {
                                icon: 'eur',
                                route: 'usage.overview',
                                label: 'Vergoeding licentie'
                            }
                        ]
                    }
                ])
            },
            user () {
                return {
                    avatar: '',
                    name: this.$token.name,
                    role: SystemRoles[this.$token.role] || this.$token.role
                }
            },
            env () {
                let current = this.$token.env
                if (!current && this.$environment) {
                    current = this.environments.find(x => x.environmentID === this.$environment)
                }
                if (current) {
                    return {
                        name: current.name,
                        theme: current.theme || '#ee8008'
                    }
                } else {
                    return {
                        name: 'Qiri',
                        theme: '#ee8008'
                    }
                }
            },
            effectiveRole () {
                if (this.$token.env || !this.$environment) {
                    return this.$token.role
                } else {
                    return 'user'
                }
            }
        },
        methods: {
            refreshEnvironments () {
                if (this.$token.role === 'superadministrator' || this.$token.role === 'administrator') {
                    this.$model.fetch('/env/list').then(environments => {
                        this.environments = environments
                    })
                }
            },
            toggleEnvironmentsMenu () {
                if (this.environments.length === 0) {
                    this.showEnvironmentsMenu = false
                } else {
                    this.showEnvironmentsMenu = !this.showEnvironmentsMenu
                    if (this.showEnvironmentsMenu) {
                        this.refreshEnvironments()
                    }
                }
            },
            switchEnvironment (environment) {
                this.$router.push({
                    name: 'dashboard',
                    query: {
                        ...this.$route.query,
                        environment: environment ? environment.environmentID : undefined
                    }
                })
                this.showEnvironmentsMenu = false
            },
            isOpen (item) {
                if (item && item.name) {
                    return this.states[item.name] === true
                }
            },
            toggle (item) {
                if (item && item.name) {
                    for (const state in this.states) {
                        if (state !== item.name) {
                            this.states[state] = false
                        }
                    }
                    this.$set(this.states, item.name, this.states[item.name] !== true)
                }
            },
            filterByAccess (nav) {
                const role = this.effectiveRole

                let result = []
                for (const item of nav) {
                    if (item.role && item.role.indexOf(role) === -1) {
                        continue
                    } else if (item.route) {
                        const {route} = this.$router.resolve({ name: item.route })
                        const meta = route && route.meta || {}
                        const routeAudience = meta.audience ? Array.isArray(meta.audience) ? meta.audience : [meta.audience] : []
                        // Skip if route requires an active environment, but none is active.
                        if (routeAudience.some(aud => aud.indexOf('actor:/env/*') === 0) && !this.$environment) {
                            continue
                        }
                        // Include item if route is public or current token covers the necessary audience.
                        else if (meta.public === true || !meta.audience || checkAudience(this.$token.aud, meta.audience)) {
                            result.push(item)
                        }
                    } else if (item.nav) {
                        const filtered = this.filterByAccess(item.nav)
                        if (filtered.length > 0) {
                            result.push(extend({}, item, { nav: filtered }))
                        }
                    } else {
                        result.push(item)
                    }
                }
                return result
            }
        }
    }
</script>
