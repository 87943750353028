<template>
    <table class="table">
        <thead>
            <tr>
                <th>Tabel</th>
                <th>Maximale leeftijd (in dagen)</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item of records" :key="item.model">
                <td>{{ getModelTable(item.model) }}</td>
                <td><vue-form-field :field="`${item.fieldPrefix}.days`" :bare="true"></vue-form-field></td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    import get from 'lodash/get'
    import SimpleSchema from '@qiri/simpl-schema'
    import {ModuleSettings} from '@qiri/models/environment'
    import {getModelTable} from '@qiri/models/data/util'
    import {VueFormContext} from '@qiri/vue-form/providers'

    export default {
        name: 'partial-data-RetentionTable',
        inject: {
            form: {
              from: VueFormContext
            }
        },
        props: {
            field: String
        },
        computed: {
            records () {
                const settings = get(this.form.value, this.field)
                if (!settings) {
                    return []
                }

                let records = []
                for (const modelName of ['Order', 'OrderLine', 'APIEvent']) {
                    let setting = settings.find(x => x.model === modelName)
                    if (!setting) {
                        setting = {
                            model: modelName,
                            days: null
                        }
                        settings.push(setting)
                    }
                    records.push({
                        fieldPrefix: `${this.field}.${settings.indexOf(setting)}`,
                        model: setting.model,
                        days: setting.days
                    })
                }

                return records
            }
        },
        methods: {
            getModelTable (modelName) {
                if (modelName === 'APIEvent') {
                    return 'API-log logregels'
                } else {
                    return getModelTable(modelName)
                }
            }
        }
    }
</script>
