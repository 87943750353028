import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  orderID: {
    type: String,
    label: 'Qiri order ID',
    description: 'De order waar deze orderregel bij hoort.',
    primaryKey: true,
    foreignKey: 'Order'
  },
  productID: {
    type: String,
    label: 'Qiri product ID',
    description: 'Het aangeschafte product.',
    primaryKey: true,
    foreignKey: 'Product'
  },
  quantity: {
    type: Number,
    label: 'Aantal',
    description: 'Het aantal producten die gekocht zijn.'
  },
  suggestionToken: {
    type: String,
    label: 'Merkteken',
    description: 'Merkteken van de suggestie gevolgd door gebruiker.'
  }
})
