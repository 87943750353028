import {identity} from '../util'
import {filter} from './filter'

export const distinct = (equalsFn = identity) => {
  const seen = new Map()
  return filter(x => {
    const key = equalsFn(x)
    if (!seen.has(key)) {
      seen.set(key, true)
      return true
    } else {
      return false
    }
  })
}
