<template>
    <table class="table">
        <thead>
            <tr>
                <th>Algoritme naam</th>
                <th class="align--center">Gebruik toestaan</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="algorithmSetting of algorithmSettings" :key="algorithmSetting.algorithmID">
                <td>{{ getAlgorithmName(algorithmSetting.algorithmID) }}</td>
                <td class="align--center">
                    <vue-form-field :field="`${field}.${getIndex(algorithmSetting)}.enabled`" :bare="true"></vue-form-field>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    import get from 'lodash/get'
    import SimpleSchema from '@qiri/simpl-schema'
    import {getAlgorithms} from '@qiri/models/algorithm/Algorithm'
    import {VueFormContext} from '@qiri/vue-form/providers'

    export default {
        name: 'partial-algorithms-AccessTable',
        inject: {
            form: {
              from: VueFormContext
            }
        },
        props: {
            field: String,
            algorithms: {
                type: Array,
                default () {
                    return getAlgorithms().map(x => x.algorithmID)
                }
            }
        },
        computed: {
            algorithmSettings () {
                const settings = get(this.form.value, this.field)
                if (!settings) {
                    return []
                }
                
                let result = []
                for (const algorithmID of this.algorithms) {
                    let algorithmSetting = settings.find(x => x.algorithmID === algorithmID)
                    if (!algorithmSetting) {
                        algorithmSetting = {
                            algorithmID,
                            enabled: false
                        }
                        settings.push(algorithmSetting)
                    }
                    result.push(algorithmSetting)
                }
                return result
            }
        },
        methods: {
            getAlgorithmName (algorithmID) {
                const algorithm = getAlgorithms().find(x => x.algorithmID === algorithmID)
                return algorithm ? algorithm.name : ''
            },
            getIndex (algorithmSetting) {
                const settings = get(this.form.value, this.field)
                return settings.indexOf(algorithmSetting)
            }
        }
    }
</script>
