import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  'userID': {
    type: String,
    label: 'Gebruiker ID',
    description: 'De unieke waarde waarmee de gebruiker geïdentificeerd kan worden',
    primaryKey: true
  },
  'data': {
    type: Object,
    blackbox: true,
    label: 'Profiel data',
    description: 'De data in het profiel'
  }
})
