import VueScrollTo from 'vue-scrollto'

export default {
  inserted: function (el, binding) {
    if (!binding.value || binding.value.scrollTo !== false) {
      VueScrollTo.scrollTo(el, 500, { offset: -60 })
    }
    el.focus()
  }
}
