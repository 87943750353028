/**
 * @todo
 */
export const abortable = () => {
  let aborted = false
  let innerAbort
  const abort = (err) => {
    aborted = true
    if (innerAbort) {
      innerAbort(err)
    }
  }
  const $ = (source) => (start, sink) => {
    if (start !== 0) {
      return
    }
    let ended = false
    let talkback
    innerAbort = (err) => {
      if (!ended) {
        ended = true
        talkback && talkback(2, err)
        sink(2, err)
        return true
      }
      return false
    }
    if (aborted) {
      process.nextTick(innerAbort)
    }
    source(0, (t, d) => {
      if (t === 0) {
        talkback = d
        sink(0, talkback)
      } else if (t === 2) {
        ended = true
        sink(2, d)
      } else {
        sink(t, d)
      }
    })
  }
  $.abort = abort
  return $
}
