export default {
  name: 'VueFormField_Checkbox',
  inheritAttrs: false,
  props: {
    fieldID: String,
    value: Boolean,
    bare: Boolean,
    inline: Boolean,
    disabled: Boolean
  },
  render (h) {
    const checkbox = h(
      'input',
      {
        attrs: {
          id: this.fieldID,
          type: 'checkbox',
          checked: !!this.value,
          disabled: this.disabled
        },
        on: {
          change: (evt) => this.onChange(evt)
        }
      }
    )
    if (this.bare) {
      // Need a label for styling the checkbox.
      return h('span', {}, [
        checkbox,
        h('label', { attrs: { for: this.fieldID }})
      ])
    } else {
      return checkbox
    }
  },
  methods: {
    onChange (evt) {
      const value = !!evt.target.checked
      this.$emit('input', value)
    }
  }
}
