<template>
    <section class="padding">
        <div class="grid">
            <div class="column size--100">
                <block :table="true">
                    <template slot="title"><h1>{{ title }}</h1></template>
                    <template slot="content">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Bedrijfsnaam</th>
                                    <th>&nbsp;</th>
                                    <th class="icon">
                                        <router-link
                                            v-if="companies.length === 0 || companyType !== 'owner'"
                                            :to="{
                                                name: `${routePrefix}.add`,
                                                query: $route.query
                                            }"
                                            :title="getTitleForRoute({ name: `${routePrefix}.add` })"
                                            class="btn icon"
                                        >
                                            <icon name="plus" />
                                        </router-link>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="company of companies" :key="company.companyID">
                                    <td>
                                        <router-link
                                            :to="{
                                                name: `${routePrefix}.edit`,
                                                params: { companyID: company.companyID },
                                                query: $route.query
                                            }"
                                            title="Bedrijf bewerken"
                                            class="btn icon"
                                        >
                                            <icon name="pencil" />
                                        </router-link>
                                        {{ company.name }}
                                    </td>
                                    <td>
                                        <router-link
                                            :to="{
                                                name: `${routePrefix}.users.overview`,
                                                params: { companyID: company.companyID },
                                                query: $route.query
                                            }"
                                            :title="usersTitle"
                                        >
                                            {{ usersTitle }}
                                        </router-link>
                                    </td>
                                    <td class="icon">
                                        <btn
                                            v-if="companyType !== 'owner'"
                                            icon="trash"
                                            title="Bedrijf verwijderen"
                                            class="icon"
                                            @click.prevent="removeRecord(company)"
                                        ></btn>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </block>
            </div>
        </div>
        <modal name="delete" title="Weet je zeker dat je deze klant wilt verwijderen?">
            <p>Wil je de klant "{{ selectedRecord && selectedRecord.name }}" verwijderen?</p>
            <p><strong>Let op!</strong> Dit verwijdert ook alle data in de omgeving van de klant! Om te bevestigen, type "{{ deleteConfirmCheck }}" in het onderstaande veld.</p>
            <input type="text" v-model="deleteConfirmInput" :placeholder="`Type &quot;${deleteConfirmCheck}&quot; om te bevestigen`">
            <br><br>
            <button type="button" @click="removeRecord(selectedRecord, true)" :disabled="deleteConfirmInput !== deleteConfirmCheck">Ja, verwijderen</button>
            <button type="button" @click="$root.$emit('modal', 'delete', false)">Nee, annuleren</button>
        </modal>
    </section>
</template>

<script>
    import Modal from '@/components/Partials/Modal'

    export default {
        name: 'page-companies-Overview',
        components: {
            Modal
        },
        data () {
            return {
                allCompanies: [],
                selectedRecord: null,
                deleteConfirmInput: '',
                deleteConfirmCheck: ''
            }
        },
        mounted () {
            this.$model.list(`${this.companyActor}/list`, 'allCompanies')
        },
        watch: {
            'companyActor' (companyActor) {
                this.$model.list(`${this.companyActor}/list`, 'allCompanies')
            }
        },
        computed: {
            title () {
                return this.$route.meta.title
            },
            routePrefix () {
                return this.$route.name.split('.').slice(0, -1).join('.')
            },
            companyType () {
                return this.$route.meta.company.type
            },
            companyActor () {
                return `${this.$route.meta.company.actorPrefix}/Company`
            },
            usersTitle () {
                return this.getTitleForRoute({
                    name: `${this.routePrefix}.users.overview`,
                    params: { companyID: 'dummy' }
                })
            },
            companies () {
                return this.allCompanies.filter(company => company.type === this.companyType)
            }
        },
        methods: {
            getTitleForRoute (location) {
                const resolved = this.$router.resolve(location)
                return resolved.route.meta.title
            },
            async removeRecord (record, confirmed) {
                if (!confirmed) {
                    this.selectedRecord = record
                    this.deleteConfirmInput = ''
                    this.deleteConfirmCheck = record.name
                    this.$root.$emit('modal', 'delete', true)
                } else {
                    this.selectedRecord = null
                    this.$root.$emit('modal', 'delete', false)
                    await this.$model.remove(this.companyActor, record.companyID)
                }
            }
        }
    }
</script>
