import SimpleSchema from '@qiri/simpl-schema'
import Task from '../task-scheduler/Task'

/**
 * @todo
 */
const ExportTask = new SimpleSchema({
  'exportID': {
    type: String,
    foreignKey: 'Export'
  }
})
ExportTask.extend(Task)
export default ExportTask
