<template>
    <section v-if="loading" class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>Documentatie</h1></template>
                    <template slot="content">
                        <p>Bezig met laden...</p>
                    </template>
                </block>
            </div>
        </div>
    </section>
    <section v-else class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>Documentatie</h1></template>
                    <template slot="content">
                        <p>Hier kun je de documentatie van Qiri vinden.</p>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block :table="true">
                    <template slot="content">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th colspan="2">Naam</th>
                                    <th>Omschrijving</th>
                                    <th v-if="isAdmin">Beschikbaarheid</th>
                                    <th class="icon" v-if="isSuperAdmin">
                                        <router-link :to="{ name: 'documentation.add', query: $route.query }" title="Documentatie toevoegen" class="btn icon">
                                            <icon name="plus" />
                                        </router-link>
                                    </th>
                                    <th class="icon" v-else>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="document of sortedDocuments" :key="document.documentID">
                                    <td class="icon align--top">
                                        <btn icon="download" title="Downloaden" class="icon" @click.prevent="download(document)" :disabled="downloading"></btn>
                                        <router-link
                                            v-if="isSuperAdmin"
                                            :to="{ name: 'documentation.update', params: { documentID: document.documentID }, query: $route.query }"
                                            title="Documentatie wijzigen"
                                            class="btn icon"
                                        >
                                            <icon name="pencil" />
                                        </router-link>
                                    </td>
                                    <td class="align--top">{{ document.name }}</td>
                                    <td>{{ document.description }}</td>
                                    <td v-if="isAdmin" class="whitespace--nowrap align--top">{{ getAvailability(document) }}</td>
                                    <td class="icon align--top" v-if="isSuperAdmin">
                                        <btn icon="trash" title="Verwijderen" class="icon" @click.prevent="removeRecord(document)"></btn>
                                    </td>
                                    <td class="icon" v-else>&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </block>
            </div>
        </div>
        <modal name="delete" title="Weet je zeker dat je deze documentatie wilt verwijderen?">
            <p>Wil je de documentatie "{{ selectedRecord && selectedRecord.name }}" verwijderen?</p>
            <button type="button" @click="removeRecord(selectedRecord, true)">Ja, verwijderen</button>
            <button type="button" @click="$root.$emit('modal', 'delete', false)">Nee, annuleren</button>
        </modal>
    </section>
</template>

<script>
    import Modal from '@/components/Partials/Modal'
    import download from './download'

    export default {
        name: 'page-documentation-Overview',
        components: {
            Modal
        },
        data () {
            return {
                documents: [],
                selectedRecord: null,
                loading: true,
                downloading: false
            }
        },
        async mounted () {
            this.$model.list(`/documentation/Document/list`, 'documents')
            this.loading = false
        },
        computed: {
            sortedDocuments () {
                return this.documents.sort((a, b) => a.name > b.name ? 1 : a.name === b.name ? 0 : -1)
            },
            isSuperAdmin () {
                return this.$token.role === 'superadministrator'
            },
            isAdmin () {
                return this.isSuperAdmin || this.$token.role === 'administrator'
            },
            isUser () {
                return this.isAdmin || this.$token.role === 'user'
            }
        },
        methods: {
            getAvailability (document) {
                const labels = {
                    'user': 'Alle Qiri gebruikers en administrators',
                    'administrator': 'Alleen administrators'
                }
                return labels[document.role]
            },
            async download (document) {
                this.downloading = true
                try {
                    const host = this.$api.host('http', 'app')
                    const response = await fetch(`${host}/storage?hash=${document.fileHash}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${this.$encodedToken}`
                        }
                    })
                    if (!response.ok) {
                        throw new Error(`Something went wrong.`)
                    }
                    download(await response.blob(), document.name, document.mimeType)
                } catch (err) {
                    console.error(err)
                } finally {
                    this.downloading = false
                }
            },
            async removeRecord (record, confirmed) {
                if (!confirmed) {
                    this.selectedRecord = record
                    this.$root.$emit('modal', 'delete', true)
                } else {
                    this.selectedRecord = null
                    this.$root.$emit('modal', 'delete', false)
                    await this.$model.remove(`/documentation/Document`, record.documentID)
                }
            }
        }
    }
</script>
