import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  'documentID': {
    type: String,
    label: 'Document ID',
    description: 'De unieke waarde waarmee het document geïdentificeerd kan worden',
    primaryKey: true
  },
  'reference': {
    type: String,
    label: 'Referentienummer',
    optional: true
  },
  'name': {
    type: String,
    label: 'Naam'
  },
  'description': {
    type: String,
    label: 'Omschrijving',
    multiline: true,
    optional: true
  },
  'role': {
    type: String,
    label: 'Beschikbaarheid',
    defaultValue: 'administrator',
    options: {
      'Beschikbaar voor alle Qiri gebruikers en administrators.': 'user',
      'Alleen beschikbaar voor administrators.': 'administrator'
    }
  },
  'fileHash': {
    type: String
  },
  'mimeType': {
    type: String
  }
})
