import Overview from './components/Pages/Overview'

import ExportsOverview from './components/Pages/exports/Overview'
import ExportsForm from './components/Pages/exports/Form'

import SchedulePage from '@/modules/task-scheduler/components/Pages/Schedule'

import RemoteServersOverview from '@/modules/general/components/Pages/remoteservers/Overview'
import RemoteServersForm from '@/modules/general/components/Pages/remoteservers/Form'

export default {
  'api-log.overview': {
    path: '/api-log/overview',
    component: Overview,
    meta: {
      audience: 'actor:/env/*/api-log/APILog',
    }
  },
  'api-log.exports': {
    path: '/api-log/exports',
    component: ExportsOverview,
    meta: {
      audience: [
        'actor:/env/*/api-log-export/Export'
      ]
    }
  },
  'api-log.exports.create': {
    path: '/api-log/exports/create',
    component: ExportsForm,
    meta: {
      audience: [
        'actor:/env/*/api-log-export/Export',
        'actor:/env/*/data/CustomField/list'
      ],
      title: 'API-log export aanmaken',
      form: {
        mode: 'create'
      }
    }
  },
  'api-log.exports.update': {
    path: '/api-log/exports/:exportID/edit',
    component: ExportsForm,
    meta: {
      audience: [
        'actor:/env/*/api-log-export/Export',
        'actor:/env/*/data/CustomField/list'
      ],
      title: 'API-log export aanpassen',
      form: {
        mode: 'edit'
      }
    }
  },
  'api-log.exports.schedule': {
    path: '/api-log/exports/:exportID/schedule',
    component: SchedulePage,
    meta: {
      audience: [
        'actor:/env/*/api-log-export/Export',
        'actor:/env/*/api-log-export/ExportSchedule'
      ],
      title: 'Uitvoering API-log export inplannen',
      actorPath: '/env/{environmentID}/api-log-export/ExportSchedule',
      idField: 'exportID',
      overviewRoute: 'api-log.exports'
    }
  },
  'api-log.remoteservers.overview': {
    path: '/api-log/remoteservers',
    component: RemoteServersOverview,
    meta: {
      audience: 'actor:/env/*/api-log/RemoteServer'
    }
  },
  'api-log.remoteservers.add': {
    path: '/api-log/remoteservers/add',
    component: RemoteServersForm,
    meta: {
      audience: 'actor:/env/*/api-log/RemoteServer',
      title: 'Externe server toevoegen',
      form: {
        mode: 'create',
        action: 'api-log.remoteservers.overview'
      }
    }
  },
  'api-log.remoteservers.update': {
    path: '/api-log/remoteservers/:remoteServerID/edit',
    component: RemoteServersForm,
    meta: {
      audience: 'actor:/env/*/api-log/RemoteServer',
      title: 'Externe server aanpassen',
      form: {
        mode: 'edit',
        action: 'api-log.remoteservers.overview'
      }
    }
  },
}
