import moment from 'moment-timezone'
import SimpleSchema from '@qiri/simpl-schema'
import Identifier from '@qiri/types/Identifier'

import Stream, {pull} from '@qiri/stream'
import {iterate} from '@qiri/stream/sources/iterate'
import {map} from '@qiri/stream/operators/map'

import {getIDField} from '../../data/util'

/**
 * @todo
 */
export default function systemField ({schema}, configuration, {model, timezone}) {
  const single = schema.schema('output').type.singleType !== Array

  // Input must be a SimpleSchema, or else we wouldn't be able to find relations.
  const modelSchema = schema.schema(single ? 'output' : 'output.$').type.singleType
  if (!SimpleSchema.isSimpleSchema(modelSchema)) {
    return false
  }

  const modelName = schema.schema('output').name
  let idField
  try {
    idField = getIDField(modelSchema, modelName)
  } catch (err) {
    // Not an actual model.
    return false
  }

  // Create the configuration's schema.
  const configurationSchema = new SimpleSchema({
    field: {
      type: String,
      label: 'Qiri-systeemveld',
      description: 'Kies Qiri-systeemveld.',
      allowedValues: [
        ['Datum toegevoegd', 'insert-date']
      ]
    }
  })

  // Just provide the configuration's schema if no configuration was actually provided.
  if (!configuration) {
    return {configurationSchema}
  }

  // Use the configured field to create the sink and output schema.
  const {field} = configuration
  const systemFields = {
    'insert-date': {
      type: Date,
      label: 'Datum toegevoegd',
      mapFn: record => {
        let date = moment.utc(record.created_at$)
        if (timezone) {
          date = date.tz(timezone)
        }
        return date.format('YYYY-MM-DD HH:mm:ss')
      }
    }
  }
  const systemField = systemFields[field]

  if (single) {
    return {
      configurationSchema,
      schema: new SimpleSchema({
        'output': {
          type: systemField.type,
          name: field,
          label: systemField.label
        }
      }),
      get [Stream.sink] () {
        return source => pull(
          source,
          map(systemField.mapFn)
        )
      }
    }
  } else {
    return {
      configurationSchema,
      schema: new SimpleSchema({
        'output': {
          type: Array,
          name: field,
          label: systemField.label
        },
        'output.$': {
          type: systemField.type
        }
      }),
      get [Stream.sink] () {
        return source => pull(
          source,
          map(list => pull(
            iterate(list),
            map(systemField.mapFn)
          ))
        )
      }
    }
  }
}
