import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  'tokenID': {
    type: String
  },
  'userID': {
    type: String,
    optional: true
  },
  'displayName': {
    type: String,
    optional: true
  }
})
