import castPath from 'lodash/_castPath'
import isIndex from 'lodash/_isIndex'
import isObject from 'lodash/isObject'
import toKey from 'lodash/_toKey'
import toPath from 'lodash/toPath'

/**
 * @todo
 */
export function vueSet (vm, object, path, value) {
  if (!isObject(object)) {
    return object
  }
  path = castPath(path, object)

  let index = -1
  const length = path.length
  const lastIndex = length - 1
  let nested = object

  while (nested != null && ++index < length) {
    const key = toKey(path[index])
    let newValue = value

    if (index != lastIndex) {
      let objValue = nested[key]
      newValue = isObject(objValue)
        ? objValue
        : (isIndex(path[index + 1]) ? [] : {})
    }
    vm.$set(nested, key, newValue)
    nested = nested[key]
  }
  return object
}
