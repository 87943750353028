<template>
    <div>
        <section class="padding">
            <div class="grid">
                <div class="column size--100">
                    <block>
                        <template slot="title"><h1>{{ meta.title }}</h1></template>
                        <template slot="content" v-if="loading">
                            <p>Bezig met laden...</p>
                        </template>
                    </block>
                </div>
            </div>
        </section>
        <ScheduleForm v-if="!loading" :schema="schema" :saving="saving" v-model="schedule" @save="saveSchedule"></ScheduleForm>
    </div>
</template>

<script>
    import uuidv4 from 'uuid/v4'
    import get from 'lodash/get'
    import moment from 'moment-timezone'
    import SimpleSchema from '@qiri/simpl-schema'
    import {Schedule} from '@qiri/models/task-scheduler'
    import ScheduleForm from '@/modules/task-scheduler/components/Partials/ScheduleForm'

    export default {
        name: 'page-scheduler-Schedule',
        components: {
            ScheduleForm
        },
        data () {
            return {
                schedule: null,
                loading: true,
                saving: false
            }
        },
        async mounted () {
            // Load the schedule.
            if (!this.recordID) {
                throw new Error(`Missing route parameter "recordID".`)
            }
            this.record = await this.$api.call(`${this.actorPath}/Record/${this.recordID}/get`)
            this.schedule = this.record.schedule
            if (!this.schedule) {
                this.schedule = {
                    timezone: moment.tz.guess(),
                    recur: {
                        type: null,
                        settings: null
                    },
                    exclude: {}
                }
            }
            this.loading = false
        },
        computed: {
            meta () {
                return this.$route.meta
            },
            recordID () {
                return this.$route.params[this.meta.idField]
            },
            actorPath () {
                return this.$route.meta.actorPath.replace('{environmentID}', `${this.$environment}`)
            },
            schema () {
                if (this.meta.schema) {
                    return this.meta.schema
                } else {
                    return Schedule
                }
            }
        },
        methods: {
            async saveSchedule () {
                this.saving = true
                const schedule = this.schema.clean(this.schedule)
                await this.$api.dispatch(`${this.actorPath}/Record/${this.recordID}/update`, {$set: {
                    schedule
                }})
                this.$router.push({ name: this.meta.overviewRoute, query: this.$route.query })
            }
        }
    }
</script>
