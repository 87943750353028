<template>
    <vue-nested-form :schema="schema" field="protocol.settings">
        <vue-form-field field="location"></vue-form-field>
    </vue-nested-form>
</template>

<script>
    import RemoteServer from '@qiri/models/protocols/RemoteServer'

    export default {
        name: 'page-feeds-protocol-RemoteServer',
        computed: {
            schema () {
                return RemoteServer
            }
        }
    }
</script>
