import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  'id': {
    type: String,
    label: 'ID',
    primaryKey: true
  },
  'period': {
    type: String,
    label: 'Periode'
  },
  'userID': {
    type: String,
    label: 'User ID',
    optional: true
  },
  'tokenID': {
    type: String,
    label: 'Token ID',
    optional: true
  },
  'description': {
    type: String,
    label: 'Omschrijving'
  },
  'count': {
    type: SimpleSchema.Integer,
    label: 'Aantal API-requests'
  },
  'apiScriptCount': {
    type: SimpleSchema.Integer,
    label: 'Aantal API-script-requests'
  },
  'csvRecordCount': {
    type: SimpleSchema.Integer,
    label: 'Aantal record in CSV-bestand'
  }
})
