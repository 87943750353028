<template>
    <vue-form v-else tag="section" :state="form" ref="form" :schema="schema" v-model="record" class="padding" @submit="doSubmit()">
        <div class="grid">
            <div class="column size--20"></div>
            <div class="column size--60">
                <block>
                    <template slot="title"><h1>Licentie voor gebruik accepteren</h1></template>
                    <template slot="content">
                        <p>
                            Qiri&reg; is een artificial intelligence-product van {{ token.tac.company }}. U heeft een Licentie voor gebruik toegekend gekregen. Dit betekent dat u de software onder bepaalde voorwaarden mag gebruiken. Welke dat zijn kunt u lezen in de <a :href="token.tac.url" target="_blank">Algemene voorwaarden</a> van {{ token.tac.company }}.
                        </p>
                        <vue-form-field field="agreeToTerms">
                            Ik accepteer de <a :href="token.tac.url" target="_blank">Algemene voorwaarden</a> van {{ token.tac.company }} die op mijn gebruik van Qiri van toepassing zijn.
                        </vue-form-field>
                        <button type="button" class="full" title="Versturen" :disabled="form.invalid || saving" @click.prevent="$refs.form.submit()">
                            <template v-if="saving"><icon name="refresh" :spin="true" /></template>
                            <template v-else>Versturen</template>
                        </button>
                        <ul class="errors" v-if="submitError">
                            <li v-if="submitError">
                                <icon name="exclamation-circle"></icon> {{ submitError }}
                            </li>
                        </ul>
                    </template>
                </block>
            </div>
            <div class="column size--20"></div>
        </div>
    </vue-form>
</template>

<script>
    import SimpleSchema from '@qiri/simpl-schema'

    export default {
        name: 'page-terms-and-conditions',
        data () {
            return {
                form: {},
                record: {},
                saving: false,
                submitError: null
            }
        },
        computed: {
            token () {
                return this.$token
            },
            schema () {
                return new SimpleSchema({
                    'agreeToTerms': {
                        type: Boolean,
                        autoValue () {
                            if (!this.isSet) {
                                return false
                            }
                        },
                        custom () {
                            if (this.value !== true) {
                                return 'required'
                            }
                        }
                    },
                    'timestamp': {
                        type: Date,
                        autoValue () {
                            if (this.siblingField('agreeToTerms').value === true) {
                                return new Date()
                            }
                        }
                    }
                })
            }
        },
        methods: {
            async doSubmit () {
                this.saving = true
                this.submitError = null

                const record = this.schema.clean(this.record)
                try {
                    // Gain access to the API using the supplied token.
                    const api = await this.$rpc.access()

                    // Submit the request.
                    await api.rootActor.dispatch(`/security/TermsAndConditions/accept`, record)

                    // Everything went well. Try to login again.
                    const encodedToken = await api.rootActor.dispatch(`/security/Authenticate/continue`)
                    if (!encodedToken) {
                        throw new Error(`No token received`)
                    }

                    // Pass encoded token to plugin, read back decoded token.
                    this.$encodedToken = encodedToken
                    const token = this.$token

                    // Should be OK now, redirect to dashboard.
                    this.$router.replace({ name: 'dashboard', query: this.$route.query })

                } catch (err) {
                    // TODO: Show custom error if submitted password is same as "old" or if token has expired/is already used.
                    console.error(err)
                    this.submitError = 'Er is iets fout gegaan.'
                    this.saving = false
                }
            }
        }
    }
</script>
