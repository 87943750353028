<template>
    <section v-if="loading" class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>Foutmeldingen - Configuratie</h1></template>
                    <template slot="content">
                        <p>Bezig met laden...</p>
                    </template>
                </block>
            </div>
        </div>
    </section>
    <vue-form v-else tag="section" :state="form" ref="form" :schema="schema" v-model="config" class="padding" @submit="saveConfig()">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>Foutmeldingen - Configuratie</h1></template>
                    <template slot="content">
                        <vue-form-field field="emails.errors"></vue-form-field>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="content">
                        <button type="button" class="full" :title="formErrors" :disabled="form.invalid || saving" @click.prevent="$refs.form.submit()">
                            <template v-if="saving"><icon name="refresh" :spin="true" /></template>
                            <template v-else>Configuratie aanpassen</template>
                        </button>
                    </template>
                </block>
            </div>
        </div>
    </vue-form>
</template>

<script>
    import {StatusConfig} from '@qiri/models/data'

    export default {
        name: 'page-status-Config',
        data () {
            return {
                loading: true,
                saving: false,
                form: {},
                config: {
                    configID: 'default'
                }
            }
        },
        async mounted () {
            const config = await this.$model.get(`/env/${this.$environment}/data/StatusConfig`, 'default')
            if (config) {
                this.config = config
            }
            this.loading = false
        },
        computed: {
            schema () {
                return StatusConfig
            },
            formErrors () {
                let text = []
                if (this.form.errors && this.form.errors.length > 0) {
                    text = [
                        'Fouten:',
                        ...this.form.errors.map(error => error.message)
                    ]
                }
                return text.join('\n')
            }
        },
        methods: {
            async saveConfig () {
                this.saving = true
                await this.$model.upsert(`/env/${this.$environment}/data/StatusConfig`, 'default', this.config)
                this.$router.replace({ name: 'data.status', query: this.$route.query })
            }
        }
    }
</script>
