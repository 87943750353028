<template>
    <span>Er zijn minder regels geïmporteerd uit het bronbestand voor data-import "<router-link :to="{ name: 'data.feeds.log', params: { feedID: content.feedID }, query: $route.query }">{{ content.feed }}</router-link>" dan verwacht. Controleer het bronbestand.</span>
</template>

<script>
    export default {
        name: 'page-status-type-ImportCountWarning',
        props: {
            message: Object
        },
        computed: {
            content () {
                return this.message.content
            }
        }
    }
</script>
