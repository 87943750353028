import moment from 'moment'
import SimpleSchema from '@qiri/simpl-schema'
import PersonModel from '../data/Person'
import OrderModel from '../data/Order'

export const Configuration = new SimpleSchema({
  'orderCount': {
    type: SimpleSchema.Integer,
    label: 'Aantal orders om te analyseren',
    description: 'Het aantal orders die gebruikt zullen worden om het persoonlijke tijdsinterval tussen aankoopmomenten te berekenen (standaard 4, maximaal 10).',
    min: 2,
    max: 10
  },
  'intervalFormula': {
    type: String,
    label: 'Formule',
    description: 'De formule waarmee het persoonlijke tijdsinterval berekend wordt (standaard “Mediaan”).',
    defaultValue: 'MEDIAN',
    allowedValues: {
      'Gemiddelde': 'AVERAGE',
      'Mediaan': 'MEDIAN'
    }
  }
})

export const Input = {
  Order: ['personID', 'timestamp']
}

export const Output = ({model} = {}) => new SimpleSchema({
  'person': {
    type: model ? model('Person').type : PersonModel,
    label: 'Persoon',
    table: 'Personen'
  },
  'details': {
    type: Object
  },
  'details.orderCount': {
    type: SimpleSchema.Integer
  },
  'details.lastOrderDate': {
    type: Date
  },
  'details.expectedInterval': {
    type: SimpleSchema.Integer
  },
  'details.daysSinceLastOrder': {
    type: SimpleSchema.Integer
  },
  'details.expectedRepeatInterval': {
    type: SimpleSchema.Integer
  },
  'details.expectedNextOrderDate': {
    type: Date
  }
})

export const Preview = ({customFields}) => new SimpleSchema({
  ...customFields
    .filter(customField => customField.model === 'Person' && customField.type === 'foreignKey')
    .reduce(
      (acc, cur) => {
        acc[cur.name] = {
          type: String,
          label: cur.name,
          render (record) {
            return record.person[cur.name]
          }
        }
        return acc
      },
      {}
    ),
  'lastOrderDate': {
    type: String,
    label: 'Laatste keer',
    render (record) {
      return moment(record.details.lastOrderDate).format('D-M-YYYY')
    }
  },
  'expectedInterval': {
    type: String,
    label: 'Persoonlijk tijdsinterval (dagen)',
    render (record) {
      return record.details.expectedInterval
    }
  },
  'expectedRepeatInterval': {
    type: String,
    label: 'Nummer huidige aankoopmoment-cyclus',
    render (record) {
      return record.details.expectedRepeatInterval
    }
  },
  'expectedNextOrderDate': {
    type: String,
    label: 'Verwachte volgende keer',
    render (record) {
      return moment(record.details.expectedNextOrderDate).format('D-M-YYYY')
    }
  }
})

export const Request = new SimpleSchema({
  'person': {
    type: String,
    label: 'Persoon',
    description: 'De persoon voor wie de bezoekgegevens worden opgevraagd.',
    optional: true
  },
  'date': {
    type: Date,
    label: 'Datum',
    description: 'De datum waarop een eerstvolgend aankoopmoment voorspeld moet worden. Indien leeg wordt ‘vandaag’ gebruikt.',
    optional: true
  },
  'interval': {
    type: SimpleSchema.Integer,
    label: 'Tijdsduur',
    description: 'Het aantal dagen vanaf de opgegeven datum waarbinnen het voorspelde eerstvolgende aankoopmoment geldig is. Indien leeg niet van toepassing.',
    optional: true,
    min: 0
  },
  'minOrderCount': {
    type: SimpleSchema.Integer,
    label: 'Minimaal aantal orders',
    description: 'Indien ingevuld, het minimale aantal orders dat de persoon moet hebben geplaatst.',
    optional: true,
    min: 0
  },
  'minOrderDate': {
    type: Date,
    label: 'Laatste aankoopmoment niet eerder dan',
    description: 'Indien ingevuld, mag het laatste aankoopmoment niet eerder zijn dan de opgegeven datum.',
    optional: true
  },
  'maxOrderDate': {
    type: Date,
    label: 'Laatste aankoopmoment niet later dan',
    description: 'Indien ingevuld, mag het laatste aankoopmoment niet later zijn dan de opgegeven datum.',
    optional: true
  },
  'minExpectedRepeatInterval': {
    type: SimpleSchema.Integer,
    label: 'Minimaal nummer aankoopmoment-cyclus',
    description: 'Indien ingevuld, mag het nummer van het huidige aankoopmoment-cyclus waarin de persoon zich bevindt, niet lager zijn.',
    optional: true,
    min: 0
  },
  'maxExpectedRepeatInterval': {
    type: SimpleSchema.Integer,
    label: 'Maximaal nummer aankoopmoment-cyclus',
    description: 'Indien ingevuld, mag het nummer van het huidige aankoopmoment-cyclus waarin de persoon zich bevindt, niet hoger zijn.',
    optional: true,
    min: 0
  },
  'filter': {
    type: Array,
    label: 'Filters',
    optional: true
  },
  'filter.$': {
    type: String
  },
  'limit': {
    type: SimpleSchema.Integer,
    label: 'Limiet',
    description: 'Maximaal aantal personen dat wordt getoond.',
    min: 1,
    optional: true
  }
})

export const RequestConfiguration = new SimpleSchema({

})
