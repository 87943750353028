import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  storeID: {
    type: String,
    label: 'Qiri winkel ID',
    description: 'De unieke waarde waarmee de winkel geïdentificeerd kan worden.',
    primaryKey: true
  }
})
