import SimpleSchema from '@qiri/simpl-schema'
import * as protocols from '../protocols'

/**
 * @todo
 */
export const factory = ({protocolType} = {}) => new SimpleSchema({
  'remoteServerID': {
    type: String,
    primaryKey: true
  },
  'name': {
    type: String,
    label: 'Naam'
  },
  'description': {
    type: String,
    multiline: true,
    label: 'Omschrijving',
    optional: true
  },
  // Protocol
  'protocol': {
    type: Object
  },
  'protocol.type': {
    type: String,
    label: 'Protocol',
    allowedValues: Object.keys(protocols).filter(x => x !== 'RemoteServer')
  },
  'protocol.settings': {
    type: protocolType ? protocols[protocolType] : Object,
    blackbox: !protocolType,
    label: 'Protocol instellingen'
  }
})

/**
 * @todo
 */
const RemoteServer = factory()
export default RemoteServer

/**
 * @static
 */
RemoteServer.getSchemaFor = (doc) => factory({
  protocolType: doc && doc.protocol && doc.protocol.type
})
