<template>
    <div :class="{'block': true, 'block--collapsible': collapsible}">
        <div class="block--title" v-if="$slots.title" @click="toggle()">
            <slot name="title"></slot>
            <div v-if="collapsible" class="block--toggle">
                <icon v-if="collapsed" name="arrow-circle-down"></icon>
                <icon v-else name="arrow-circle-up"></icon>
            </div>
        </div>
        <div class="block--content" :class="{'block--table': table}" v-if="collapsed !== true && $slots.content">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'block',
        props: {
            table: {
                type: Boolean,
                default: false
            },
            collapsed: {
                type: Boolean,
                default: null
            }
        },
        computed: {
            collapsible () {
                return typeof this.collapsed === 'boolean'
            }
        },
        methods: {
            toggle () {
                if (this.collapsible) {
                    this.$emit('toggle')
                }
            }
        }
    }
</script>
