import moment from 'moment'
import SimpleSchema from '@qiri/simpl-schema'
import ModuleSettings from './ModuleSettings'
import IPAddress from './IPAddress'
import Credentials from '../security/Credentials'
import {
  correctName,
  validateName,
  correctPhone,
  validatePhone
} from './util'

// Try to load bcrypt as an optional module.
let bcrypt
try {
  bcrypt = require('@qiri/bcrypt').default
  console.log(`DEBUG: Module "bcrypt" has been loaded.`)
} catch (err) {
  // Can't load it, ignore the error.
  console.log(`DEBUG: Module "bcrypt" can not be loaded.`)
}

SimpleSchema.setDefaultMessages({
  messages: {
    nl: {
      passwordsMustMatch: `De wachtwoorden moeten overeenkomen`,
      invalidName: `{{{label}}} is geen geldige naam`
    }
  }
})

const User = new SimpleSchema({
  'userID': {
    type: String,
    label: 'Gebruiker ID',
    description: 'De unieke waarde waarmee de gebruiker geïdentificeerd kan worden',
    primaryKey: true
  },
  'companyID': {
    type: String,
    label: 'Bedrijf ID',
    description: 'De ID van het bedrijf waar de medewerker onderdeel van is',
    foreignKey: 'Company'
  },
  'title': {
    type: String,
    label: 'Aanspreektitel',
    description: 'De titel waarmee de medewerker wordt aangesproken'
  },
  'firstName': {
    type: String,
    label: 'Voornaam',
    description: 'De voornaam van de medewerker',
    autoValue () {
      if (this.isSet && this.value) {
        return correctName(this.value)
      }
    },
    custom () {
      if (this.isSet && this.value && !validateName(this.value)) {
        return 'invalidName'
      }
    }
  },
  'infixName': {
    type: String,
    label: 'Tussenvoegsel(s)',
    description: 'De tussenvoegsel(s) van de medewerker',
    optional: true,
    autoValue () {
      if (this.isSet && this.value) {
        return correctName(this.value)
      }
    },
    custom () {
      if (this.isSet && this.value && !validateName(this.value)) {
        return 'invalidName'
      }
    }
  },
  'surName': {
    type: String,
    label: 'Achternaam',
    description: 'De achternaam van de medewerker',
    autoValue () {
      if (this.isSet && this.value) {
        return correctName(this.value)
      }
    },
    custom () {
      if (this.isSet && this.value && !validateName(this.value)) {
        return 'invalidName'
      }
    }
  },
  'emailAddress': {
    type: String,
    label: 'E-mailadres',
    description: 'Het e-mailadres van de medewerker',
    regEx: SimpleSchema.RegEx.EmailWithTLD
  },
  'directPhone': {
    type: String,
    label: 'Direct telefoonnummer',
    description: 'Het directe telefoonnummer van de persoon',
    optional: true,
    autoValue () {
      if (this.isSet && this.value) {
        return correctPhone(this.value)
      }
    },
    custom () {
      if (this.isSet && this.value && !validatePhone(this.value)) {
        return 'invalidPhone'
      }
    }
  },
  'mobilePhone': {
    type: String,
    label: 'Mobiele telefoonnummer',
    description: 'Het mobiele telefoonnummer van de persoon',
    optional: true,
    autoValue () {
      if (this.isSet && this.value) {
        return correctPhone(this.value)
      }
    },
    custom () {
      if (this.isSet && this.value && !validatePhone(this.value)) {
        return 'invalidPhone'
      }
    }
  },
  'role': {
    type: String,
    label: 'Rol',
    description: 'De rol van de gebruiker',
    allowedValues: [
      'user',
      'administrator',
      'superadministrator'
    ]
  },
  'expiryDate': {
    type: Date,
    label: 'Verloopt op datum',
    description: 'Nu oneindig. Indien ingevuld, de datum waarop toegang tot Qiri verloopt.',
    optional: true
  },
  'whitelist': {
    type: Array,
    label: 'Privé IP adressen',
    description: 'Privé IP adressen met toegang tot Qiri (de whitelist)',
    defaultValue: []
  },
  'whitelist.$': {
    type: IPAddress,
    label: 'Privé IP adres',
    description: 'Privé IP adress met toegang tot Qiri'
  },
  'userName': {
    type: String,
    label: 'Gebruikersnaam',
    description: 'Gebruikersnaam van de medewerker'
  },
  'passwordHash': {
    type: String,
    label: 'Wachtwoord hash',
    description: 'Hash van het huidige wachtwoord',
    autoValue () {
      if (this.isSet && bcrypt) {
        // TODO: Read SALT_ROUNDS from configuration.
        const SALT_ROUNDS = 10
        return bcrypt.hash(this.value, SALT_ROUNDS)
      }
    }
  },
  'passwordSetTimestamp': {
    type: Date,
    label: 'Wachtwoord ingesteld timestamp',
    description: 'De timestamp waarop het wachtwoord is ingesteld',
    autoValue () {
      // Set to current date once password hash gets set.
      if (this.siblingField('passwordHash').isSet) {
        //return moment.utc().format()
        return new Date()
      }
    }
  },
  'passwordExpiryTimestamp': {
    type: Date,
    label: 'Wachtwoord verloop timestamp',
    description: 'De timestamp waarop het wachtwoord verloopt',
    optional: true
  },
  'changePasswordToken': {
    type: String,
    label: 'Wachtwoord wijzigen token',
    description: 'De token die gebruikt moet worden voor het wijzigen van het wachtwoord',
    optional: true
  },
  'termsAndConditionsTimestamp': {
    type: Date,
    label: 'Licentie voor gebruik geaccepteerd op',
    description: 'De datum & tijd wanneer de gebruiker de licentie voor gebruik heeft geaccepteerd',
    optional: true
  },
  'termsAndConditionsToken': {
    type: String,
    label: 'Licentie voor gebruik token',
    description: 'De token die gebruikt moet worden voor het accepteren van de licentie voor gebruik',
    optional: true
  },
  'moduleSettings': {
    type: Array,
    label: 'Module instellingen',
    description: 'Instellingen van de modules van de medewerker',
    defaultValue: []
  },
  'moduleSettings.$': {
    type: ModuleSettings.omit('pricing'),
    label: 'Module instellingen',
    description: 'Instellingen voor een module van de medewerker'
  }
})
User.extend(Credentials.pick('userName'))

export default User
