<template>
    <div id="app">
        <header>
            <layout-header></layout-header>
        </header>
        <aside>
            <layout-navigation></layout-navigation>
        </aside>
        <div id="content">
            <router-view :key="$route.fullPath"></router-view>
            <footer>
                <layout-footer></layout-footer>
            </footer>
        </div>
        <notification></notification>
    </div>
</template>

<script>
    // La-yout
    import LayoutHeader from '@/components/Layout/Header'
    import LayoutFooter from '@/components/Layout/Footer'
    import LayoutNavigation from '@/components/Layout/Navigation'
    import Notification from '@/components/Partials/Notification'

    export default {
        name: 'layout-Default',
        components: {
            LayoutHeader,
            LayoutFooter,
            LayoutNavigation,
            Notification
        }
    }
</script>
