<template>
  <section v-if="loading" class="padding">
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h1>Blootstelling aan Qiri en ontwikkeling klantwaarde: between-groups analysis</h1></template>
          <template slot="content">
            <p>Bezig met laden...</p>
          </template>
        </block>
      </div>
    </div>
  </section>
  <section v-else class="padding">
    <div class="grid">
      <div class="column size--100">
        <block :collapsed="titleCollapsed" @toggle="titleCollapsed = !titleCollapsed">
          <template slot="title"><h1>Blootstelling aan Qiri en ontwikkeling klantwaarde: between-groups analysis</h1></template>
          <template slot="content">
            <p>
              Wat gebeurt er met de klantwaarde als je iemand blootstelt aan Qiri? Qiri probeer daar zelf antwoord op te geven.
              Ze kijkt iedere dag naar jullie actieve klanten en analyseert de orderdata voor verschillende periodes:
              wat is er te zien sinds de afgelopen maand (dus sinds {{periodMonth}}),
              sinds een kwartaal (in de laatste drie maanden, dus sinds {{periodQuarter}}),
              sinds een jaar (in de afgelopen {{daysInYear}} dagen, dus sinds {{periodYear}}),
              en ‘lifetime’ (over alle beschikbare orderdata heen).
            </p>
            <p>
              Daarbij verdeelt Qiri jullie actieve klanten in twee groepen: de ene groep bestaat uit personen die nog nooit een suggestie uit Qiri hebben gehad, de andere uit personen die al wél eerder door Qiri werden geadviseerd. Binnen elk van die groep kijkt Qiri naar het aantal personen dat een order plaatste en naar de gemiddelde orderwaarde per persoon. Vervolgens berekent ze haar eigen ‘toegevoegde waarde’: het verschil in gemiddelde orderwaarde vermenigvuldigd met het aantal personen aan wie ze een API-response gaf.
            </p>
            <p>
              Zie je positieve toegevoegde waarden staan? En is nog niet iedere klant aan Qiri blootgesteld? Dan valt er met Qiri nog veel meer rendement te behalen!
            </p>
          </template>
        </block>
      </div>
    </div>
    <div class="grid" v-for="(period, periodName) of periods" :key="periodName">
      <div class="column size--100">
        <block :collapsed="collapsed[periodName]" @toggle="toggle(periodName)">
          <template slot="title"><h2>Personen die {{period.label}} een bestelling deden...</h2></template>
          <template slot="content">
            <table class="table">
              <tbody>
                <tr>
                  <td colspan="2"><b>... maar nog nooit een Qiri-suggestie hebben gekregen (de controlegroep):</b></td>
                </tr>
                <tr>
                  <td>Aantal personen:</td>
                  <td>{{formatNumber(data[periodName].without.personCount, {decimals: 0})}}</td>
                </tr>
                <tr>
                  <td>Gemiddelde orderwaarde per persoon:</td>
                  <td>&euro; {{formatNumber(data[periodName].without.averageOrderValue)}}</td>
                </tr>
              </tbody>
              <tbody v-if="period.hasRecent">
                <tr><td colspan="2">&nbsp;</td></tr>
                <tr>
                  <td colspan="2"><b>... en {{period.label}} een Qiri-suggestie kregen:</b></td>
                </tr>
                <tr>
                  <td>Aantal personen:</td>
                  <td>{{formatNumber(data[periodName].withRecent.personCount, {decimals: 0})}}</td>
                </tr>
                <tr>
                  <td>Gemiddelde orderwaarde per persoon:</td>
                  <td>&euro; {{formatNumber(data[periodName].withRecent.averageOrderValue)}}</td>
                </tr>
                <tr>
                  <td>Verschil orderwaarde met controlegroep:</td>
                  <td>
                    &euro; {{formatNumber(data[periodName].withRecent.averageOrderValue - data[periodName].without.averageOrderValue)}}
                    ({{formatPercentage(data[periodName].withRecent.averageOrderValue / data[periodName].without.averageOrderValue - 1)}})
                  </td>
                </tr>
                <tr>
                  <td>"Toegevoegde waarde" t.o.v. controlegroep:</td>
                  <td>&euro; {{formatNumber((data[periodName].withRecent.averageOrderValue - data[periodName].without.averageOrderValue) * data[periodName].withRecent.personCount)}}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr><td colspan="2">&nbsp;</td></tr>
                <tr>
                  <td colspan="2" ><b>... en ooit een Qiri-suggestie kregen:</b></td>
                </tr>
                <tr>
                  <td>Aantal personen:</td>
                  <td>{{formatNumber(data[periodName].with.personCount, {decimals: 0})}}</td>
                </tr>
                <template v-if="data[periodName].with.personCount > 0">
                  <tr>
                    <td>Gemiddelde orderwaarde per persoon:</td>
                    <td>&euro; {{formatNumber(data[periodName].with.averageOrderValue)}}</td>
                  </tr>
                  <tr>
                    <td>Verschil orderwaarde met controlegroep:</td>
                    <td>
                      &euro; {{formatNumber(data[periodName].with.averageOrderValue - data[periodName].without.averageOrderValue)}}
                      ({{formatPercentage(data[periodName].with.averageOrderValue / data[periodName].without.averageOrderValue - 1)}})
                    </td>
                  </tr>
                  <tr>
                    <td>"Toegevoegde waarde" t.o.v. controlegroep:</td>
                    <td>&euro; {{formatNumber((data[periodName].with.averageOrderValue - data[periodName].without.averageOrderValue) * data[periodName].with.personCount)}}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </template>
        </block>
      </div>
    </div>
  </section>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'page-monitoring-BetweenCustomer',
    data () {
      return {
        loading: true,
        periods: {
          'weekly': {
            label: 'in de afgelopen week',
            hasRecent: true
          },
          'monthly': {
            label: 'in de afgelopen maand',
            hasRecent: true
          },
          'quarterly': {
            label: 'in het afgelopen kwartaal',
            hasRecent: true
          },
          'yearly': {
            label: 'in het afgelopen jaar',
            hasRecent: true
          },
          'lifetime': {
            label: 'ooit',
            hasRecent: false
          }
        },
        data: {
          lifetime: null,
          yearly: null,
          quarterly: null,
          monthly: null,
          weekly: null
        },
        titleCollapsed: false,
        collapsed: {
          lifetime: true,
          yearly: true,
          quarterly: true,
          monthly: true,
          weekly: false
        }
      }
    },
    computed: {
      daysInMonth () {
        const date = moment().startOf('day')
        return moment(date).diff(moment(date).subtract(1, 'months'), 'days')
      },
      periodMonth () {
        return moment().subtract(1, 'months').format('DD-MM-YYYY')
      },
      periodQuarter () {
        return moment().subtract(3, 'months').format('DD-MM-YYYY')
      },
      daysInYear () {
        const date = moment().startOf('day')
        return moment(date).diff(moment(date).subtract(1, 'years'), 'days')
      },
      periodYear () {
        return moment().subtract(1, 'years').format('DD-MM-YYYY')
      }
    },
    async mounted () {
      await this.refresh()
    },
    methods: {
      async refresh () {
        try {
          this.loading = true
          for (const [periodName, period] of Object.entries(this.periods)) {
            const result = await this.$model.fetch(`/env/${this.$environment}/monitoring/ValueDevelopment/betweenCustomers`, {period: periodName})
            this.$set(this.data, periodName, result[0])
          }
        } catch (err) {
          console.error(err)
        } finally {
          this.loading = false
        }
      },
      toggle (period) {
        this.$set(this.collapsed, period, !this.collapsed[period])
      },
      capitalize (s) {
        return `${s.substr(0, 1).toUpperCase()}${s.substr(1)}`
      },
      formatDate (d) {
        return d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : ''
      },
      formatNumber (n, options = {}) {
        const {
          decimals = 2,
          decimalPoint = ',',
          thousandsSeparator = '.'
        } = options

        let [major, minor] = Number(n).toFixed(decimals).split('.')
        major = major.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator)
        return minor ? `${major}${decimalPoint}${minor}` : major
      },
      formatPercentage (n) {
        if (isNaN(n)) {
          return '0%'
        }
        const decimals = n < 0.1 ? 1 : 0
        return `${n >= 0 ? '+' : ''}${this.formatNumber(n * 100, {decimals})}%`
      }
    }
  }
</script>
