<template>
    <section v-if="loading" class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>Algoritmes configureren</h1></template>
                    <template slot="content">
                        <p>Bezig met laden...</p>
                    </template>
                </block>
            </div>
        </div>
    </section>
    <section v-else class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>Algoritmes configureren</h1></template>
                    <template slot="content">
                        <p>Hier kun je de algoritmes configureren.</p>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block :table="true">
                    <template slot="content">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Algoritme</th>
                                    <th class="icon">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="algorithm of algorithms" :key="algorithm.algorithmID">
                                    <td>
                                        <router-link
                                            :to="{
                                                name: `${routePrefix}algorithms.configure`,
                                                params: { algorithmID: algorithm.algorithmID },
                                                query: $route.query
                                            }"
                                            class="btn icon"
                                        >
                                            <icon name="cog"></icon>
                                        </router-link>
                                        {{ algorithm.name }}
                                    </td>
                                    <td class="icon">&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="content">
                        <button type="button" class="full" @click.prevent="executeAlgorithms()">
                            Algoritmes herberekenen
                        </button>
                    </template>
                </block>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'page-algorithms-Overview',
        data () {
            return {
                algorithms: [],
                loading: true
            }
        },
        async mounted () {
            const view = await this.$api.call(`${this.uiActor}/view`, {
                route: {
                    params: this.$route.params,
                    query: this.$route.query
                }
            })
            this.algorithms = view.algorithms
            this.loading = false
        },
        computed: {
            routePrefix () {
                return this.$route.meta.routePrefix ? `${this.$route.meta.routePrefix}.` : ''
            },
            uiActor () {
                return this.$route.meta.uiActor || `/env/${this.$environment}/algorithms/ui/Overview`
            }
        },
        methods: {
            async executeAlgorithms () {
                await this.$api.dispatch(`/env/${this.$environment}/algorithms/ui/Overview/execute`)
            }
        }
    }
</script>
