import SimpleSchema from '@qiri/simpl-schema'
import {getAlgorithm} from '../algorithm'

/**
 * @private
 */
const Request = new SimpleSchema({
  'person': {
    type: String,
    label: 'Persoon',
    optional: true
  },
  'product': {
    type: String,
    label: 'Product',
    optional: true
  },
  'limit': {
    type: SimpleSchema.Integer,
    label: 'Limiet'
  }
})

/**
 * @private
 */
const ResponseFactory = ({algorithmID} = {}, context) => new SimpleSchema({
  'qiriApiResponseId': {
    type: String,
    label: 'Qiri API response ID'
  },
  'count': {
    type: SimpleSchema.Integer,
    label: 'Aantal resultaten'
  },
  'results': {
    type: Array,
    label: 'Resultaten'
  },
  'results.$': {
    type: algorithmID ? getAlgorithm(algorithmID, context).outputSchema : Object,
    blackbox: !algorithmID,
    label: 'Resultaat'
  }
})

/**
 * @private
 */
const Response = ResponseFactory()

/**
 * @static
 */
Response.getSchemaFor = (doc, context) => ResponseFactory(doc, context)

/**
 * @todo
 */
export const factory = ({algorithmID} = {}, context) => new SimpleSchema({
  'requestTime': {
    type: Date,
    label: 'Timestamp API-request'
  },
  'method': {
    type: String,
    label: 'HTTP methode'
  },
  'url': {
    type: String,
    label: 'HTTP URL'
  },
  'algorithm': {
    type: String,
    label: 'Qiri algoritme',
    optional: true
  },
  'tokenID': {
    type: String,
    label: 'API-token ID'
  },
  'request': {
    type: algorithmID ? getAlgorithm(algorithmID, context).requestSchema : Request,
    label: 'API-request'
  },
  'response': {
    type: Response.getSchemaFor({algorithmID}, context),
    label: 'API-response'
  },
  'statusCode': {
    type: SimpleSchema.Integer,
    label: 'HTTP status code'
  }
})

/**
 * @todo
 */
const APIEvent = factory()
export default APIEvent

/**
 * @static
 */
APIEvent.getSchemaFor = (doc, context) => factory(doc, context)
