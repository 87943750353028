<template>
    <i :class="iconClass" @click="$emit('click')"></i>
</template>

<script>
    export default {
        name: 'icon',
        props: {
            name: {
                type: String,
                required: true
            },
            spin: Boolean
        },
        computed: {
            iconClass () {
                let classes = [ 'fa', `fa-${this.name}` ]
                if (this.spin) {
                    classes.push('fa-spin')
                }
                return classes.join(' ')
            }
        }
    }
</script>
