import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  configID: {
    type: String,
    primaryKey: true
  },
  emails: {
    type: Object,
    optional: true
  },
  'emails.errors': {
    type: String,
    label: 'Stuur foutmeldingen naar (1 e-mailadres per regel)',
    multiline: true,
    optional: true
  }
})
