import SimpleSchema from '@qiri/simpl-schema'
import Schedule from './Schedule'

export default new SimpleSchema({
  'taskID': {
    type: String,
    label: 'Taak ID',
    primaryKey: true
  },
  'environmentID': {
    type: String,
    label: 'Omgeving ID'
  },
  'group': {
    type: String,
    label: 'Groep',
    optional: true
  },
  'schedule': {
    type: Schedule,
    label: 'Planning',
    optional: true
  },
  'nextDate': {
    type: Date,
    label: 'Volgende uitvoering op',
    optional: true
  },
  'state': {
    type: String,
    label: 'Staat',
    allowedValues: [
      'idle',
      'queued',
      'executing',
      'canceled',
      'executed'
    ],
    defaultValue: 'created'
  },
  'action': {
    type: String,
    label: 'Actie'
  },
  'payload': {
    type: Object,
    blackbox: true,
    optional: true
  },
  'module': {
    type: String,
    label: 'Module',
    optional: true,
    index: true
  },
  'executedAt': {
    type: Date,
    label: 'Laatste keer uitgevoerd op',
    optional: true
  },
  'finishedAt': {
    type: Date,
    label: 'Laatste keer afgerond op',
    optional: true
  },
  'result': {
    type: Object,
    optional: true,
    label: 'Laatste resultaat'
  },
  'result.code': {
    type: SimpleSchema.Integer
  },
  'result.data': {
    type: Object,
    blackbox: true,
    optional: true
  }
})
