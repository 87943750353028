import SimpleSchema from '@qiri/simpl-schema'
import PersonModel from '../data/Person'

export const Configuration = new SimpleSchema({

})

export const Output = ({model} = {}) => new SimpleSchema({
  'person': {
    type: model ? model('Person').type : PersonModel,
    label: 'Persoon'
  }
})

export const Preview = ({customFields}) => new SimpleSchema({
  ...customFields
    .filter(customField => customField.model === 'Person' && customField.type === 'foreignKey')
    .reduce(
      (acc, cur) => {
        acc[cur.name] = {
          type: String,
          label: cur.name,
          render (record) {
            return record.person[cur.name]
          }
        }
        return acc
      },
      {}
    )
})

export const Request = new SimpleSchema({
  'person': {
    type: String,
    label: 'Persoon',
    description: 'De persoon om op te zoeken'
  },
  'limit': {
    type: SimpleSchema.Integer,
    label: 'Limiet',
    description: 'Maximaal aantal resultaten',
    min: 1,
    defaultValue: 20
  }
})

export const RequestConfiguration = new SimpleSchema({

})
