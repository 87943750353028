import SimpleSchema from '@qiri/simpl-schema'
import ProductModel from '../data/Product'
import ProductPromotionModel from '../data/ProductPromotion'
import OrderModel from '../data/Order'
import OrderLineModel from '../data/OrderLine'
import OrderBy from './OrderBy'

import * as productPromotions from './ProductPromotions'

export const Configuration = new SimpleSchema({

})

export const Input = {
  Order: ['personID', 'timestamp'],
  OrderLine: ['orderID', 'productID', 'quantity'],
  ProductPromotion: ['products.$', 'startDate', 'endDate']
}

export const Output = productPromotions.Output
export const Preview = productPromotions.Preview

export const Request = new SimpleSchema({
  'person': {
    type: String,
    label: 'Persoon',
    description: 'De persoon voor wie relevante productpromoties opgehaald moeten worden (heeft in het verleden het product eens gekocht).'
  },
  'startDate': {
    type: Date,
    label: 'Start datum',
    description: 'De start datum van de productpromotie. Indien leeg wordt ‘vandaag’ gebruikt.',
    optional: true
  },
  'endDate': {
    type: Date,
    label: 'Eind datum',
    description: 'De eind datum van de productpromotie (optioneel).',
    optional: true
  },
  'minInterval': {
    type: SimpleSchema.Integer,
    label: 'Minimale persoonlijke tijdsinterval',
    description: 'Indien ingevuld, worden producten met een kleinere persoonlijke tijdsinterval uitgesloten.',
    optional: true,
    min: 0
  },
  'maxInterval': {
    type: SimpleSchema.Integer,
    label: 'Maximale persoonlijke tijdsinterval',
    description: 'Indien ingevuld, worden producten met een grotere persoonlijke tijdsinterval uitgesloten.',
    optional: true,
    min: 0
  },
  'maxExpectedRepeatInterval': {
    type: SimpleSchema.Integer,
    label: 'Maximale nummer huidige productaankoop-cyclus',
    description: 'Indien ingevuld, worden producten met een groter nummer huidige productaankoop-cyclus uitgesloten.',
    optional: true,
    min: 1
  },
  'minLastPurchaseDate': {
    type: Date,
    label: 'Laatste aankoopdatum niet eerder dan',
    description: 'Indien ingevuld, mag de laatste aankoopdatum niet eerder zijn dan de opgegeven datum.',
    optional: true
  },
  'minExpectedNextPurchaseDate': {
    type: Date,
    label: 'Verwachte eerstvolgende productaanschaf niet eerder dan',
    description: 'Indien ingevuld, mag de verwachte eerstvolgende productaanschaf niet eerder zijn dan de opgegeven datum.',
    optional: true
  },
  'useCategory': {
    type: Boolean,
    label: 'Gebruik product categorieën',
    description: 'Zoek productpromoties op basis van product categorieën i.p.v. exacte producten.',
    optional: true,
    options: {
      'Ja': 'true',
      'Nee': 'false'
    }
  },
  'skipNoProducts': {
    type: Boolean,
    label: 'Negeer productpromoties zonder producten',
    description: 'Indien een productpromotie geen producten heeft, gebruik deze dan niet als suggestie.',
    optional: true,
    options: {
      'Ja': 'true',
      'Nee': 'false'
    }
  },
  'onlyPersonalProducts': {
    type: Boolean,
    label: 'Toon alleen persoonlijke gekochte producten',
    description: 'Toon alleen producten welke eerder zijn gekocht door de persoon.',
    optional: true,
    options: {
      'Ja': 'true',
      'Nee': 'false'
    }
  },
  'productLimit': {
    type: SimpleSchema.Integer,
    label: 'Limiet producten per productpromotie',
    description: 'Maximaal aantal producten om te tonen per productpromotie.',
    min: 1,
    optional: true
  },
  'filter': {
    type: Array,
    label: 'Filters',
    optional: true
  },
  'filter.$': {
    type: String
  },
  'limit': {
    type: SimpleSchema.Integer,
    label: 'Limiet',
    description: 'Maximaal aantal productpromoties dat wordt getoond.',
    min: 1,
    defaultValue: 3
  }/*,
  'orderBy': OrderBy.arraySchema({
    defaultValue: [{ field: 'factor', order: 'desc' }]
  }),
  'orderBy.$': OrderBy.elementSchema({
    indexFields: Output
  })*/
})

export const RequestConfiguration = new SimpleSchema({
  'maxLimit': {
    type: SimpleSchema.Integer,
    label: 'Limiet',
    description: 'Maximaal aantal suggesties in een enkele API-response',
    min: 1,
    defaultValue: 50
  }
})
