import SimpleSchema from '@qiri/simpl-schema'

import Stream, {pull} from '@qiri/stream'
import {iterate} from '@qiri/stream/sources/iterate'
import {map} from '@qiri/stream/operators/map'
import {reduce} from '@qiri/stream/operators/reduce'
import {last} from '@qiri/stream/sinks/last'

/**
 * @todo
 */
export const meta = {
  name: 'count',
  label: 'Waarden tellen'
}

/**
 * @todo
 */
export default function count ({schema}) {
  const single = schema.schema('output').type.singleType !== Array
  // TODO: Remove this logic.
  if (single) {
    return false
  }

  let sink
  if (single) {
    sink = source => pull(
      source,
      map(x => x ? 1 : 0)
    )
  } else {
    sink = source => pull(
      source,
      map(list => pull(
        iterate(list),
        reduce((count, x) => count + (x ? 1 : 0), 0),
        last()
      ))
    )
  }

  return {
    meta,
    schema: new SimpleSchema({
      'output': {
        type: SimpleSchema.Integer
      }
    }),
    get [Stream.sink] () {
      return sink
    }
  }
}
