import {Duplex} from 'stream'
import inject from 'reconnect-core'

export default class WebSocketClient extends Duplex {
  /**
   * @todo
   */
  static connect (address, options = {}) {
    // build you own reconnect module
    const reconnect = inject(() => new WebSocketClient(address, options))
    const re = reconnect()
    re.connect()
    return re
  }

  /**
   * Creates a new `WebSocket` client.
   */
  constructor (source, options = {}) {
    super(options)

    this._id = options.id
    this._url = options.url

    if (typeof source === 'string') {
      this._ws = new WebSocket(source);
    } else {
      this._ws = source
    }

    // Determine the ID of the client based on the socket, if available.
    if (!this._id) {
      const socket = this._ws._socket
      if (socket) {
        this._id = `${socket.remoteAddress}:${socket.remotePort}`
      } else {
        this._id = '0'
      }
    }

    this._ws.addEventListener('message', (msg) => {
      if (Buffer.isBuffer(msg.data)) {
        this.push(msg.data)
      } else {
        blobToBuffer(msg.data, (err, buf) => {
          if (err) {
            this.emit('error', err)
          } else {
            this.push(buf)
          }
        })
      }
    })
    this._ws.addEventListener('open', () => {
      this.emit('open')
      this.emit('connect')
    })
    this._ws.addEventListener('close', () => {
      this.emit('close')
    })
    this._ws.addEventListener('error', (err) => {
      if (this.listenerCount('error') > 0) {
        this.emit('error', err)
      }
    })

    this.on('end', () => {
      this.disconnect()
    })
    this.on('finish', () => {
      this.disconnect()
    })
  }

  /**
   * The connection is not yet open.
   */
  static get CONNECTING () { return 0 }

  /**
   * The connection is open and ready to communicate.
   */
  static get OPEN () { return 1 }

  /**
   * The connection is in the process of closing.
   */
  static get CLOSING () { return 2 }

  /**
   * The connection is closed or couldn't be opened.
   */
  static get CLOSED () { return 3 }

  /**
   * @todo
   */
  get readyState () {
    return this._ws.readyState
  }

  /**
   * @todo
   */
  get id () {
    return this._id
  }

  /**
   * @todo
   */
  get url () {
    return this._url || '/'
  }

  /**
   * @todo
   */
  disconnect () {
    if (this._ws && this._ws.readyState === WebSocketClient.OPEN) {
      this._ws.close()
    }
  }

  /**
   * @override
   */
  _write (chunk, encoding, callback) {
    if (this._ws.readyState === WebSocketClient.OPEN) {
      if (!Buffer.isBuffer(chunk)) {
        chunk = Buffer.from(chunk, encoding)
      }
      this._ws.send(chunk)
    }
    callback()
  }

  /**
   * @override
   */
  _read (size) {
    // Do nothing, pushed when received.
  }
}

/**
 * @internal
 */
function blobToBuffer (blob, cb) {
  if (typeof Blob === 'undefined' || !(blob instanceof Blob)) {
    throw new Error('first argument must be a Blob')
  }
  if (typeof cb !== 'function') {
    throw new Error('second argument must be a function')
  }

  var reader = new FileReader()

  function onLoadEnd (e) {
    reader.removeEventListener('loadend', onLoadEnd, false)
    if (e.error) cb(e.error)
    else cb(null, Buffer.from(reader.result))
  }

  reader.addEventListener('loadend', onLoadEnd, false)
  reader.readAsArrayBuffer(blob)
}
