/**
 * Similar to `Array.prototype.reduce`.
 */
export const reduce = (fn, initialValue) => (source) => (start, sink) => {
  if (start !== 0) {
    return
  }
  let talkback
  let takeFirst = initialValue === undefined
  let acc = initialValue
  source(0, (t, d) => {
    if (t === 0) {
      talkback = d
      sink(0, d)
    } else if (t === 1) {
      if (takeFirst) {
        takeFirst = false
        acc = d
      } else {
        acc = fn(acc, d)
      }
      talkback(1)
    } else if (t === 2) {
      if (!d) {
        sink(1, acc)
      }
      sink(2, d)
    } else {
      sink(t, d)
    }
  })
}
