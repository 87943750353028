import isNumber from 'lodash/isNumber'
import isBoolean from 'lodash/isBoolean'

const NULL_VALUE = '__null__'

export default {
  name: 'VueFormField_Select',
  props: {
    fieldID: String,
    value: [String, Number, Boolean],
    options: [Array, Object],
    inline: Boolean,
    disabled: Boolean,
    nullLabel: {
      type: String,
      default: 'Maak een selectie'
    }
  },
  render (h) {
    let options = Array.isArray(this.options) ? this.options : Object.entries(this.options)
    let nullOption = options.find(([value, label]) => value === null)
    options = options.filter(([value, label]) => value !== null)
    
    let nullOptionsDisabled = false
    if (!nullOption) {
      nullOptionsDisabled = this.value !== undefined
      nullOption = [null, this.nullLabel]
    }

    let currentValue = this.value === undefined ? null : this.value

    if (isNumber(currentValue)) {
      currentValue = `${currentValue}`
      this._valueConverter = (v) => parseInt(v, 10)
    } else if (isBoolean(currentValue)) {
      currentValue = currentValue ? 'true' : 'false'
      this._valueConverter = (v) => v === 'true'
    } else {
      this._valueConverter = (v) => v
    }

    return h(
      'select',
      {
        staticClass: this.inline ? 'inline' : '',
        attrs: {
          id: this.fieldID,
          disabled: this.disabled
        },
        on: {
          change: (evt) => this.onSelect(evt)
        }
      },
      [
        h(
          'option', 
          {
            attrs: {
              value: NULL_VALUE,
              disabled: nullOptionsDisabled,
              selected: currentValue === null
            }
          },
          nullOption[1]
        ),
        ...options.map(([value, label]) => h(
          'option',
          {
            attrs: {
              value,
              disabled: value === undefined,
              selected: currentValue === value
            }
          },
          label
        ))
      ]
    )
  },
  methods: {
    onSelect (evt) {
      const selected = Array.from(evt.target.options)
        .filter(option => option.selected)
        .map(option => option._value || option.value)
      const value = selected.length > 0 ? selected[0] : null
      this.$emit('input', value === NULL_VALUE ? null : this._valueConverter(value))
    }
  }
}
