import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  messageID: {
    type: String,
    primaryKey: true
  },
  acked: {
    type: Date,
    label: 'Bevestigd op',
    optional: true
  },
  date: {
    type: Date,
    label: 'Datum & tijd'
  },
  type: {
    type: String,
    label: 'Type'
  },
  content: {
    type: Object,
    blackbox: true
  }
})
