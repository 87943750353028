import {pull} from '../pull'
import {flatten} from './flatten'
import {map} from './map'

/**
 * @todo
 */
export const flatMap = (mapFn) => (source) => pull(
  source,
  map(mapFn),
  flatten()
)
