/**
 * @todo
 */
export const once = (value) => (start, sink) => {
  if (start !== 0) {
    return
  }
  let ended = false
  sink(0, t => {
    if (t === 2) {
      ended = true
    }
  })
  if (typeof value === 'function') {
    value = value()
  }
  if (value && typeof value.then === 'function') {
    const onfulfilled = v => {
      if (ended) {
        return
      }
      sink(1, v)
      sink(2)
    }
    const onrejected = err => {
      if (ended) {
        return
      }
      sink(2, err)
    }
    value.then(onfulfilled, onrejected)
  } else if (!ended) {
    sink(1, value)
    sink(2)
  }
}
