<template>
    <section v-if="loading" class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>{{ title }}</h1></template>
                    <template slot="content">
                        <p v-if="loading">Bezig met laden...</p>
                    </template>
                </block>
            </div>
        </div>
    </section>
    <vue-form v-else tag="section" :state="form" ref="form" :schema="schema" v-model="user" class="padding" @submit="doSubmit()">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>{{ title }}</h1></template>
                    <template slot="content">
                        <vue-form-group>
                            <vue-form-field column="15" field="title" :options="titleOptions"></vue-form-field>
                            <vue-form-field column="30" field="firstName"></vue-form-field>
                            <vue-form-field column="15" field="infixName"></vue-form-field>
                            <vue-form-field column="40" field="surName"></vue-form-field>
                        </vue-form-group>
                        <vue-form-field field="emailAddress"></vue-form-field>
                        <vue-form-group>
                            <vue-form-field column="30" field="companyPhone" :value="company.generalPhone" :disabled="true"></vue-form-field>
                            <vue-form-field column="35" field="directPhone"></vue-form-field>
                            <vue-form-field column="35" field="mobilePhone"></vue-form-field>
                        </vue-form-group>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h2>Toegang tot Qiri</h2></template>
                    <template slot="content">
                        <vue-form-field v-if="isOwner" field="role" :options="roleOptions"></vue-form-field>
                        <vue-form-field field="expiryDate"></vue-form-field>
                        <vue-form-field field="userName"></vue-form-field>
                        <vue-form-group>
                            <vue-form-field column="50" field="password"></vue-form-field>
                            <vue-form-field column="50" field="repeatPassword"></vue-form-field>
                        </vue-form-group>
                        <label>Privé-IP-adres(-sen) toegang tot Qiri:</label>
                        <WhitelistTable field="whitelist"></WhitelistTable>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block :table="true">
                    <template slot="title"><h2>Gebruik van modules</h2></template>
                    <template slot="content">
                        <AccessTable field="moduleSettings" :role="user.role" :modules="companyModules"></AccessTable>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="content">
                        <button type="button" class="full" title="Opslaan" :disabled="saving" @click.prevent="$refs.form.submit()">
                            <template v-if="saving"><icon name="refresh" :spin="true" /></template>
                            <template v-else>{{ title }}</template>
                        </button>
                        <ul class="errors" v-if="formErrors.length > 0 || submitErrors.length > 0">
                            <li v-for="error of formErrors">
                                <icon name="exclamation-circle"></icon> <vue-markdown :source="error"></vue-markdown>
                            </li>
                            <li v-for="error of submitErrors">
                                <icon name="exclamation-circle"></icon> <vue-markdown :source="error"></vue-markdown>
                            </li>
                        </ul>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid" v-if="false">
            <div class="column size--100">
                <block>
                    <template slot="content">
                        <pre>{{ debugJSON }}</pre>
                    </template>
                </block>
            </div>
        </div>
    </vue-form>
</template>

<script>
    import {createHmac} from 'crypto'
    import uuidv4 from 'uuid/v4'
    import pick from 'lodash/pick'
    import omit from 'lodash/omit'
    import VueMarkdown from '@qiri/vue-markdown'
    import SimpleSchema from '@qiri/simpl-schema'
    import {Credentials} from '@qiri/models/security'
    import {User} from '@qiri/models/environment'
    import {getSystemRoles, getSystemModules} from '@qiri/models/environment/Module'
    import WhitelistTable from '@/components/Partials/WhitelistTable'
    import AccessTable from '@/components/Partials/ModuleAccessTable'

    export default {
        name: 'page-users-Form',
        components: {
            VueMarkdown,
            WhitelistTable,
            AccessTable
        },
        data () {
            return {
                form: {},
                company: null,
                user: null,
                loading: true,
                saving: false,
                submitErrors: []
            }
        },
        async mounted () {
            // Load the company.
            if (!this.companyID) {
                throw new Error(`Missing property "companyID".`)
            }
            this.company = await this.$model.get(this.companyActor, this.companyID)
            if (!this.company) {
                throw new Error(`Company "${this.companyID}" not found.`)
            }

            // Load the user, if any.
            if (this.formMode === 'edit') {
                if (!this.userID) {
                    throw new Error(`Missing property "userID".`)
                }
                // TODO: Omit "passwordHash" on the server.
                this.user = await this.$model.get(this.userActor, this.userID)
                    .then(user => user && omit(user, 'passwordHash'))

                if (!this.user) {
                    throw new Error(`User "${this.userID}" not found.`)
                }
            } else {
                this.user = {
                    userID: uuidv4(),
                    companyID: this.companyID,
                    title: this.titleOptions[0][0],
                    role: null,
                    moduleSettings: null
                }

                // Override defaults for users from the owner's company.
                if (this.isOwner) {
                    // Copy module settings directly from global settings for the "administrator" role.
                    const settings = await this.$model.get('/qiri-settings/Settings', 'global')
                    this.user.role = 'administrator'
                    this.user.moduleSettings = settings.roles.administrator
                } else {
                    this.user.role = 'user'
                    this.user.moduleSettings = this.company.moduleSettings
                        .filter(md => md.enabled)
                        .map(md => ({
                            moduleID: md.moduleID,
                            enabled: true
                        }))
                }
            }

            // Finished loading.
            this.loading = false

            this.$watch('defaultUserName', (newVal, oldVal) => {
                if (!this.user.userName || this.user.userName == oldVal) {
                    this.$set(this.user, 'userName', newVal)
                }
            })
        },
        watch: {
            'user.role' (newValue, oldValue) {
                // For a superadministrator, ensure all modules are enabled.
                if (oldValue && newValue !== oldValue &&
                    (newValue === 'superadministrator' || newValue === 'administrator')) {
                    this.user.moduleSettings = getSystemModules()
                        .filter(sm => sm.roles.indexOf(newValue) !== -1)
                        .map(sm => ({
                            moduleID: sm.moduleID,
                            enabled: true
                        }))
                }
            }
        },
        computed: {
            title () {
                return this.$route.meta.title
            },
            companyID () {
                return this.$route.params.companyID
            },
            isOwner () {
                return this.company && this.company.type === 'owner'
            },
            isCustomer () {
                return this.company && this.company.type === 'customer'
            },
            companyActor () {
                return `${this.$route.meta.company.actorPrefix}/Company`
            },
            userActor () {
                return `${this.$route.meta.company.actorPrefix}/User`
            },
            userID () {
                return this.$route.params.userID
            },
            formMode () {
                return this.$route.meta.form.mode
            },
            formAction () {
                return this.$route.meta.form.action
            },
            schema () {
                const schema = new SimpleSchema({
                    'companyPhone': {
                        type: String,
                        label: 'Telefoonnummer bedrijf',
                        optional: true
                    },
                    'repeatPassword': {
                        type: String,
                        label: 'Herhaal wachtwoord',
                        optional: this.formMode === 'edit',
                        encrypted: true,
                        custom () {
                            if (this.isSet && this.siblingField('password').value !== this.value) {
                                return 'passwordsMustMatch'
                            }
                        }
                    },
                    // Use SHA256 on the client to prevent ever sending the password in clear text.
                    // On the server, this SHA256 hash will be used as input for Bcrypt for the storage format.
                    'passwordHash': {
                        type: String,
                        optional: true,
                        autoValue () {
                            const passwordField = this.siblingField('password')
                            if (passwordField.isSet && passwordField.value) {
                                // TODO: Make salt configurable (or atleast not static).
                                const hash = createHmac('sha256', 'qiri')
                                hash.update(passwordField.value, 'utf8')
                                return hash.digest('hex')
                            }
                        }
                    },
                    // If password gets set in this UI, it always is marked as expired right away.
                    // This forces the password to be temporary and forces the user to change it on first login.
                    'passwordExpiryTimestamp': {
                        type: Date,
                        autoValue () {
                            if (this.siblingField('password').isSet) {
                                return new Date()
                            }
                        }
                    }
                })
                schema.extend(Credentials.pick('password'))
                schema.extend({
                    'password': {
                        type: String,
                        label: 'Gewenst wachtwoord',
                        optional: this.formMode === 'edit'
                    }
                })
                schema.extend(User.omit('passwordHash', 'passwordSetTimestamp'))
                return schema
            },
            titleOptions () {
                const titles = ['De heer', 'Mevrouw']
                return titles.map(title => [title, title])
            },
            roleOptions () {
                return pick(getSystemRoles(), 'administrator', 'superadministrator')
            },
            defaultUserName () {
                if (!this.company.name || (!this.user.firstName && !this.user.infixName && !this.user.surName)) {
                    return ''
                } else {
                    const format = (v) => (v || '').replace(/\W/g, '').toLowerCase()
                    return `${format(this.company.name.split(' ')[0])}.${format(this.user.firstName)}${format(this.user.infixName)}${format(this.user.surName)}`
                }
            },
            companyModules () {
                if (this.isOwner) {
                    return getSystemModules().map(x => x.moduleID)
                } else if (!this.company || !this.company.moduleSettings) {
                    return []
                } else {
                    return this.company.moduleSettings
                        .filter(x => x.enabled)
                        .map(x => x.moduleID)
                }
            },
            formErrors () {
                let messages = []
                if (this.form.errors && this.form.errors.length > 0) {
                    for (const error of this.form.errors) {
                        let message = `${error.message}.`
                        // Don't show duplicate messages.
                        const duplicateMessage = messages.find(x => x === message)
                        if (!duplicateMessage) {
                            messages.push(message)
                        }
                    }
                }
                return messages
            },
            debugJSON () {
                return JSON.stringify(this.user, null, '  ')
            }
        },
        methods: {
            async doSubmit () {
                this.saving = true
                this.submitErrors = []
                try {
                    const record = omit(this.schema.clean(this.user), 'userID', 'password', 'repeatPassword')
                    if (this.formMode === 'create') {
                        await this.$model.create(this.userActor, this.user.userID, record)
                        this.$router.replace({ name: this.formAction, query: this.$route.query })
                    } else {
                        await this.$model.update(this.userActor, this.user.userID, { $set: record })
                        this.$router.push({ name: this.formAction, query: this.$route.query })
                    }
                } catch (err) {
                    if (err.error === 'validation-error') {
                        this.submitErrors = err.details.map(error => error.message)
                    } else {
                        this.submitErrors = [err.message]
                    }
                    this.saving = false
                }
            }
        }
    }
</script>
