import SimpleSchema from '@qiri/simpl-schema'

/**
 * @todo
 */
export function requiredIf (field, predicate) {
  if (typeof predicate === 'string') {
    const value = predicate
    predicate = (v) => v === value
  }
  return function () {
    const shouldBeRequired = predicate(this.siblingField(field).value)
    if (shouldBeRequired) {
      if (!this.operator) {
        if (!this.isSet || this.value === null || this.value === '') {
          return SimpleSchema.ErrorTypes.REQUIRED
        }
      } else if (this.isSet) {
        if (this.operator === '$set' && this.value === null || this.value === '') {
          return SimpleSchema.ErrorTypes.REQUIRED
        } else if (this.operator === '$unset') {
          return SimpleSchema.ErrorTypes.REQUIRED
        } else if (this.operator === '$rename') {
          return SimpleSchema.ErrorTypes.REQUIRED
        }
      }
    }
  }
}

/**
 * @todo
 */
export function applicableIf (field, predicate) {
  if (typeof predicate === 'string') {
    const value = predicate
    predicate = (v) => v === value
  }
  return function () {
    return predicate(this.siblingField(field).value)
  }
}

/**
 * @todo
 */
export function propertyToType (property) {
  const type = property.type.singleType
  if (type === SimpleSchema.Integer) {
    return 'number'
  } else if (type === Number) {
    return 'decimal'
  } else if (type === String) {
    return property.multiline ? 'text' : 'string'
  } else if (type === Boolean) {
    return 'bool'
  } else if (type === Date) {
    return 'date'
  } else if (type === Array) {
    return 'list'
  } else if (SimpleSchema.isSimpleSchema(type)) {
    return 'object'
  } else {
    return 'unknown'
  }
}

/**
 * @todo
 */
export function getFullLabel (property, schema) {
  let labels = []
  let props = []
  for (const prop of property.split('.')) {
    props.push(prop)
    labels.push(schema.label(props.join('.')))
  }
  return labels.join(': ')
}

/**
 * @todo
 */
export function mergeLabelAndDescription (label, description) {
    if (label.indexOf('?') === label.length - 1) {
        return `${label} ${description}`
    } else if (label.indexOf(':') >= 0) {
        return `${label}. ${description}`
    } else {
        return `${label}: ${description}`
    }
}
