import SimpleSchema from '@qiri/simpl-schema'
import Pricing from './Pricing'
import {getAlgorithms} from '../algorithm/Algorithm'

export const getSystemRoles = () => ({
  'superadministrator': 'Superadministrator',
  'administrator': 'Administrator',
  'user': 'Gebruiker'
})

export const getSystemModules = () => [
  {
    moduleID: 'qiri-settings',
    name: 'Basisinstellingen Qiri',
    roles: ['superadministrator'],
    units: []
  },
  {
    moduleID: 'owner',
    name: 'Eigenaar',
    roles: ['superadministrator'],
    units: []
  },
  {
    moduleID: 'customer',
    name: 'Klant',
    roles: ['superadministrator', 'administrator'],
    required: true,
    units: [
      {
        name: 'static.month',
        label: 'per maand',
        factor: 100
      },
      {
        name: 'User.count',
        label: 'per gebruiker',
        factor: 100
      }
    ]
  },
  {
    moduleID: 'data',
    name: 'Data',
    roles: ['superadministrator', 'administrator', 'user'],
    units: [
      {
        name: 'static.month',
        label: 'per maand',
        factor: 100
      },
      {
        name: 'Person.size',
        description: 'Tabellen & velden: Tabel personen',
        label: 'per veld per record',
        factor: 1000000
      },
      {
        name: 'Product.size',
        description: 'Tabellen & velden: Tabel producten',
        label: 'per veld per record',
        factor: 1000000
      },
      {
        name: 'ProductCategory.size',
        description: 'Tabellen & velden: Tabel product categorieën',
        label: 'per veld per record',
        factor: 1000000
      },
      {
        name: 'ProductPromotion.size',
        description: 'Tabellen & velden: Tabel productpromoties',
        label: 'per veld per record',
        factor: 1000000
      },
      {
        name: 'Order.size',
        description: 'Tabellen & velden: Tabel orders',
        label: 'per veld per record',
        factor: 1000000
      },
      {
        name: 'OrderLine.size',
        description: 'Tabellen & velden: Tabel orderregels',
        label: 'per veld per record',
        factor: 1000000
      }
    ]
  },
  {
    moduleID: 'query-import',
    name: 'Data: Query-imports',
    roles: ['superadministrator', 'administrator', 'user'],
    units: []
  },
  {
    moduleID: 'data-export',
    name: 'Data: Data-exports',
    roles: ['superadministrator', 'administrator', 'user'],
    units: [
      {
        name: 'static.month',
        label: 'per maand',
        factor: 100
      },
      {
        name: 'DataExport.fields',
        label: 'per veld per record',
        factor: 1000000
      }
    ]
  },
  {
    moduleID: 'algorithms',
    name: 'Algoritmes: Algoritmes configureren',
    roles: ['superadministrator', 'administrator', 'user'],
    units: [
      {
        name: 'static.month',
        label: 'per maand',
        factor: 100
      }
    ]
  },
  {
    moduleID: 'monitoring',
    name: 'Monitoring: Waarde-ontwikkeling door Qiri-suggesties',
    roles: ['superadministrator', 'administrator', 'user'],
    units: []
  },
  {
    moduleID: 'suggestions',
    name: 'Suggesties: Suggesties configureren',
    roles: ['superadministrator', 'administrator', 'user'],
    units: [
      {
        name: 'static.month',
        label: 'per maand',
        factor: 100
      }
    ]
  },
  {
    moduleID: 'suggestions-query',
    name: 'Suggesties: Suggesties bekijken',
    roles: ['superadministrator', 'administrator', 'user'],
    units: getAlgorithms().filter(algorithm => !algorithm.internal).map(algorithm => ({
      name: `${algorithm.algorithmID}.enabled`,
      description: algorithm.name,
      label: 'per maand',
      factor: 100
    }))
  },
  {
    moduleID: 'api',
    name: 'API: API-tokens',
    roles: ['superadministrator', 'administrator', 'user'],
    units: [
      {
        name: 'static.month',
        label: 'per maand',
        factor: 100
      },
      {
        name: 'APIToken.count',
        label: 'per API-token',
        //description: 'API-tokens',
        factor: 100
      }
    ]
  },
  {
    moduleID: 'api-template',
    name: 'API: API-scripts',
    roles: ['superadministrator', 'administrator', 'user'],
    units: [
      {
        name: 'static.month',
        label: 'per maand',
        factor: 100
      }
    ]
  },
  {
    moduleID: 'api-script-snippets',
    name: 'API: API-script snippets',
    roles: ['superadministrator', 'administrator', 'user'],
    units: [
      {
        name: 'static.month',
        label: 'per maand',
        factor: 100
      }
    ]
  },
  {
    moduleID: 'api-log',
    name: 'API: API-log logregels',
    roles: ['superadministrator', 'administrator', 'user'],
    units: [
      {
        name: 'static.month',
        label: 'per maand',
        factor: 100
      },
      {
        name: 'Suggestions.CsvExport.records',
        label: 'per geëxporteerd record in CSV-bestand',
        description: 'API-log CSV-bestand opvragen',
        overrideModuleName: 'API',
        factor: 1000000
      },
      {
        name: 'APILog.count',
        label: 'per API-verzoek',
        factor: 1000000
      },
      {
        name: 'APILog.scriptCount',
        label: 'per API-script-verzoek',
        factor: 1000000
      }
    ]
  },
  {
    moduleID: 'api-log-export',
    name: 'API: API-log exports',
    roles: ['superadministrator', 'administrator', 'user'],
    units: [
      {
        name: 'static.month',
        label: 'per maand',
        factor: 100
      },
      {
        name: 'APILogExport.fields',
        label: 'per veld per record',
        factor: 1000000
      }
    ]
  },
  {
    moduleID: 'api-reporting',
    name: 'API: API-log rapportage',
    roles: ['superadministrator', 'administrator', 'user'],
    units: [
      {
        name: 'static.month',
        label: 'per maand',
        factor: 100
      }
    ]
  },
  {
    moduleID: 'usage',
    name: 'Verbruik',
    roles: ['superadministrator', 'administrator', 'user'],
    units: [
      {
        name: 'static.month',
        label: 'per maand',
        description: 'Vergoeding licentie',
        factor: 100
      }
    ]
  }
]

export default new SimpleSchema({
  'moduleID': {
    type: String,
    label: 'Qiri module ID',
    description: 'De unieke waarde waarmee de module geïdentificeerd kan worden',
    primaryKey: true
  },
  'name': {
    type: String,
    label: 'Naam',
    description: 'De naam van de module'
  },
  'units': {
    type: Array,
    label: 'Eenheden',
    description: 'De eenheden die uitgelezen kunnen worden'
  },
  'units.$': {
    type: Object,
    label: 'Eenheid',
    description: 'Een eenheid die uitgelezen kan worden'
  },
  'units.$.name': {
    type: String,
    label: 'De naam van de eenheid'
  },
  'units.$.label': {
    type: String,
    label: 'De label van de eenheid'
  },
  'roles': {
    type: Array,
    label: 'Rollen',
    description: 'De rollen die toegang kunnen krijgen tot de module'
  },
  'roles.$': {
    type: String,
    label: 'Rol',
    description: 'Een rol die toegang kan krijgen tot de module',
    allowedValues: Object.keys(getSystemRoles())
  },
  'pricing': {
    type: Array,
    label: 'Vergoedingen',
    description: 'De diverse vergoedingen die van toepassingen zijn op het gebruik van de module'
  },
  'pricing.$': {
    type: Pricing,
    label: 'Vergoeding',
    description: 'Een vergoeding die van toepassing is op het gebruik van de module'
  }
})
