/**
 * @todo
 */
export const take = (max) => (source) => (start, sink) => {
  if (start !== 0) {
    return
  }
  let taken = 0
  let sourceTalkback
  const talkback = (t, d) => {
    if (taken < max) {
      sourceTalkback(t, d)
    }
  }
  source(0, (t, d) => {
    if (t === 0) {
      sourceTalkback = d
      sink(0, talkback)
    } else if (t === 1) {
      if (taken < max) {
        taken++
        sink(t, d)
        if (taken === max) {
          sink(2)
          sourceTalkback(2)
        }
      }
    } else {
      sink(t, d)
    }
  })
}
