// Pages
import Overview from './components/Overview'
import Configure from './components/Configure'

export default {
    'algorithms.overview': {
        path: '/algorithms/overview',
        component: Overview,
        meta: {
            audience: 'actor:/env/*/algorithms/*'
        }
    },
    'algorithms.configure': {
        path: '/algorithms/:algorithmID/configure',
        component: Configure,
        meta: {
            audience: 'actor:/env/*/algorithms/*'
        }
    }
}
