import moment from 'moment-timezone'
import get from 'lodash/get'
import SimpleSchema from '@qiri/simpl-schema'

import Stream, {pull} from '@qiri/stream'
import {iterate} from '@qiri/stream/sources/iterate'
import {map} from '@qiri/stream/operators/map'

/**
 * @todo
 */
export default function field ({schema}, configuration, {timezone}) {
  const single = schema.schema('output').type.singleType !== Array

  const elementSchema = schema.schema(single ? 'output' : 'output.$')
  const elementType = elementSchema.type.singleType === Object && elementSchema.blackbox !== true
    ? schema.getObjectSchema(single ? 'output' : 'output.$')
    : elementSchema.type.singleType

  if (!SimpleSchema.isSimpleSchema(elementType)) {
    return false
  }

  // Create the configuration's schema.
  const configurationSchema = new SimpleSchema({
    field: {
      type: String,
      label: 'Qiri-veld',
      description: 'Kies Qiri-veld.',
      allowedValues: Object.entries(elementType.mergedSchema())
        .filter(([propertyName, property]) => propertyName.indexOf('$') === -1)
        .sort((a, b) => a[0] < b[0] ? -1 : a[0] === b[0] ? 0 : 1)
        .map(([propertyName, property]) => [
          property.label ? `${displayPropertyName(propertyName)} (${property.label})` : displayPropertyName(propertyName),
          propertyName
        ])
    }
  })

  // Just provide the configuration's schema if no configuration was actually provided.
  if (!configuration) {
    return {configurationSchema}
  }

  const {field} = configuration

  const fieldSchema = elementType.schema(field)
  if (!fieldSchema) {
    console.log(`WARNING: Field "${field}" not found in "${elementSchema.name}".`)
    return false
  }
  const fieldSingle = fieldSchema.type.singleType !== Array
  const fieldType = fieldSingle
    ? fieldSchema.type.singleType
    : elementType.schema(`${field}.$`).type.singleType

  let transform
  if (fieldType === Date) {
    transform = map(record => {
      const value = get(record, field)
      if (value) {
        let date = moment.utc(value)
        if (timezone) {
          date = date.tz(timezone)
        }
        return date.format('YYYY-MM-DD HH:mm:ss')
      } else {
        return '0000-00-00 00:00:00'
      }
    })
  } else {
    transform = map(record => get(record, field))
  }

  let sink
  if (single) {
    sink = source => pull(
      source,
      transform
    )
  } else {
    sink = source => pull(
      source,
      map(list => pull(
        iterate(list),
        transform
      ))
    )
  }

  // Provide new schema based on selected field.
  if (single) {
    if (fieldSchema.type.singleType === Array) {
      return {
        configurationSchema,
        schema: new SimpleSchema({
          'output': {
            type: Array,
            name: field,
            label: fieldSchema.label
          },
          'output.$': {
            type: fieldType
          }
        }),
        get [Stream.sink] () {
          return sink
        }
      }
    } else {
      return {
        configurationSchema,
        schema: new SimpleSchema({
          'output': {
            type: fieldType,
            name: field,
            label: fieldSchema.label
          }
        }),
        get [Stream.sink] () {
          return sink
        }
      }
    }
  } else {
    if (fieldSchema.type.singleType === Array) {
      // TODO: Implement.
      throw new Error(`Array fields in one-to-many relations are not (yet) supported.`)
      /*return {
        configurationSchema,
        schema: new SimpleSchema({
          'output': {
            type: Array,
            name: field,
            label: fieldSchema.label
          },
          'output.$': {
            type: Array
          },
          'output.$.$': {
            type: fieldSchema.type.singleType
          }
        }),
        get [Stream.sink] () {
          return sink
        }
      }*/
    } else {
      return {
        configurationSchema,
        schema: new SimpleSchema({
          'output': {
            type: Array,
            name: field,
            label: fieldSchema.label
          },
          'output.$': {
            type: fieldType
          }
        }),
        get [Stream.sink] () {
          return sink
        }
      }
    }
  }
}

/**
 * @private
 */
function displayPropertyName (propertyName) {
  const fields = propertyName.split('.')
  let padding = ''
  for (let i = 0; i < fields.length - 1; i++) {
    padding += '-'
  }
  return `${padding} ${fields[fields.length - 1]}`
}
