// Pages
import ModuleAccess from './components/ModuleAccess'
import AlgorithmAccess from './components/AlgorithmAccess'
import ModulePricing from './components/ModulePricing'
import DataRetention from './components/DataRetention'

import AlgorithmsOverview from '../algorithms/components/Overview'
import AlgorithmsConfigure from '../algorithms/components/Configure'

import SuggestionsOverview from '../suggestions/components/Overview'
import SuggestionsConfigure from '../suggestions/components/Configure'

export default {
    'qiri-settings.module-access': {
        path: '/settings/module-access',
        component: ModuleAccess,
        meta: {
            audience: 'actor:/qiri-settings/Settings'
        }
    },
    'qiri-settings.algorithm-access': {
        path: '/settings/algorithm-access',
        component: AlgorithmAccess,
        meta: {
            audience: 'actor:/qiri-settings/Settings'
        }
    },
    'qiri-settings.module-pricing': {
        path: '/settings/module-pricing',
        component: ModulePricing,
        meta: {
            audience: 'actor:/qiri-settings/Settings'
        }
    },
    'qiri-settings.data-retention': {
        path: '/settings/data-retention',
        component: DataRetention,
        meta: {
            audience: 'actor:/qiri-settings/Settings'
        }
    },
    'qiri-settings.algorithms.overview': {
        path: '/settings/algorithms/overview',
        component: AlgorithmsOverview,
        meta: {
            audience: 'actor:/qiri-settings/ui/algorithms/Overview',
            routePrefix: 'qiri-settings',
            uiActor: '/qiri-settings/ui/algorithms/Overview'
        }
    },
    'qiri-settings.algorithms.configure': {
        path: '/settings/algorithms/:algorithmID/configure',
        component: AlgorithmsConfigure,
        meta: {
            audience: 'actor:/qiri-settings/ui/algorithms/Configure',
            routePrefix: 'qiri-settings',
            uiActor: '/qiri-settings/ui/algorithms/Configure'
        }
    },
    'qiri-settings.suggestions.overview': {
        path: '/settings/suggestions/overview',
        component: SuggestionsOverview,
        meta: {
            audience: 'actor:/qiri-settings/ui/suggestions/Overview',
            routePrefix: 'qiri-settings',
            uiActor: '/qiri-settings/ui/suggestions/Overview'
        }
    },
    'qiri-settings.suggestions.configure': {
        path: '/settings/suggestions/:algorithmID/configure',
        component: SuggestionsConfigure,
        meta: {
            audience: 'actor:/qiri-settings/ui/suggestions/Configure',
            routePrefix: 'qiri-settings',
            uiActor: '/qiri-settings/ui/suggestions/Configure'
        }
    }
}
