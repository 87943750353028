<template>
    <span>Veld "{{ content.sourceField }}" uit bronbestand "<router-link :to="{ name: 'data.feeds.log', params: { feedID: content.feedID }, query: $route.query }">{{ content.feed }}</router-link>" mag niet leeg zijn.</span>
</template>

<script>
    export default {
        name: 'page-status-type-ValidationError',
        props: {
            message: Object
        },
        computed: {
            content () {
                return this.message.content
            }
        }
    }
</script>
