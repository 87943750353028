/**
 * @todo
 */
export const effect = (fn) => (source) => (start, sink) => {
  if (start !== 0) {
    return
  }
  source(0, (t, d) => {
    if (t === 1) {
      const v = fn(d)
      if (v && typeof v.then === 'function') {
        v.then(() => {
          sink(1, d)
        })
      } else {
        sink(1, d)
      }
    } else {
      sink(t, d)
    }
  })
}
