<template>
    <vue-nested-form :schema="schema" field="protocol.settings">
        <vue-form-field field="encryption" :options="encryptionOptions"></vue-form-field>
        <vue-form-field field="host"></vue-form-field>
        <vue-form-field field="port"></vue-form-field>
        <vue-form-field field="username"></vue-form-field>
        <vue-form-field field="password"></vue-form-field>
        <vue-form-field field="location"></vue-form-field>
    </vue-nested-form>
</template>

<script>
    import FTP from '@qiri/models/protocols/FTP'

    export default {
        name: 'page-feeds-protocol-FTP',
        data () {
            return {
                encryptionOptions: [
                    [ null, 'Geen' ],
                    [ 'explicit', 'TLS/SSL Explicit' ],
                    [ 'implicit', 'TLS/SSL Implicit' ]
                ]
            }
        },
        computed: {
            schema () {
                return FTP
            }
        }
    }
</script>
