<template>
    <feed-form mode="create" title="Data-import toevoegen"></feed-form>
</template>

<script>
    import Form from './Form'

    export default {
        name: 'page-feeds-Create',
        components: {
            FeedForm: Form
        }
    }
</script>
