import {noop} from '../util'

/**
 * @todo
 */
export const empty = (fn = noop) => (start, sink) => {
  if (start !== 0) {
    return
  }
  fn()

  let disposed = false

  sink(0, end => {
    if (end !== 2) {
      return
    }
    disposed = true
  })

  if (disposed) {
    return
  }

  sink(2)
}
