import Vue from 'vue'
import VueRouter from 'vue-router'
import extend from 'lodash/extend'
Vue.use(VueRouter)

// Layouts
import CenterLayout from '@/components/Layout/CenterLayout'

// Pages
import NotFound from '@/components/Pages/NotFound'
import Unauthorized from '@/components/Pages/Unauthorized'
import Login from '@/components/Pages/security/Login'
import Logout from '@/components/Pages/security/Logout'
import ChangePassword from '@/components/Pages/security/ChangePassword'
import TermsAndConditions from '@/components/Pages/security/TermsAndConditions'
import Dashboard from '@/components/Pages/Dashboard'
import QiriSettingsRoutes from '@/modules/qiri-settings/routes'
import DocumentationRoutes from '@/modules/documentation/routes'
import OwnerRoutes from '@/modules/owner/routes'
import CustomerRoutes from '@/modules/customer/routes'
import GeneralRoutes from '@/modules/general/routes'
import DataRoutes from '@/modules/data/routes'
import AlgorithmsRoutes from '@/modules/algorithms/routes'
import MonitoringRoutes from '@/modules/monitoring/routes'
import SuggestionsRoutes from '@/modules/suggestions/routes'
import APIRoutes from '@/modules/api/routes'
import APITemplateRoutes from '@/modules/api-template/routes'
import APIScriptSnippetsRoutes from '@/modules/api-script-snippets/routes'
import APILogRoutes from '@/modules/api-log/routes'
import APIReportingRoutes from '@/modules/api-reporting/routes'
import UsageRoutes from '@/modules/usage/routes'

const routesFrom = (routes, audience) => Object.entries(routes).map(([name, route]) => extend(
    route,
    { name }
))

export default new VueRouter({
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    },
    routes: [
        {
            path: '/',
            redirect: '/dashboard'
        },
        {
            path: '/unauthorized',
            name: 'unauthorized',
            component: Unauthorized,
            meta: {
                public: true
            }
        },
        // Authentication
        {
            path: '/login',
            name: 'auth.login',
            component: Login,
            meta: {
                layout: CenterLayout,
                public: true
            }
        },
        {
            path: '/logout',
            name: 'auth.logout',
            component: Logout,
            meta: {
                layout: CenterLayout
            }
        },
        {
            path: '/change-password',
            name: 'auth.change-password',
            component: ChangePassword,
            meta: {
                layout: CenterLayout,
                audience: 'actor:/security/ChangePassword/submit'
            }
        },
        {
            path: '/terms-and-conditions',
            name: 'auth.terms-and-conditions',
            component: TermsAndConditions,
            meta: {
                layout: CenterLayout,
                audience: 'actor:/security/TermsAndConditions/accept'
            }
        },
        // Dashboard
        {
            path: '/dashboard',
            name: 'dashboard',
            component: Dashboard
        },
        // Modules
        ...routesFrom(QiriSettingsRoutes),
        ...routesFrom(DocumentationRoutes),
        ...routesFrom(OwnerRoutes),
        ...routesFrom(CustomerRoutes),
        ...routesFrom(GeneralRoutes),
        ...routesFrom(DataRoutes),
        ...routesFrom(AlgorithmsRoutes),
        ...routesFrom(MonitoringRoutes),
        ...routesFrom(SuggestionsRoutes),
        ...routesFrom(APIRoutes),
        ...routesFrom(APITemplateRoutes),
        ...routesFrom(APIScriptSnippetsRoutes),
        ...routesFrom(APILogRoutes),
        ...routesFrom(APIReportingRoutes),
        ...routesFrom(UsageRoutes),
        // 404
        {
            path: '*',
            name: '404',
            component: NotFound,
            meta: {
                title: '404 Page not found'
            }
        }
    ]
})
