import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  path: {
    type: String,
    label: 'Pad',
    defaultValue: '*',
    optional: true
  }
})
