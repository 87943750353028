var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('section',{staticClass:"padding"},[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"column size--100"},[_c('block',[_c('template',{slot:"title"},[_c('h1',[_vm._v("API-script historie")])]),_vm._v(" "),_c('template',{slot:"content"},[_c('p',[_vm._v("Bezig met laden...")])])],2)],1)])]):_c('section',{staticClass:"padding"},[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"column size--100"},[_c('block',[_c('template',{slot:"title"},[_c('h1',[_vm._v("API-script historie")])]),_vm._v(" "),_c('template',{slot:"content"},[_c('p',[_vm._v("Hier kun je de versies van een API-scripts beheren.")])])],2)],1)]),_vm._v(" "),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"column size--100"},[_c('block',{attrs:{"table":true}},[_c('template',{slot:"content"},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Naam")]),_vm._v(" "),_c('th',[_vm._v("Versie")]),_vm._v(" "),_c('th',{staticClass:"icon"},[_c('router-link',{staticClass:"btn icon",attrs:{"to":{ name: 'api-template.scripts.new-version', query: _vm.$route.query },"title":("API-script versie toevoegen (versie " + (_vm.headScript.version + 1) + ")")}},[_c('icon',{attrs:{"name":"plus"}})],1)],1)])]),_vm._v(" "),_c('tbody',_vm._l((_vm.sortedScripts),function(script){return _c('tr',{key:script.scriptID + '@' + script.version},[_c('td',{staticClass:"icon-inline align--top"},[(script === _vm.headScript && _vm.isNotActive(script))?_c('router-link',{staticClass:"btn icon",attrs:{"to":{
                                            name: 'api-template.scripts.edit-version',
                                            params: {
                                                scriptID: script.scriptID,
                                                version: script.version
                                            },
                                            query: _vm.$route.query
                                        },"title":"API-script versie aanpassen"}},[_c('icon',{attrs:{"name":"pencil"}})],1):_c('router-link',{staticClass:"btn icon",attrs:{"to":{
                                            name: 'api-template.scripts.view-version',
                                            params: {
                                                scriptID: script.scriptID,
                                                version: script.version
                                            },
                                            query: _vm.$route.query
                                        },"title":"API-script versie bekijken"}},[_c('icon',{attrs:{"name":"eye"}})],1),_vm._v("\n                                    "+_vm._s(script.name)+"\n                                ")],1),_vm._v(" "),_c('td',[_vm._v("Versie "+_vm._s(script.version))]),_vm._v(" "),_c('td',{staticClass:"icon align--top"},[(_vm.isNotActive(script))?_c('btn',{staticClass:"icon",attrs:{"icon":"toggle-off","title":("Versie " + (script.version) + " actief maken")},on:{"click":function($event){$event.preventDefault();return _vm.activateScript(script)}}}):_c('btn',{staticClass:"icon",attrs:{"icon":"toggle-on","title":("Versie " + (script.version) + " is actief")}}),_vm._v(" "),_c('btn',{staticClass:"icon",attrs:{"disabled":!_vm.isNotActive(script) || _vm.scripts.length === 0,"icon":"trash","title":"Versie verwijderen"},on:{"click":function($event){$event.preventDefault();return _vm.removeRecord(script)}}})],1)])}),0)])])],2)],1)]),_vm._v(" "),_c('modal',{attrs:{"name":"delete","title":"Weet je zeker dat je deze versie wilt verwijderen?"}},[_c('p',[_vm._v("Wil je API-script versie "+_vm._s(_vm.selectedRecord && _vm.selectedRecord.version)+" verwijderen?")]),_vm._v(" "),_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.removeRecord(_vm.selectedRecord, true)}}},[_vm._v("Ja, verwijderen")]),_vm._v(" "),_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.$root.$emit('modal', 'delete', false)}}},[_vm._v("Nee, annuleren")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }