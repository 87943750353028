export default {
  name: 'VueFormField_Text',
  props: {
    fieldID: String,
    value: String,
    disabled: Boolean
  },
  render (h) {
    return h(
      'textarea',
      {
        attrs: {
          id: this.fieldID,
          disabled: this.disabled
        },
        on: {
          change: (evt) => this.onChange(evt),
          input: (evt) => this.onChange(evt)
        }
      },
      this.value
    )
  },
  methods: {
    onChange (evt) {
      const value = evt.target.value
      this.$emit('input', value)
    }
  }
}
