/**
 * @todo
 */
export const first = () => (source) => {
  let talkback
  let finished = false
  let failed = false
  let result
  let resolve, reject

  source(0, (t, d) => {
    if (t === 0) {
      talkback = d
      talkback(1)
    } else if (t === 1 && !finished) {
      finished = true
      result = d
      resolve && resolve(d)
      talkback(2)
    } else if (t === 2 && !finished) {
      finished = true
      failed = true
      result = d
      if (d) {
        reject && reject(d)
      } else {
        resolve && resolve(d)
      }
    }
  })

  if (finished) {
    if (failed) {
      throw result
    } else {
      return result
    }
  } else {
    return new Promise((_resolve, _reject) => {
      resolve = _resolve
      reject = _reject
    })
  }
}
