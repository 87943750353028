// Pages
import Overview from './components/Overview'
import Configure from './components/Configure'
import Query from './components/Query'

export default {
    'suggestions.overview': {
        path: '/suggestions/overview',
        component: Overview,
        meta: {
            audience: 'actor:/env/*/suggestions/ui/Overview/*'
        }
    },
    'suggestions.configure': {
        path: '/suggestions/:algorithmID/configure',
        component: Configure,
        meta: {
            audience: 'actor:/env/*/suggestions/ui/Configure/*'
        }
    },
    'suggestions.query': {
        path: '/suggestions/query',
        component: Query,
        meta: {
            audience: 'actor:/env/*/suggestions/ui/Query/*'
        }
    }
}
