import isNumber from 'lodash/isNumber'
import moment from 'moment'
import SimpleSchema from '@qiri/simpl-schema'

import Pricing from './Pricing'
import Availability from './Availability'
import ProductFeature from './ProductFeature'
import QuantitySold from './QuantitySold'
import Interval from './Interval'
import Classification from './Classification'
import NutritionalValue from './NutritionalValue'

/**
 * @todo
 */
export function isProductAvailable (product, now) {
  const availability = product.availability
  if (availability) {
    if (availability.unavailable === true) {
      return false
    } else if (availability.availableInWebshop !== true && availability.availableInPhysicalStore !== true) {
      return false
    }
  }

  if (isNumber(product.quantityInStock) && product.quantityInStock < 1) {
    return false
  }

  if (product.availableFrom && moment(now).isBefore(moment(product.availableFrom))) {
    return false
  }
  if (product.availableUntil && moment(now).isAfter(moment(product.availableUntil))) {
    return false
  }

  return true
}

/**
 * @todo
 */
export default new SimpleSchema({
  productID: {
    type: String,
    label: 'Qiri product ID',
    description: 'De unieke waarde waarmee het product geïdentificeerd kan worden.',
    primaryKey: true
  },
  name: {
    type: String,
    label: 'Naam',
    description: 'Naam van het product.'
  },
  description: {
    type: String,
    label: 'Omschrijving',
    description: 'Omschrijving van het product.',
    optional: true,
    multiline: true
  },
  abstractProduct: {
    type: Array,
    label: 'Abstract product',
    description: 'Abstract product van het product.'
  },
  'abstractProduct.$': {
    type: String,
    label: 'Qiri product categorie ID',
    description: 'De categorie van het product.',
    foreignKey: 'ProductCategory'
  },
  abstractProductGoogle: {
    type: String,
    label: 'Abstract product Google',
    description: 'Abstract product van het product volgens lijst van Google (zie https://support.google.com/merchants/answer/6324436).'
  },
  pricing: {
    type: Pricing,
    label: 'Prijzen',
    description: 'De prijzen van het product.'
  },
  unitType: {
    type: String,
    label: 'Eenheid type',
    description: 'De type eenheid van het product (bijv. stuks, liters, gram).'
  },
  unitQuantity: {
    type: Number,
    label: 'Eenheid aantal',
    description: 'Het aantal van de eenheid (bijv. 2 stuks of 100 gram).'
  },
  availability: {
    type: Availability,
    label: 'Beschikbaarheid',
    description: 'De beschikbaarheid van het product.'
  },
  quantityInStock: {
    type: SimpleSchema.Integer,
    label: 'Aantal op voorraad',
    description: 'Het aantal producten op voorraad.'
  },
  availableFrom: {
    type: Date,
    label: 'Beschikbaar vanaf',
    description: 'Datum en tijd vanaf wanneer het product beschikbaar is.'
  },
  availableUntil: {
    type: Date,
    label: 'Beschikbaar tot en met',
    description: 'Datum en tijd vanaf wanneer het product beschikbaar is.'
  },
  features: {
    type: Array,
    label: 'Product eigenschappen',
    description: 'Kenmerken van het product, zoals keurmerken (Bio, Eco, A-merk of huismerk).'
  },
  'features.$': {
    type: ProductFeature,
    label: 'Product eigenschap'
  },
  webshopURL: {
    type: String,
    label: 'URL bestelpagina',
    description: 'URL naar de bestelpagina van het product.'
  },
  imageURL: {
    type: String,
    label: 'URL afbeelding',
    description: 'URL naar een afbeelding van het product.'
  },
  videoURL: {
    type: String,
    label: 'URL video',
    description: 'URL naar een video van het product.'
  },
  rankingNumber: {
    type: SimpleSchema.Integer,
    label: 'Rangordenummer',
    description: 'Rangordenummer waarmee de volgorde waarin het product aangeboden moet worden, aangeduid wordt (oplopend).'
  },
  classification: {
    type: Classification,
    label: 'Classificatie',
    description: 'Geeft aan hoe het product geclassificeerd wordt.',
    optional: true
  },
  nutritionalValue: {
    type: NutritionalValue,
    label: 'Voedingswaarden',
    description: 'De voedingswaarden van het product, indien van toepassing.',
    optional: true
  }
})
