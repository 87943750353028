import SimpleSchema from '@qiri/simpl-schema'

SimpleSchema.setDefaultMessages({
  messages: {
    nl: {
      invalidUserNamePattern: `De gebruikersnaam voldoet niet aan de voorwaarden`,
      invalidPasswordPattern: `Het wachtwoord voldoet niet aan de voorwaarden:
- Niet gelijk zijn aan je huidige wachtwoord.
- Minimaal 8 karakters lang zijn.
- Minimaal 1 kleine letter bevatten.
- Minimaal 1 hoofdletter bevatten.
- Minimaal 1 cijfer bevatten.
- Minimaal 1 speciaal karakter bevatten, anders dan een cijfer of letter.`
    }
  }
})

const USERNAME_REGEXP = /^[a-z0-9]{3,}\.[a-z0-9]{3,}/
//const PASSWORD_REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}/
const PASSWORD_REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).{8,}/

export default new SimpleSchema({
  'userName': {
    type: String,
    label: 'Gebruikersnaam',
    custom () {
      if (this.isSet && !USERNAME_REGEXP.test(this.value)) {
        return 'invalidUserNamePattern'
      }
    }
  },
  'password': {
    type: String,
    label: 'Wachtwoord',
    encrypted: true,
    custom () {
      if (this.isSet && !PASSWORD_REGEXP.test(this.value)) {
        return 'invalidPasswordPattern'
      }
    }
  }
})
