import isString from 'lodash/isString'
import doFetch from 'node-fetch'
import SimpleSchema from '@qiri/simpl-schema'
import Handlebars from '@qiri/handlebars'

import Stream, {pull} from '@qiri/stream'
import {map} from '@qiri/stream/operators/map'

/**
 * @todo
 */
export const meta = {
  name: 'fetch',
  label: 'Externe waarde ophalen'
}

/**
 * @todo
 */
export default function fetch ({schema}, configuration) {
  const elementSchema = schema.schema('output')

  // Create the configuration's schema if input is an object.
  let configurationSchema
  let mappingFn
  if (SimpleSchema.isSimpleSchema(elementSchema.type.singleType) || elementSchema.type.singleType === Object) {
    configurationSchema = new SimpleSchema({
      url: {
        type: String,
        label: 'URL',
        description: 'De URL welke opgehaald dient te worden.'
      }
    })

    // Just provide the configuration's schema if no configuration was actually provided.
    if (!configuration) {
      return {
        meta,
        configurationSchema
      }
    }

    // Allow the URL to contain placeholders based in input record.
    const urlFn = Handlebars.compile(configuration.url)
    mappingFn = record => urlFn(record)
  } else {
    return false
  }

  const sink = source => pull(
    source,
    map(async (value) => {
      const url = mappingFn(value)
      if (isString(url) && url.indexOf('http') === 0) {
        let retries = 0
        let lastError
        while (retries < 10) {
          try {
            const response = await doFetch(url)
            if (!response.ok) {
              throw new Error(`Did not get an "OK" response.`)
            }
            return await response.text()
          } catch (err) {
            lastError = err
            console.error(err)
            retries++
          }
        }
        //throw lastError
        return ''
      } else {
        return ''
      }
    })
  )

  return {
    meta,
    configurationSchema,
    schema: new SimpleSchema({
      'output': {
        type: String
      }
    }),
    get [Stream.sink] () {
      return sink
    }
  }
}
