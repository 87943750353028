export default {
  name: 'VueFormField_Integer',
  props: {
    fieldID: String,
    value: [String, Number],
    inline: Boolean,
    disabled: Boolean
  },
  render (h) {
    return h(
      'input',
      {
        staticClass: this.inline ? 'inline' : '',
        attrs: {
          id: this.fieldID,
          type: 'number',
          value: this.value,
          disabled: this.disabled
        },
        on: {
          change: (evt) => this.onChange(evt),
          input: (evt) => this.onChange(evt)
        }
      }
    )
  },
  methods: {
    onChange (evt) {
      let value = evt.target.value
      if (typeof value === 'string' && value.length > 0) {
        value = Number(value)
        if (isNaN(value)) {
          value = null
        }
      }
      this.$emit('input', value)
    }
  }
}
