import {decode} from 'base64url'
import {pull} from '@qiri/stream/pull'
import {filter} from '@qiri/stream/operators/filter'
import {first} from '@qiri/stream/sinks/first'

/**
 * @private
 */
const TOKEN_NAME = 'qiri-security-token'

/**
 * @private
 */
const USER_PROFILE_NAME = 'qiri-user-profile'

/**
 * @todo
 */
export default function install (Vue, options) {
  // Sync security token between active tabs.
  /*const syncTokenRequest = `${TOKEN_NAME}-REQUEST`
  const syncTokenResponse = `${TOKEN_NAME}-RESPONSE`
  const syncToken = (event) => {
    if (!event) {
      event = window.event
    }
    if (!event.newValue) {
      return
    }
    if (event.key === syncTokenRequest) {
      window.localStorage.setItem(syncTokenResponse, window.sessionStorage.getItem(TOKEN_NAME))
      window.localStorage.removeItem(syncTokenResponse)
    } else if (event.key === syncTokenResponse && !window.sessionStorage.getItem(TOKEN_NAME)) {
      window.sessionStorage.setItem(TOKEN_NAME, event.newValue)
    }
  }
  if(window.addEventListener) {
    window.addEventListener('storage', syncToken, false)
  } else {
    window.attachEvent('onstorage', syncToken)
  }
  if (!window.sessionStorage.getItem(TOKEN_NAME)) {
    window.localStorage.setItem(syncTokenRequest, 'dummy')
    window.localStorage.removeItem(syncTokenRequest)
  }*/

  // Setup plugin's model.
  const security = new Vue({
    data: {
      userProfile: JSON.parse(window.localStorage.getItem(USER_PROFILE_NAME) || '{}'),
      encodedToken: window.sessionStorage.getItem(TOKEN_NAME),
      environmentID: null,
      scopes: {},
      expiryTimeLeft: -1
    },
    watch: {
      'encodedToken' (encodedToken) {
        if (encodedToken) {
          window.sessionStorage.setItem(TOKEN_NAME, encodedToken)
        } else {
          window.sessionStorage.removeItem(TOKEN_NAME)
        }
      }
    }
  })

  let ignoreUserProfile = false
  Object.defineProperty(Vue.prototype, '$userProfile', {
    get () {
      return security.userProfile
    },
    set (userProfile) {
      ignoreUserProfile = true
      Vue.set(security, 'userProfile', userProfile || {})
      if (userProfile) {
        window.localStorage.setItem(USER_PROFILE_NAME, JSON.stringify(userProfile))
      } else {
        window.localStorage.removeItem(USER_PROFILE_NAME)
      }
      Vue.nextTick(() => {
        ignoreUserProfile = false
      })
    }
  })
  const syncUserProfile = (userProfile) => {
    // Ignore if no token is set.
    if (ignoreUserProfile || !security.encodedToken) {
      return
    }
    window.localStorage.setItem(USER_PROFILE_NAME, JSON.stringify(userProfile))

    const api = security.$api
    if (api) {
      api.dispatch(`/security/UserProfile/put`, userProfile)
    }
  }
  security.$watch('$userProfile', syncUserProfile, {deep: true})

  Object.defineProperty(Vue.prototype, '$encodedToken', {
    get () {
      return security.encodedToken
    },
    set (encodedToken) {
      if (security.encodedToken !== encodedToken) {
        security.encodedToken = encodedToken
      }
    }
  })

  Object.defineProperty(Vue.prototype, '$token', {
    get () {
      const encodedToken = security.encodedToken
      if (encodedToken) {
          return decodeToken(encodedToken)
      } else {
          return {}
      }
    }
  })

  Object.defineProperty(Vue.prototype, '$environment', {
    get () {
      const token = this.$token
      return (token.env ? token.env.id : null) || security.environmentID
    },
    set (environmentID) {
      security.environmentID = environmentID
    }
  })

  Vue.prototype.$decodeToken = decodeToken

  Object.defineProperty(Vue.prototype, '$security', {
    get () {
      const api = this.$api
      const environmentID = this.$environment
      return {
        async access (scope) {
          const scopeKey = `qiri-scope-${scope}-${environmentID}-token`
          let token = window.sessionStorage.getItem(scopeKey)
          // TODO: Check if token has expired.
          if (!token) {
            // Request access for the given scope.
            const tokenID = await api.dispatch('/security/Authenticate/access', { scope, environment: environmentID })
            if (!tokenID) {
              return null
            }

            // Observe the generated API token ID, wait for the JWT token to be assigned.
            const cursor = await api.call(`/env/${environmentID}/api/APIToken/watch`, { tokenID })
            const apiToken = await pull(
              cursor,
              filter(x => x.token),
              first()
            )
            token = apiToken.token

            // Store the resulting token in the session storage.
            window.sessionStorage.setItem(scopeKey, token)
          }
          return token
        },
        clear () {
          window.sessionStorage.clear()
          window.localStorage.clear()
          security.encodedToken = null
        },
        get expiryTimeLeft () {
          return security.expiryTimeLeft
        }
      }
    }
  })

  // Watch token and keep a counter with how many seconds the token is still valid for.
  let expiryHandle
  let expiryDate

  const checkExpiryDate = () => {
    security.expiryTimeLeft = Math.max(0, Math.round((expiryDate.getTime() - Date.now()) / 1000))
  }
  const checkExpiryDateOnToken = token => {
    if (expiryHandle) {
      clearInterval(expiryHandle)
      expiryHandle = null
      expiryDate = null
    }
    if (token) {
      expiryDate = new Date(token.exp * 1000)
      checkExpiryDate()
      expiryHandle = setInterval(checkExpiryDate, 1000)
    } else {
      security.expiryTimeLeft = -1
    }
  }
  security.$watch('$token', checkExpiryDateOnToken, {immediate: true})
}

/**
 * @private
 */
function decodeToken (encodedToken) {
  return JSON.parse(decode(encodedToken.split('.')[1]))
}
