<template>
    <p>
        {{ label }}:
        <button v-for="item of items" :class="isSelected(item[0]) ? '' : 'white'" @click.prevent="onSelect(item[0])">{{ item[1] }}</button>
    </p>
</template>

<script>
    export default {
        name: 'ButtonSelect',
        props: {
            label: String,
            value: [Array, String],
            options: [Array, Object],
            multi: {
                type: Boolean,
                default: true
            }
        },
        data () {
            return {
                selected: this.multi
                    ? Array.isArray(this.value) ? this.value : [this.value]
                    : this.value
            }
        },
        watch: {
            'selected' (selected) {
                this.$emit('input', selected)
            }
        },
        computed: {
            items () {
                return Array.isArray(this.options) ? this.options : Object.entries(this.options)
            }
        },
        methods: {
            isSelected (value) {
                if (this.multi) {
                    return this.selected.indexOf(value) >= 0
                } else {
                    return this.selected === value
                }
            },
            onSelect (value) {
                if (this.multi) {
                    const index = this.selected.indexOf(value)
                    if (index >= 0) {
                        this.selected.splice(index, 1)
                    } else {
                        this.selected.push(value)
                    }
                } else {
                    this.selected = value
                }
            }
        }
    }
</script>

<style scoped>
    button {
        margin-right: 10px;
    }
</style>
