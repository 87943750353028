<template>
    <section v-if="loading" class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>{{ title }}</h1></template>
                    <template slot="content">
                        <p v-if="loading">Bezig met laden...</p>
                    </template>
                </block>
            </div>
        </div>
    </section>
    <vue-form v-else tag="section" :state="form" ref="form" :schema="schema" v-model="company" class="padding" @submit="doSubmit()">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>{{ title }}</h1></template>
                    <template slot="content">
                        <vue-form-field field="name"></vue-form-field>
                        <vue-form-group>
                            <vue-form-field column="50" field="ccRegistrationNumber"></vue-form-field>
                            <vue-form-field column="50" field="vatNumber"></vue-form-field>
                        </vue-form-group>
                        <vue-form-group>
                            <vue-form-field column="50" field="generalPhone"></vue-form-field>
                            <vue-form-field column="50" field="websiteURL"></vue-form-field>
                        </vue-form-group>
                        <vue-form-field v-if="isOwner" field="termsAndConditionsURL"></vue-form-field>
                        <vue-form-field v-if="isCustomer" field="invoicingEmailAddress"></vue-form-field>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--50">
                <block>
                    <template slot="title"><h2>Vestigingsadres</h2></template>
                    <template slot="content">
                        <AddressForm field="visitAddress"></AddressForm>
                    </template>
                </block>
            </div>
            <div class="column size--50">
                <block>
                    <template slot="title"><h2>Postadres</h2></template>
                    <template slot="content" v-if="company.postAddress">
                        <AddressForm field="postAddress"></AddressForm>
                        <br>
                        <button type="button" class="full" @click.prevent="removeAddress('postAddress')">
                            Postadres is hetzelfde als het vestigingsadres
                        </button>
                    </template>
                    <template slot="content" v-else>
                        <button type="button" class="full" @click.prevent="createAddress('postAddress')">
                            Postadres is anders dan het vestigingsadres
                        </button>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h2>Toegang tot Qiri</h2></template>
                    <template slot="content">
                        <vue-form-field v-if="isCustomer" field="expiryDate"></vue-form-field>
                        <label>IP-adres(-sen) bedrijfsvestiging(-en) met toegang tot Qiri:</label>
                        <WhitelistTable field="whitelist"></WhitelistTable>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid" v-if="isCustomer">
            <div class="column size--100">
                <block :table="true">
                    <template slot="title"><h2>Gebruik van modules</h2></template>
                    <template slot="content">
                        <ModuleAccessTable field="moduleSettings" role="user"></ModuleAccessTable>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid" v-if="isCustomer">
            <div class="column size--100">
                <block :table="true">
                    <template slot="title"><h2>Gebruik van algoritmes</h2></template>
                    <template slot="content">
                        <AlgorithmAccessTable field="algorithmSettings"></AlgorithmAccessTable>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid" v-if="isCustomer">
            <div class="column size--100">
                <block :table="true">
                    <template slot="title"><h2>Licentie voor gebruik</h2></template>
                    <template slot="content">
                        <ModulePricingTable field="moduleSettings"></ModulePricingTable>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid" v-if="isCustomer">
            <div class="column size--100">
                <block :table="true">
                    <template slot="title"><h2>Data bewaartermijn</h2></template>
                    <template slot="content">
                        <DataRetentionTable field="dataRetention"></DataRetentionTable>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="content">
                        <button type="button" class="full" title="Opslaan" :disabled="saving" @click.prevent="$refs.form.submit()">
                            <template v-if="saving"><icon name="refresh" :spin="true" /></template>
                            <template v-else>{{ title }}</template>
                        </button>
                        <ul class="errors" v-if="formErrors.length > 0">
                            <li v-for="error of formErrors">
                                <icon name="exclamation-circle"></icon> {{ error }}
                            </li>
                        </ul>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid" v-if="false">
            <div class="column size--100">
                <block>
                    <template slot="content">
                        <pre>{{ debugJSON }}</pre>
                    </template>
                </block>
            </div>
        </div>
    </vue-form>
</template>

<script>
    import uuidv4 from 'uuid/v4'
    import omit from 'lodash/omit'
    import get from 'lodash/get'
    import slugify from 'slugify'
    import AddressForm from './AddressForm'
    import WhitelistTable from '@/components/Partials/WhitelistTable'
    import ModuleAccessTable from '@/components/Partials/ModuleAccessTable'
    import AlgorithmAccessTable from '@/components/Partials/AlgorithmAccessTable'
    import ModulePricingTable from '@/components/Partials/ModulePricingTable'
    import DataRetentionTable from '@/components/Partials/DataRetentionTable'

    /**
     * @private
     */
    const simplifyName = (name) => slugify(name, { remove: /\W/g, lower: true })

    export default {
        name: 'page-companies-Form',
        components: {
            AddressForm,
            WhitelistTable,
            ModuleAccessTable,
            AlgorithmAccessTable,
            ModulePricingTable,
            DataRetentionTable
        },
        data () {
            return {
                form: {},
                allCompanies: [],
                company: null,
                loading: true,
                saving: false
            }
        },
        async mounted () {
            this.$model.list(`${this.companyActor}/list`, 'allCompanies')

            // Load the company, if any.
            if (this.formMode === 'edit') {
                if (!this.companyID) {
                    throw new Error(`Missing property "companyID".`)
                }
                this.company = await this.$model.get(this.companyActor, this.companyID)
                if (!this.company) {
                    throw new Error(`Company "${this.companyID}" not found.`)
                }

                // Ensure new fields are added.
                if (!this.company.dataRetention) {
                    this.$set(this.company, 'dataRetention', [])
                }

            } else {
                this.company = {
                    companyID: uuidv4(),
                    type: this.companyType,
                    moduleSettings: [],
                    algorithmSettings: [],
                    dataRetention: []
                }

                // Copy module settings from global/default if customer for "user" role.
                if (this.isCustomer) {
                    const settings = await this.$model.get('/qiri-settings/Settings', 'global')
                    this.company.moduleSettings = settings.defaultPricing.map(priceSetting => ({
                        moduleID: priceSetting.moduleID,
                        enabled: get(settings.roles.user.find(x => x.moduleID === priceSetting.moduleID), 'enabled', false),
                        pricing: priceSetting.pricing
                    }))
                    this.company.algorithmSettings = settings.algorithmSettings.map(x => ({
                        algorithmID: x.algorithmID,
                        enabled: x.enabled
                    }))
                    this.company.dataRetention = settings.dataRetention.map(x => ({
                        model: x.model,
                        days: x.days
                    }))
                }
            }

            // Finished loading.
            this.loading = false
        },
        computed: {
            title () {
                return this.$route.meta.title
            },
            companyType () {
                return this.$route.meta.company.type
            },
            isOwner () {
                return this.companyType === 'owner'
            },
            isCustomer () {
                return this.companyType === 'customer'
            },
            companyActor () {
                return `${this.$route.meta.company.actorPrefix}/Company`
            },
            companyID () {
                return this.$route.params.companyID
            },
            formMode () {
                return this.$route.meta.form.mode
            },
            formAction () {
                return this.$route.meta.form.action
            },
            schema () {
                const schema = this.$route.meta.company.model.omit('users')

                // Add validation to ensure the company name is unique.
                schema.addDocValidator(doc => {
                  let errors = []
                  if (doc.name) {
                    const name = simplifyName(doc.name)
                    if (this.allCompanies.some(company => company.companyID !== doc.companyID && simplifyName(company.name) === name)) {
                        errors.push({
                            name: 'name',
                            type: 'mustBeUnique',
                            value: doc.name
                        })
                    }
                  }
                  return errors
                })

                return schema
            },
            formErrors () {
                let messages = []
                if (this.form.errors && this.form.errors.length > 0) {
                    for (const error of this.form.errors) {
                        let message = `${error.message}.`
                        // Don't show duplicate messages.
                        const duplicateMessage = messages.find(x => x === message)
                        if (!duplicateMessage) {
                            messages.push(message)
                        }
                    }
                }
                return messages
            },
            companyModules () {
                if (!this.company || !this.company.moduleSettings) {
                    return []
                } else {
                    return this.company.moduleSettings
                        .filter(x => x.enabled)
                        .map(x => x.moduleID)
                }
            },
            debugJSON () {
                return JSON.stringify(this.company, null, '  ')
            }
        },
        methods: {
            createAddress (field) {
                this.$set(this.company, field, {})
            },
            removeAddress (field) {
                this.$set(this.company, field, null)
            },
            async doSubmit () {
                this.saving = true
                if (this.formMode === 'create') {
                    await this.$model.create(this.companyActor, this.company.companyID, omit(this.company, 'companyID'))
                    this.$router.replace({ name: this.formAction, query: this.$route.query })
                } else {
                    await this.$model.update(this.companyActor, this.company.companyID, { $set: omit(this.company, 'companyID') })
                    this.$router.push({ name: this.formAction, query: this.$route.query })
                }
            }
        }
    }
</script>
