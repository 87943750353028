import SimpleSchema from '@qiri/simpl-schema'
import {getSystemRoles} from '../environment/Module'
import ModuleSettings from '../environment/ModuleSettings'
import AlgorithmSettings from '../algorithm/AlgorithmSettings'
import DataRetention from '../data/DataRetention'

const SettingsPerRole = new SimpleSchema(Object.entries(getSystemRoles()).reduce(
  (acc, [key, value]) => {
    acc[key] = {
      type: Array,
      label: value
    }
    acc[`${key}.$`] = {
      type: ModuleSettings.pick('moduleID', 'enabled')
    }
    return acc
  },
  {}
))

export default new SimpleSchema({
  'settingsID': {
    type: String,
    label: 'Instellingen ID',
    description: 'De unieke waarde waarmee de instellingen geïdentificeerd worden',
    primaryKey: true
  },
  'roles': SettingsPerRole,
  'defaultPricing': {
    type: Array,
    label: 'Standaard licentie per module',
    defaultValue: []
  },
  'defaultPricing.$': {
    type: ModuleSettings.pick('moduleID', 'pricing'),
    label: 'Standaard licentie voor module'
  },
  'algorithmSettings': {
    type: Array,
    label: 'Algoritme instellingen',
    description: 'Standaard instellingen van de algoritmes',
    defaultValue: []
  },
  'algorithmSettings.$': {
    type: AlgorithmSettings,
    label: 'Algoritme instellingen',
    description: 'Standaard instellingen voor een algoritme'
  },
  'dataRetention': {
    type: Array,
    label: 'Data bewaartermijn instellingen',
    description: 'Standaard instellingen voor data bewaartermijn',
    defaultValue: []
  },
  'dataRetention.$': {
    type: DataRetention,
    label: 'Data bewaartermijn instellingen',
    description: 'Standaard instellingen voor data bewaartermijn'
  }
})
