import dnode from '@qiri/dnode/browser'
import {WebSocket} from '@qiri/ws/browser'

import promisify from './promisify'

const APP_HOST = process.env.NODE_ENV === 'production' || window.location.protocol === 'https:'
  ? window.location.hostname
  : `${window.location.hostname}:${parseInt(window.location.port, 10) + 1}`

const API_HOST = process.env.NODE_ENV === 'production' || window.location.protocol === 'https:'
  ? window.location.hostname.replace(/app([^\.]*)\.(.*)/, 'api$1.$2')
  : `${window.location.hostname}:${parseInt(window.location.port, 10) + 1}`

const SNIPPETS_HOST = API_HOST.match(/api[0-9]*\.qiri\.nl/i) ? 'qiri.app' : API_HOST

export default function install (Vue, options) {
  const rpc = new Vue({
    data: {
      instance: null,
      state: 'initial'
    }
  })

  const onError = (err) => {
    console.error(err)
  }

  let client = null
  const connect = (encodedToken) => {
    rpc.state = 'connecting'

    // Create a websocket with the server.
    const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws'
    client = WebSocket.connect(`${protocol}://${APP_HOST}/ws?token=${encodedToken}`)
    client.on('connect', (s) => {
      const d = dnode()
      d.on('fail', onError)
      d.on('error', onError)
      d.on('remote', async (instance) => {
        rpc.instance = (component) => promisify(s, component, instance)
        rpc.state = 'connected'
      })
      s.pipe(d).pipe(s)
    })
  }
  const disconnect = () => {
    if (client) {
      rpc.state = 'disconnected'
      rpc.instance = null
      client.disconnect()
      client = null
    }
  }

  rpc.$watch(
    () => rpc.$encodedToken,
    (encodedToken) => {
      if (encodedToken && !client) {
        connect(encodedToken)
      } else if (!encodedToken && client) {
        disconnect()
      }
    },
    { immediate: true }
  )

  Object.defineProperty(Vue.prototype, '$rpc', {
    get () {
      const component = this
      if (rpc.$encodedToken && rpc.instance) {
        const instance = rpc.instance(component)
        return {
          access () {
            return instance.access(rpc.$encodedToken)
          }
        }
      } else {
        return null
      }
    }
  })


  // TODO: Optimze by caching "access"/api as long as the token didn't change.
  const accessAPI = (component) => new Promise((resolve) => {
    const rpc = component.$rpc
    if (rpc) {
      rpc.access().then(api => resolve(api))
    } else {
      const unwatch = component.$watch(() => component.$rpc, async (rpc) => {
        if (rpc) {
          unwatch()
          resolve(await rpc.access())
        }
      })
    }
  })

  Object.defineProperty(Vue.prototype, '$api', {
    get () {
      const component = this
      return {
        get state () {
          return rpc.state
        },
        host (protocol = 'http', context = 'app') {
          if (window.location.protocol === 'https:') {
            protocol += 's'
          }
          let host
          if (context === 'app') {
            host = APP_HOST
          } else if (context === 'api') {
            host = API_HOST
          } else if (context === 'snippets') {
            host = SNIPPETS_HOST
          } else {
            throw new Error(`Unknown context "${context}".`)
          }
          return `${protocol}://${host}`
        },
        async call (path, payload, options) {
          const api = await accessAPI(component)
          return await api.rootActor.call(path, payload, options)
        },
        async dispatch (path, payload, options) {
          const api = await accessAPI(component)
          return await api.rootActor.dispatch(path, payload, options)
        }
      }
    }
  })
}
