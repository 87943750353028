<template>
    <table class="table form-padding">
        <tbody>
            <tr v-for="(ipAddress, index) of whitelist" :key="tag(ipAddress)">
                <td>
                    <vue-form-field v-if="index < whitelist.length - 1" :field="`${field}.${index}.description`" :bare="true"></vue-form-field>
                    <vue-form-field v-else :field="`${field}.$.description`" :bare="true" v-model="newRecord.description"></vue-form-field>
                </td>
                <td>
                    <vue-form-field v-if="index < whitelist.length - 1" :field="`${field}.${index}.address`" :bare="true"></vue-form-field>
                    <vue-form-field v-else :field="`${field}.$.address`" :bare="true" v-model="newRecord.address"></vue-form-field>
                </td>
                <td class="icon">
                    <btn v-if="index < whitelist.length - 1" icon="trash" title="Verwijderen" class="icon" @click.prevent="remove(index)"></btn>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    import get from 'lodash/get'
    import uuidv4 from 'uuid/v4'
    import SimpleSchema from '@qiri/simpl-schema'
    import {IPAddress} from '@qiri/models/environment'
    import {VueFormContext} from '@qiri/vue-form/providers'

    const TAG = Symbol('tag')

    export default {
        name: 'partial-WhitelistTable',
        inject: {
            form: {
              from: VueFormContext
            }
        },
        props: {
            field: String
        },
        data () {
            return {
                newRecord: {
                    description: '',
                    address: ''
                }
            }
        },
        mounted () {
            this.$watch(
                'newRecord',
                async (record) => {
                    if (record.description !== '' || record.address !== '') {
                        let whitelist = get(this.form.value, this.field)
                        if (whitelist) {
                            whitelist.push(record)
                            this.newRecord = {
                                description: '',
                                address: ''
                            }
                        }
                    }
                },
                { deep: true }
            )
        },
        computed: {
            whitelist () {
                return [
                    ...get(this.form.value, this.field, []),
                    this.newRecord
                ]
            }
        },
        methods: {
            tag (obj) {
                if (obj && !obj[TAG]) {
                    obj[TAG] = uuidv4()
                }
                return obj && obj[TAG]
            },
            remove (index) {
                let whitelist = get(this.form.value, this.field)
                if (whitelist) {
                    whitelist.splice(index, 1)
                }
            }
        }
    }
</script>
