import SimpleSchema from '@qiri/simpl-schema'
import Pricing from './Pricing'

export default new SimpleSchema({
  'moduleID': {
    type: String,
    label: 'Qiri module ID',
    description: 'Het ID van de module',
    foreignKey: 'Module'
  },
  'enabled': {
    type: Boolean,
    label: 'Gebruik toestaan',
    description: 'Geeft aan of de module is ingeschakeld en gebruikt mag worden'
  },
  'pricing': {
    type: Array,
    label: 'Vergoedingen',
    description: 'De diverse vergoedingen die van toepassingen zijn op het gebruik van de module'
  },
  'pricing.$': {
    type: Pricing,
    label: 'Vergoeding',
    description: 'Een vergoeding die van toepassing is op het gebruik van de module'
  }
})
