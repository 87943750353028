import VueForm from './components/VueForm'
import VueNestedForm from './components/VueNestedForm'
import VueFormGroup from './components/VueFormGroup'
import VueFormField from './components/VueFormField'

import focus from './directives/focus'

export default function install (Vue, options) {
  Vue.component('vue-form', VueForm)
  Vue.component('vue-nested-form', VueNestedForm)
  Vue.component('vue-form-group', VueFormGroup)
  Vue.component('vue-form-field', VueFormField(Vue))

  Vue.directive('focus', focus)
}
