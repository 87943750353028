import moment from 'moment'
import SimpleSchema from '@qiri/simpl-schema'
import ProductModel from '../data/Product'
import OrderModel from '../data/Order'
import OrderLineModel from '../data/OrderLine'
import OrderBy from './OrderBy'
import {renderProductName} from './util'

export const Configuration = new SimpleSchema({
  'orderCount': {
    type: SimpleSchema.Integer,
    label: 'Aantal orders om te analyseren',
    description: 'Het aantal (meest recente) orders dat gebruikt wordt om het persoonlijke tijdsinterval en persoonlijke aantal producten te berekenen (maximaal 10, standaard 4).',
    min: 2,
    max: 10
  },
  'intervalFormula': {
    type: String,
    label: 'Formule persoonlijk tijdsinterval',
    description: 'De formule waarmee het persoonlijke tijdsinterval berekend wordt (standaard “Mediaan”).',
    defaultValue: 'MEDIAN',
    allowedValues: {
      'Gemiddelde': 'AVERAGE',
      'Mediaan': 'MEDIAN'
    }
  },
  'quantityFormula': {
    type: String,
    label: 'Formule persoonlijk aantal producten',
    description: 'De formule waarmee het persoonlijke aantal producten berekend wordt (standaard “Mediaan”).',
    defaultValue: 'MEDIAN',
    allowedValues: {
      'Gemiddelde': 'AVERAGE',
      'Mediaan': 'MEDIAN',
      'Laatste': 'LAST'
    }
  }
})

export const Input = {
  Order: ['personID', 'timestamp'],
  OrderLine: ['orderID', 'productID', 'quantity']
}

export const Output = ({model} = {}) => new SimpleSchema({
  'product': {
    type: model ? model('Product').type : ProductModel,
    label: 'Product',
    table: 'Producten'
  },
  'details': {
    type: Object
  },
  'details.intervalType': {
    type: String
  },
  'details.interval': {
    type: Number,
    label: 'Persoonlijk tijdsinterval',
    index: true
  },
  'details.quantity': {
    type: Number,
    label: 'Persoonlijk aantal producten'
  },
  'details.lastPurchaseQuantity': {
    type: SimpleSchema.Integer
  },
  'details.lastPurchaseDate': {
    type: Date
  },
  'details.daysSinceLastPurchase': {
    type: SimpleSchema.Integer
  },
  'details.expectedRepeatInterval': {
    type: SimpleSchema.Integer
  },
  'details.expectedNextPurchaseDate': {
    type: Date,
    label: 'Verwachte eerstvolgende productaanschaf',
    index: true
  }
})

export const Preview = new SimpleSchema({
  'name': {
    type: String,
    label: 'Productnaam',
    render: renderProductName
  },
  'lastPurchaseDate': {
    type: String,
    label: 'Laatste aankoopdatum',
    render (record) {
      return moment(record.details.lastPurchaseDate).format('D-M-YYYY')
    }
  },
  'lastPurchaseQuantity': {
    type: String,
    label: 'Aantal producten laatste aankoop',
    render (record) {
      if (typeof record.details.lastPurchaseQuantity === 'number') {
        return `${record.details.lastPurchaseQuantity}`
      } else {
        return 'N.v.t.'
      }
    }
  },
  'interval': {
    type: String,
    label: 'Persoonlijk tijdsinterval (dagen)',
    render (record) {
      return record.details.interval
    }
  },
  'expectedRepeatInterval': {
    type: String,
    label: 'Nummer huidige productaankoop-cyclus',
    render (record) {
      if (typeof record.details.expectedRepeatInterval === 'number') {
        return `${record.details.expectedRepeatInterval}`
      } else {
        return 'N.v.t.'
      }
    }
  }
})

export const Request = new SimpleSchema({
  'person': {
    type: String,
    label: 'Persoon',
    description: 'De persoon voor wie de suggesties opgevraagd worden. Alleen de producten die al door deze persoon zijn gekocht, worden in de lijst getoond.'
  },
  'date': {
    type: Date,
    label: 'Datum',
    description: 'De datum waarop de voorspelling wordt gedaan. Indien leeg wordt ‘vandaag’ als datum gebruikt.',
    optional: true
  },
  'minInterval': {
    type: SimpleSchema.Integer,
    label: 'Minimale persoonlijke tijdsinterval',
    description: 'Indien ingevuld, worden producten met een kleinere persoonlijke tijdsinterval uitgesloten.',
    optional: true,
    min: 0
  },
  'maxInterval': {
    type: SimpleSchema.Integer,
    label: 'Maximale persoonlijke tijdsinterval',
    description: 'Indien ingevuld, worden producten met een grotere persoonlijke tijdsinterval uitgesloten.',
    optional: true,
    min: 0
  },
  'maxExpectedRepeatInterval': {
    type: SimpleSchema.Integer,
    label: 'Maximale nummer huidige productaankoop-cyclus',
    description: 'Indien ingevuld, worden producten met een groter nummer huidige productaankoop-cyclus uitgesloten.',
    optional: true,
    min: 1
  },
  'minLastPurchaseDate': {
    type: Date,
    label: 'Laatste aankoopdatum niet eerder dan',
    description: 'Indien ingevuld, mag de laatste aankoopdatum niet eerder zijn dan de opgegeven datum.',
    optional: true
  },
  'minExpectedNextPurchaseDate': {
    type: Date,
    label: 'Verwachte eerstvolgende productaanschaf niet eerder dan',
    description: 'Indien ingevuld, mag de verwachte eerstvolgende productaanschaf niet eerder zijn dan de opgegeven datum.',
    optional: true
  },
  'includeProductPromotions': {
    type: Boolean,
    label: 'Productpromoties opzoeken',
    description: 'Indien het product in een productpromotie gevonden wordt, deze ook teruggeven in het resultaat?',
    optional: true,
    options: {
      'Ja': 'true',
      'Nee': 'false'
    }
  },
  'filter': {
    type: Array,
    label: 'Filters',
    optional: true
  },
  'filter.$': {
    type: String
  },
  'limit': {
    type: SimpleSchema.Integer,
    label: 'Limiet',
    description: 'Maximaal aantal suggesties dat wordt getoond.',
    min: 1,
    defaultValue: 20
  }/*,
  'orderBy': OrderBy.arraySchema({
    defaultValue: [{ field: 'details.expectedNextDate', order: 'asc' }]
  }),
  'orderBy.$': OrderBy.elementSchema({
    indexFields: Output
  })*/
})

export const RequestConfiguration = new SimpleSchema({
  'maxLimit': {
    type: SimpleSchema.Integer,
    label: 'Limiet',
    description: 'Maximaal aantal suggesties in een enkele API-response',
    min: 1,
    defaultValue: 50
  }
})
