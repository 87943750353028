import Stream from '../Stream'

/**
 * @todo
 */
export const map = (fn) => (source) => (start, sink) => {
  if (start !== 0) {
    return
  }
  source(0, (t, d) => {
    if (t === 1) {
      const v = fn(d)
      if (v && typeof v.then === 'function' && !(Stream.source in v)) {
        v.then(
          d => {
            sink(1, d)
          },
          err => {
            sink(2, err)
          }
        )
      } else {
        sink(1, v)
      }
    } else {
      sink(t, d)
    }
  })
}
