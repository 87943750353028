import SimpleSchema from '@qiri/simpl-schema'

/**
 * @todo
 */
export const FieldTypes = {
  'string': 'Tekst regel',
  'text': 'Tekst',
  'number': 'Nummer (zonder decimalen)',
  'decimal': 'Nummer (met decimalen)',
  'date': 'Datum & tijd',
  'bool': 'Booleanse waarde',
  'json': 'JSON',
  'foreignKey': 'Foreign key'
}

/**
 * @todo
 */
export function compareFields (a, b) {
  if (a.primaryKey) {
    return -1
  } else if (b.primaryKey) {
    return 1
  } else if (a.foreignKey && !b.foreignKey) {
    return -1
  } else if (!a.foreignKey && b.foreignKey) {
    return 1
  } else {
    return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
  }
}

/**
 * @todo
 */
export default new SimpleSchema({
  model: {
    type: String,
    label: 'Qiri-veld tabel'
  },
  name: {
    type: String,
    label: 'Qiri-veld naam',
    regEx: /^[a-z_]+[a-z_0-9]*$/i
  },
  description: {
    type: String,
    label: 'Qiri-veld omschrijving',
    optional: true
  },
  primaryKey: {
    type: Boolean,
    label: 'Primary key',
    defaultValue: false
  },
  foreignKey: {
    type: String,
    label: 'Foreign key',
    optional: true
  },
  type: {
    type: String,
    label: 'Veld type',
    allowedValues: Object.keys(FieldTypes)
  },
  owner: {
    type: String,
    label: 'Eigenaar'
  }
})
