<template>
  <section v-if="loading" class="padding">
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h1>Suggesties bekijken</h1></template>
          <template slot="content">
            <p v-if="loadingError" class="error">{{ loadingError }}</p>
            <p v-else>Bezig met laden...</p>
          </template>
        </block>
      </div>
    </div>
  </section>
  <section v-else class="padding">
    <vue-form tag="div" class="grid" :state="contextForm" :schema="contextSchema" v-model="contextData">
      <div class="column size--100">
        <block>
          <template slot="title"><h1>Suggesties bekijken</h1></template>
          <template slot="content">
            <vue-form-group>
              <vue-form-field column="25" field="model" :options="modelOptions"></vue-form-field>
              <vue-form-field v-if="contextData.model" column="25" field="field" :options="fieldOptionsPerModel[contextData.model]"></vue-form-field>
              <vue-form-field v-if="contextData.field" column="50" field="value"></vue-form-field>
            </vue-form-group>
          </template>
        </block>
      </div>
    </vue-form>
    <div v-for="algorithm of accessibleAlgorithms" :key="algorithm.algorithmID" class="grid">
      <div class="column size--100">
        <block :table="algorithm.requestFields.length === 0" :collapsed="!algorithm.visible" @toggle="toggle(algorithm)">
          <template slot="title">
            <h2>
              {{ algorithm.name }}
              <small v-if="algorithm.missingFields.length > 0" class="warnings"><icon name="exclamation-triangle"></icon></small>
            </h2>
          </template>
          <template slot="content">
            <p v-if="algorithm.missingFields.length > 0" class="warnings">
              <icon name="exclamation-triangle"></icon>
              Voor dit algoritme worden nog niet alle benodigde Qiri-velden geïmporteerd.
              Daarom zullen suggesties onvolledig zijn.
              Zorg ervoor dat er <router-link :to="getDataImportRoute()">data-imports</router-link> ingesteld worden naar deze Qiri-velden:
              <ul>
                <li v-for="(field, index) of algorithm.missingFields" :key="index">
                  Qiri-veld "{{ field.field }}" in tabel "{{ field.model }}".
                </li>
              </ul>
            </p>
            <p v-if="algorithm.description" v-html="algorithm.description"></p>
            <vue-form tag="div" v-show="algorithm.requestFormVisible" v-if="algorithm.requestFields.length > 0" :state="algorithm.requestForm" :schema="algorithm.requestSchema" v-model="algorithm.requestData">
              <vue-form-field v-for="field of getSimpleFields(algorithm.requestFields)" :key="field.name" :field="field.name">
                {{ field.label }}: <em>{{ field.description }}</em>
              </vue-form-field>
              <br>
            </vue-form>
            <ButtonSelect v-show="algorithm.requestFormVisible" label="Tonen" :options="viewOptions" v-model="algorithm.view" :multi="false"></ButtonSelect>
            <table v-if="algorithm.view === 'table'" class="table">
              <thead>
                <tr>
                  <th v-for="field of algorithm.previewFields">{{ field.label }}</th>
                  <th class="icon">
                    <btn
                      title="Parameters configureren"
                      class="icon"
                      icon="cog"
                      @click.prevent="algorithm.requestFormVisible = !algorithm.requestFormVisible"
                    ></btn>
                    <a
                      v-if="algorithm.csvSupported"
                      :href="getDownloadURL(algorithm)"
                      title="CSV downloaden"
                      class="btn icon"
                    >
                      <icon name="cloud-download" />
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!algorithm.requestForm.valid">
                  <td :colspan="algorithm.previewFields.length + 1">Vul hierboven de gewenste parameters in.</td>
                </tr>
                <tr v-else-if="!algorithm.responseData">
                  <td :colspan="algorithm.previewFields.length + 1">Bezig met laden...</td>
                </tr>
                <tr v-else-if="algorithm.responseData.errorCode || algorithm.responseData.error">
                  <td :colspan="algorithm.previewFields.length + 1">
                    Sorry, maar er is iets fout gegaan. Dit is de foutmelding die ik ontvangen heb:<br />
                    <em>
                      <template v-if="algorithm.responseData.errorCode">{{ algorithm.responseData.errorCode }}</template>
                      {{ algorithm.responseData.error }}
                    </em>
                  </td>
                </tr>
                <tr v-else-if="algorithm.responseData.count === 0">
                  <td :colspan="algorithm.previewFields.length + 1">Er zijn geen resultaten.</td>
                </tr>
                <template v-else-if="algorithm.responseData.count > 0">
                  <tr v-for="record of algorithm.responseData.results">
                    <td v-for="field of algorithm.previewFields"><RenderField :field="field" :record="record"></RenderField></td>
                    <td class="icon">&nbsp;</td>
                  </tr>
                </template>
              </tbody>
              <tfoot v-if="algorithm.hasSummary && algorithm.responseData && algorithm.responseData.count > 0">
                <tr>
                  <td v-for="field of algorithm.previewFields">
                    <template v-if="field.summary">{{ field.summary(algorithm.responseData.results, algorithm.responseData) }}</template>
                  </td>
                </tr>
              </tfoot>
            </table>
            <pre v-else-if="algorithm.view === 'json'">{{ debugJSON(algorithm.responseData) }}</pre>
            <template v-else-if="algorithm.view === 'example'">
              <ButtonSelect v-if="algorithm.csvSupported" label="Formaat" :options="formatOptions" v-model="algorithm.format" :multi="false"></ButtonSelect>
              <ButtonSelect label="Omgeving" :options="getExampleOptions(algorithm.format)" v-model="algorithm.example" :multi="false"></ButtonSelect>
              <pre v-if="algorithm.example === 'url'">GET {{ getRequestURL(algorithm) }}&amp;token={TOKEN}</pre>
              <pre v-else-if="algorithm.example === 'curl'">curl -H "Authorization: Bearer {TOKEN}" -X GET "{{ getRequestURL(algorithm) }}"</pre>
              <pre v-else-if="algorithm.example === 'browser'">var options = {
  method: 'GET',
  headers: {
  'Authorization': 'Bearer {TOKEN}'
  },
  mode: 'cors',
  cache: 'no-store'
};
fetch('{{ getRequestURL(algorithm) }}', options)
  .then(function (response) {
  return response.json();
  })
  .then(function (json) {
  // Toon het resultaat in de console.
  console.log(json);
  });</pre>
              <pre v-else-if="algorithm.example === 'node.js'">const fetch = require('node-fetch');

async function execute() {
  const response = await fetch('{{ getRequestURL(algorithm) }}', {
  method: 'GET',
  headers: {
    'Authorization': 'Bearer {TOKEN}'
  },
  mode: 'cors',
  cache: 'no-store'
  });
  const json = await response.json();

  // Toon het resultaat in de console.
  console.log(json);
}
execute();</pre>
              <pre v-else-if="algorithm.example === 'php'">&lt;?php
  $ch = curl_init();
  curl_setopt($ch, CURLOPT_URL, '{{ getRequestURL(algorithm) }}');
  curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
  curl_setopt($ch, CURLOPT_HTTPHEADER, array(
  'Authorization: Bearer {TOKEN}',
  'Cache-Control: no-cache'
  ));
  $output = curl_exec($ch);
  $json = json_decode($output);
  curl_close($ch);

  // Toon het resultaat in de console.
  var_dump($json);
?&gt;</pre>
            </template>
          </template>
        </block>
      </div>
    </div>
    <div class="grid" v-if="false">
      <div class="column size--100">
        <block>
          <template slot="content">
            <pre>{{ debugJSON(contextData) }}</pre>
          </template>
        </block>
      </div>
    </div>
  </section>
</template>

<script>
  import moment from 'moment'
  import first from 'lodash/first'
  import isDate from 'lodash/isDate'
  import cloneDeep from 'lodash/cloneDeep'
  import omit from 'lodash/omit'
  import SimpleSchema from '@qiri/simpl-schema'
  import {getAlgorithm} from '@qiri/models/algorithm'
  import ButtonSelect from '@/components/Partials/ButtonSelect'
  import RenderField from './RenderField'

  export default {
    name: 'page-algorithms-Query',
    components: {
      ButtonSelect,
      RenderField
    },
    data () {
      return {
        contextForm: {},
        contextData: {
          token: null,
          field: null,
          model: null,
          value: null
        },
        enabledAlgorithms: [],
        tokenOptions: [],
        modelOptions: [
          [null, '<Geen>'],
          ['Person', 'Personen'],
          ['Store', 'Winkels'],
          ['Product', 'Producten'],
          ['ProductPromotion', 'Productpromoties']
        ],
        customFields: [],
        fieldOptionsPerModel: {
          'Person': [],
          'Store': [],
          'Product': [],
          'ProductPromotion': []
        },
        algorithms: [],
        mappedFields: [],
        loading: true,
        loadingError : null
      }
    },
    async mounted () {
      // Gain access to the API scope.
      const apiToken = await this.$security.access('api')
      if (!apiToken) {
        this.loadingError = 'Geen toegang'
        return
      }
      this.$set(this.contextData, 'token', apiToken)

      // Get necessary meta data of token.
      const {jti: apiTokenID} = this.$decodeToken(apiToken)
      const apiTokenRecord = await this.$api.call(`/env/${this.$environment}/api/APIToken/Record/${apiTokenID}/get`)
      this.enabledAlgorithms = apiTokenRecord.algorithmSettings
        .filter(x => x.enabled)
        .map(x => x.algorithmID)

      // Get the custom fields.
      this.customFields = await this.$model.fetch(`/env/${this.$environment}/data/CustomField/list`)
      for (const customField of this.customFields) {
        if ((customField.type === 'foreignKey' || customField.indexed) && this.fieldOptionsPerModel[customField.model]) {
          this.fieldOptionsPerModel[customField.model].push([customField.name, customField.name])
        }
      }

      // Get view specific data.
      const view = await this.$api.call(`/env/${this.$environment}/suggestions/ui/Query/view`, {
        route: {
          params: this.$route.params,
          query: this.$route.query
        }
      })

      this.mappedFields = view.mappedFields

      // Get algorithms.
      this.algorithms = view.algorithms
        .map(algorithm => ({
          ...getAlgorithm(algorithm.algorithmID),
          ...algorithm
        }))
        .map(algorithm => ({
          ...algorithm,
          visible: false,
          view: 'table',
          example: 'url',
          format: 'json',
          requestFields: algorithm.requestSchema ? this.schemaFields(algorithm.requestSchema.omit('person', 'store', 'product', 'productPromotion', 'orderBy')) : [],
          requestForm: {},
          requestFormVisible: false,
          requestData: cloneDeep(omit(algorithm.requestDefaults, 'person', 'store', 'product', 'productPromotion', 'orderBy')),
          responseData: null,
          previewFields: this.schemaFields(algorithm.previewSchema),
          missingFields: this.getMissingFields(algorithm, algorithm.inputFields, this.mappedFields)
        }))
        .map(algorithm => ({
          ...algorithm,
          csvSupported: algorithm.requestFields.filter(x => x.name === 'limit' && x.optional).length > 0,
          requestModels: this.schemaFields(algorithm.requestSchema.pick('person', 'store', 'product', 'productPromotion'))
            .reduce(
              (acc, cur) => {
                if (cur.name === 'person') {
                  acc['Person'] = true
                } else if (cur.name === 'store') {
                  acc['Store'] = true
                } else if (cur.name === 'product') {
                  acc['Product'] = true
                } else if (cur.name === 'productPromotion') {
                  acc['ProductPromotion'] = true
                }
                if (cur.optional) {
                  acc['None'] = true
                }
                return acc
              },
              {}
            ),
          hasSummary: algorithm.previewFields.filter(field => !!field.summary).length > 0
        }))

      // Watch request form of algorithms so we can automatically refresh.
      for (const algorithm of this.algorithms) {
        this.$watch(() => algorithm.requestData, () => this.refresh(algorithm), { deep: true })
      }

      // Restore state.
      this.loadContext()

      this.loading = false
    },
    watch: {
      'contextData.model' (model, previousModel) {
        if (previousModel !== undefined) {
          const options = model ? this.fieldOptionsPerModel[model].map(kv => kv[0]) : []
          if (!this.contextData.field || options.every(option => option !== this.contextData.field)) {
            this.$set(this.contextData, 'field', model ? first(first(this.fieldOptionsPerModel[model])) : null)
            this.$set(this.contextData, 'value', '')
            this.saveContext()
          }
        }
      },
      'contextData.field' (field, previousField) {
        if (previousField && field !== previousField) {
          this.$set(this.contextData, 'value', '')
          this.saveContext()
        }
      },
      'contextData.value' () {
        if (this.contextData.value) {
          const record = this.contextData.value.split(',').map(v => `${this.contextData.field}:${v}`).join(',')
          const recordField = this.contextData.model[0].toLowerCase() + this.contextData.model.substr(1)

          for (const algorithm of this.algorithms) {
            this.$set(algorithm.requestData, recordField, record)
          }
        } else {
          for (const algorithm of this.algorithms) {
            this.$delete(algorithm.requestData, 'person')
            this.$delete(algorithm.requestData, 'store')
            this.$delete(algorithm.requestData, 'product')
            this.$delete(algorithm.requestData, 'productPromotion')
          }
        }
        this.saveContext()
      }
    },
    computed: {
      contextSchema () {
        return new SimpleSchema({
          'token': {
            type: String,
            label: 'API-token',
            description: 'De API-token waarmee de API van het algoritme benaderd zal worden'
          },
          'model': {
            type: String,
            label: 'Tabel om in te zoeken',
            optional: true
          },
          'field': {
            type: String,
            label: 'Veld om op te zoeken',
            optional: true
          },
          'value': {
            type: String,
            label: 'Waarde om op te zoeken',
            optional: true
          }
        })
      },
      accessibleAlgorithms () {
        return this.algorithms
          .filter(algorithm => this.enabledAlgorithms.indexOf(algorithm.algorithmID) >= 0)
          .filter(algorithm => this.canRefresh(algorithm, ['person', 'store', 'product', 'productPromotion']))
          .filter(algorithm => algorithm.requestModels[this.contextData.model || 'None'] === true)
      },
      viewOptions () {
        return [
          [ 'table', 'Tabel' ],
          [ 'json', 'JSON' ],
          [ 'example', 'Code voorbeeld' ]
        ]
      },
      formatOptions () {
        return [
          [ 'json', 'JSON' ],
          [ 'csv', 'CSV' ]
        ]
      }
    },
    methods: {
      schemaFields (schema) {
        if (!schema) {
          return []
        }
        if (typeof schema === 'function') {
          schema = schema({
            customFields: this.customFields
          })
        }
        let fields = []
        for (const [propertyName, property] of Object.entries(schema.schema())) {
          if (propertyName.indexOf('.') >= 0) {
            continue
          }
          fields.push({
            name: propertyName,
            label: property.label,
            description: property.description,
            optional: property.optional,
            isComplex: property.type.singleType === Array || SimpleSchema.isSimpleSchema(property.type.singleType),
            render: property.render,
            summary: property.summary
          })
        }
        return fields
      },
      getSimpleFields (fields) {
        return fields.filter(x => !x.isComplex)
      },
      getMissingFields (algorithm, inputFields, mappedFields) {
        if (!inputFields) {
          return []
        }

        const missingFields = []
        for (const [modelName, requiredFields] of Object.entries(inputFields)) {
          for (const fieldName of requiredFields) {
            const mappedField = mappedFields.find(x => x.model === modelName && x.field === fieldName)
            if (!mappedField) {
              missingFields.push({
                model: modelName,
                field: fieldName
              })
            }
          }
        }

        /*
          // Remap foreign key mappings to their primary key counterparts, since that's what the input fields refer to.
          const fieldsLookup = {}
          const foreignKeyFields = getForeignKeyFields(modelName)
          for (const mappedField of mappedFields.filter(x => x.model === modelName)) {
            const foreignKeyField = foreignKeyFields.find(x => x.name === mappedField.field)
            if (foreignKeyField) {

            } else {
              fieldsLookup[mappedField.field] = true
            }
          }

          for (const fieldName of requiredFields) {
            if (fieldsLookup[fieldName] !== true) {
              missingFields.push({
                model: modelName,
                field: fieldName
              })
            }
          } */

        return missingFields
      },
      toggle (algorithm) {
        algorithm.visible = !algorithm.visible
      },
      getRequestData (algorithm, fields) {
        let data = {}
        for (const [key, value] of Object.entries(algorithm.requestData)) {
          if (value !== undefined && value !== null && value !== '') {
            data[key] = value
          }
        }

        const requestSchema = fields ? algorithm.requestSchema.pick(...fields) : algorithm.requestSchema
        const validationContext = requestSchema.newContext()
        return validationContext.clean(data)
      },
      getRequestURL (algorithm, {search = true, format = algorithm.format} = {}) {
        const data = this.getRequestData(algorithm)
        const urlHost = this.$api.host('http', 'api')
        const urlPath = first(algorithm.audience).substr(4)
        const urlSearchParams = []

        if (format !== 'json') {
          urlSearchParams.push(`format=${format}`)
        }

        for (const [key, value] of Object.entries(data)) {
          if (isDate(value)) {
            urlSearchParams.push(`${key}=${encodeURIComponent(moment(value).format('YYYY-MM-DD'))}`)
          } else if (key === 'orderBy') {
            // TODO: Ignore for now until implemented.
            /*for (const orderField of value) {
              urlSearchParams.push(`orderBy=${encodeURIComponent(orderField.field + ':' + orderField.order)}`)
            }*/
          } else if (key === 'limit' && format !== 'json') {
            // Ignore limit for anything formats other than JSON.
          } else {
            urlSearchParams.push(`${key}=${encodeURIComponent(value)}`)
          }
        }

        return `${urlHost}${urlPath}${search && urlSearchParams.length > 0 ? `?${urlSearchParams.join('&')}` : ''}`
      },
      getDownloadURL (algorithm) {
        return `${this.getRequestURL(algorithm, {format: 'csv'})}&token=${this.contextData.token}`
      },
      getDataImportRoute () {
        return {
          name: 'data.feeds',
          query: this.$route.query
        }
      },
      canRefresh (algorithm, fields) {
        const data = this.getRequestData(algorithm, fields)
        const requestSchema = fields ? algorithm.requestSchema.pick(...fields) : algorithm.requestSchema
        const validationContext = requestSchema.newContext()
        return validationContext.validate(data)
      },
      async refresh (algorithm) {
        if (!algorithm) {
          return await Promise.all(this.accessibleAlgorithms
            .filter(x => x.visible)
            .map(x => this.refresh(x))
          )
        }
        if (!algorithm.visible || !this.canRefresh(algorithm)) {
          return
        }
        algorithm.responseData = null

        const url = this.getRequestURL(algorithm)
        try {
          const response = await fetch(`${url}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${this.contextData.token}`
            },
            mode: 'cors',
            cache: 'no-store'
          })
          algorithm.responseData = await response.json()
        } catch (err) {
          console.error(err)
          algorithm.responseData = {
            error: err.message
          }
        }
      },
      loadContext () {
        const {model, field, value} = JSON.parse(window.sessionStorage.getItem('qiri-query-context') || '{}')
        this.$set(this.contextData, 'model', model)
        this.$set(this.contextData, 'field', field)
        this.$set(this.contextData, 'value', value)
      },
      saveContext () {
        window.sessionStorage.setItem('qiri-query-context', JSON.stringify({
          model: this.contextData.model,
          field: this.contextData.field,
          value: this.contextData.value
        }))
      },
      debugJSON (obj) {
        return JSON.stringify(obj, null, '  ')
      },
      getExampleOptions (format) {
        if (format === 'json') {
          return [
            [ 'url', 'URL' ],
            [ 'curl', 'CURL' ],
            [ 'browser', 'Browser (JavaScript)' ],
            [ 'node.js', 'Node.js (JavaScript)' ],
            [ 'php', 'PHP' ]
          ]
        } else if (format === 'csv') {
          return [
            [ 'url', 'URL' ],
            [ 'curl', 'CURL' ]
          ]
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  label em {
    font-style: normal;
    font-weight: normal;
  }
</style>

<style lang="scss">
  i.has-productpromotion {
    color: #85beff;
  }
</style>
