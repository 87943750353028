import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  headerRow: {
    type: Boolean,
    label: 'Veldnamen op eerste regel',
    defaultValue: true
  },
  delimiter: {
    type: String,
    label: 'Veldwaardes gescheiden door',
    defaultValue: ';'
  },
  quote: {
    type: String,
    label: 'Tekst ingesloten door',
    defaultValue: '"'
  }
})
