// Pages
import DataModel from './components/Pages/data/Model'
import FeedsOverview from './components/Pages/feeds/Overview'
import FeedsCreate from './components/Pages/feeds/Create'
import FeedsUpdate from './components/Pages/feeds/Update'
import FeedsLog from './components/Pages/feeds/Log'
import QueryImportsOverview from './components/Pages/query-imports/Overview'
import QueryImportsForm from './components/Pages/query-imports/Form'
import QueryImportsLog from './components/Pages/query-imports/Log'
import ExportsOverview from './components/Pages/exports/Overview'
import ExportsForm from './components/Pages/exports/Form'
import ExportsLog from './components/Pages/exports/Log'
import RemoteServersOverview from '@/modules/general/components/Pages/remoteservers/Overview'
import RemoteServersForm from '@/modules/general/components/Pages/remoteservers/Form'
import StatusOverview from './components/Pages/status/Overview'
import StatusConfig from './components/Pages/status/Config'

import SchedulePage from '@/modules/task-scheduler/components/Pages/Schedule'

export default {
  'data.model': {
    path: '/data/model',
    component: DataModel,
    meta: {
      audience: 'actor:/env/*/data/CustomField',
    }
  },

  'data.feeds': {
    path: '/data/feeds',
    component: FeedsOverview,
    meta: {
      audience: [
        'actor:/env/*/data/CustomField',
        'actor:/env/*/data/Feed'
      ]
    }
  },
  'data.feeds.create': {
    path: '/data/feeds/create',
    component: FeedsCreate,
    meta: {
      audience: [
        'actor:/env/*/data/CustomField',
        'actor:/env/*/data/Feed',
        'actor:/env/*/data/FieldsFromSource'
      ]
    }
  },
  'data.feeds.update': {
    path: '/data/feeds/:feedID/edit',
    component: FeedsUpdate,
    meta: {
      audience: [
        'actor:/env/*/data/CustomField',
        'actor:/env/*/data/Feed',
        'actor:/env/*/data/FieldsFromSource'
      ]
    }
  },
  'data.feeds.schedule': {
    path: '/data/feeds/:feedID/schedule',
    component: SchedulePage,
    meta: {
      audience: [
        'actor:/env/*/data/Feed'
      ],
      title: 'Verwerking bronbestand inplannen',
      actorPath: '/env/{environmentID}/data/Feed',
      idField: 'feedID',
      overviewRoute: 'data.feeds'
    }
  },
  'data.feeds.log': {
    path: '/data/feeds/:feedID/log',
    component: FeedsLog,
    meta: {
      audience: [
        'actor:/env/*/data/Feed',
        'actor:/env/*/data/Log'
      ]
    }
  },

  'data.query-imports': {
    path: '/data/query-imports',
    component: QueryImportsOverview,
    meta: {
      audience: 'actor:/env/*/data/QueryImports'
    }
  },
  'data.query-imports.create': {
    path: '/data/query-imports/create',
    component: QueryImportsForm,
    meta: {
      audience: [
        'actor:/env/*/data/QueryImport'
      ],
      title: 'Query-import aanmaken',
      form: {
        mode: 'create'
      }
    }
  },
  'data.query-imports.update': {
    path: '/data/query-imports/:queryImportID/edit',
    component: QueryImportsForm,
    meta: {
      audience: [
        'actor:/env/*/data/QueryImport'
      ],
      title: 'Query-import aanpassen',
      form: {
        mode: 'edit'
      }
    }
  },
  'data.query-imports.log': {
    path: '/data/query-imports/:queryImportID/log',
    component: QueryImportsLog,
    meta: {
      audience: [
        'actor:/env/*/data/QueryImport'
      ]
    }
  },
  'data.query-imports.schedule': {
    path: '/data/query-imports/:queryImportID/schedule',
    component: SchedulePage,
    meta: {
      audience: [
        'actor:/env/*/data/QueryImport'
      ],
      title: 'Uitvoering query-import inplannen',
      actorPath: '/env/{environmentID}/data/QueryImport',
      idField: 'queryImportID',
      overviewRoute: 'data.query-imports'
    }
  },

  'data.exports': {
    path: '/data/exports',
    component: ExportsOverview,
    meta: {
      audience: 'actor:/env/*/data-export/Export'
    }
  },
  'data.exports.create': {
    path: '/data/exports/create',
    component: ExportsForm,
    meta: {
      audience: [
        'actor:/env/*/data-export/Export',
        'actor:/env/*/data/CustomField/list'
      ],
      title: 'Data-export aanmaken',
      form: {
        mode: 'create'
      }
    }
  },
  'data.exports.update': {
    path: '/data/exports/:exportID/edit',
    component: ExportsForm,
    meta: {
      audience: [
        'actor:/env/*/data-export/Export',
        'actor:/env/*/data/CustomField/list'
      ],
      title: 'Data-export aanpassen',
      form: {
        mode: 'edit'
      }
    }
  },
  'data.exports.log': {
    path: '/data/exports/:exportID/log',
    component: ExportsLog,
    meta: {
      audience: [
        'actor:/env/*/data-export/Export',
        'actor:/env/*/data/Log'
      ]
    }
  },
  'data.exports.schedule': {
    path: '/data/exports/:exportID/schedule',
    component: SchedulePage,
    meta: {
      audience: [
        'actor:/env/*/data-export/Export'
      ],
      title: 'Uitvoering data-export inplannen',
      actorPath: '/env/{environmentID}/data-export/Export',
      idField: 'exportID',
      overviewRoute: 'data.exports'
    }
  },

  'data.remoteservers.overview': {
    path: '/data/remoteservers',
    component: RemoteServersOverview,
    meta: {
      audience: 'actor:/env/*/data/RemoteServer'
    }
  },
  'data.remoteservers.add': {
    path: '/data/remoteservers/add',
    component: RemoteServersForm,
    meta: {
      audience: 'actor:/env/*/data/RemoteServer',
      title: 'Externe server toevoegen',
      form: {
        mode: 'create',
        action: 'data.remoteservers.overview'
      }
    }
  },
  'data.remoteservers.update': {
    path: '/data/remoteservers/:remoteServerID/edit',
    component: RemoteServersForm,
    meta: {
      audience: 'actor:/env/*/data/RemoteServer',
      title: 'Externe server aanpassen',
      form: {
        mode: 'edit',
        action: 'data.remoteservers.overview'
      }
    }
  },

  'data.status': {
    path: '/data/status',
    component: StatusOverview,
    meta: {
      audience: 'actor:/env/*/data/StatusMessage'
    }
  },
  'data.status.config': {
    path: '/data/status/config',
    component: StatusConfig,
    meta: {
      audience: 'actor:/env/*/data/StatusConfig'
    }
  }
}
