<template>
    <section v-if="loading" class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>API-tokens</h1></template>
                    <template slot="content">
                        <p>Bezig met laden...</p>
                    </template>
                </block>
            </div>
        </div>
    </section>
    <section v-else class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>API-tokens</h1></template>
                    <template slot="content">
                        <p>Hier worden alle API-tokens getoond die niet verlopen of ingetrokken zijn.</p>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block :table="true">
                    <template slot="content">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Omschrijving gebruik en gebruiker(s)</th>
                                    <th>Geautoriseerde IP-adres(-sen)</th>
                                    <th>Verloopdatum</th>
                                    <th>API-token</th>
                                    <th class="icon">
                                        <router-link :to="{ name: `api.tokens.create`, query: $route.query }" title="API-token toevoegen" class="btn icon">
                                            <icon name="plus" />
                                        </router-link>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="token of tokens" :key="token.tokenID">
                                    <td class="align--top">
                                        <router-link :to="{ name: `api.tokens.update`, params: { tokenID: token.tokenID }, query: $route.query }" title="API-token aanpassen" class="btn icon">
                                            <icon name="pencil" />
                                        </router-link>
                                        {{ token.description }}
                                    </td>
                                    <td>{{ formatWhitelist(token.whitelist) }}</td>
                                    <td class="whitespace--nowrap align--top">{{ formatDate(token.expiryDate) }}</td>
                                    <td class="whitespace--nowrap align--top">
                                        <btn icon="eye" title="Tonen" class="icon" @click.prevent="view(token)"></btn>
                                        <btn icon="clipboard" title="Kopiëren naar klembord" class="icon" :data-clipboard-text="token.token"></btn>
                                        {{ token.token.substr(0, 12) }}...
                                        <icon name="scissors" />
                                        ...{{ token.token.substr(-12) }}
                                    </td>
                                    <td class="icon align--top">
                                        <btn icon="trash" title="Intrekken" class="icon" @click.prevent="revoke(token)"></btn>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </block>
            </div>
        </div>
        <modal name="view" title="API-token">
            <textarea v-if="modalRecord" v-model="modalRecord.token" rows="5" :disabled="true"></textarea>
            <button type="button" @click="$root.$emit('modal', 'view', false)">Sluiten</button>
        </modal>
        <modal name="revoke" title="Weet je zeker dat je deze API-token wilt intrekken?">
            <p>Wil je de API-token intrekken?</p>
            <button type="button" @click="revoke(modalRecord, true)">Ja, intrekken</button>
            <button type="button" @click="$root.$emit('modal', 'revoke', false)">Nee, annuleren</button>
        </modal>
    </section>
</template>

<script>
    import cloneDeep from 'lodash/cloneDeep'
    import moment from 'moment'
    import Clipboard from 'clipboard'
    import Modal from '@/components/Partials/Modal'
    import {APIToken} from '@qiri/models/environment'

    export default {
        name: 'page-api-TokenOverview',
        components: {
            Modal
        },
        data () {
            return {
                tokens: [],
                modalRecord: null,
                loading: true
            }
        },
        async mounted () {
            this.clipboard = new Clipboard('[data-clipboard-text]', {
                container: this.$el
            })
            this.clipboard.on('success', (evt) => {
                console.log(evt)
                this.$root.$emit('notification', 'Gelukt', 'De API-token is gekopiëerd naar je klembord.')
            })
            this.clipboard.on('error', () => {
                this.$root.$emit('notification', 'Mislukt', 'Het is niet gelukt om de API-token te kopiëren naar je klembord.')
            })

            this.$model.list(`/env/${this.$environment}/api/APIToken/listValid`, 'tokens')

            this.loading = false
        },
        beforeDestroy () {
            this.clipboard.destroy()
            this.clipboard = null
        },
        computed: {
            schema () {
                return APIToken
            }
        },
        methods: {
            formatDate (d) {
                return d ? moment(d).format('DD-MM-YYYY') : 'Oneindig geldig'
            },
            formatWhitelist (whitelist) {
                return whitelist.map(ip => ip.address).join(', ')
            },
            view (token) {
                this.modalRecord = token
                this.$root.$emit('modal', 'view', true)
            },
            async revoke (token, confirmed) {
                if (!confirmed) {
                    this.modalRecord = token
                    this.$root.$emit('modal', 'revoke', true)
                } else {
                    await this.$model.update(`/env/${this.$environment}/api/APIToken`, this.modalRecord.tokenID, { $set: {
                        revokedAt: moment.utc().format()
                    }})
                    this.modalRecord = null
                    this.$root.$emit('modal', 'revoke', false)
                }
            }
        }
    }
</script>
