import SimpleSchema from '@qiri/simpl-schema'
import Schedule from '../task-scheduler/Schedule'

/**
 * @todo
 */
const ExportSchedule = new SimpleSchema({
  'exportID': {
    type: String,
    foreignKey: 'Export'
  }
})
ExportSchedule.extend(Schedule)
export default ExportSchedule
