<template>
    <feed-form mode="update" title="Data-import aanpassen" :feedID="$route.params.feedID"></feed-form>
</template>

<script>
    import Form from './Form'

    export default {
        name: 'page-feeds-Update',
        components: {
            FeedForm: Form
        }
    }
</script>
