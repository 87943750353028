import CompanyOverview from '@/components/Pages/companies/Overview'
import CompanyForm from '@/components/Pages/companies/Form'
import UserOverview from '@/components/Pages/users/Overview'
import UserForm from '@/components/Pages/users/Form'
import {Customer} from '@qiri/models/environment'

const CompanyMeta = {
    type: 'customer',
    model: Customer,
    actorPrefix: '/customer'
}

export default {
    'customers.overview': {
        path: '/customers/overview',
        component: CompanyOverview,
        meta: {
            audience: 'actor:/customer/Company',
            title: 'Klanten overzicht',
            company: CompanyMeta
        }
    },
    'customers.add': {
        path: '/customers/add',
        component: CompanyForm,
        meta: {
            audience: 'actor:/customer/Company',
            title: 'Klant toevoegen',
            company: CompanyMeta,
            form: {
                mode: 'create',
                action: 'customers.overview'
            }
        }
    },
    'customers.edit': {
        path: '/customers/:companyID/edit',
        component: CompanyForm,
        meta: {
            audience: 'actor:/customer/Company',
            title: 'Klant bewerken',
            company: CompanyMeta,
            form: {
                mode: 'edit',
                action: 'customers.overview'
            }
        }
    },
    'customers.users.overview': {
        path: '/customers/:companyID/users',
        component: UserOverview,
        meta: {
            audience: 'actor:/customer/User',
            title: 'Gebruikers overzicht',
            company: CompanyMeta
        }
    },
    'customers.users.add': {
        path: '/customers/:companyID/users/add',
        component: UserForm,
        meta: {
            audience: 'actor:/customer/User',
            title: 'Gebruiker toevoegen',
            company: CompanyMeta,
            form: {
                mode: 'create',
                action: 'customers.users.overview'
            }
        }
    },
    'customers.users.edit': {
        path: '/customers/:companyID/users/:userID/edit',
        component: UserForm,
        meta: {
            audience: 'actor:/customer/User',
            title: 'Gebruiker bewerken',
            company: CompanyMeta,
            form: {
                mode: 'edit',
                action: 'customers.users.overview'
            }
        }
    }
}
