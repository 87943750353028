<template>
    <vue-nested-form :schema="schema" field="format.settings">
        <vue-form-field field="rootElement"></vue-form-field>
    </vue-nested-form>
</template>

<script>
    import XML from '@qiri/models/formats/XML'

    export default {
        name: 'page-feeds-format-XML',
        computed: {
            schema () {
                return XML
            }
        }
    }
</script>
