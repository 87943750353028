import SimpleSchema from '@qiri/simpl-schema'

/**
 * @todo
 */
export default new SimpleSchema({
  'sequence': {
    type: String,
    primaryKey: true
  },
  'module': {
    type: String,
    label: 'Module'
  },
  'timestamp': {
    type: Date,
    label: 'Timestamp'
  },
  'priority': {
    type: SimpleSchema.Integer,
    label: 'Prioriteit'
  },
  'acknowledged': {
    type: Boolean,
    label: 'Bevestigd',
    defaultValue: false
  },
  'type': {
    type: String,
    label: 'Type'
  },
  'recordID': {
    type: String,
    label: 'Record ID',
    optional: true
  },
  'data': {
    type: Object,
    label: 'Data',
    blackbox: true,
    optional: true
  },
  'audit': {
    type: Object,
    label: 'Data',
    blackbox: true,
    optional: true
  }
})
