<template>
    <span>
        Kon data-export "<router-link :to="{ name: 'data.exports.log', params: { exportID: content.exportID }, query: $route.query }">{{ content.dataExport }}</router-link>" niet uitvoeren. De ontvangen foutmelding was: <em>{{ content.error.message }}</em>
    </span>
</template>

<script>
    export default {
        name: 'page-status-type-ExportError',
        props: {
            message: Object
        },
        computed: {
            content () {
                return this.message.content
            }
        }
    }
</script>
