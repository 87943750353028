var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"padding"},[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"column size--100"},[_c('block',{attrs:{"table":true}},[_c('template',{slot:"title"},[_c('h1',[_vm._v(_vm._s(_vm.title))])]),_vm._v(" "),_c('template',{slot:"content"},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Naam")]),_vm._v(" "),_c('th',[_vm._v("Rol")]),_vm._v(" "),_c('th',{staticClass:"icon"},[_c('router-link',{staticClass:"btn icon",attrs:{"to":{
                                            name: (_vm.routePrefix + ".add"),
                                            params: { companyID: _vm.companyID },
                                            query: _vm.$route.query
                                        },"title":_vm.getTitleForRoute({
                                            name: (_vm.routePrefix + ".add"),
                                            params: { companyID: _vm.companyID }
                                        })}},[_c('icon',{attrs:{"name":"plus"}})],1)],1)])]),_vm._v(" "),_c('tbody',_vm._l((_vm.users),function(user){return _c('tr',{key:user.userID},[_c('td',[_c('router-link',{staticClass:"btn icon",attrs:{"to":{
                                            name: (_vm.routePrefix + ".edit"),
                                            params: { companyID: user.companyID, userID: user.userID },
                                            query: _vm.$route.query
                                        },"title":_vm.getTitleForRoute({
                                            name: (_vm.routePrefix + ".edit"),
                                            params: { companyID: user.companyID, userID: user.userID }
                                        })}},[_c('icon',{attrs:{"name":"pencil"}})],1),_vm._v("\n                                    "+_vm._s(_vm.getDisplayName(user))+"\n                                ")],1),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.getRoleLabel(user.role)))]),_vm._v(" "),_c('td',{staticClass:"icon"},[_c('btn',{staticClass:"icon",attrs:{"icon":"trash","title":"Gebruiker verwijderen"},on:{"click":function($event){$event.preventDefault();return _vm.removeRecord(user)}}})],1)])}),0)])])],2)],1)]),_vm._v(" "),_c('modal',{attrs:{"name":"delete","title":"Weet je zeker dat je deze gebruiker wilt verwijderen?"}},[_c('p',[_vm._v("Wil je de gebruiker \""+_vm._s(_vm.getDisplayName(_vm.selectedRecord))+"\" verwijderen?")]),_vm._v(" "),_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.removeRecord(_vm.selectedRecord, true)}}},[_vm._v("Ja, verwijderen")]),_vm._v(" "),_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.$root.$emit('modal', 'delete', false)}}},[_vm._v("Nee, annuleren")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }