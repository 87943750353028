import {pull} from '@qiri/stream/pull'
import {map} from '@qiri/stream/operators/map'
import {filter} from '@qiri/stream/operators/filter'
import {drain} from '@qiri/stream/sinks/drain'
import SimpleSchema from '@qiri/simpl-schema'
import models from './index'
import {propertyToType} from '../util'

/**
 * @todo
 */
export function getModelTable (modelName) {
  const model = models.schema(modelName)
  return model.table
}

/**
 * @todo
 */
export function getIDField (schema, debugName) {
  const idProperty = Object.entries(schema.schema()).find(([_, property]) => property.primaryKey === true)
  if (!idProperty) {
    throw new Error(`No primary key property found${debugName ? ` for ${debugName}` : ''}.`)
  }
  return idProperty[0]
}

/**
 * @todo
 */
export function getForeignKeyFields (model) {
  if (typeof model === 'string') {
    model = models.schema(model).type.singleType
  }

  let fields = []
  for (const [propertyName, property] of Object.entries(model.mergedSchema())) {
    if (property.foreignKey) {
      fields.push({
        name: propertyName,
        ...property
      })
    }
  }
  return fields
}

/**
 * @todo
 */
export function getRelatedModels (modelName) {
  const model = models.schema(modelName).type.singleType

  let results = new Map()
  for (const [propertyName, property] of Object.entries(model.mergedSchema())) {
    if (property.foreignKey) {
      results.set(property.foreignKey, true)
    }
  }
  return results.keys()
}

/**
 * @todo
 */
export function getFieldsFor (modelName, customFields = []) {
  const model = models.schema(modelName).type.singleType

  let fields = []
  for (const [ propertyName, property ] of Object.entries(model.mergedSchema())) {
    if (property.primaryKey || property.foreignKey || propertyName.indexOf('$') === -1) {
      fields.push({
        name: propertyName,
        label: property.label,
        description: property.description,
        primaryKey: property.primaryKey,
        foreignKey: property.foreignKey,
        type: propertyToType(property),
        owner: 'system',
        index: property.index
      })
    }
  }

  // Get custom fields.
  for (const customField of customFields) {
    if (customField.model === modelName) {
        const existingField = fields.find(x => x.name === customField.name)
        if (existingField) {
          existingField.fieldID = customField.fieldID
          existingField.validationTemplate = customField.validationTemplate
        } else {
          fields.push({ ...customField })
        }
    }
  }

  return fields.sort((a, b) => {
      if (a.primaryKey) {
          return -1
      } else if (b.primaryKey) {
          return 1
      } else if (a.foreignKey && !b.foreignKey) {
          return -1
      } else if (!a.foreignKey && b.foreignKey) {
          return 1
      } else {
          return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
      }
  })
}

/**
 * @todo
 */
export function fieldTypeToRealType (fieldType) {
  switch (fieldType) {
    case 'string':
      return String
    case 'text':
      return String
    case 'number':
      return Number
    case 'decimal':
      return Number
    case 'date':
      return Date
    case 'bool':
      return Boolean
    case 'json':
      return Object
    case 'foreignKey':
      return String
    default:
      return String
  }
}

/**
 * @todo
 */
export function fieldToColumn (field) {
  let column = field
  let replacement
  do {
    if (replacement) {
      column = replacement
    }
    replacement = column.replace(/(?!^)([A-Z]+[a-z]*)([A-Z]?)/, (match, p1, p2) => '_' + p1.toLowerCase() + p2)
  } while (column && column !== replacement)
  return replacement.toLowerCase()
}
