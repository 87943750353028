import {noop, asIterator} from '../util'

/**
 * @private
 */
const exists = x => typeof x !== 'undefined'

/**
 * @private
 */
const absent = x => typeof x === 'undefined'

/**
 * @todo
 */
export const flatten = () => (source) => (start, sink) => {
  if (start !== 0) {
    return
  }
  let outerEnded = false
  let outerTalkback
  let innerStarting = false
  let innerTalkback
  const talkback = (t,d) => {
    if (t === 1) {
      (innerTalkback || outerTalkback || noop)(1, d)
    } else if (t === 2) {
      innerTalkback && innerTalkback(2, d)
      outerTalkback && outerTalkback(2, d)
    }
  }
  source(0, (T, D) => {
    if (T === 0) {
      outerTalkback = D
      sink(0, talkback)
    } else if (T === 1) {
      if (innerTalkback) {
        innerTalkback(2)
      }
      const innerSource = asIterator(D)
      if (!innerSource) {
        const err = new Error(`Received data is not an iterable source.`)
        outerTalkback(2, err)
        sink(2, err)
        return
      }
      innerStarting = true
      innerSource(0, (t, d) => {
        if (t === 0) {
          innerTalkback = d
          innerStarting = false
          innerTalkback(1)
        } else if (t === 1) {
          sink(1, d)
        } else if (t === 2 && absent(d)) {
          if (outerEnded) {
            sink(2)
          } else {
            innerTalkback = undefined
            outerTalkback(1)
          }
        } else if (t === 2 && exists(d)) {
          sink(2, d)
        }
      })
    } else if (T === 2 && absent(D)) {
      if (!innerTalkback && !innerStarting) {
        sink(2)
      } else {
        outerEnded = true
      }
    } else if (T === 2 && exists(D)) {
      sink(2, D)
    }
  })
}
