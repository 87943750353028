<template>
    <section v-if="loading" class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>{{ title }}</h1></template>
                    <template slot="content">
                        <p v-if="loading">Bezig met laden...</p>
                    </template>
                </block>
            </div>
        </div>
    </section>
    <vue-form v-else tag="section" :state="form" ref="form" :schema="schema" v-model="document" class="padding" @submit="doSubmit()">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>{{ title }}</h1></template>
                    <template slot="content">
                        <template v-if="formMode === 'create'">
                            <vue-form-field field="name" type="file" @files="onFiles" label="Bijlage"></vue-form-field>
                        </template>
                        <template v-else>
                            <vue-form-field field="name" type="string" :disabled="true"></vue-form-field>
                            <vue-form-field field="name" type="file" @files="onFiles" label="Bijlage vervangen"></vue-form-field>
                        </template>
                        <vue-form-field field="description"></vue-form-field>
                        <vue-form-field field="role"></vue-form-field>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="content">
                        <button type="button" class="full" title="Opslaan" :disabled="uploading || saving" @click.prevent="$refs.form.submit()">
                            <template v-if="uploading || saving"><icon name="refresh" :spin="true" /></template>
                            <template v-else>{{ title }}</template>
                        </button>
                        <ul class="errors" v-if="uploadError || formErrors.length > 0 || submitErrors.length > 0">
                            <li v-if="uploadError">
                                <icon name="exclamation-circle"></icon> {{ uploadError }}
                            </li>
                            <li v-for="(error, index) of formErrors" :key="index">
                                <icon name="exclamation-circle"></icon> {{ error }}
                            </li>
                            <li v-for="(error, index) of submitErrors" :key="index">
                                <icon name="exclamation-circle"></icon> <vue-markdown :source="error"></vue-markdown>
                            </li>
                        </ul>
                    </template>
                </block>
            </div>
        </div>
    </vue-form>
</template>

<script>
    import uuidv4 from 'uuid/v4'
    import omit from 'lodash/omit'
    import SimpleSchema from '@qiri/simpl-schema'
    import {Document} from '@qiri/models/documentation'

    export default {
        name: 'page-documentation-Form',
        data () {
            return {
                form: {},
                document: {},
                loading: true,
                uploading: false,
                uploadError: null,
                saving: false,
                submitErrors: []
            }
        },
        async mounted () {
            if (this.formMode === 'edit') {
                if (!this.documentID) {
                    throw new Error(`Missing property "documentID".`)
                }

                this.document = await this.$model.get(`/documentation/Document`, this.documentID)

                if (!this.document) {
                    throw new Error(`Document "${this.documentID}" not found.`)
                }
            } else {
                this.document = this.schema.clean({
                    documentID: uuidv4()
                })
            }
            this.loading = false
        },
        computed: {
            schema () {
                return Document
            },
            documentID () {
                return this.$route.params.documentID
            },
            title () {
                return this.$route.meta.title
            },
            formMode () {
                return this.$route.meta.form.mode
            },
            formErrors () {
                let messages = []
                if (this.form.errors && this.form.errors.length > 0) {
                    for (const error of this.form.errors) {
                        let message = `${error.message}.`
                        // Don't show duplicate messages.
                        const duplicateMessage = messages.find(x => x === message)
                        if (!duplicateMessage) {
                            messages.push(message)
                        }
                    }
                }
                return messages
            }
        },
        methods: {
            async onFiles (files) {
                this.uploading = true
                this.uploadError = null

                const file = files[0]
                if (!file) {
                    return
                }

                try {
                    const formData = new FormData()
                    formData.append('file', file)

                    const host = this.$api.host('http', 'app')
                    const response = await fetch(`${host}/storage`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${this.$encodedToken}`
                        },
                        body: formData
                    })

                    if (!response.ok) {
                        throw new Error(`Something went wrong.`)
                    }

                    const {hash, mimeType} = await response.json()
                    this.document.fileHash = hash
                    this.document.mimeType = mimeType
                } catch (err) {
                    console.error('File upload error:', err)
                    this.uploadError = 'Er is een fout opgetreden tijdens het uploaden van het document.'
                } finally {
                    this.uploading = false
                }
            },
            async doSubmit () {
                this.saving = true
                this.submitErrors = []
                try {
                    const document = omit(this.schema.clean(this.document), 'documentID')
                    if (this.formMode === 'create') {
                        await this.$model.create(`/documentation/Document`, this.document.documentID, document)
                    } else {
                        await this.$model.update(`/documentation/Document`, this.document.documentID, { $set: document })
                    }
                    this.$router.replace({ name: 'documentation.overview', query: this.$route.query })
                } catch (err) {
                    if (err.error === 'validation-error') {
                        this.submitErrors = err.details.map(error => error.message)
                    } else {
                        this.submitErrors = [err.message]
                    }
                    this.saving = false
                }
            }
        }
    }
</script>
