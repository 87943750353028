import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  host: {
    type: String,
    label: 'Host',
    regEx: SimpleSchema.RegEx.Domain
  },
  port: {
    type: SimpleSchema.Integer,
    label: 'Poort',
    defaultValue: 22
  },
  username: {
    type: String,
    label: 'Gebruikersnaam'
  },
  password: {
    type: String,
    label: 'Wachtwoord',
    encrypted: true,
    autoValue () {
      if (this.isSet && this.encrypt) {
        return this.encrypt(this.value)
      }
    }
  },
  location: {
    type: String,
    label: 'Bestandsnaam en locatie'
  }
})
