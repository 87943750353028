<template>
    <section v-if="loading" class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>API-scripts</h1></template>
                    <template slot="content">
                        <p>Bezig met laden...</p>
                    </template>
                </block>
            </div>
        </div>
    </section>
    <section v-else class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>API-scripts</h1></template>
                    <template slot="content">
                        <p>Hier kun je de API-scripts beheren. Met deze scripts is het mogelijk om maatwerk functionaliteit rechtstreeks binnen Qiri te implementeren.</p>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block :table="true">
                    <template slot="content">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Naam</th>
                                    <th>Omschrijving</th>
                                    <th>Actieve versie</th>
                                    <th>Laatste versie</th>
                                    <th class="icon">
                                        <router-link :to="{ name: 'api-template.scripts.add', query: $route.query }" title="API-script toevoegen" class="btn icon">
                                            <icon name="plus" />
                                        </router-link>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="script of sortedScripts" :key="script.scriptID + '@' + script.version">
                                    <td class="align--top whitespace--nowrap">{{ script.name }}</td>
                                    <td v-html="script.description"></td>
                                    <td class="align--top whitespace--nowrap">Versie {{ script.activeVersion }}</td>
                                    <td class="align--top whitespace--nowrap">Versie {{ script.version }}</td>
                                    <td class="icon align--top whitespace--nowrap">
                                        <router-link
                                            :to="{ name: 'api-template.scripts.history', params: { scriptID: script.scriptID }, query: $route.query }"
                                            title="API-script historie"
                                            class="btn icon"
                                        >
                                            <icon name="history" />
                                        </router-link>
                                        <router-link
                                            :to="{ name: 'api-template.scripts.log', params: { scriptID: script.scriptID }, query: $route.query }"
                                            title="API-script logboek"
                                            class="btn icon"
                                        >
                                            <icon name="eye" />
                                        </router-link>
                                        <btn icon="trash" title="API-script verwijderen" class="icon" @click.prevent="removeRecord(script)"></btn>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </block>
            </div>
        </div>
        <modal name="delete" title="Weet je zeker dat je dit script wilt verwijderen?">
            <p>Wil je het script "{{ selectedRecord && selectedRecord.name }}" verwijderen?</p>
            <p>
                <strong>Let op!</strong>
                Hiermee verwijder je alle onderliggende versies.
                De bijbehorende URL om het script aan te roepen zal hierdoor ook stoppen met werken.
                Zorg er dus voor dat het script niet meer in gebruik is.
            </p>
            <button type="button" @click="removeRecord(selectedRecord, true)">Ja, verwijderen</button>
            <button type="button" @click="$root.$emit('modal', 'delete', false)">Nee, annuleren</button>
        </modal>
    </section>
</template>

<script>
    import Modal from '@/components/Partials/Modal'

    export default {
        name: 'page-scripts-Overview',
        components: {
            Modal
        },
        data () {
            return {
                headScript: null,
                scripts: [],
                selectedRecord: null,
                loading: true
            }
        },
        async mounted () {
            this.refresh()
        },
        watch: {
            overviewMode () {
                this.refresh()
            },
            scriptID () {
                this.refresh()
            }
        },
        computed: {
            sortedScripts () {
                return this.scripts.sort((a, b) => a.name > b.name ? 1 : a.name === b.name ? 0 : -1)
            },
            overviewMode () {
                return this.$route.meta.overview.mode
            },
            scriptID () {
                return this.$route.params.scriptID
            }
        },
        methods: {
            async refresh () {
                this.loading = true
                this.scripts = []

                // Default mode is just an overview of scripts.
                this.$model.list(`/env/${this.$environment}/api-template/Script/list`, 'scripts')

                this.loading = false
            },
            async removeRecord (record, confirmed) {
                if (!confirmed) {
                    this.selectedRecord = record
                    this.$root.$emit('modal', 'delete', true)
                } else {
                    this.selectedRecord = null
                    this.$root.$emit('modal', 'delete', false)
                    await this.$model.remove(`/env/${this.$environment}/api-template/Script`, record.scriptID)
                }
            }
        }
    }
</script>
