/**
 * @todo
 */
export const filter = (filterFn) => (source) => (start, sink) => {
  if (start !== 0) {
    return
  }
  let talkback
  source(0, (t, d) => {
    if (t === 0) {
      talkback = d
      sink(t, d)
    } else if (t === 1) {
      if (filterFn(d)) {
        sink(t, d)
      } else {
        talkback(1)
      }
    } else {
      sink(t, d)
    }
  })
}

/**
 * @todo
 */
export const filterAsync = (filterFn) => (source) => (start, sink) => {
  if (start !== 0) {
    return
  }
  let talkback
  source(0, async (t, d) => {
    if (t === 0) {
      talkback = d
      sink(t, d)
    } else if (t === 1) {
      if (await filterFn(d)) {
        sink(t, d)
      } else {
        talkback(1)
      }
    } else {
      sink(t, d)
    }
  })
}
