<template>
    <span>{{ display }}</span>
</template>

<script>
    export default {
        name: 'partial-Profile',
        props: {
            get: {
                type: String,
                default: 'name'
            }
        },
        computed: {
            display () {
                return this.$token[this.get]
            }
        }
    }
</script>
