<template>
    <section class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>Tabellen &amp; velden</h1></template>
                    <template slot="content">
                        <ModelSelect v-model="selectedModel"></ModelSelect>
                        <p v-if="selectedModel">
                            <button @click.prevent="beginInsertField()">Veld toevoegen aan tabel {{ modelLabel }}</button>
                        </p>
                        <p v-if="selectedModel && false">
                            <a :href="`${$api.host('http')}/download/csv?model=${selectedModel}&token=${$encodedToken}`" target="_BLANK">Tabel {{ modelLabel }} downloaden als CSV</a>
                        </p>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid" v-if="selectedModel">
            <div class="column size--100">
                <block :table="true">
                    <template slot="title"><h2>Velden</h2></template>
                    <template slot="content">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Qiri-veld naam</th>
                                    <th>Qiri-veld omschrijving</th>
                                    <th>Veld type</th>
                                    <th>Doorzoekbaar?</th>
                                    <th class="icon">&nbsp;</th>
                                </tr>
                            </thead>
                            <vue-form tag="tbody" :state="tableForm" :schema="tableFormSchema" validation-mode="full" v-model="tableFormField" :defaults="{ bare: true }">
                                <tr v-for="field of selectedFields" :key="field.name" :class="getFieldClass(field)">
                                    <template v-if="tableFormField && field.fieldID === tableFormField.fieldID">
                                        <td class="whitespace--nowrap">{{ field.name }}</td>
                                        <td><vue-form-field field="description" v-focus="{ scrollTo: false }"></vue-form-field></td>
                                        <td class="whitespace--nowrap">{{ getFieldTypeLabel(field) }}</td>
                                        <td v-if="field.type !== 'foreignKey'"><vue-form-field field="indexed"></vue-form-field></td>
                                        <td v-else>Ja</td>
                                        <td class="icon whitespace--nowrap">
                                            <btn icon="save" :title="getSubmitTitle(tableForm)" class="icon" :disabled="tableForm.invalid" @click.prevent="submitTableForm()"></btn>
                                            <btn icon="ban" title="Annuleren" class="icon" @click.prevent="cancelTableForm()"></btn>
                                        </td>
                                    </template>
                                    <template v-else>
                                        <td class="whitespace--nowrap">{{ field.name }}</td>
                                        <td>{{ field.description }}</td>
                                        <td class="whitespace--nowrap">{{ getFieldTypeLabel(field) }}</td>
                                        <td>{{ field.type === 'foreignKey' || field.indexed ? 'Ja' : 'Nee' }}</td>
                                        <td class="icon" v-if="field.owner === 'system'">&nbsp;</td>
                                        <td class="icon whitespace--nowrap" v-else>
                                            <btn icon="trash" title="Verwijderen" class="icon" @click.prevent="removeField(field)"></btn>
                                            <btn icon="pencil" title="Wijzigen" class="icon" @click.prevent="beginEditField(field)"></btn>
                                            <btn icon="check" title="Validatie regels" class="icon" @click.prevent="beginEditValidation(field)"></btn>
                                        </td>
                                    </template>
                                </tr>
                                <tr v-if="tableFormMode === 'insert'">
                                    <td><vue-form-field field="name" v-focus></vue-form-field></td>
                                    <td><vue-form-field field="description"></vue-form-field></td>
                                    <td><vue-form-field field="type" :options="fieldTypeLabels"></vue-form-field></td>
                                    <td v-if="tableFormField.type !== 'foreignKey'"><vue-form-field field="indexed"></vue-form-field></td>
                                    <td v-else>Ja</td>
                                    <td class="icon whitespace--nowrap">
                                        <btn icon="save" :title="getSubmitTitle(tableForm)" class="icon" :disabled="tableForm.invalid" @click.prevent="submitTableForm()"></btn>
                                        <btn icon="ban" title="Annuleren" class="icon" @click.prevent="cancelTableForm()"></btn>
                                    </td>
                                </tr>
                            </vue-form>
                        </table>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid" v-if="selectedModel">
            <div class="column size--100">
                <block>
                    <template slot="title"><h2>Oorsprong van velden met foreign keys</h2></template>
                    <template slot="content">
                        <p>Uit welke externe systemen en databases krijgt Qiri deze gegevens?</p>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Qiri-veld tabel</th>
                                    <th>Qiri-veld naam</th>
                                    <th>Relatie type</th>
                                    <th>Naam extern systeem</th>
                                    <th>Naam externe tabel</th>
                                    <th>Naam extern veld</th>
                                    <th class="icon">&nbsp;</th>
                                </tr>
                            </thead>
                            <vue-form tag="tbody" :state="relationForm" :schema="relationFormSchema" validation-mode="full" v-model="relationFormField" :defaults="{ bare: true }">
                                <tr v-for="field of relationFields" :key="field.fieldID || field.name" :class="field.external ? 'external-field' : ''">
                                    <template v-if="relationFormField && field.fieldID === relationFormField.fieldID">
                                        <td class="whitespace--nowrap">{{ getModelTable(field.model) }}</td>
                                        <td class="whitespace--nowrap">{{ field.name }}</td>
                                        <td class="whitespace--nowrap">{{ getFieldTypeLabel(field) }}</td>
                                        <td><vue-form-field field="relation.name" v-focus="{ scrollTo: false }"></vue-form-field></td>
                                        <td><vue-form-field field="relation.table"></vue-form-field></td>
                                        <td><vue-form-field field="relation.field"></vue-form-field></td>
                                        <td class="icon">
                                            <btn icon="save" title="Opslaan" class="icon" :disabled="relationForm.invalid" @click.prevent="submitRelationForm()"></btn>
                                            <btn icon="ban" title="Annuleren" class="icon" @click.prevent="cancelRelationForm()"></btn>
                                        </td>
                                    </template>
                                    <template v-else>
                                        <td class="whitespace--nowrap">{{ getModelTable(field.model) }}</td>
                                        <td class="whitespace--nowrap">{{ field.name }}</td>
                                        <td class="whitespace--nowrap">{{ getFieldTypeLabel(field) }}</td>
                                        <td class="whitespace--nowrap">{{ field.relation ? field.relation.name : '' }}</td>
                                        <td class="whitespace--nowrap">{{ field.relation ? field.relation.table : '' }}</td>
                                        <td class="whitespace--nowrap">{{ field.relation ? field.relation.field : '' }}</td>
                                        <td class="icon">
                                            <btn v-if="!field.external" icon="pencil" title="Wijzigen" class="icon" @click.prevent="beginEditRelation(field)"></btn>
                                        </td>
                                    </template>
                                </tr>
                            </vue-form>
                        </table>
                    </template>
                </block>
            </div>
        </div>

        <div class="grid" v-if="false">
            <div class="column size--100">
                <block>
                    <template slot="content">
                        <pre>{{ customFieldsJSON }}</pre>
                    </template>
                </block>
            </div>
        </div>
        <modal name="validation" title="Validatie regel">
            <vue-form
                tag="p"
                v-if="selectedField"
                :state="validationForm"
                :schema="validationSchema"
                v-model="editValidation"
            >
                <vue-form-field field="validationTemplate"></vue-form-field>
            </vue-form>
            <button type="button" :disabled="validationForm.invalid" @click.prevent="submitValidationForm()">Opslaan</button>
            <button type="button" @click.prevent="$root.$emit('modal', 'validation', false)">Annuleren</button>
        </modal>
        <modal name="delete" title="Weet je zeker dat je dit veld wilt verwijderen?">
            <p>Wil je het veld "{{ selectedField && selectedField.name }}" verwijderen?</p>
            <button type="button" @click="removeField(selectedField, true)">Ja, verwijderen</button>
            <button type="button" @click="$root.$emit('modal', 'delete', false)">Nee, annuleren</button>
        </modal>
        <modal name="field-in-use" title="Veld is in gebruik">
            <p>Kan het veld "{{ selectedField && selectedField.name }}" niet verwijderen, omdat deze in gebruik is:</p>
            <ul>
                <li v-for="usage of fieldUsage" :key="usage.id"><b>{{ usage.type }}:</b> {{ usage.name }}</li>
            </ul>
            <button type="button" @click="$root.$emit('modal', 'field-in-use', false)">Annuleren</button>
        </modal>
    </section>
</template>

<script>
    import uuidv4 from 'uuid'
    import slugify from 'slugify'
    import omit from 'lodash/omit'
    import clone from 'lodash/clone'
    import SimpleSchema from '@qiri/simpl-schema'
    import {getFullLabel, mergeLabelAndDescription} from '@qiri/models/util'
    import models, {MODELS, CustomField} from '@qiri/models/data'
    import {getRelatedModels, fieldToColumn} from '@qiri/models/data/util'
    import {FieldTypes, compareFields} from '@qiri/models/data/Field'
    import ModelSelect from '../../Partials/ModelSelect'
    import Modal from '@/components/Partials/Modal'

    const propertyToType = (property) => {
        const type = property.type.singleType
        if (type === SimpleSchema.Integer) {
            return 'number'
        } else if (type === Number) {
            return 'decimal'
        } else if (type === String) {
            return property.multiline ? 'text' : 'string'
        } else if (type === Boolean) {
            return 'bool'
        } else if (type === Date) {
            return 'date'
        } else if (type === Array) {
            return 'list'
        } else if (SimpleSchema.isSimpleSchema(type)) {
            return 'object'
        } else {
            return 'unknown'
        }
    }

    export default {
        name: 'page-data-Model',
        components: {
            ModelSelect,
            Modal
        },
        data () {
            return {
                selectedModel: null,

                systemFields: [],
                customFields: [],

                selectedField: null,
                fieldUsage: [],
                editValidation: {},
                validationForm: {},

                tableForm: {},
                tableFormMode: null,
                tableFormField: null,

                relationForm: {},
                relationFormField: null
            }
        },
        mounted () {
            // Get system fields from the models.
            const showHiddenFields = this.$token.role === 'administrator' || this.$token.role === 'superadministrator'
            MODELS.forEach(modelName => {
                const model = models.schema(modelName)
                const schema = model.type.singleType
                for (const [ propertyName, property ] of Object.entries(schema.mergedSchema())) {
                    const propertyType = propertyToType(property)
                    const isHiddenField = property.primaryKey || property.foreignKey
                    const isSimpleField = propertyName.indexOf('$') === -1 && FieldTypes[propertyType]

                    if ((showHiddenFields && (isHiddenField || isSimpleField)) ||
                        (!showHiddenFields && !isHiddenField && isSimpleField)) {
                        this.systemFields.push({
                            model: modelName,
                            name: propertyName,
                            description: mergeLabelAndDescription(getFullLabel(propertyName, schema), property.description),
                            primaryKey: property.primaryKey,
                            foreignKey: property.foreignKey,
                            type: propertyType,
                            owner: 'system'
                        })
                    }
                }
            })

            // Get custom fields from the backend.
            this.$model.list(`/env/${this.$environment}/data/CustomField/list`, 'customFields')
        },
        computed: {
            fieldTypeLabels () {
                return FieldTypes
            },
            modelLabel () {
                const modelName = this.selectedModel
                if (!modelName) {
                    return ''
                }
                const model = models.schema(modelName)
                return model.table
            },
            relatedModels () {
                const modelName = this.selectedModel
                return modelName ? Array.from(getRelatedModels(modelName)) : []
            },
            tableFormSchema () {
                if (this.tableFormMode === 'insert') {
                    const schema = CustomField.pick('name', 'description', 'type', 'indexed')

                    // Add validation to ensure label (and name) is unique.
                    schema.addDocValidator(doc => {
                      let errors = []
                      if (doc.name) {
                        if (this.fields.some(field => field.model === this.selectedModel && fieldToColumn(field.name).toLowerCase() === fieldToColumn(doc.name).toLowerCase())) {
                            errors.push({
                                name: schema.label('name'),
                                type: 'mustBeUnique',
                                value: doc.name
                            })
                        }
                      }
                      return errors
                    })

                    return schema
                } else if (this.tableFormMode === 'update') {
                    return CustomField.pick('fieldID', 'description', 'type', 'indexed')
                } else {
                    return null
                }
            },
            relationFormSchema () {
                return CustomField.pick('fieldID', 'relation')
            },
            fields () {
                let fields = []
                for (const systemField of this.systemFields) {
                    fields.push({
                        ...systemField,
                        fieldID: null,
                        validationTemplate: null
                    })
                }
                for (const customField of this.customFields) {
                    const existingField = fields.find(x => x.model === customField.model && x.name === customField.name)
                    if (existingField) {
                        existingField.fieldID = customField.fieldID
                        existingField.validationTemplate = customField.validationTemplate
                    } else {
                        fields.push({ ...customField })
                    }
                }
                return fields.sort(compareFields)
            },
            selectedFields () {
                return this.fields.filter(field => field.model === this.selectedModel)
            },
            relationFields () {
                // Get the foreign keys from the related models.
                let relatedFields = []
                for (const modelName of this.relatedModels) {
                    // Get system foreign key.
                    this.fields
                        .filter(field => field.model === this.selectedModel && field.foreignKey === modelName)
                        .forEach(field => relatedFields.push({
                            ...field,
                            relation: {
                                name: 'Qiri',
                                table: this.getModelTable(modelName),
                                field: this.fields.find(x => x.model === modelName && x.primaryKey).name
                            },
                            external: true
                        }))
                }
                for (const modelName of this.relatedModels) {
                    // Get user foreign keys in related model.
                    this.fields
                        .filter(field => field.model === modelName && field.type === 'foreignKey')
                        .forEach(field => relatedFields.push({
                            ...field,
                            external: true
                        }))
                }

                // Get the foreign keys from the current model.
                const ownFields = this.fields
                    .filter(field => field.model === this.selectedModel && field.type === 'foreignKey')
                    .map(field => ({
                        ...field,
                        external: false
                    }))

                return relatedFields.concat(ownFields)
            },
            validationSchema () {
                return CustomField.pick('validationTemplate')
            },
            customFieldsJSON () {
                return JSON.stringify(this.customFields, null, '  ')
            }
        },
        methods: {
            getModelTable (modelName) {
                const model = models.schema(modelName)
                return model.table
            },
            getFieldClass (field) {
                let classes = {}

                let fieldType = field.type || 'unknown'
                if (field.primaryKey) {
                    fieldType = 'primaryKey'
                } else if (field.foreignKey) {
                    fieldType = 'foreignKey'
                }

                return {
                    [`${field.owner}-field`]: true,
                    [`${fieldType}-field`]: true
                }
            },
            getFieldTypeLabel (field) {
                if (field.primaryKey) {
                    return 'Primary key'
                } else if (field.foreignKey) {
                    return 'Foreign key'
                } else {
                    return FieldTypes[field.type]
                }
            },
            getSubmitTitle (form) {
                let title = ['Opslaan']
                if (form.errors.length > 0) {
                    title = [
                        ...title,
                        'Fouten:',
                        ...form.errors.map(error => error.message)
                    ]
                }
                return title.join('\n')
            },
            beginInsertField () {
                this.tableFormMode = 'insert'
                this.tableFormField = {
                    name: '',
                    description: '',
                    type: undefined,
                    indexed: false
                }
            },
            beginEditField (field) {
                this.tableFormMode = 'update'
                this.tableFormField = {
                    fieldID: field.fieldID,
                    description: field.description,
                    type: field.type,
                    indexed: !!field.indexed
                }
            },
            async submitTableForm () {
                // TODO: Since action is async, show feedback in UI while waiting (e.g. loading indicator in submit button).
                if (this.tableFormMode === 'insert') {
                    await this.$model.create(`/env/${this.$environment}/data/CustomField`, uuidv4(), {
                        model: this.selectedModel,
                        owner: 'user',
                        ...this.tableFormField
                    })
                } else if (this.tableFormMode === 'update') {
                    await this.$model.update(`/env/${this.$environment}/data/CustomField`, this.tableFormField.fieldID, { $set: {
                        description: this.tableFormField.description,
                        type: this.tableFormField.type,
                        indexed: !!this.tableFormField.indexed
                    }})
                }

                this.tableFormMode = null
                this.tableFormField = null
            },
            cancelTableForm (field) {
                this.tableFormMode = null
                this.tableFormField = null
            },
            beginEditRelation (field) {
                this.relationFormField = {
                    fieldID: field.fieldID,
                    relation: clone(field.relation)
                }
            },
            async submitRelationForm () {
                // TODO: Since action is async, show feedback in UI while waiting (e.g. loading indicator in submit button).
                await this.$model.update(`/env/${this.$environment}/data/CustomField`, this.relationFormField.fieldID, { $set: {
                    relation: this.relationFormField.relation
                }})
                this.relationFormField = null
            },
            cancelRelationForm (field) {
                this.relationFormField = null
            },
            async removeField (field, confirmed) {
                if (!confirmed) {
                    // Check usage. Is the field in use? Show warning instead (don't allow remove).
                    const usage = await this.$api.call(`/env/${this.$environment}/data/CustomField/getUsage`, {
                        model: this.selectedModel,
                        field: field.name
                    })
                    if (usage.length > 0) {
                        this.selectedField = field
                        this.fieldUsage = usage
                        this.$root.$emit('modal', 'field-in-use', true)
                    } else {
                        this.selectedField = field
                        this.$root.$emit('modal', 'delete', true)
                    }
                } else {
                    this.selectedField = null
                    this.$root.$emit('modal', 'delete', false)
                    await this.$model.remove(`/env/${this.$environment}/data/CustomField`, field.fieldID)
                }
            },
            beginEditValidation (field) {
                this.selectedField = field
                this.editValidation = {
                    validationTemplate: field.validationTemplate
                }
                this.$root.$emit('modal', 'validation', true)
            },
            async submitValidationForm () {
                // TODO: Since action is async, show feedback in UI while waiting (e.g. loading indicator in submit button).
                await this.$model.update(`/env/${this.$environment}/data/CustomField`, this.selectedField.fieldID, { $set: {
                    validationTemplate: this.editValidation.validationTemplate
                }})
                this.selectedField = null
                this.editValidation = null
                this.$root.$emit('modal', 'validation', false)
            }
        }
    }
</script>
