import {randomBytes} from 'crypto'
import {bytesToUuid} from './util'

/**
 * @private
 */
const ID_LENGTH = 16

/**
 * @private
 */
let lastTime = 0

/**
 * @private
 */
let lastSequence = 0

/**
 * @todo
 */
export default class Identifier {
  /**
   * @todo
   */
  constructor (time, sequence, hash) {
    this._time = time
    this._sequence = sequence
    this._hash = hash
  }

  /**
   * @todo
   */
  static create () {
    let id = new Identifier()
    id._time = Date.now()
    id._hash = randomBytes(8)

    if (id._time <= lastTime) {
      if (lastSequence >= 65536) {
        throw new Error(`Only 65536 identifiers can be generated for the same seed time`)
      }
      id._time = lastTime
      id._sequence = ++lastSequence
    } else {
      lastTime = id._time
      id._sequence = lastSequence = 0
    }

    return id
  }

  /**
   * @todo
   */
  static encodingLength () {
    return ID_LENGTH
  }

  /**
   * @todo
   */
  static encode (id, buffer, offset) {
    if (!id) {
      return null
    } else if (Buffer.isBuffer(id)) {
      return id
    }
    if (typeof id === 'string') {
      return Buffer.from(id.replace(/-/g, ''), 'hex')
    }
    if (!buffer) {
      buffer = Buffer.allocUnsafe(ID_LENGTH)
    }
    id.encode(buffer, offset)
    return buffer
  }

  /**
   * @todo
   */
  static decode (buffer, offset, end) {
    if (!buffer) {
      return null
    } else if (buffer instanceof Identifier) {
      return buffer
    }
    if (typeof buffer === 'string') {
      buffer = Buffer.from(buffer.replace(/-/g, ''), 'hex')
    }
    const id = new Identifier()
    id.decode(buffer, offset, end)
    return id
  }

  /**
   * @todo
   */
  static compare (left, right) {
    if (left instanceof Identifier) {
      return left.compare(right)
    } else if (Buffer.isBuffer(left)) {
      return Buffer.compare(left, Identifier.encode(right))
    } else {
      throw new TypeError(`Arguments must be Identifiers or Buffers`)
    }
  }

  /**
   * @todo
   */
  get time () {
    return this._time
  }

  /**
   * @todo
   */
  get sequence () {
    return this._sequence
  }

  /**
   * @todo
   */
  get encodingLength () {
    return ID_LENGTH
  }

  /**
   * @todo
   */
  encode (buffer, offset = 0) {
    buffer.writeUIntBE(this._time, offset, 6)
    buffer.writeUInt16BE(this._sequence, offset + 6)
    this._hash.copy(buffer, offset + 8, 0, 8)
    return ID_LENGTH
  }

  /**
   * @todo
   */
  decode (buffer, offset = 0, end = buffer.length) {
    if (end > buffer.length || offset > buffer.length || end - offset < ID_LENGTH) {
      throw new Error(`Decoded "Identifier" message is not valid`)
    }
    this._time = buffer.readUIntBE(offset, 6)
    this._sequence = buffer.readUInt16BE(offset + 6)
    this._hash = buffer.slice(offset + 8, offset + 16)
    return ID_LENGTH
  }

  /**
   * @todo
   */
  compare (other) {
    other = Identifier.decode(other)
    if (this._time < other._time) {
      return -1
    } else if (this._time > other._time) {
      return 1
    } else if (this._sequence < other._sequence) {
      return -1
    } else if (this._sequence > other._sequence) {
      return 1
    } else {
      return Buffer.compare(this._hash, other._hash)
    }
  }

  /**
   * @todo
   */
  valueOf () {
    return Identifier.encode(this)
  }

  /**
   * @todo
   */
  toJSON () {
    return this.toString()
  }

  /**
   * @todo
   */
  toString () {
    const buf = Buffer.allocUnsafe(ID_LENGTH)
    this.encode(buf, 0)
    return bytesToUuid(buf)
  }
}

Identifier.encode.bytes = ID_LENGTH
Identifier.decode.bytes = ID_LENGTH
