<template>
    <button type="button" :class="color" @click="handleClick"><icon v-if="icon" :name="icon"></icon> <slot></slot></button>
</template>

<script>
    export default {
        name: 'btn',
        props: {
            color: {
                type: String
            },
            icon: {
                type: String
            }
        },
        methods: {
            handleClick (evt) {
              this.$emit('click', evt)
            }
        }
    }
</script>
