import SimpleSchema from '@qiri/simpl-schema'
import Audit from '../security/Audit'

export default new SimpleSchema({
  'taskExecutionID': {
    type: String,
    label: 'Taak uitvoering ID',
    primaryKey: true
  },
  'environmentID': {
    type: String,
    label: 'Omgeving ID'
  },
  'taskID': {
    type: String,
    label: 'Taak ID',
    foreignKey: 'Task'
  },
  'action': {
    type: String,
    label: 'Actie'
  },
  'payload': {
    type: Object,
    blackbox: true,
    optional: true
  },
  'module': {
    type: String,
    label: 'Module',
    optional: true,
    index: true
  },
  'audit': {
    type: Audit,
    label: 'Uitgevoerd door',
    optional: true
  },
  'executedAt': {
    type: Date,
    label: 'Uitgevoerd op',
    optional: true
  },
  'finishedAt': {
    type: Date,
    label: 'Afgerond op',
    optional: true
  },
  'result': {
    type: Object,
    optional: true,
    label: 'Resultaat'
  },
  'result.code': {
    type: SimpleSchema.Integer
  },
  'result.data': {
    type: Object,
    blackbox: true,
    optional: true
  }
})
