<template>
  <section v-if="loading" class="padding">
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h1>{{ title }}</h1></template>
          <template slot="content">
            <p v-if="loading">Bezig met laden...</p>
          </template>
        </block>
      </div>
    </div>
  </section>
  <vue-form v-else tag="section" :state="form" ref="form" :schema="schema" v-model="apiLogExport" class="padding" @submit="doSubmit()">
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h1>{{ title }}</h1></template>
          <template slot="content">
            <vue-form-field field="name"></vue-form-field>
            <vue-form-field field="description"></vue-form-field>
            <vue-form-field field="algorithmID" :options="algorithmOptions"></vue-form-field>
          </template>
        </block>
      </div>
    </div>
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h2>Voorwaarden</h2></template>
          <template slot="content">
            <table class="table form-padding">
              <tbody>
                <tr v-for="(filter, index) of filters" :key="tag(filter)">
                  <td :colspan="getColspanForFilter(filter)">
                    <vue-form-field v-if="index < filters.length - 1" :field="`filters.${index}.select`" :bare="true"></vue-form-field>
                    <vue-form-field v-else :field="`filters.$.select`" :bare="true" null-label="Nieuwe voorwaarde toevoegen" v-model="newFilter.select"></vue-form-field>
                  </td>
                  <template v-if="filter.select === 'field'">
                    <td><vue-form-field :field="`filters.${index}.name`" :bare="true" :options="getFieldsForModelAsOptions()"></vue-form-field></td>
                    <td><vue-form-field :field="`filters.${index}.operator`" :bare="true" :options="getOperatorsForFilter(filter)"></vue-form-field></td>
                    <td><vue-form-field :field="`filters.${index}.value`" :bare="true"></vue-form-field></td>
                  </template>
                  <template v-else-if="filter.select === 'insert-date'">
                    <td><vue-form-field :field="`filters.${index}.operator`" :bare="true" :options="getOperatorsForFilter(filter)"></vue-form-field></td>
                    <td><vue-form-field :field="`filters.${index}.value`" :bare="true"></vue-form-field></td>
                  </template>
                  <td class="icon">
                    <btn v-if="index < filters.length - 1" icon="trash" title="Verwijderen" class="icon" @click.prevent="removeFilter(index)"></btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </block>
      </div>
    </div>
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h2>Opties</h2></template>
          <template slot="content">
            <vue-form-field field="onlySinceLastExport">{{ onlySinceLastExportLabel }}</vue-form-field>
          </template>
        </block>
      </div>
    </div>
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h2>Bestandsformaat van het export bestand</h2></template>
          <template slot="content">
            <vue-form-field field="format.type" :options="formatOptions"></vue-form-field>
            <component :is="formatForm"></component>
          </template>
        </block>
      </div>
    </div>
    <div class="grid" v-if="!apiLogExport.format || !apiLogExport.format.type">
      <div class="column size--100">
        <block>
          <template slot="title"><h2>Toewijzen van velden aan export bestand</h2></template>
          <template slot="content">
            <p>Kies eerst het bestandsformaat van het export bestand.</p>
          </template>
        </block>
      </div>
    </div>
    <div class="grid" v-else>
      <div class="column size--100">
        <block :table="true">
          <template slot="title"><h2>Toewijzen van velden aan export bestand</h2></template>
          <template slot="content">
            <MappingTable v-model="apiLogExport.mappings" :models="models" :source="tableSource"></MappingTable>
          </template>
        </block>
      </div>
    </div>
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="content">
            <button type="button" class="full" title="Opslaan" :disabled="saving" @click.prevent="$refs.form.submit()">
              <template v-if="saving"><icon name="refresh" :spin="true" /></template>
              <template v-else>{{ title }}</template>
            </button>
            <ul class="errors" v-if="formErrors.length > 0 || submitErrors.length > 0">
              <li v-for="error of formErrors">
                <icon name="exclamation-circle"></icon> <vue-markdown :source="error"></vue-markdown>
              </li>
              <li v-for="error of submitErrors">
                <icon name="exclamation-circle"></icon> <vue-markdown :source="error"></vue-markdown>
              </li>
            </ul>
          </template>
        </block>
      </div>
    </div>
  </vue-form>
</template>

<script>
  import uuidv4 from 'uuid/v4'
  import omit from 'lodash/omit'
  import moment from 'moment-timezone'
  import VueMarkdown from '@qiri/vue-markdown'
  import SimpleSchema from '@qiri/simpl-schema'
  import models, {MODELS} from '@qiri/models/data'
  import {APIEvent, Export} from '@qiri/models/api-log'
  import {getFieldsFor, fieldTypeToRealType} from '@qiri/models/data/util'
  import {getAlgorithms} from '@qiri/models/algorithm'
  import * as operators from '@qiri/models/aggregate/operators'
  import TableSource from '@qiri/models/aggregate/sources/table'

  import MappingTable from '@/modules/aggregate/components/MappingTable'

  import {pull} from '@qiri/stream/pull'
  import {iterate} from '@qiri/stream/sources/iterate'
  import {empty} from '@qiri/stream/sources/empty'

  import * as formats from './formats'

  /**
   * @private
   */
  const TAG = Symbol('tag')

  /**
   * @private
   */
  const OPERATOR = Symbol('operator')

  /**
   * @todo
   */
  export default {
    name: 'page-api-log-exports-Form',
    components: {
      VueMarkdown,
      MappingTable
    },
    data () {
      return {
        form: {},
        apiLogExport: {},
        newFilter: {
          select: null
        },
        customFields: [],
        loading: true,
        saving: false,
        submitErrors: []
      }
    },
    async mounted () {
      // Load the user, if any.
      if (this.formMode === 'edit') {
        // Ensure a record ID was given.
        if (!this.exportID) {
          throw new Error(`Missing property "exportID".`)
        }

        // Load the record from the backend.
        this.apiLogExport = await this.$model.get(`/env/${this.$environment}/api-log-export/Export`, this.exportID)

        // Ensure that we loaded the record.
        if (!this.apiLogExport) {
          throw new Error(`Export "${this.exportID}" not found.`)
        }

        // Normalize existing record.
        this.$set(this.apiLogExport, 'filters', this.apiLogExport.filters || [])
        this.$set(this.apiLogExport, 'mappings', this.apiLogExport.mappings || [])
      } else {
        // Create a new record.
        this.apiLogExport = this.schema.clean({
          exportID: uuidv4(),
          filters: [],
          mappings: []
        })
      }

      // Watch for new filters.
      this.$watch(
        'newFilter',
        (filter) => {
          if (filter.select) {
            this.apiLogExport.filters.push(filter)
            this.newFilter = {
              select: null
            }
          }
        },
        {deep: true}
      )

      // Load the custom fields.
      this.customFields = await this.$model.fetch(`/env/${this.$environment}/data/CustomField/list`)

      // Finished loading.
      this.loading = false
    },
    computed: {
      schema () {
        return Export
      },
      algorithmOptions () {
        const options = getAlgorithms()
          .filter(algorithm => algorithm.requestSchema && algorithm.outputSchema)
          .map(algorithm => [
            algorithm.algorithmID,
            algorithm.name
          ])
        options.splice(0, 0,  [null, 'Geen algoritme: alle logregels'])
        return options
      },
      models () {
        const modelsSchemaDef = {}

        // Define data models.
        for (const modelName of MODELS) {
          const modelSchema = models.schema(modelName)
          const customFields = this.customFields.filter(x => x.model === modelName)

          const customSchema = {}
          for (const customField of customFields) {
            customSchema[customField.name] = {
              type: fieldTypeToRealType(customField.type),
              label: customField.description,
              optional: true
            }
          }

          const mergedSchema = new SimpleSchema(modelSchema.type.singleType)
          mergedSchema.extend(customSchema)

          modelsSchemaDef[modelName] = {
            type: mergedSchema,
            label: modelSchema.label,
            table: modelSchema.table,
            store: modelSchema.store
          }
        }

        // Define APIEvent.
        modelsSchemaDef.APIEvent = {
          type: APIEvent.getSchemaFor(
            {
              algorithmID: this.apiLogExport.algorithmID
            },
            {
              model: (modelName) => modelsSchemaDef[modelName]
            }
          ),
          label: 'API-log logregel',
          table: 'API-log logregels'
        }

        return new SimpleSchema(modelsSchemaDef)
      },
      exportID () {
        return this.$route.params.exportID
      },
      title () {
        return this.$route.meta.title
      },
      filters () {
        return [
          ...this.apiLogExport.filters,
          this.newFilter
        ]
      },
      tableSource () {
        // Get the table source used by the condition and mapping UI stages.
        return TableSource(
          {},
          {
            table: 'APIEvent'
          },
          {
            model: modelName => this.models.schema(modelName),
            store: () => empty()
          }
        )
      },
      onlySinceLastExportLabel () {
        let label = 'Exporteer alleen nieuwe regels die aan Qiri zijn toegevoegd na de laatste keer uitvoeren van deze export'
        if (this.apiLogExport.lastExport && this.apiLogExport.lastExport.startDate) {
          label += ` op ${moment(this.apiLogExport.lastExport.startDate).format('YYYY-MM-DD HH:mm:ss')}`
        }
        return label
      },
      formatOptions () {
        // TODO: Get from metadata schema.
        return {
          'CSV': 'CSV'
        }
      },
      formatForm () {
        const formatType = this.apiLogExport && this.apiLogExport.format && this.apiLogExport.format.type
        if (!formatType) {
          return null
        }
        return formats[formatType]
      },
      formMode () {
        return this.$route.meta.form.mode
      },
      formErrors () {
        let messages = []
        if (this.form.errors && this.form.errors.length > 0) {
          for (const error of this.form.errors) {
            let message = `${error.message}.`
            // Don't show duplicate messages.
            const duplicateMessage = messages.find(x => x === message)
            if (!duplicateMessage) {
              messages.push(message)
            }
          }
        }
        return messages
      }
    },
    methods: {
      tag (obj) {
        if (obj && !obj[TAG]) {
          obj[TAG] = uuidv4()
        }
        return obj && obj[TAG]
      },
      getFieldsForModelAsOptions () {
        return Object.entries(this.models.schema('APIEvent').type.singleType.mergedSchema())
          .filter(([propertyName, property]) =>
            propertyName.indexOf('$') === -1 &&
            property.type.singleType !== Array
          )
          .sort((a, b) => a[0] < b[0] ? -1 : a[0] === b[0] ? 0 : 1)
          .map(([propertyName, property]) => [
            propertyName,
            property.label ? `${propertyName} (${property.label})` : propertyName
          ])
      },
      getOperatorsForFilter (filter) {
        if (filter.select === 'insert-date') {
          return [
            ['bigger-than', 'Is groter dan'],
            ['bigger-than-or-equal', 'Is groter dan of gelijk aan'],
            ['smaller-than', 'Is kleiner dan'],
            ['smaller-than-or-equal', 'Is kleiner dan of gelijk aan']
          ]
        } else {
          return [
            ['equal', 'Is gelijk aan'],
            ['not-equal', 'Is ongelijk aan'],
            ['bigger-than', 'Is groter dan'],
            ['bigger-than-or-equal', 'Is groter dan of gelijk aan'],
            ['smaller-than', 'Is kleiner dan'],
            ['smaller-than-or-equal', 'Is kleiner dan of gelijk aan'],
            ['in', 'Komt voor in (komma-gescheiden) lijst'],
            ['not-in', 'Komt niet voor in (komma-gescheiden) lijst']
          ]
        }
      },
      getColspanForFilter (filter) {
        const columns = 5
        switch (filter.select) {
          case null:
          case '':
            return columns

          case 'field':
            return 1

          case 'insert-date':
            return 2
        }
      },
      removeFilter (index) {
        this.apiLogExport.filters.splice(index, 1)
      },
      async doSubmit () {
        this.saving = true
        this.submitErrors = []
        try {
          const apiLogExport = omit(this.schema.clean(this.apiLogExport), 'exportID')
          apiLogExport.timezone = moment.tz.guess()

          if (this.formMode === 'create') {
            await this.$model.create(`/env/${this.$environment}/api-log-export/Export`, this.apiLogExport.exportID, apiLogExport)
            this.$router.replace({ name: 'api-log.exports', query: this.$route.query })
          } else {
            await this.$model.update(`/env/${this.$environment}/api-log-export/Export`, this.apiLogExport.exportID, { $set: apiLogExport })
            this.$router.push({ name: 'api-log.exports', query: this.$route.query })
          }
        } catch (err) {
          if (err.error === 'validation-error') {
            this.submitErrors = err.details.map(error => error.message)
          } else {
            this.submitErrors = [err.message]
          }
          this.saving = false
        }
      }
    }
  }
</script>
