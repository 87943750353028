import extend from 'lodash/extend'
import moment from 'moment'
import SimpleSchema from '@qiri/simpl-schema'
import PersonModel from '../data/Person'
import OrderModel from '../data/Order'
import OrderLineModel from '../data/OrderLine'

export const Configuration = new SimpleSchema({

})

export const Input = {
  Order: ['personID', 'timestamp'],
  OrderLine: ['orderID', 'productID', 'quantity']
}

export const Output = ({model} = {}) => new SimpleSchema({
  'person': {
    type: model ? model('Person').type : PersonModel,
    label: 'Persoon',
    table: 'Personen'
  },
  'details': {
    type: Object
  },
  'details.firstOrderDate': {
    type: Date
  },
  'details.lastOrderDate': {
    type: Date
  }
})

export const Preview = ({customFields}) => new SimpleSchema(extend(
  customFields
    .filter(customField => customField.model === 'Person' && customField.type === 'foreignKey')
    .reduce(
      (acc, cur) => {
        acc[cur.name] = {
          type: String,
          label: cur.name,
          render (record) {
            return record.person[cur.name]
          }
        }
        return acc
      },
      {}
    ),
  {
    'lastDate': {
      type: String,
      label: 'Laatste keer',
      render (record) {
        return moment(record.details.lastOrderDate).format('D-M-YYYY')
      }
    }
  }
))

export const Request = new SimpleSchema({
  'product': {
    type: String,
    label: 'Product',
    description: 'Het product om op te zoeken'
  },
  'minOrderDate': {
    type: Date,
    label: 'Laatste aankoopmoment niet eerder dan',
    description: 'Indien ingevuld, mag het laatste aankoopmoment niet eerder zijn dan de opgegeven datum.',
    optional: true
  },
  'maxOrderDate': {
    type: Date,
    label: 'Laatste aankoopmoment niet later dan',
    description: 'Indien ingevuld, mag het laatste aankoopmoment niet later zijn dan de opgegeven datum.',
    optional: true
  },
  'filter': {
    type: Array,
    label: 'Filters',
    optional: true
  },
  'filter.$': {
    type: String
  },
  'limit': {
    type: SimpleSchema.Integer,
    label: 'Limiet',
    description: 'Maximaal aantal personen dat wordt getoond.',
    min: 1,
    optional: true
  }
})

export const RequestConfiguration = new SimpleSchema({

})
