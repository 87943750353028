import SimpleSchema from '@qiri/simpl-schema'

/**
 * @todo
 */
export default new SimpleSchema({
  'feedLogID': {
    type: String,
    primaryKey: true
  },
  'feedID': {
    type: String
  },
  'date': {
    type: Date,
    label: 'Datum'
  },
  'type': {
    type: String,
    label: 'Type'
  },
  'data': {
    type: Object,
    label: 'Data',
    blackbox: true,
    optional: true
  },
  'audit': {
    type: Object,
    label: 'Data',
    blackbox: true,
    optional: true
  }
})
