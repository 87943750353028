<template>
    <section v-if="loading" class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>API-script historie</h1></template>
                    <template slot="content">
                        <p>Bezig met laden...</p>
                    </template>
                </block>
            </div>
        </div>
    </section>
    <section v-else class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>API-script historie</h1></template>
                    <template slot="content">
                        <p>Hier kun je de versies van een API-scripts beheren.</p>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block :table="true">
                    <template slot="content">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Naam</th>
                                    <th>Versie</th>
                                    <th class="icon">
                                        <router-link :to="{ name: 'api-template.scripts.new-version', query: $route.query }" :title="`API-script versie toevoegen (versie ${headScript.version + 1})`" class="btn icon">
                                            <icon name="plus" />
                                        </router-link>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="script of sortedScripts" :key="script.scriptID + '@' + script.version">
                                    <td class="icon-inline align--top">
                                        <router-link v-if="script === headScript && isNotActive(script)"
                                            :to="{
                                                name: 'api-template.scripts.edit-version',
                                                params: {
                                                    scriptID: script.scriptID,
                                                    version: script.version
                                                },
                                                query: $route.query
                                            }"
                                            title="API-script versie aanpassen"
                                            class="btn icon"
                                        >
                                            <icon name="pencil" />
                                        </router-link>
                                        <router-link v-else
                                            :to="{
                                                name: 'api-template.scripts.view-version',
                                                params: {
                                                    scriptID: script.scriptID,
                                                    version: script.version
                                                },
                                                query: $route.query
                                            }"
                                            title="API-script versie bekijken"
                                            class="btn icon"
                                        >
                                            <icon name="eye" />
                                        </router-link>
                                        {{ script.name }}
                                    </td>
                                    <td>Versie {{ script.version }}</td>
                                    <td class="icon align--top">
                                        <btn v-if="isNotActive(script)" icon="toggle-off" :title="`Versie ${script.version} actief maken`" class="icon" @click.prevent="activateScript(script)"></btn>
                                        <btn v-else icon="toggle-on" :title="`Versie ${script.version} is actief`" class="icon"></btn>
                                        <btn :disabled="!isNotActive(script) || scripts.length === 0" icon="trash" title="Versie verwijderen" class="icon" @click.prevent="removeRecord(script)"></btn>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </block>
            </div>
        </div>
        <modal name="delete" title="Weet je zeker dat je deze versie wilt verwijderen?">
            <p>Wil je API-script versie {{ selectedRecord && selectedRecord.version }} verwijderen?</p>
            <button type="button" @click="removeRecord(selectedRecord, true)">Ja, verwijderen</button>
            <button type="button" @click="$root.$emit('modal', 'delete', false)">Nee, annuleren</button>
        </modal>
    </section>
</template>

<script>
    import Modal from '@/components/Partials/Modal'

    export default {
        name: 'page-scripts-History',
        components: {
            Modal
        },
        data () {
            return {
                headScript: null,
                scripts: [],
                selectedRecord: null,
                loading: true
            }
        },
        async mounted () {
            this.refresh()
        },
        watch: {
            overviewMode () {
                this.refresh()
            },
            scriptID () {
                this.refresh()
            }
        },
        computed: {
            sortedScripts () {
                return [
                    this.headScript,
                    ...this.scripts.sort((a, b) => a.version < b.version ? 1 : a.version === b.version ? 0 : -1)
                ]
            },
            overviewMode () {
                return this.$route.meta.overview.mode
            },
            scriptID () {
                return this.$route.params.scriptID
            }
        },
        methods: {
            async refresh () {
                this.loading = true
                this.scripts = []

                // Get the current script's version, so we know what we're going to override.
                this.headScript = await this.$model.get(`/env/${this.$environment}/api-template/Script`, this.scriptID)
                if (!this.headScript) {
                    throw new Error(`Script "${this.scriptID}" not found.`)
                }

                // History mode lists previous versions of a script.
                this.$model.list(`/env/${this.$environment}/api-template/Script/listHistory`, 'scripts', {
                    recordID: this.scriptID
                })

                this.loading = false
            },
            isNotActive (script) {
                return script.version !== this.headScript.activeVersion
            },
            async activateScript (script) {
                //await this.$model.dispatch(`/env/${this.$environment}/api-template/Script`, this.script.scriptID, 'activate', {version: script.version})
                // If we just modify the "activeVersion", but don't increment the version itself, we don't create a new version.
                // This way, the latest script can be used to mark which script is active.
                await this.$model.update(`/env/${this.$environment}/api-template/Script`, script.scriptID, {$set: {
                    activeVersion: script.version
                }})

                // Reflect active version in UI.
                if (this.headScript) {
                    this.headScript.activeVersion = script.version
                }

                this.$root.$emit('notification', 'Gelukt', `Versie ${script.version} van script "${script.name}" is nu actief.`)
            },
            async removeRecord (record, confirmed) {
                if (!confirmed) {
                    this.selectedRecord = record
                    this.$root.$emit('modal', 'delete', true)
                } else if (record === this.headScript) {
                    this.selectedRecord = null
                    this.$root.$emit('modal', 'delete', false)

                    // Replace head script with previous version.
                    const replacementScript = this.sortedScripts[1]
                    replacementScript.activeVersion = this.headScript.activeVersion

                    this.scripts = this.scripts.filter(script => script.version !== replacementScript.version)
                    this.headScript = replacementScript

                    await this.$model.removeVersion(`/env/${this.$environment}/api-template/Script`, replacementScript.scriptID, replacementScript.version)
                    await this.$model.update(`/env/${this.$environment}/api-template/Script`, replacementScript.scriptID, { $set: replacementScript })
                } else {
                    this.selectedRecord = null
                    this.$root.$emit('modal', 'delete', false)
                    this.scripts = this.scripts.filter(script => script.version !== record.version)
                    await this.$model.removeVersion(`/env/${this.$environment}/api-template/Script`, record.scriptID, record.version)
                }
            }
        }
    }
</script>
