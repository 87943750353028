import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  orderID: {
    type: String,
    label: 'Qiri order ID',
    description: 'De unieke waarde waarmee de order geïdentificeerd kan worden.',
    primaryKey: true
  },
  personID: {
    type: String,
    label: 'Qiri persoon ID',
    description: 'De persoon die de order heeft geplaatst.',
    foreignKey: 'Person'
  },
  timestamp: {
    type: Date,
    label: 'Datum & tijd',
    description: 'De datum & tijd wanneer de order is geplaatst.',
    //index: 1
  },
  totalOrderValue: {
    type: SimpleSchema.Integer,
    label: 'Totale order waarde',
    description: 'De totale waarde van de order in centen.'
  }
})
