import Overview from './components/Overview'

export default {
    'api-reporting.overview': {
        path: '/api-reporting/overview',
        component: Overview,
        meta: {
            audience: 'actor:/env/*/api-reporting/APIReport',
        }
    }
}
