import get from 'lodash/get'
import {VueFormContext} from '../providers'
import {vueSet} from '../util'

export default {
  name: 'VueNestedForm',
  inject: {
    parentForm: {
      from: VueFormContext
    }
  },
  provide() {
    return {
      [VueFormContext]: this
    }
  },
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    state: {
      type: Object,
      default () {
        return {}
      }
    },
    schema: {
      type: Object,
      required: true
    },
    field: String,
    disabled: Boolean
  },
  data () {
    return {
      value: null,
      dirtyFields: [],
      unvalidatedFields: []
    }
  },
  render (h) {
    return h(
      this.tag,
      {},
      this.$slots.default
    )
  },
  mounted () {
    this.validationContext = this.schema.newContext()
    this.$set(this.state, 'valid', false)
    this.$set(this.state, 'invalid', true)
    this.$set(this.state, 'errors', [])

    // Sync value based on given field from the parent's value.
    this.syncValue(true)
    this.$watch(() => get(this.parentForm.value, this.field), () => this.syncValue(), { deep: true })

    // Add to parent form.
    if (this.disabled !== true) {
      this.parentForm.addChildForm(this)
    }
  },
  destroyed () {
    if (this.disabled !== true) {
      this.parentForm.removeChildForm(this)
    }
  },
  watch: {
    'disabled' (disabled) {
      if (disabled === true) {
        this.parentForm.removeChildForm(this)
      } else {
        this.parentForm.addChildForm(this)
      }
    }
  },
  computed: {
    defaults () {
      return this.parentForm.defaults
    }
  },
  methods: {
    syncValue (init = false) {
      this.value = get(this.parentForm.value, this.field)

      // Set defaults from the schema.
      if (init) {
        this.value = this.validationContext.clean(this.value || {})
        vueSet(this, this.parentForm.value, this.field, this.value)
      }

      this.$set(this.state, 'value', this.value)
      this.$emit('change', this.value)
    },
    addChildForm (childForm) {
      this.parentForm.addChildForm(childForm)
    },
    removeChildForm (childForm) {
      this.parentForm.removeChildForm(childForm)
    },
    markFieldDirty (field) {
      if (this.dirtyFields.indexOf(field) === -1) {
        this.dirtyFields.push(field)
      }
      if (this.unvalidatedFields.indexOf(field) === -1) {
        this.unvalidatedFields.push(field)
      }
    },
    validate (context) {
      const record = this.validationContext.clean(this.value || {})

      let keys
      if (context === 'watch') {
        // Try and figure out the keys that got changed.
        keys = this.unvalidatedFields
        this.unvalidatedFields = []
      }
      const isValid = this.validationContext.validate(record, {
        keys
      })

      this.state.valid = isValid
      this.state.invalid = !isValid
      this.state.errors = this.validationContext.validationErrors().map(error => ({
        ...error,
        message: this.validationContext.keyErrorMessage(error.name)
      }))
      return this.state
    }
  }
}
