<template>
    <span>{{ label }}</span>
</template>

<script>
    import moment from 'moment-timezone'
    import {scheduleToString} from '@qiri/models/task-scheduler/Schedule'

    export default {
        name: 'ScheduleLabel',
        components: {},
        props: {
            schedule: Object
        },
        data () {
            return {}
        },
        computed: {
            label () {
                return scheduleToString(this.schedule)
            }
        }
    }
</script>
