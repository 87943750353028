/**
 * @todo
 */
export const defaultCompare = (a, b) => {
  if (a < b) {
    return -1
  } else if (a > b) {
    return 1
  } else {
    return 0
  }
}

/**
 * @todo
 */
export const merge = (sources, options = {}) => (start, sink) => {
  if (start !== 0) {
    return
  }

  const {
    compare = defaultCompare,
    firstOnEqual
  } = options

  const n = sources.length
  const sourceTalkbacks = new Array(n)
  const queue = new Array(n)
  const values = new Array(n)
  let waitingCount = 0
  let valueCount = 0
  let startCount = 0
  let activeCount = 0
  let endCount = 0
  const flush = () => {
    let index = -1
    for (let i = 0; i < n; i++) {
      if (sourceTalkbacks[i] === undefined) {
        continue
      }
      const value = values[i]
      if (index === -1) {
        index = i
      } else {
        const diff = compare(value, values[index])
        if (diff < 0) {
          index = i
        } else if (firstOnEqual === true && diff === 0) {
          values[i] = undefined
          valueCount--
          queue[waitingCount++] = sourceTalkbacks[i]
        }
      }
    }
    if (index >= 0) {
      const value = values[index]
      values[index] = undefined
      valueCount--
      queue[waitingCount++] = sourceTalkbacks[index]
      sink(1, value)
    }
  }
  const talkback = t => {
    if (t === 1) {
      for (let i = 0; i < waitingCount; i++) {
        queue[i](1)
      }
      waitingCount = 0
    } else {
      for (let i = 0; i < n; i++) {
        if (sourceTalkbacks[i]) {
          sourceTalkbacks[i](t)
        }
      }
    }
  }
  for (let i = 0; i < n; i++) {
    sources[i](0, (t, d) => {
      if (t === 0) {
        sourceTalkbacks[i] = d
        queue[waitingCount++] = d
        activeCount++
        if (++startCount === n) {
          sink(0, talkback)
        }
      } else if (t === 1) {
        if (values[i] === undefined) {
          values[i] = d
          valueCount++
        } else {
          const o = values[i]
          values[i] = d
          sink(1, o)
        }
        if (valueCount === activeCount) {
          flush()
        }
      } else if (t === 2) {
        sourceTalkbacks[i] = undefined
        activeCount--
        if (++endCount === n || d) {
          sink(2, d)
        } else if (valueCount === activeCount) {
          flush()
        }
      } else {
        sink(t, d)
      }
    })
  }
}
