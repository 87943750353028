import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  'algorithmID': {
    type: String,
    label: 'Qiri algoritme ID',
    description: 'Het ID van het algoritme',
    foreignKey: 'Module'
  },
  'enabled': {
    type: Boolean,
    label: 'Gebruik toestaan',
    description: 'Geeft aan of het algoritme is ingeschakeld en gebruikt mag worden'
  }
})
