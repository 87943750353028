import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  name: {
    type: String,
    label: 'Naam'
  },
  iconURL: {
    type: String,
    label: 'URL icoon / afbeelding'
  }
})
