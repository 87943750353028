import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  url: {
    type: String,
    label: 'URL',
    regEx: SimpleSchema.RegEx.Url
  },
  username: {
    type: String,
    label: 'Gebruikersnaam',
    optional: true
  },
  password: {
    type: String,
    label: 'Wachtwoord',
    optional: true,
    encrypted: true,
    autoValue () {
      if (this.isSet && this.encrypt) {
        return this.encrypt(this.value)
      }
    }
  }
})
