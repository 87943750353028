/**
 * @todo
 */
export const onEnd = (fn) => (source) => (start, sink) => {
  if (start !== 0) {
    return
  }

  let ended = false
  let sourceTalkback
  const talkback = (t, d) => {
    if (t === 2) {
      if (!ended) {
        ended = true
        fn(d)
        sourceTalkback(2, d)
      }
    } else {
      sourceTalkback(t, d)
    }
  }

  source(0, (t, d) => {
    if (t === 0) {
      sourceTalkback = d
      sink(0, talkback)
    } else if (t === 2) {
      if (!ended) {
        ended = true
        fn(d)
        sink(2, d)
      }
    } else {
      sink(t, d)
    }
  })
}
