<template>
    <table class="table">
        <thead>
            <tr>
                <th>Module naam</th>
                <th>Vergoeding</th>
                <th>Eenheid</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item of unitPricing">
                <td>{{ displayUnitLabel(item) }}</td>
                <td><vue-form-field :field="`${item.fieldPrefix}.price`" :factor="item.pricing.factor" :bare="true"></vue-form-field></td>
                <td>&euro; {{ item.unit.label }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    import get from 'lodash/get'
    import SimpleSchema from '@qiri/simpl-schema'
    import {ModuleSettings} from '@qiri/models/environment'
    import {getSystemModules} from '@qiri/models/environment/Module'
    import {VueFormContext} from '@qiri/vue-form/providers'

    const SystemModules = getSystemModules()

    export default {
        name: 'partial-modules-PricingTable',
        inject: {
            form: {
              from: VueFormContext
            }
        },
        props: {
            field: String
        },
        computed: {
            unitPricing () {
                const settings = get(this.form.value, this.field)
                if (!settings) {
                    return []
                }

                let result = []
                for (const systemModule of SystemModules) {
                    let moduleSetting = settings.find(x => x.moduleID === systemModule.moduleID)
                    if (!moduleSetting && systemModule.required === true) {
                        moduleSetting = {
                            moduleID: systemModule.moduleID,
                            enabled: false,
                            pricing: []
                        }
                        settings.push(moduleSetting)
                    }
                    if (!moduleSetting) {
                        continue
                    } else if (!moduleSetting.pricing) {
                        this.$set(moduleSetting, 'pricing', [])
                    }

                    // Remove prices for units that don't exist.
                    moduleSetting.pricing = moduleSetting.pricing.filter(pricing => {
                        return systemModule.units.some(unit => unit.name === pricing.unit)
                    })

                    // "Project" the pricing for the UI based on units from the system modules.
                    for (const unit of systemModule.units) {
                        let pricing = moduleSetting.pricing.find(x => x.unit === unit.name)
                        if (pricing) {
                            // "Migrate" price if factor is different.
                            if (pricing.factor !== unit.factor) {
                                pricing.price = Math.round(pricing.price / pricing.factor * unit.factor)
                                pricing.factor = unit.factor
                            }
                        } else {
                            pricing = {
                                interval: 'monthly',
                                unit: unit.name,
                                price: 0,
                                factor: unit.factor
                            }
                            moduleSetting.pricing.push(pricing)
                        }
                        if (systemModule.required === true || moduleSetting.enabled !== false) {
                            result.push({
                                moduleID: systemModule.moduleID,
                                fieldPrefix: `${this.field}.${settings.indexOf(moduleSetting)}.pricing.${moduleSetting.pricing.indexOf(pricing)}`,
                                unit,
                                pricing
                            })
                        }
                    }
                }
                return result
            }
        },
        methods: {
            displayUnitLabel ({ moduleID, unit }) {
                const module = SystemModules.find(x => x.moduleID === moduleID)
                const moduleName = unit.overrideModuleName || module.name
                if (unit.description) {
                    return `${moduleName}: ${unit.description}`
                } else {
                    return moduleName
                }
            }
        }
    }
</script>
