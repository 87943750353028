import SimpleSchema from '@qiri/simpl-schema'
import ipaddr from 'ipaddr.js'

SimpleSchema.setDefaultMessages({
  messages: {
    nl: {
      invalidIPAddress: `{{{label}}} moet een geldig IPv4 of IPv6 adres zijn`
    }
  }
})

/**
 * @private
 */
const CIDR_REGEXP = /^(.+)\/(\d+)$/

/**
 * @todo
 */
export function parseIPAddress (address) {
  const hasCIDR = CIDR_REGEXP.test(address)
  if (hasCIDR) {
    return ipaddr.parseCIDR(address)
  } else {
    const ipAddress = ipaddr.parse(address)
    const mask = ipAddress.kind() === 'ipv4' ? 32 : 128
    return [ipAddress, mask]
  }
}

/**
 * @todo
 */
export function validateIPAddress (address) {
  try {
    parseIPAddress(address)
    return true
  } catch (err) {
    return false
  }
}

/**
 * @todo
 */
export function matchIPAddress (address, range) {
  return ipaddr.parse(address).match(parseIPAddress(range))
}

/**
 * @todo
 */
export default new SimpleSchema({
  'description': {
    type: String,
    label: 'Omschrijving',
    description: 'De omschrijving van het IP adres'
  },
  'address': {
    type: String,
    label: 'IP adres',
    description: 'Het IP adres',
    custom () {
      if (this.isSet && !validateIPAddress(this.value)) {
        return 'invalidIPAddress'
      }
    }
  }
})
