import SimpleSchema from '@qiri/simpl-schema'
import ProductModel from '../data/Product'
import OrderBy from './OrderBy'
import {renderProductName} from './util'

export const Configuration = new SimpleSchema({
  'periodInWeeks': {
    type: SimpleSchema.Integer,
    label: 'Laatste aantal weken',
    description: 'Het aantal weken waarbinnen orders beoordeeld worden (bijvoorbeeld 53 voor een heel jaar).',
    min: 10,
    defaultValue: 52
  },
  'factorThreshold': {
    type: SimpleSchema.Integer,
    label: 'Minimaal percentage kopers',
    description: 'Ondergrens met het minimale percentage personen dat een product moet hebben gekocht om het product tot ‘Bestseller’ te mogen benoemen (bijvoorbeeld 10%).',
    min: 0,
    max: 100,
    defaultValue: 10
  }
})

export const Input = {
  Order: ['personID', 'timestamp'],
  OrderLine: ['orderID', 'productID', 'quantity']
}

export const Output = ({model} = {}) => new SimpleSchema({
  'product': {
    type: model ? model('Product').type : ProductModel,
    label: 'Product',
    table: 'Producten'
  },
  'uniqueBuyers': {
    type: SimpleSchema.Integer,
    label: 'Aantal andere die dit ook kochten'
  },
  'percentageBuyers': {
    type: Number,
    label: '% anderen die dit ook kochten',
    index: true
  }
})

export const Preview = new SimpleSchema({
  'name': {
    type: String,
    label: 'Productnaam',
    render: renderProductName
  },
  'percentageBuyers': {
    type: String,
    label: '% anderen die dit ook kochten',
    render (record) {
      return `${Math.round(record.percentageBuyers * 100)}%`
    }
  }
})

export const Request = new SimpleSchema({
  'person': {
    type: String,
    label: 'Persoon',
    description: 'De persoon voor wie de suggesties opgevraagd worden. Producten die al door deze persoon zijn gekocht, worden uit de Bestseller-lijst weggelaten.',
    optional: true
  },
  'product': {
    type: String,
    label: 'Product',
    description: 'Het product op basis waarvan suggesties worden opgevraagd. Dit product wordt uit de Besteller-lijst weggelaten.',
    optional: true
  },
  'date': {
    type: Date,
    label: 'Datum',
    description: 'De datum waarvoor de voorspelling gemaakt moet worden. Indien leeg wordt ‘vandaag’ gebruikt.',
    optional: true
  },
  'includeProductPromotions': {
    type: Boolean,
    label: 'Productpromoties opzoeken',
    description: 'Indien het product in een productpromotie gevonden wordt, deze ook teruggeven in het resultaat?',
    optional: true,
    options: {
      'Ja': 'true',
      'Nee': 'false'
    }
  },
  'filter': {
    type: Array,
    label: 'Filters',
    optional: true
  },
  'filter.$': {
    type: String
  },
  'limit': {
    type: SimpleSchema.Integer,
    label: 'Limiet',
    description: 'Maximaal aantal producten uit de Bestsellers-lijst dat wordt getoond.',
    min: 1,
    defaultValue: 20
  }/*,
  'orderBy': OrderBy.arraySchema({
    defaultValue: [{ field: 'factor', order: 'desc' }]
  }),
  'orderBy.$': OrderBy.elementSchema({
    indexFields: Output
  })*/
})

export const RequestConfiguration = new SimpleSchema({
  'maxLimit': {
    type: SimpleSchema.Integer,
    label: 'Limiet',
    description: 'Maximaal aantal suggesties in een enkele API-response',
    min: 1,
    defaultValue: 50
  }
})
