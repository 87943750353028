<template>
  <section v-if="loading" class="padding">
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h1>API-log exports</h1></template>
          <template slot="content">
            <p v-if="loadingError" class="error">{{ loadingError }}</p>
            <p v-else>Bezig met laden...</p>
          </template>
        </block>
      </div>
    </div>
  </section>
  <section v-else class="padding">
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h1>API-log exports</h1></template>
          <template slot="content">
            <p>
              <router-link :to="{ name: 'api-log.exports.create', query: $route.query }" class="btn">API-log export toevoegen</router-link>
            </p>
          </template>
        </block>
      </div>
    </div>
    <div class="grid">
      <div class="column size--100">
        <block :table="true">
          <template slot="content">
            <table class="table">
              <thead>
                <tr>
                  <th>Naam</th>
                  <th>Omschrijving</th>
                  <th v-if="false">Planning</th>
                  <th v-if="false">Status</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(apiLogExport, index) in displayExports" :key="apiLogExport.exportID">
                  <td class="icon-inline">
                    <router-link :to="{ name: 'api-log.exports.update', params: { exportID: apiLogExport.exportID }, query: $route.query }" title="Export wijzigen" class="btn icon">
                      <icon name="pencil" />
                    </router-link>
                    {{ apiLogExport.name }}
                  </td>
                  <td>{{ apiLogExport.description }}</td>
                  <td v-if="false" class="icon-inline">
                    <router-link :to="{ name: 'api-log.exports.schedule', params: { exportID: apiLogExport.exportID }, query: $route.query }" title="Planning wijzigen" class="btn icon">
                      <icon name="pencil" />
                    </router-link>
                    <ScheduleLabel :schedule="getScheduleFor(apiLogExport)"></ScheduleLabel>
                  </td>
                  <td v-if="false">{{ getStatusLabel(apiLogExport) }}</td>
                  <td class="icon">
                    <template v-if="apiLogExport.state !== 'disabled' && getStatus(apiLogExport) !== 'executing' && getStatus(apiLogExport) !== 'queued'">
                      <btn v-if="false" @click.prevent="executeTask(apiLogExport)" icon="play" title="Uitvoeren" class="icon"></btn>
                      <a :href="getDownloadURL(apiLogExport)" title="Downloaden" class="btn icon">
                        <icon name="cloud-download" />
                      </a>
                    </template>
                    <btn v-if="getStatus(apiLogExport) === 'queued' || getStatus(apiLogExport) === 'executing'" @click.prevent="cancelTask(apiLogExport)" icon="stop" title="Annuleren" class="icon"></btn>
                    <router-link v-if="false" :to="{ name: 'api-log.exports.log', params: { exportID: apiLogExport.exportID }, query: $route.query }" title="Logboek" class="btn icon">
                      <icon name="eye" />
                    </router-link>
                    <btn v-if="apiLogExport.state === 'enabled'" @click.prevent="disableExport(apiLogExport)" icon="toggle-on" title="Uitschakelen" class="icon"></btn>
                    <btn v-else @click.prevent="enableExport(apiLogExport)" icon="toggle-off" title="Inschakelen" class="icon"></btn>
                    <btn icon="trash" title="Verwijderen" class="icon" @click.prevent="removeRecord(apiLogExport)"></btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </block>
      </div>
    </div>
    <modal name="delete" title="Weet je zeker dat je deze API-log export wilt verwijderen?">
      <p>Wil je de API-log export "{{ selectedRecord && selectedRecord.name }}" verwijderen?</p>
      <button type="button" @click="removeRecord(selectedRecord, true)">Ja, verwijderen</button>
      <button type="button" @click="$root.$emit('modal', 'delete', false)">Nee, annuleren</button>
    </modal>
  </section>
</template>

<script>
  import uuidv4 from 'uuid/v4'
  import get from 'lodash/get'
  import last from 'lodash/last'
  import pick from 'lodash/pick'
  import Modal from '@/components/Partials/Modal'
  import ScheduleLabel from '@/modules/task-scheduler/components/Partials/ScheduleLabel'

  const statusLabels = {
    'initial': 'Initieel',
    'created': 'Aangemaakt',
    'scheduled': 'Ingepland',
    'ignored': 'Overgeslagen',
    'queued': 'In wachtrij',
    'executing': 'Bezig met uitvoeren',
    'canceling': 'Bezig met annuleren',
    'canceled': 'Geannuleerd',
    'executed': 'Uitgevoerd'
  }

  export default {
    name: 'page-exports-Overview',
    components: {
      Modal,
      ScheduleLabel
    },
    data () {
      return {
        selectedRecord: null,
        exports: [],
        schedules: [],
        tasks: [],
        statusLabels,
        exportToken: null,
        loading: true,
        loadingError : null
      }
    },
    async mounted () {
      // Gain access to the export scope.
      this.exportToken = await this.$security.access('export')
      if (!this.exportToken) {
          this.loadingError = 'Geen toegang'
          return
      }

      this.$model.list(`/env/${this.$environment}/api-log-export/Export/list`, 'exports')
      this.$model.list(`/env/${this.$environment}/api-log-export/ExportSchedule/list`, 'schedules')
      this.$model.list(`/env/${this.$environment}/api-log-export/ExportTask/list`, 'tasks')

      this.loading = false
    },
    computed: {
      displayExports () {
        return this.exports.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
      }
    },
    methods: {
      async removeRecord (record, confirmed) {
        if (!confirmed) {
          this.selectedRecord = record
          this.$root.$emit('modal', 'delete', true)
        } else {
          this.selectedRecord = null
          this.$root.$emit('modal', 'delete', false)
          await this.$model.remove(`/env/${this.$environment}/api-log-export/Export`, record.exportID)
        }
      },
      getScheduleFor (apiLogExport) {
        return this.schedules.find(x => x.exportID === apiLogExport.exportID)
      },
      async enableExport (apiLogExport) {
        await this.$model.update(`/env/${this.$environment}/api-log-export/Export`, apiLogExport.exportID, { $set: {
          state: 'enabled'
        }})
      },
      async disableExport (apiLogExport) {
        await this.$model.update(`/env/${this.$environment}/api-log-export/Export`, apiLogExport.exportID, { $set: {
          state: 'disabled'
        }})
      },
      async executeTask (apiLogExport) {
        await this.$api.dispatch(`/env/${this.$environment}/api-log-export/ExportTask/execute`, {
          exportID: apiLogExport.exportID
        })
      },
      async cancelTask (apiLogExport) {
        const task = this.getTaskFor(apiLogExport)
        if (!task) {
          return
        }
        this.$api.dispatch(`/env/${this.$environment}/api-log-export/ExportTask/cancel`, {
          taskID: task.taskID
        })
      },
      getTaskFor (apiLogExport) {
        const schedule = this.getScheduleFor(apiLogExport)

        const activeTask = last(this.tasks.filter(x => x.exportID === apiLogExport.exportID && x.triggeredAt && !x.finishedAt && x.state !== 'canceling'))
        const finishedTask = last(this.tasks.filter(x => x.exportID === apiLogExport.exportID && x.triggeredAt && x.finishedAt))
        const scheduledTask = schedule && schedule.activeTaskID ? this.tasks.find(x => x.exportID === apiLogExport.exportID && x.state === 'scheduled') : null

        return activeTask || finishedTask || scheduledTask
      },
      getStatus (apiLogExport) {
        return get(this.getTaskFor(apiLogExport), 'state', 'initial')
      },
      getStatusLabel (apiLogExport) {
        if (apiLogExport.state === 'disabled') {
          return 'Uitgeschakeld'
        } else {
          const task = this.getTaskFor(apiLogExport)
          const status = get(task, 'state', 'initial')
          let label = statusLabels[status]
          let suffix = ''
          if (status === 'executing' && task.progress) {
            suffix = ` (${(task.progress.current / task.progress.total * 100).toFixed(2).replace('.', ',')}%)`
          } else if (status === 'executed' && task.result && !task.result.success) {
            label = 'Foutmelding'
            const error = get(task.result, 'data.error')
            if (error) {
              suffix = ` (${error.message})`
            }
          }
          return `${label}${suffix}`
        }
      },
      getDownloadURL (apiLogExport) {
        return `${this.$api.host()}/export?module=api-log-export&exportID=${apiLogExport.exportID}&token=${this.exportToken}`
      }
    }
  }
</script>
