<template>
    <vue-form tag="section" :state="form" ref="form" :schema="schema" v-model="schedule" class="padding" @submit="doSubmit()">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h2>Vanaf wanneer</h2></template>
                    <template slot="content">
                        <vue-form-field field="startDate" :time="true"></vue-form-field>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h2>Herhalings patroon</h2></template>
                    <template slot="content">
                        <div class="grid">
                            <div class="column size--30">
                                <vue-form-field type="radio" field="recur.type" group="recurType" v-model="recurType" option="None">Geen</vue-form-field>
                                <vue-form-field type="radio" field="recur.type" group="recurType" v-model="recurType" option="Minutely">Minutelijks</vue-form-field>
                                <vue-form-field type="radio" field="recur.type" group="recurType" v-model="recurType" option="Hourly">Uurlijks</vue-form-field>
                                <vue-form-field type="radio" field="recur.type" group="recurType" v-model="recurType" option="Daily">Dagelijks</vue-form-field>
                                <vue-form-field type="radio" field="recur.type" group="recurType" v-model="recurType" option="Weekly">Weekelijks</vue-form-field>
                                <vue-form-field type="radio" field="recur.type" group="recurType" v-model="recurType" option="Monthly">Maandelijks</vue-form-field>
                                <vue-form-field type="radio" field="recur.type" group="recurType" v-model="recurType" option="Yearly">Jaarlijks</vue-form-field>
                            </div>
                            <div class="column size--70" v-if="recurType === 'None'">
                                <label class="radio-button">
                                    Eenmalig uitvoeren
                                </label>
                            </div>
                            <div class="column size--70" v-if="recurType === 'Minutely'">
                                <vue-nested-form tag="label" class="radio-button" :schema="recurSchema('Minutely')" field="recur.settings">
                                    Elke <vue-form-field field="interval" :bare="true" :inline="true"></vue-form-field> minuut/minuten
                                </vue-nested-form>
                            </div>
                            <div class="column size--70" v-if="recurType === 'Hourly'">
                                <vue-nested-form tag="label" class="radio-button" :schema="recurSchema('Hourly')" field="recur.settings">
                                    Elke <vue-form-field field="interval" :bare="true" :inline="true"></vue-form-field> uur/uren
                                </vue-nested-form>
                            </div>
                            <div class="column size--70" v-if="recurType === 'Daily'">
                                <vue-form-field type="radio" group="recurSubType" field="recur.type" v-model="recurSubType" option="WeekDay">
                                    Elke werkdag
                                </vue-form-field>
                                <vue-form-field type="radio" group="recurSubType" field="recur.type" v-model="recurSubType" option="Daily">
                                    <vue-nested-form tag="span" class="radio-button" :schema="recurSchema('Daily')" field="recur.settings">
                                        Elke <vue-form-field field="interval" :bare="true" :inline="true"></vue-form-field> dag(en)
                                    </vue-nested-form>
                                </vue-form-field>
                            </div>
                            <div class="column size--70" v-if="recurType === 'Weekly'">
                                <vue-nested-form tag="label" class="radio-button" :schema="recurSchema('Weekly')" field="recur.settings">
                                    Elke <vue-form-field field="interval" :bare="true" :inline="true"></vue-form-field> week/weken
                                </vue-nested-form>
                            </div>
                            <div class="column size--70" v-if="recurType === 'Monthly'">
                                <vue-nested-form tag="label" class="radio-button" :schema="recurSchema('Monthly')" field="recur.settings">
                                    Dag
                                    <vue-form-field field="day" :bare="true" :inline="true"></vue-form-field>
                                    van elke
                                    <vue-form-field field="interval" :bare="true" :inline="true"></vue-form-field>
                                    maand(en)
                                </vue-nested-form>
                            </div>
                            <div class="column size--70" v-if="recurType === 'Yearly'">
                                <vue-form-field type="radio" group="recurSubType" field="recur.type" v-model="recurSubType" option="Yearly">
                                    <vue-nested-form tag="span" class="radio-button" :schema="recurSchema('Yearly')" :disabled="recurSubType !== 'Yearly'" field="recur.settings">
                                        Elke
                                        <vue-form-field field="day" :bare="true" :inline="true"></vue-form-field>
                                        <vue-form-field field="month" :bare="true" :inline="true" :options="monthOptions"></vue-form-field>
                                    </vue-nested-form>
                                </vue-form-field>
                                <vue-form-field type="radio" group="recurSubType" field="recur.type" v-model="recurSubType" option="YearlyOccurence">
                                    <vue-nested-form tag="span" class="radio-button" :schema="recurSchema('YearlyOccurence')" :disabled="recurSubType !== 'YearlyOccurence'" field="recur.settings">
                                        De
                                        <vue-form-field field="occurrence" :bare="true" :inline="true" :options="occurrenceOptions"></vue-form-field>
                                        <vue-form-field field="dayOfWeek" :bare="true" :inline="true" :options="dayOfWeekOptions"></vue-form-field>
                                        van
                                        <vue-form-field field="month" :bare="true" :inline="true" :options="monthOptions"></vue-form-field>
                                    </vue-nested-form>
                                </vue-form-field>
                            </div>
                        </div>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--50">
                <block>
                    <template slot="title"><h2>Tot wanneer</h2></template>
                    <template slot="content">
                        <vue-form-field type="radio" field="endType" option="none">
                            Geen einddatum
                        </vue-form-field>
                        <vue-form-field type="radio" field="endType" option="count">
                            Eindigt na <vue-form-field field="endAfter" :bare="true" :inline="true"></vue-form-field> keer
                        </vue-form-field>
                        <vue-form-field type="radio" field="endType" option="date">
                            Eindigt op <vue-form-field field="endDate" :bare="true" :inline="true" :time="true"></vue-form-field>
                        </vue-form-field>
                    </template>
                </block>
            </div>
            <div class="column size--50">
                <block>
                    <template slot="title"><h2>Uitzonderingen</h2></template>
                    <template slot="content">
                        Niet uitvoeren op:
                        <vue-form-group>
                            <vue-form-field column="25" field="exclude.monday"></vue-form-field>
                            <vue-form-field column="25" field="exclude.tuesday"></vue-form-field>
                            <vue-form-field column="25" field="exclude.wednesday"></vue-form-field>
                            <vue-form-field column="25" field="exclude.thursday"></vue-form-field>
                        </vue-form-group>
                        <vue-form-group>
                            <vue-form-field column="25" field="exclude.friday"></vue-form-field>
                            <vue-form-field column="25" field="exclude.saturday"></vue-form-field>
                            <vue-form-field column="25" field="exclude.sunday"></vue-form-field>
                        </vue-form-group>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid" v-if="false">
            <div class="column size--100">
                <block>
                    <template slot="content">
                        <p>recurType: {{ recurType }}</p>
                        <p>recurSubType: {{ recurSubType }}</p>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title" v-if="occurences.length === 1"><h2>Volgende uitvoerdatum</h2></template>
                    <template slot="title" v-else><h2>Volgende {{ occurences.length }} uitvoerdatums</h2></template>
                    <template slot="content">
                        <ol>
                            <li v-for="occurence of occurences">{{ occurence }}</li>
                        </ol>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="content">
                        <button type="button" class="full" :title="formErrors" :disabled="saving" @click.prevent="$refs.form.submit()">
                            <template v-if="saving"><icon name="refresh" :spin="true" /></template>
                            <template v-else>Planning aanpassen</template>
                        </button>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid" v-if="false">
            <div class="column size--100">
                <block>
                    <template slot="content">
                        <pre>{{ scheduleJSON }}</pre>
                        <pre>{{ formJSON }}</pre>
                    </template>
                </block>
            </div>
        </div>
    </vue-form>
</template>

<script>
    import uuidv4 from 'uuid/v4'
    import get from 'lodash/get'
    import moment from 'moment-timezone'
    import SimpleSchema from '@qiri/simpl-schema'
    import Schedule, {
        RecurPatterns,
        DaysOfWeek,
        Months,
        Occurrences,
        scheduleToString,
        getNextDate
    } from '@qiri/models/task-scheduler/Schedule'

    const RECUR_TYPES = {
        'None': null,
        'Minutely': false,
        'Hourly': false,
        'Daily': true,
        'WeekDay': 'Daily',
        'WeekEnd': 'Daily',
        'Weekly': false,
        'Monthly': false,
        'Yearly': true,
        'YearlyOccurence': 'Yearly'
    }

    export default {
        name: 'partial-scheduler-ScheduleForm',
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: Object,
            schema: Object,
            saving: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                schedule: null,
                form: {},
                recurType: null,
                recurSubType: null
            }
        },
        async mounted () {
            this.$watch('value', () => this.syncValue(), { immediate: true })
            this.$watch('schedule', () => this.onChange(), { immediate: true })
        },
        watch: {
            'recurType' (recurType) {
                const replaceType = RECUR_TYPES[recurType]
                if (replaceType === false) {
                    this.$set(this.schedule.recur, 'type', recurType)
                } else if (replaceType === true) {
                    this.recurSubType = recurType
                } else {
                    this.$set(this.schedule.recur, 'type', null)
                }
            },
            'recurSubType' (recurSubType) {
                if (recurSubType) {
                    this.$set(this.schedule.recur, 'type', recurSubType)
                }
            }
        },
        computed: {
            dayOfWeekOptions () {
                return DaysOfWeek
            },
            monthOptions () {
                return Months
            },
            occurrenceOptions () {
                return Occurrences
            },
            scheduleAsString () {
                if (!this.schedule) {
                    return ''
                }
                return scheduleToString(this.schedule)
            },
            occurences () {
                if (!this.schedule || !this.schedule.startDate) {
                    return []
                }
                let occurences = []
                let limit = this.schedule.endType === 'count' ? (this.schedule.endAfter || 1) : 5
                let nextDate
                while ((nextDate = getNextDate(this.schedule, nextDate)) !== false && occurences.length < limit) {
                    occurences.push(moment(nextDate).locale('nl').format('LLLL'))
                }
                return occurences
            },
            formErrors () {
                let text = []
                if (this.form.errors && this.form.errors.length > 0) {
                    text = [
                        'Fouten:',
                        ...this.form.errors.map(error => error.message)
                    ]
                }
                return text.join('\n')
            },
            formJSON () {
                return JSON.stringify(this.form, null, '  ')
            },
            scheduleJSON () {
                return JSON.stringify(this.schedule, null, '  ')
            }
        },
        methods: {
            syncValue () {
                this.schedule = this.value
            },
            onChange () {
                if (!this.schedule) {
                    return
                }

                // Sync recur type.
                if (this.schedule.recur && this.schedule.recur.type) {
                    const replaceType = RECUR_TYPES[this.schedule.recur.type]
                    if (typeof replaceType === 'string') {
                        this.recurType = replaceType
                        this.recurSubType = this.schedule.recur.type
                    } else {
                        this.recurType = this.schedule.recur.type
                        this.recurSubType = this.schedule.recur.type
                    }
                } else {
                    if (!this.schedule.recur) {
                        this.$set(this.schedule, 'recur', {})
                    }
                    this.recurType = 'None'
                    this.recurSubType = null
                }

                this.$emit('change', this.schedule)
            },
            recurSchema (recurType) {
                return RecurPatterns[recurType]
            },
            doSubmit () {
                this.$emit('save', this.schedule)
            }
        }
    }
</script>
