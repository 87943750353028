import SimpleSchema from '@qiri/simpl-schema'
import Filter from '../aggregate/Filter'
import Mapping from '../aggregate/Mapping'
import Schedule from '../task-scheduler/Schedule'
import * as protocols from '../protocols'
import * as formats from '../formats'

/**
 * @todo
 */
export const factory = ({ protocolType, formatType } = {}) => new SimpleSchema({
  'exportID': {
    type: String,
    primaryKey: true
  },
  'name': {
    type: String,
    label: 'Naam'
  },
  'description': {
    type: String,
    multiline: true,
    label: 'Omschrijving',
    optional: true
  },
  timezone: {
    type: String,
    label: 'Tijdzone',
    optional: true
  },
  'schedule': {
    type: Schedule,
    label: 'Planning',
    optional: true
  },
  'state': {
    type: String,
    label: 'Staat',
    defaultValue: 'enabled',
    allowedValues: [
      'enabled',
      'disabled'
    ]
  },
  'taskID': {
    type: String,
    label: 'Taak ID',
    optional: true
  },
  'lastExport': {
    type: Object,
    optional: true
  },
  'lastExport.startDate': {
    type: Date,
    optional: true
  },
  'lastExport.endDate': {
    type: Date,
    optional: true
  },
  'model': {
    type: String,
    label: 'Model'
  },
  // Protocol
  'protocol': {
    type: Object,
    optional: true
  },
  'protocol.type': {
    type: String,
    label: 'Protocol',
    allowedValues: Object.keys(protocols)
  },
  'protocol.settings': {
    type: protocolType ? protocols[protocolType] : Object,
    blackbox: !protocolType,
    label: 'Protocol instellingen'
  },
  'protocol.location': {
    type: String,
    label: 'Bestandsnaam en locatie',
    optional: true
  },
  // Compression
  'compression': {
    type: Object,
    optional: true
  },
  'compression.type': {
    type: String,
    label: 'Bestandscompressie',
    allowedValues: {
      'Geen': null,
      'Zip': 'zip',
      'Gzip': 'gzip'
    },
    optional: true
  },
  'compression.name': {
    type: String,
    label: 'Bestandsnaam binnen archief',
    optional: true
  },
  // File format
  'format': {
    type: Object
  },
  'format.type': {
    type: String,
    label: 'Bestandsformaat',
    allowedValues: Object.keys(formats)
  },
  'format.settings': {
    type: formatType ? formats[formatType] : Object,
    blackbox: !formatType,
    label: 'Bestandsformaat instellingen'
  },
  // Filters
  'filters': {
    type: Array,
    label: 'Filters',
    optional: true
  },
  'filters.$': {
    type: Filter,
    label: 'Filter'
  },
  'onlySinceLastExport': {
    type: Boolean,
    label: 'Exporteer alleen nieuwe regels die zijn toegevoegd na de laatste keer uitvoeren van deze export',
    defaultValue: true
  },
  // Mappings
  'mappings': {
    type: Array,
    label: 'Mappings',
    minCount: 1,
    defaultValue: []
  },
  'mappings.$': {
    type: Mapping,
    label: 'Mapping'
  }
})

/**
 * @todo
 */
const Export = factory()
export default Export

/**
 * @static
 */
Export.getSchemaFor = (doc) => factory({
  protocolType: doc && doc.protocol && doc.protocol.type,
  formatType: doc && doc.format && doc.format.type
})
