export default {
  name: 'VueFormField_Currency',
  props: {
    fieldID: String,
    value: [String, Number],
    inline: Boolean,
    disabled: Boolean,
    factor: {
      type: Number,
      default: 100
    }
  },
  render (h) {
    return h(
      'input',
      {
        staticClass: this.inline ? 'inline' : '',
        attrs: {
          id: this.fieldID,
          type: 'number',
          step: `${1 / this.factor * 10}`,
          value: formatNumber(this.value, this.factor),
          disabled: this.disabled
        },
        on: {
          input: (evt) => this.onChange(evt),
          blur: (evt) => this.onChange(evt),
          input: (evt) => this.onChange(evt)
        }
      }
    )
  },
  methods: {
    onChange (evt) {
      let value = evt.target.value
      if (typeof value === 'string' && value.length > 0) {
        value = parseFloat(value)
        if (isNaN(value)) {
          value = null
        }
      }
      value = Math.round(value * this.factor)
      if (!evt.inputType) {
        this.$el.value = formatNumber(value, this.factor)
      }
      this.$emit('input', value)
    }
  }
}

/**
 * @private
 */
function formatNumber (n, factor) {
  if (typeof n !== 'number') {
    n = parseFloat(n)
  }
  if (!isNaN(n)) {
    n = (n / factor).toFixed(Math.log10(factor))
  }
  return n
}
