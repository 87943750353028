<template>
    <section class="padding">
        <div class="grid">
            <div class="column size--100">
                <block :table="true">
                    <template slot="title"><h1>{{ title }}</h1></template>
                    <template slot="content">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Naam</th>
                                    <th>Rol</th>
                                    <th class="icon">
                                        <router-link
                                            :to="{
                                                name: `${routePrefix}.add`,
                                                params: { companyID: companyID },
                                                query: $route.query
                                            }"
                                            :title="getTitleForRoute({
                                                name: `${routePrefix}.add`,
                                                params: { companyID: companyID }
                                            })"
                                            class="btn icon"
                                        >
                                            <icon name="plus" />
                                        </router-link>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="user of users" :key="user.userID">
                                    <td>
                                        <router-link
                                            :to="{
                                                name: `${routePrefix}.edit`,
                                                params: { companyID: user.companyID, userID: user.userID },
                                                query: $route.query
                                            }"
                                            :title="getTitleForRoute({
                                                name: `${routePrefix}.edit`,
                                                params: { companyID: user.companyID, userID: user.userID }
                                            })"
                                            class="btn icon"
                                        >
                                            <icon name="pencil" />
                                        </router-link>
                                        {{ getDisplayName(user) }}
                                    </td>
                                    <td>{{ getRoleLabel(user.role) }}</td>
                                    <td class="icon">
                                        <btn
                                            icon="trash"
                                            title="Gebruiker verwijderen"
                                            class="icon"
                                            @click.prevent="removeRecord(user)"
                                        ></btn>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </block>
            </div>
        </div>
        <modal name="delete" title="Weet je zeker dat je deze gebruiker wilt verwijderen?">
            <p>Wil je de gebruiker "{{ getDisplayName(selectedRecord) }}" verwijderen?</p>
            <button type="button" @click="removeRecord(selectedRecord, true)">Ja, verwijderen</button>
            <button type="button" @click="$root.$emit('modal', 'delete', false)">Nee, annuleren</button>
        </modal>
    </section>
</template>

<script>
    import Modal from '@/components/Partials/Modal'
    import {getSystemRoles} from '@qiri/models/environment/Module'

    const SystemRoles = getSystemRoles()

    export default {
        name: 'page-users-Overview',
        components: {
            Modal
        },
        data () {
            return {
                allUsers: [],
                selectedRecord: null
            }
        },
        mounted () {
            this.$model.list(`${this.userActor}/list`, 'allUsers')
        },
        watch: {
            'userActor' (userActor) {
                this.$model.list(`${this.userActor}/list`, 'allUsers')
            }
        },
        computed: {
            title () {
                return this.$route.meta.title
            },
            routePrefix () {
                return this.$route.name.split('.').slice(0, -1).join('.')
            },
            companyID () {
                return this.$route.params.companyID
            },
            userActor () {
                return `${this.$route.meta.company.actorPrefix}/User`
            },
            users () {
                return this.allUsers.filter(x => x.companyID === this.companyID)
            }
        },
        methods: {
            getTitleForRoute (location) {
                const resolved = this.$router.resolve(location)
                return resolved.route.meta.title
            },
            getDisplayName (user) {
                if (user) {
                    return `${user.firstName || ''} ${user.infixName || ''} ${user.surName || ''}`
                } else {
                    return ''
                }
            },
            getRoleLabel (role) {
                return SystemRoles[role] || role
            },
            async removeRecord (record, confirmed) {
                if (!confirmed) {
                    this.selectedRecord = record
                    this.$root.$emit('modal', 'delete', true)
                } else {
                    this.selectedRecord = null
                    this.$root.$emit('modal', 'delete', false)
                    await this.$model.remove(this.userActor, record.userID)
                }
            }
        }
    }
</script>
