/**
 * @todo
 */
export const takeWhile = (fn, {emitLast = false} = {}) => (source) => (start, sink) => {
  if (start !== 0) {
    return
  }
  let ended = false
  let sourceTalkback
  const talkback = (t, d) => {
    if (!ended) {
      sourceTalkback(t, d)
    }
  }
  source(0, (t, d) => {
    if (t === 0) {
      sourceTalkback = d
      sink(0, talkback)
    } else if (t === 1) {
      const take = fn(d)
      if (take || emitLast) {
        sink(t, d)
        if (!take) {
          ended = true
          sink(2)
          sourceTalkback(2)
        }
      } else {
        ended = true
        sink(2)
        sourceTalkback(2)
      }
    } else {
      sink(t, d)
    }
  })
}
