<template>
  <section v-if="loading" class="padding">
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h1>{{ title }}</h1></template>
          <template slot="content">
            <p v-if="loading">Bezig met laden...</p>
          </template>
        </block>
      </div>
    </div>
  </section>
  <vue-form v-else tag="section" :state="form" ref="form" :schema="schema" v-model="queryImport" class="padding" @submit="doSubmit()">
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h1>{{ title }}</h1></template>
          <template slot="content">
            <vue-form-field field="name"></vue-form-field>
            <vue-form-field field="description"></vue-form-field>
          </template>
        </block>
      </div>
    </div>
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h2>Query</h2></template>
          <template slot="content">
            <p>Geef hier de query op welke de data teruggeeft die geimporteerd moet worden.</p>
            <MonacoEditor
                ref="codeEditor"
                class="editor"
                v-model="queryImport.query"
                theme="vs-dark"
                language="pgsql"
                :options="editorOptions"
                @editorDidMount="codeEditorDidMount"
            />
          </template>
        </block>
      </div>
    </div>
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="content">
            <button type="button" class="full" title="Opslaan" :disabled="saving" @click.prevent="$refs.form.submit()">
              <template v-if="saving"><icon name="refresh" :spin="true" /></template>
              <template v-else>{{ title }}</template>
            </button>
            <ul class="errors" v-if="formErrors.length > 0 || submitErrors.length > 0">
              <li v-for="(error, index) of formErrors" :key="index">
                <icon name="exclamation-circle"></icon> <vue-markdown :source="error"></vue-markdown>
              </li>
              <li v-for="(error, index) of submitErrors" :key="index">
                <icon name="exclamation-circle"></icon> <vue-markdown :source="error"></vue-markdown>
              </li>
            </ul>
          </template>
        </block>
      </div>
    </div>
  </vue-form>
</template>

<script>
  import uuidv4 from 'uuid/v4'
  import omit from 'lodash/omit'
  import moment from 'moment-timezone'
  import MonacoEditor from 'vue-monaco'
  import VueMarkdown from '@qiri/vue-markdown'
  import {QueryImport} from '@qiri/models/data'

  /**
   * @private
   */
  const TAG = Symbol('tag')

  export default {
    name: 'page-query-imports-Form',
    components: {
      VueMarkdown,
      MonacoEditor
    },
    data () {
      return {
        form: {},
        queryImport: {},
        loading: true,
        saving: false,
        submitErrors: [],
        editorOptions: {
          minimap: {
            enabled: false
          }
        }
      }
    },
    async mounted () {
      // Load the user, if any.
      if (this.formMode === 'edit') {
        // Ensure a record ID was given.
        if (!this.queryImportID) {
          throw new Error(`Missing property "queryImportID".`)
        }

        // Load the record from the backend.
        this.queryImport = await this.$model.get(`/env/${this.$environment}/data/QueryImport`, this.queryImportID)

        // Ensure that we loaded the record.
        if (!this.queryImport) {
          throw new Error(`Query-import "${this.queryImportID}" not found.`)
        }
      } else {
        // Create a new record.
        this.queryImport = this.schema.clean({
          queryImportID: uuidv4(),
          model: this.$route.query.model,
          query: 'SELECT * FROM data.<data-table>'
        })
      }

      // Finished loading.
      this.loading = false
    },
    computed: {
      schema () {
        return QueryImport
      },
      queryImportID () {
        return this.$route.params.queryImportID
      },
      title () {
        return this.$route.meta.title
      },
      formMode () {
        return this.$route.meta.form.mode
      },
      formErrors () {
        let messages = []
        if (this.form.errors && this.form.errors.length > 0) {
          for (const error of this.form.errors) {
            let message = `${error.message}.`
            // Don't show duplicate messages.
            const duplicateMessage = messages.find(x => x === message)
            if (!duplicateMessage) {
              messages.push(message)
            }
          }
        }
        return messages
      }
    },
    methods: {
      tag (obj) {
        if (obj && !obj[TAG]) {
          obj[TAG] = uuidv4()
        }
        return obj && obj[TAG]
      },
      codeEditorDidMount (editor) {
        editor.getModel().updateOptions({
          tabSize: 2
        })
      },
      async doSubmit () {
        this.saving = true
        this.submitErrors = []
        try {
          const queryImport = omit(this.schema.clean(this.queryImport), 'queryImportID')
          queryImport.timezone = moment.tz.guess()

          if (this.formMode === 'create') {
            await this.$model.create(`/env/${this.$environment}/data/QueryImport`, this.queryImport.queryImportID, queryImport)
            this.$router.replace({ name: 'data.query-imports', query: this.$route.query })
          } else {
            await this.$model.update(`/env/${this.$environment}/data/QueryImport`, this.queryImport.queryImportID, { $set: queryImport })
            this.$router.push({ name: 'data.query-imports', query: this.$route.query })
          }
        } catch (err) {
          if (err.error === 'validation-error') {
            this.submitErrors = err.details.map(error => error.message)
          } else {
            this.submitErrors = [err.message]
          }
          this.saving = false
        }
      }
    }
  }
</script>
