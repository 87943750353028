import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  remoteServerID: {
    type: String,
  },
  location: {
    type: String,
    label: 'Bestandsnaam en locatie',
    optional: true
  }
})
