<template>
    <section class="padding">
        <vue-form :state="form" ref="form" :schema="schema" v-model="credentials" class="grid" @submit="doSubmit()">
            <div class="column size--30"></div>
            <div class="column size--40">
                <block>
                    <template slot="title"><h1>Inloggen</h1></template>
                    <template slot="content">
                        <vue-form-field field="userName" v-focus></vue-form-field>
                        <vue-form-field field="password"></vue-form-field>
                        <button type="submit" :class="{'full': true, 'disabled': saving}" title="Inloggen">
                            <template v-if="saving"><icon name="refresh" :spin="true" /></template>
                            <template v-else>Inloggen</template>
                        </button>
                        <ul class="errors" v-if="submitError">
                            <li v-if="submitError">
                                <icon name="exclamation-circle"></icon> {{ submitError }}
                            </li>
                        </ul>
                    </template>
                </block>
            </div>
            <div class="column size--30"></div>
        </vue-form>
    </section>
</template>

<script>
    import {createHmac} from 'crypto'
    import SimpleSchema from '@qiri/simpl-schema'

    export default {
        name: 'page-login',
        data () {
            return {
                form: {},
                credentials: {},
                saving: false,
                submitError: null
            }
        },
        computed: {
            schema () {
                return new SimpleSchema({
                    'userName': {
                        type: String,
                        label: 'Gebruikersnaam'
                    },
                    'password': {
                        type: String,
                        label: 'Wachtwoord',
                        encrypted: true
                    },
                    'passwordHash': {
                        type: String,
                        autoValue () {
                            const passwordField = this.siblingField('password')
                            if (passwordField.isSet && passwordField.value) {
                                // TODO: Make salt configurable (or atleast not static).
                                const hash = createHmac('sha256', 'qiri')
                                hash.update(passwordField.value, 'utf8')
                                return hash.digest('hex')
                            }
                        }
                    }
                })
            }
        },
        methods: {
            async doSubmit () {
                this.saving = true
                this.submitError = null

                try {
                    const credentials = this.schema.clean(this.credentials)
                    const response = await fetch(`${this.$api.host('http')}/authenticate`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            userName: credentials.userName,
                            passwordHash: credentials.passwordHash
                        })
                    })
                    const json = await response.json()
                    if (!json.token) {
                        throw new Error(`No token received`)
                    }

                    // Store retrieve user profile.
                    this.$userProfile = json.userProfile

                    // Pass encoded token to plugin, read back decoded token.
                    this.$encodedToken = json.token
                    const token = this.$token

                    // Determine next action based on token type.
                    let routeName = 'dashboard'
                    if (token.action === 'ChangePassword') {
                        routeName = 'auth.change-password'
                    } else if (token.action === 'TermsAndConditions') {
                        routeName = 'auth.terms-and-conditions'
                    }
                    this.$router.replace({ name: routeName, query: this.$route.query })
                } catch (err) {
                    // TODO: Show custom errors for "unknown", "not-whitelisted", etc...
                    console.error(err)
                    this.submitError = 'Inloggen mislukt.'
                    this.saving = false
                }

            }
        }
    }
</script>
