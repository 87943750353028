import moment from 'moment'
import SimpleSchema from '@qiri/simpl-schema'
import ProductModel from '../data/Product'
import PersonModel from '../data/Person'
import OrderBy from './OrderBy'
import {renderProductName} from './util'

export const Configuration = new SimpleSchema({
  'maxRepeatInterval': {
    type: SimpleSchema.Integer,
    label: 'Maximum nummer productaankoop-cyclus',
    description: 'Maximum nummer van de productaankoop-cyclus voor elk product. Bij hogere nummers wordt het product niet meer opgenomen in het winkelwagentje.',
    min: 1,
    defaultValue: 3
  },
  'excludeSeasonalProducts': {
    type: Boolean,
    label: 'Seizoensproducten uitsluiten',
    description: 'Als een product is aangemerkt als een seizoensproduct met meer dan 6 typische aankoopmoment weken zal het product niet opgenomen worden in het winkelwagentje, tenzij de huidige week is aangemerkt als een typisch aankoopmoment.',
    optional: true,
    options: {
      'Ja': 'true',
      'Nee': 'false'
    }
  }
})

export const Input = {
  Product: ['pricing.sellingPrice'],
  Order: ['personID', 'timestamp'],
  OrderLine: ['orderID', 'productID', 'quantity']
}

export const Output = ({model} = {}) => new SimpleSchema({
  'person': {
    type: model ? model('Person').type : PersonModel,
    label: 'Persoon',
    table: 'Personen'
  },
  'product': {
    type: model ? model('Product').type : ProductModel,
    label: 'Product',
    table: 'Producten'
  },
  'details': {
    type: Object
  },
  'details.intervalType': {
    type: String
  },
  'details.interval': {
    type: Number,
    label: 'Tijdsinterval',
    index: true
  },
  'details.quantity': {
    type: Number,
    label: 'Persoonlijk aantal producten'
  },
  'details.lastPurchaseQuantity': {
    type: SimpleSchema.Integer
  },
  'details.lastPurchaseDate': {
    type: Date
  },
  'details.daysSinceLastPurchase': {
    type: SimpleSchema.Integer
  },
  'details.expectedRepeatInterval': {
    type: SimpleSchema.Integer
  },
  'details.expectedNextPurchaseDate': {
    type: Date,
    label: 'Verwachte eerstvolgende productaanschaf',
    index: true
  },
  'details.totalPrice': {
    type: SimpleSchema.Integer,
    label: 'Prijs totaal'
  }
})

export const Preview = new SimpleSchema({
  'name': {
    type: String,
    label: 'Productnaam',
    render: renderProductName
  },
  'quantity': {
    type: String,
    label: 'Verwacht aantal producten',
    render (record) {
      return record.details.quantity
    }
  },
  'expectedNextPurchaseDate': {
    type: String,
    label: 'Verwachte eerstvolgende productaanschaf',
    summary (records) {
      return 'Winkelwagen totaal:'
    },
    render (record) {
      return moment(record.details.expectedNextPurchaseDate).format('D-M-YYYY')
    }
  },
  'totalPrice': {
    type: String,
    label: 'Prijs totaal',
    summary (records, response) {
      return (response.summary.totalPrice / 100).toFixed(2)
    },
    render (record) {
      return (record.details.totalPrice / 100).toFixed(2)
    }
  }
})

export const Request = new SimpleSchema({
  'person': {
    type: String,
    label: 'Persoon',
    description: 'De persoon voor wie het winkelwagentje voorspeld moeten worden.'
  },
  'date': {
    type: Date,
    label: 'Datum',
    description: 'De datum waarop het winkelwagentje gevuld moet worden. Hier kan de voorspelling van het eerstvolgende aankoopmoment voor de persoon ingevuld worden. Indien leeg wordt ‘vandaag’ gebruikt.',
    optional: true
  },
  'interval': {
    type: SimpleSchema.Integer,
    label: 'Tijdsduur',
    description: 'Het aantal dagen waarvoor het winkelwagentje van de persoon gevuld moet worden. Indien leeg wordt het persoonlijke tijdsinterval tussen aankoopmomenten genomen.',
    optional: true,
    min: 0
  },
  /*'minInterval': {
    type: SimpleSchema.Integer,
    label: 'Minimale persoonlijke tijdsinterval',
    description: 'Indien ingevuld, worden producten met een kleinere persoonlijke tijdsinterval uitgesloten.',
    optional: true
  },*/
  'maxInterval': {
    type: SimpleSchema.Integer,
    label: 'Maximale persoonlijke tijdsinterval',
    description: 'Indien ingevuld, worden producten met een grotere persoonlijke tijdsinterval uitgesloten.',
    optional: true,
    min: 1
  },
  /*'maxExpectedRepeatInterval': {
    type: SimpleSchema.Integer,
    label: 'Maximale nummer huidige productaankoop-cyclus',
    description: 'Indien ingevuld, worden producten met een groter nummer huidige productaankoop-cyclus uitgesloten.',
    optional: true,
    min: 1
  },
  'minLastPurchaseDate': {
    type: Date,
    label: 'Laatste aankoopdatum niet eerder dan',
    description: 'Indien ingevuld, mag de laatste aankoopdatum niet eerder zijn dan de opgegeven datum.',
    optional: true
  },
  'minExpectedNextPurchaseDate': {
    type: Date,
    label: 'Verwachte eerstvolgende productaanschaf niet eerder dan',
    description: 'Indien ingevuld, mag de verwachte eerstvolgende productaanschaf niet eerder zijn dan de opgegeven datum.',
    optional: true
  },*/
  'includeGlobalIntervals': {
    type: Boolean,
    label: 'Eenmalig gekochte producten opnemen',
    description: 'Ook eenmalig gekochte producten opnemen indien die een \'Typisch herhalingsaankoopproduct\' zijn?',
    optional: true,
    options: {
      'Ja': 'true',
      'Nee': 'false'
    }
  },
  'includeProductPromotions': {
    type: Boolean,
    label: 'Productpromoties opzoeken',
    description: 'Indien het product in een productpromotie gevonden wordt, deze ook teruggeven in het resultaat?',
    optional: true,
    options: {
      'Ja': 'true',
      'Nee': 'false'
    }
  },
  'minTotalPrice': {
    type: SimpleSchema.Integer,
    label: 'Minimale waarde winkelwagen',
    description: 'Indien ingevuld, moet de totaalprijs (in centen) van de winkelwagen minimaal het opgegeven bedrag zijn.',
    optional: true,
    min: 0
  },
  'filter': {
    type: Array,
    label: 'Filters',
    optional: true
  },
  'filter.$': {
    type: String
  },
  'limit': {
    type: SimpleSchema.Integer,
    label: 'Limiet',
    description: 'Maximaal aantal producten dat in het winkelwagentje mag worden geplaatst. Indien leeg, toon alle suggesties.',
    min: 1,
    optional: true
  }/*,
  'orderBy': OrderBy.arraySchema({
    defaultValue: [{ field: 'details.expectedNextPurchaseDate', order: 'asc' }]
  }),
  'orderBy.$': OrderBy.elementSchema({
    indexFields: Output
  })*/
})

export const RequestConfiguration = new SimpleSchema({
  'maxLimit': {
    type: SimpleSchema.Integer,
    label: 'Limiet',
    description: 'Maximaal aantal suggesties in een enkele API-response',
    min: 1,
    defaultValue: 50
  }
})
