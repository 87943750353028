<template>
    <p>
        Tabel:
        <button
            v-for="(label, name) of models"
            :class="(counts[name] > 0 ? 'counter ' : '') + (value === name ? '' : 'white')"
            :count="counts[name]"
            @click.prevent="onSelect(name)"
        >
            {{ label }}
        </button>
    </p>
</template>

<script>
    export default {
        name: 'ModelSelect',
        props: {
            value: String,
            counts: {
                type: Object,
                default () {
                    return {}
                }
            },
            models: {
                type: Object,
                default () {
                    return {
                        'Person': 'Personen',
                        'Store': 'Winkels',
                        'Product': 'Producten',
                        'ProductCategory': 'Product categorieën',
                        'ProductPromotion': 'Productpromoties',
                        'Order': 'Orders',
                        'OrderLine': 'Orderregels'
                    }
                }
            }
        },
        data () {
            return {}
        },
        mounted () {
            const modelName = this.$route.query.model
            if (modelName) {
                this.$emit('input', modelName)
            }
        },
        methods: {
            onSelect (modelName) {
                this.$router.push({
                    query: {
                        ...this.$route.query,
                        model: modelName
                    }
                })
                this.$emit('input', modelName)
            }
        }
    }
</script>

<style scoped>
    button {
        margin-right: 10px;
    }
</style>
