import extend from 'lodash/extend'
import bytewise from 'bytewise'
import {pull} from '@qiri/stream/pull'
import {abortable} from '@qiri/stream/operators/abortable'
import {batch} from '@qiri/stream/operators/batch'
import {map} from '@qiri/stream/operators/map'
import {collect} from '@qiri/stream/sinks/collect'
import {drain} from '@qiri/stream/sinks/drain'
import {vueSet} from '../util'

export default function install (Vue, options) {
  Object.defineProperty(Vue.prototype, '$model', {
    get () {
      const component = this
      const cursors = component.$$model$cursors || (component.$$model$cursors = new Map())

      // TODO: Optimze by caching "access"/api as long as the token didn't change.
      const getRPC = () => new Promise((resolve) => {
        const rpc = component.$rpc
        if (rpc) {
          rpc.access().then(api => resolve(api))
        } else {
          const unwatch = component.$watch(() => component.$rpc, async (rpc) => {
            if (rpc) {
              unwatch()
              resolve(await rpc.access())
            }
          })
        }
      })
      return {
        async dispatch (actorPath, recordID, action, payload) {
          const rpc = await getRPC()
          const actor = await rpc.rootActor.resolve(`${actorPath}/Record/${recordID}`)
          await actor.dispatch(action, payload)
        },
        async create (actorPath, recordID, data) {
          //console.log(`Model "${actorPath}" create "${recordID}":`, data)
          return await this.dispatch(actorPath, recordID, 'create', data)
        },
        async update (actorPath, recordID, modifier) {
          //console.log(`Model "${actorPath}" update "${recordID}":`, modifier)
          return await this.dispatch(actorPath, recordID, 'update', modifier)
        },
        async upsert (actorPath, recordID, data) {
          //console.log(`Model "${actorPath}" upsert "${recordID}":`, data)
          return await this.dispatch(actorPath, recordID, 'upsert', data)
        },
        async remove (actorPath, recordID) {
          //console.log(`Model "${actorPath}" remove "${recordID}".`)
          return await this.dispatch(actorPath, recordID, 'remove', {})
        },
        async removeVersion (actorPath, recordID, version) {
          return await this.dispatch(actorPath, recordID, 'remove', {version})
        },
        async get (actorPath, recordID, payload = {}) {
          const rpc = await getRPC()
          const actor = await rpc.rootActor.resolve(`${actorPath}/Record/${recordID}`)
          return actor ? await actor.call('get', payload) : null
        },
        async fetch (listMethod, payload = {}) {
          const api = await getRPC()
          const cursor = await api.rootActor.call(listMethod, payload, {sync: false})
          return await pull(
            cursor,
            map(kv => kv.value),
            collect()
          )
        },
        stop (cursorID) {
          if (cursors.has(cursorID)) {
            cursors.get(cursorID).abort()
            cursors.delete(cursorID)
          }
        },
        list (listMethod, target, payload = {}) {
          const cursorID = `${listMethod}-${target}`
          this.stop(cursorID)

          const abortHandle = abortable()
          cursors.set(cursorID, abortHandle)

          const onRPC = async (rpc) => {
            if (!rpc) {
              return
            }
            const api = await rpc.access()

            let targetList = []
            vueSet(component, component, target, targetList)

            const cursor = await api.rootActor.call(listMethod, extend({ live: true }, payload), {sync: false})

            const cache = []
            pull(
              cursor,
              abortHandle,
              batch(),
              drain(entries => {
                for (const kv of entries) {
                  let index = cache.findIndex(x => bytewise.equal(x.key, kv.key))
                  if (index >= 0) {
                    const item = cache[index]
                    item.value = kv.value
                    if (item.value === null) {
                      cache.splice(index, 1)
                    }
                  } else if (kv.value !== null) {
                    cache.push({
                      key: kv.key,
                      value: kv.value
                    })
                  }
                }
                cache.sort((a, b) => bytewise.compare(a.key, b.key))
                targetList = cache.map(x => x.value)
                vueSet(component, component, target, targetList)
              })
            )
          }
          onRPC(component.$rpc)
          component.$watch(() => component.$rpc, onRPC)

          return cursorID
        }
      }
    }
  })
}
