<template>
    <div class="grid">
        <div class="column size--20 zero--padding">
            <btn icon="bars" @click.native="nav" title="Toggle navigatie" class="icon"></btn>
        </div>
        <div class="column size--60 zero--padding align--center">
            <a href="/" class="logo" alt="logo" title="Qiri: personalizing consumers shopping experience">
                <img src="/static/assets/images/logo-banner.jpg" height="35" alt="Qiri" />
            </a>
        </div>
        <div class="column size--20 zero--padding align--right">
            <template v-if="showNotifications">
                <btn icon="bell" @click.native="notify" title="Meldingen" :class="{'icon': true, 'counter-small': unackedCount > 0}" :count="unackedCount"></btn>
                <ul class="notifications">

                </ul>
            </template>
            <clock />
        </div>
    </div>
</template>

<script>
    import {checkAudience} from '@qiri/jwt/util'
    import Clock from '@/components/Partials/Clock'

    export default {
        name: 'header-default',
        components: {
            Clock
        },
        data () {
            return {
                unacked: []
            }
        },
        mounted () {
            let handle
            this.$watch(
                () => this.$environment,
                (environment) => {
                    if (handle) {
                        this.$model.stop(handle)
                        handle = null
                        this.unacked = []
                    }
                    if (environment) {
                        handle = this.$model.list(`/env/${environment}/data/Log/unackedCount`, 'unacked')
                    }
                },
                {immediate: true}
            )
        },
        computed: {
            showNotifications () {
                return this.$environment > 0 && checkAudience(this.$token.aud, 'actor:/env/*/data/Log/unackedCount')
            },
            unackedCount () {
                return this.unacked.reduce((acc, cur) => acc + cur, 0)
            }
        },
        methods: {
            notify () {
                this.$router.push({ name: 'data.status', query: this.$route.query })
            }
        }
    }
</script>
