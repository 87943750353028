// Pages
import RemoteServersOverview from './components/Pages/remoteservers/Overview'
import RemoteServersForm from './components/Pages/remoteservers/Form'

export default {
    'general.remoteservers.overview': {
        path: '/general/remoteservers',
        component: RemoteServersOverview,
        meta: {
            audience: 'actor:/env/*/data/RemoteServer'
        }
    },
    'general.remoteservers.add': {
        path: '/general/remoteservers/add',
        component: RemoteServersForm,
        meta: {
            audience: 'actor:/env/*/data/RemoteServer',
            title: 'Externe server toevoegen',
            form: {
                mode: 'create'
            }
        }
    },
    'general.remoteservers.update': {
        path: '/general/remoteservers/:remoteServerID/edit',
        component: RemoteServersForm,
        meta: {
            audience: 'actor:/env/*/data/RemoteServer',
            title: 'Externe server aanpassen',
            form: {
                mode: 'edit'
            }
        }
    }
}
