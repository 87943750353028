import SimpleSchema from '@qiri/simpl-schema'

/**
 * @todo
 */
export default new SimpleSchema({
  'target': {
    type: String,
    primaryKey: true
  },
  'mappings': {
    type: Array,
  },
  'mappings.$': {
    type: String
  }
})
