import SimpleSchema from '@qiri/simpl-schema'
import XRegExp from 'xregexp'

/**
 * @todo
 */
export function requiredIf (field, predicate) {
  if (typeof predicate === 'string') {
    const value = predicate
    predicate = (v) => v === value
  }
  return function () {
    const shouldBeRequired = predicate(this.siblingField(field).value)
    if (shouldBeRequired) {
      if (!this.operator) {
        if (!this.isSet || this.value === null || this.value === '') {
          return SimpleSchema.ErrorTypes.REQUIRED
        }
      } else if (this.isSet) {
        if (this.operator === '$set' && this.value === null || this.value === '') {
          return SimpleSchema.ErrorTypes.REQUIRED
        } else if (this.operator === '$unset') {
          return SimpleSchema.ErrorTypes.REQUIRED
        } else if (this.operator === '$rename') {
          return SimpleSchema.ErrorTypes.REQUIRED
        }
      }
    }
  }
}

/**
 * @private
 */
const NameRegExp = {
  correct: XRegExp.build(`^[\\p{L}\\s\\-,'"\\.\\/\\\\]+$`, {}, 'ui'),
  validate: XRegExp.build(`^(?=.*\\p{L})[\\p{L}\\s\\-,'"\\.\\/\\\\]+$`, {}, 'ui')
}

/**
 * @todo
 */
export function correctName (name) {
  const matches = NameRegExp.correct.exec(name || '')
  if (!matches) {
    return
  }

  name = matches[0].trim()
  const word_splitters = [' ', '-']
  const lowercase_exceptions = ['a', 'aan', 'de', 'den', 'der', 'het', 'af', 'al', 'am', 'auf', 'dem', 'ter', 'aus', 'ben', 'bij', 'bin', 'boven', 'da', 'dal', 'dalla', 'das', 'die', 'la', 'las', 'le', 'van', 'deca', 'degli', 'dei', 'del', 'della', 'des', 'di', 'do', 'don', 'dos', 'du', 'el', 'i', 'im', 'in', 'l', 'les', 'lo', 'los', 'of', 'onder', 'op', 'gen', 'ten', 'over', 's', 'te', 'tho', 'thoe', 'thor', 'to', 'toe', 'tot', 'uijt', 'uit', 'unter', 'ver', 'vom', 'von', 'voor', 'vor', 'zu', 'zum', 'zur', 'ad', 'vd', 't', 'm', 'd', 'v']
  const uppercase_exceptions = ['ii', 'iii', 'iv', 'vi', 'vii', 'viii', 'ix']
  const special_exceptions = {
    '^(ij)': (m, m1) => m1.toUpperCase(),
    '^([d,l])[\‘\’\'\`]([a-z])': (m, m1, m2) => `${m1.toLowerCase()}'${m2.toUpperCase()}`
  }

  for (const delimiter of word_splitters) {
    let words = name.split(delimiter)
    let new_words = []
    for (let word of words) {
      if (uppercase_exceptions.indexOf(word) !== -1) {
        word = word.toUpperCase()
      } else if (lowercase_exceptions.indexOf(word.replace(/[‘’'"`\/\.\\]/g, '')) === -1) {
        word = `${word.substr(0, 1).toUpperCase()}${word.substr(1)}`
      }

      for (const [pattern, replacement] of Object.entries(special_exceptions)) {
        const re = XRegExp(pattern, 'ui')
        word = XRegExp.replace(word, re, replacement)
      }

      if (word) {
        new_words.push(word)
      }
    }
    name = new_words.join(delimiter)
  }

  //name = `${name.substr(0, 1).toUpperCase()}${name.substr(1)}`
  return name
}

/**
 * @todo
 */
export function validateName (name) {
  return NameRegExp.validate.test(name || '')
}

/**
 * @private
 */
const PhoneRegExp = {
  correct: XRegExp.build(`^((?:\\+|00)(?:[1-9][0-9]{0,3}\\s|[1-9][0-9]))(.*)$`, {}, 'ui'),
  validate: XRegExp.build(`^(?=(?:[^#,*]*\\d){6,})(?:\\+\\d{0,4})?(?:\\s?\\(\\d+\\))?(?:[\\s-]?\\d+)+(?:[#*]\\d+|(?:,*\\d*)*)?$`, {}, 'ui')
}

/**
 * @todo
 */
export function correctPhone (phone) {
  const matches = PhoneRegExp.correct.exec(phone || '')
  if (!matches) {
    return
  }
  //[replace:  ::[trim:[replace:00:+:[placeholder:1]]] [trim:[placeholder:2]]]
  phone = `${matches[1].trim().replace('00', '+')} ${matches[2].trim()}`.replace(/\s+/g, ' ')
  return phone.trim()
}

/**
 * @todo
 */
export function validatePhone (phone) {
  return PhoneRegExp.validate.test(phone || '')
}
