import BetweenCustomer from './components/BetweenCustomer'

export default {
    'monitoring.value-development.between-customer': {
        path: '/monitoring/value-development/between-customer',
        component: BetweenCustomer,
        meta: {
            audience: 'actor:/env/*/monitoring/ValueDevelopment',
        }
    }
}
