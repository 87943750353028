import SimpleSchema from '@qiri/simpl-schema'
import ModuleSettings from './ModuleSettings'
import AlgorithmSettings from '../algorithm/AlgorithmSettings'
import DataRetention from '../data/DataRetention'

export default new SimpleSchema({
  'moduleSettings': {
    type: Array,
    label: 'Module instellingen',
    description: 'Instellingen van de modules van de omgeving',
    defaultValue: []
  },
  'moduleSettings.$': {
    type: ModuleSettings,
    label: 'Module instellingen',
    description: 'Instellingen voor een module van een omgeving'
  },
  'algorithmSettings': {
    type: Array,
    label: 'Algoritme instellingen',
    description: 'Instellingen van de algoritmes van een omgeving',
    defaultValue: []
  },
  'algorithmSettings.$': {
    type: AlgorithmSettings,
    label: 'Algoritme instellingen',
    description: 'Instellingen voor een algoritme van een omgeving'
  },
  'dataRetention': {
    type: Array,
    label: 'Data bewaartermijn instellingen',
    description: 'Instellingen voor data bewaartermijn van een omgeving',
    defaultValue: []
  },
  'dataRetention.$': {
    type: DataRetention,
    label: 'Data bewaartermijn instellingen',
    description: 'Instellingen voor data bewaartermijn van een omgeving'
  }
})
