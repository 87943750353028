export default {
  name: 'page-algorithms-RenderField',
  props: {
    field: Object,
    record: Object
  },
  render (createElement) {
    const value = this.field.render(this.record)
    let tagName = 'span'
    let attrs = {}
    let text = value
    if (value) {
      if (value.text) {
        text = value.text
      }
      if (value.href) {
        tagName = 'a'
        attrs.href = value.href
        attrs.target = '_blank'
      }
      if (value.icon) {
        text = [
          text,
          ' ',
          createElement(
            'i',
            {
              class: {
                fa: true,
                [`fa-${value.icon.name}`]: true,
                'has-productpromotion': true
              },
              attrs: {
                title: value.icon.title
              }
            }
          )
        ]
      }
    }
    return createElement(tagName, {attrs}, text)
  }
}
