import SimpleSchema from '@qiri/simpl-schema'

import APIEvent from './APIEvent'
import Export from './Export'
import ExportSchedule from './ExportSchedule'
import ExportTask from './ExportTask'

export {
  APIEvent,
  Export,
  ExportSchedule,
  ExportTask
}

export default new SimpleSchema({
  'APIEvent': {
    type: APIEvent,
    label: 'API-log logregel',
    table: 'API-log logregels',
    tags: {
      actor: 'APILog',
      model: 'APIEvent',
      type: 'record'
    }
  }
})
