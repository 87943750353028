<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th>Qiri-veld</th>
          <th>Omschrijving</th>
          <th>Toewijzen aan</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(mapping, mappingIndex) of allMappings" :key="tag(mapping)">
          <template v-if="mappingIndex === allMappings.length - 1">
            <td colspan="3">
              <vue-form-field
                field="mappings.$.pipeline.stages.$.operator"
                :bare="true"
                :options="getOperatorOptions()"
                null-label="Nieuw Qiri-veld toewijzen"
                v-model="newMapping.pipeline.stages[0].operator">
              </vue-form-field>
            </td>
          </template>
          <template v-else>
            <td>
              <ul>
                <li v-for="(stage, stageIndex) of mapping.pipeline.stages" :key="stageIndex">
                  {{ displayStage(mappingIndex, stageIndex) }}
                  <a v-if="isStageConfigurable(stage)" href="#" @click.prevent="editStageConfiguration(stage, mappingIndex, stageIndex)"><i class="fa fa-pencil"></i></a>
                </li>
                <li v-if="newStage && newStage.mappingIndex === mappingIndex">
                  <vue-form-field
                    :field="`mappings.$.pipeline.stages.$.operator`"
                    v-model="newStage.stage.operator"
                    :options="getOperatorOptions(getOperator(mappingIndex, mapping.pipeline.stages.length - 1))"
                    :bare="true">
                  </vue-form-field>
                </li>
                <li class="add" v-if="(!newStage || newStage.mappingIndex !== mappingIndex) && mapping.pipeline.stages[mapping.pipeline.stages.length - 1].operator">
                  <a href="#" @click.prevent="addStage(mappingIndex)">Bewerking toevoegen</a>
                </li>
              </ul>
            </td>
            <td>{{ getPipelineLabel(mapping.pipeline) }}</td>
            <td>
              <vue-form-field
                :field="`mappings.$.targetField`"
                v-model="mapping.targetField"
                :bare="true">
              </vue-form-field>
            </td>
            <td class="icon">
              <btn icon="trash" title="Verwijderen" class="icon" @click.prevent="removeMapping(mappingIndex)"></btn>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
    <modal name="operatorConfiguration" title="Functie configuratie">
      <div v-if="operatorModal">
        <vue-form-field
          v-for="field of schemaFields(getOperatorConfigurationSchema(operatorModal))"
          :key="field.name"
          :field="field.name"
          :fieldSchema="field.schema"
          :value="operatorModal.configuration[field.name] || ''"
          @input="$set(operatorModal.configuration, field.name, $event)"
        />

        <button type="button" @click.prevent="applyOperatorConfiguration()">Opslaan</button>
        <button type="button" @click.prevent="applyOperatorConfiguration(true)">Annuleren</button>
      </div>
    </modal>
  </div>
</template>

<script>
  import uuidv4 from 'uuid/v4'
  import omit from 'lodash/omit'
  import cloneDeep from 'lodash/cloneDeep'
  import SimpleSchema from '@qiri/simpl-schema'
  import {getFieldsFor, getForeignKeyFields, getRelatedModels} from '@qiri/models/data/util'
  import * as operators from '@qiri/models/aggregate/operators'
  import Modal from '@/components/Partials/Modal'

  import {pull} from '@qiri/stream/pull'
  import {iterate} from '@qiri/stream/sources/iterate'
  import {empty} from '@qiri/stream/sources/empty'

  /**
   * @private
   */
  const TAG = Symbol('tag')

  /**
   * @private
   */
  const OPERATOR = Symbol('operator')

  export default {
    name: 'aggregate-MappingTable',
    components: {
      Modal
    },
    props: {
      models: Object,
      source: Object,
      value: Array
    },
    data () {
      return {
        mappings: this.value,
        newMapping: {
          pipeline: {
            stages: [{
              operator: null,
              configuration: null
            }]
          }
        },
        operatorModal: null,
        newStage: null
      }
    },
    mounted () {
      // Watch for changes.
      this.$watch('mappings', () => this.$emit('input', this.mappings), {deep: true})

      // Watch for new mappings.
      this.$watch(
        'newMapping',
        (mapping) => {
          const stage = mapping.pipeline.stages[0]
          if (stage.operator) {
            const [operatorType, operatorName] = stage.operator.split(':')

            let operator
            let configuration
            if (operatorType === 'field' || operatorType === 'relation' || operatorType === 'systemField') {
              operator = operatorType
              configuration = {
                field: operatorName
              }
            } else if (operatorType === 'function') {
              if (stage.configuration) {
                operator = operatorName
                configuration = stage.configuration
              } else {
                // Determine if we need additional configuration.
                const operator = operators[operatorName](this.source, null, {model: this.getModel, store: this.getStore})
                if (operator.configurationSchema && !operator.schema) {
                  this.operatorModal = {
                    name: operatorName,
                    configuration: {}
                  }
                  this.$root.$emit('modal', 'operatorConfiguration', true)
                }
              }
            }

            if (configuration) {
              this.mappings.push({
                pipeline: {
                  stages: [{
                    operator,
                    configuration
                  }]
                }
              })

              // Do this here instead of above, "getOperatorFromStage" inside "getPipelineLabel"
              // depends on it being there.
              const addedMapping = this.mappings[this.mappings.length - 1]
              addedMapping.targetField = this.getPipelineLabel(addedMapping.pipeline)
            }

            // Reset the new mapping.
            this.newMapping = {
              pipeline: {
                stages: [{
                  operator: null,
                  configuration: null
                }]
              }
            }
          }
        },
        {deep: true}
      )

      // Watch for new stages.
      this.$watch(
        'newStage',
        (newStage) => {
          if (!newStage) {
            return
          }
          const {mappingIndex, stage} = newStage
          if (!stage.operator) {
            return
          }
          const mapping = this.mappings[mappingIndex]

          // Check if the current target field is either empty or the current pipeline label.
          // If it is, we want to update it later to reflect the changes below.
          const updateTargetField = !mapping.targetField || mapping.targetField === this.getPipelineLabel(mapping.pipeline)

          // Apply the new stage.
          const [operatorType, operatorName] = stage.operator.split(':')
          if (operatorType === 'field' || operatorType === 'relation' || operatorType === 'systemField') {
            mapping.pipeline.stages.push({
              operator: operatorType,
              configuration: {
                field: operatorName
              }
            })
          } else if (operatorType === 'function') {
            if (stage.configuration) {
              mapping.pipeline.stages.push({
                operator: operatorName,
                configuration: stage.configuration
              })
            } else {
              // Determine if we need additional configuration.
              const lastStageSource = this.getOperator(mappingIndex, mapping.pipeline.stages.length - 1)
              const operator = operators[operatorName](lastStageSource, null, {model: this.getModel, store: this.getStore})
              if (operator.configurationSchema && !operator.schema) {
                this.operatorModal = {
                  name: operatorName,
                  configuration: {},
                  configurationSchema: operator.configurationSchema,
                  stage
                }
                this.$root.$emit('modal', 'operatorConfiguration', true)

                // Return early, will rerun this watcher once the model applies the configuration.
                return
              }
            }
          }
          this.newStage = null

          // Update target field if necessary.
          if (updateTargetField) {
            this.$nextTick(() => {
              mapping.targetField = this.getPipelineLabel(mapping.pipeline)
              this.$forceUpdate()
            })
          }
        },
        {deep: true}
      )
    },
    computed: {
      allMappings () {
        return [
          ...this.mappings,
          this.newMapping
        ]
      }
    },
    methods: {
      getModel (modelName) {
        return this.models.schema(modelName)
      },
      getStore (tags) {
        return empty()
      },
      removeMapping (index) {
        this.mappings.splice(index, 1)
      },
      addStage (mappingIndex) {
        this.newStage = {
          mappingIndex,
          stage: {
            operator: null,
            configuration: null
          }
        }
      },
      getOperator (mappingIndex, stageIndex) {
        const mapping = this.mappings[mappingIndex]

        let operator = this.source
        for (let i = 0; i <= stageIndex; i++) {
          const stage = mapping.pipeline.stages[i]
          stage[OPERATOR] = operator = operators[stage.operator](operator, stage.configuration, {model: this.getModel, store: this.getStore})

          // Exit early if operator couldn't be created from stored configuration.
          if (operator === false) {
            return false
          }
        }

        return operator
      },
      isStageConfigurable (stage) {
        return stage.operator !== 'field' && stage.operator !== 'systemField' && stage.operator !== 'relation' && stage.configuration
      },
      editStageConfiguration (stage, mappingIndex, stageIndex) {
        this.operatorModal = {
          name: stage.operator,
          configuration: cloneDeep(stage.configuration),
          configurationSchema: this.getOperator(mappingIndex, stageIndex).configurationSchema,
          stage
        }
        this.$root.$emit('modal', 'operatorConfiguration', true)
      },
      getOperatorConfigurationSchema (config) {
        if (config.configurationSchema) {
          return config.configurationSchema
        } else {
          const operatorName = config.name
          const operator = operators[operatorName](this.source, null, {model: this.getModel, store: this.getStore})
          return operator.configurationSchema
        }
      },
      applyOperatorConfiguration (cancel) {
        if (cancel !== true) {
          if (this.operatorModal.stage) {
            this.operatorModal.stage.configuration = this.operatorModal.configuration
          } else {
            this.mappings.push({
              pipeline: {
                stages: [{
                  operator: this.operatorModal.name,
                  configuration: this.operatorModal.configuration
                }]
              }
            })

            // Do this here instead of above, "getOperatorFromStage" inside "getPipelineLabel"
            // depends on it being there.
            const addedMapping = this.mappings[this.mappings.length - 1]
            addedMapping.targetField = this.getPipelineLabel(addedMapping.pipeline)
          }
        }

        this.operatorModal = null
        this.$root.$emit('modal', 'operatorConfiguration', false)
      },
      getOperatorFromStage (stage) {
        if (stage[OPERATOR] !== undefined) {
          return stage[OPERATOR]
        }

        // Find the mapping with the given stage.
        for (let mappingIndex = 0; mappingIndex < this.mappings.length; mappingIndex++) {
          const mapping = this.mappings[mappingIndex]
          const stageIndex = mapping.pipeline.stages.indexOf(stage)
          if (stageIndex !== -1) {
            return this.getOperator(mappingIndex, stageIndex)
          }
        }
      },
      getOperatorOptions (parentOperator = this.source) {
        // No options if parent operator couldn't be created.
        if (parentOperator === false) {
          return []
        }

        // Scan for possible fields.
        const fieldOptions = []
        const fieldOperator = operators.field(parentOperator, null, {model: this.getModel, store: this.getStore})
        if (fieldOperator) {
          for (const [fieldLabel, fieldName] of fieldOperator.configurationSchema.schema('field').type.definitions[0].allowedValues) {
            fieldOptions.push([`field:${fieldName}`, fieldLabel])
          }
          if (fieldOptions.length > 0) {
            fieldOptions.splice(0, 0, [undefined, 'Qiri-veld:'])
          }
        }

        // Scan for possible system fields.
        const systemFieldOptions = []
        const systemFieldOperator = operators.systemField(parentOperator, null, {model: this.getModel, store: this.getStore})
        if (systemFieldOperator) {
          for (const [fieldLabel, fieldName] of systemFieldOperator.configurationSchema.schema('field').type.definitions[0].allowedValues) {
            systemFieldOptions.push([`systemField:${fieldName}`, fieldLabel])
          }
          if (systemFieldOptions.length > 0) {
            systemFieldOptions.splice(0, 0, [undefined, 'Qiri-systeemveld:'])
          }
        }

        // Scan for possible relations.
        const relationOptions = []
        const relationOperator = operators.relation(parentOperator, null, {model: this.getModel, store: this.getStore})
        if (relationOperator) {
          for (const [fieldLabel, fieldName] of relationOperator.configurationSchema.schema('field').type.definitions[0].allowedValues) {
            relationOptions.push([`relation:${fieldName}`, fieldLabel])
          }
          if (relationOptions.length > 0) {
            relationOptions.splice(0, 0, [undefined, 'Relaties:'])
          }
        }

        // Scan for other operators that work without additional configuration (keep it simple for now).
        const functions = [
          operators.distinct,
          operators.count,
          operators.sum,
          operators.fetch,
          operators.property
        ]
        const functionOptions = []
        for (const operatorFactory of functions) {
          const operator = operatorFactory(parentOperator, null, {model: this.getModel, store: this.getStore})
          if (operator) {
            functionOptions.push([`function:${operator.meta.name}`, operator.meta.label])
          }
        }
        if (functionOptions.length > 0) {
          functionOptions.splice(0, 0, [undefined, 'Functies:'])
        }

        return [
          ...fieldOptions,
          ...systemFieldOptions,
          ...relationOptions,
          ...functionOptions
        ]
      },
      displayStage (mappingIndex, stageIndex) {
        const stage = this.mappings[mappingIndex].pipeline.stages[stageIndex]
        const operator = stage[OPERATOR] !== undefined ? stage[OPERATOR] : this.getOperator(mappingIndex, stageIndex)

        // Handle operator creation error.
        if (operator === false) {
          return 'FOUT: kon functie niet aanmaken'
        }

        // TODO: Generate dynamically from operator itself.
        switch (stage.operator) {
          case 'field':
            return stage.configuration && stage.configuration.field
              ? `Qiri-veld: ${stage.configuration.field}`
              : `Qiri-veld`
          case 'systemField':
            return stage.configuration && stage.configuration.field
              ? `Qiri-systeemveld: ${stage.configuration.field}`
              : `Qiri-systeemveld`
          case 'relation':
            return stage.configuration && stage.configuration.field
              ? `Relatie: ${operator.schema.schema('output').label}`
              : `Relatie`
          case 'distinct':
            return `Functie: waarden ontdubbelen`
          case 'count':
            return `Functie: waarden tellen`
          case 'sum':
            return `Functie: waarden samenvoegen`
          case 'fetch':
            return `Functie: externe waarde ophalen`
          case 'property':
            return `Functie: JSON-waarde uitlezen`
          default:
            return stage.operator
        }
      },
      getPipelineLabel (pipeline) {
        // Translate operators to labels.
        let result = []
        for (const stage of pipeline.stages) {
          switch (stage.operator) {
            case 'field':
              if (stage.configuration && stage.configuration.field) {
                result.push(stage.configuration.field)
              }
              break
            case 'relation':
              if (stage.configuration && stage.configuration.field) {
                const operator = this.getOperatorFromStage(stage)
                if (operator) {
                  result.push(`uit ${operator.schema.schema('output').label.toLowerCase()}`)
                }
              }
              break
            case 'systemField':
              if (stage.configuration && stage.configuration.field) {
                result.push(stage.configuration.field)
              }
              break
            case 'distinct':
              result.push('unieke')
              break
            case 'count':
              result.push('aantal')
              break
            case 'sum':
              result.push('totaal')
              break
            case 'fetch':
              result.push('externe waarde')
              break
            case 'property':
              result.push('JSON-waarde')
              break
          }
        }

        // Capitalize first character.
        result = result.reverse().join(' ')
        return result[0].toUpperCase() + result.substr(1)
      },
      schemaFields (schema, schemaProperty) {
        if (!schema) {
          return []
        }
        let prefix = ''
        if (schemaProperty) {
          schema = schema.schema(schemaProperty).type.singleType
          prefix = `${schemaProperty}.`
        }
        let fields = []
        for (const [propertyName, property] of Object.entries(schema.schema())) {
          fields.push({
            name: `${prefix}${propertyName}`,
            parent: prefix ? prefix.substr(0, prefix.length - 1) : null,
            label: property.label,
            description: property.description,
            optional: property.optional,
            isComplex: property.type.singleType === Array || SimpleSchema.isSimpleSchema(property.type.singleType),
            schema: property
          })
        }
        return fields
      },
      tag (obj) {
        if (obj && !obj[TAG]) {
          obj[TAG] = uuidv4()
        }
        return obj && obj[TAG]
      }
    }
  }
</script>
