import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  currency: {
    type: String,
    label: 'Valuta',
    description: 'De valuta van de genoemde prijzen.'
  },
  purchasePrice: {
    type: SimpleSchema.Integer,
    label: 'Inkoop prijs',
    description: 'De inkoopprijs in centen.'
  },
  listPrice: {
    type: SimpleSchema.Integer,
    label: 'Adviesprijs',
    description: 'Standaard adviesprijs (‘van’-prijs) in centen.'
  },
  sellingPrice: {
    type: SimpleSchema.Integer,
    label: 'Verkoop prijs',
    description: 'De verkoopprijs (‘voor’-prijs) in centen.'
  },
  margin: {
    type: SimpleSchema.Integer,
    label: 'Marge',
    description: 'De marge in centen (verkoop prijs - inkoop prijs).'
  },
  vat: {
    type: SimpleSchema.Integer,
    label: 'BTW %',
    description: 'Het BTW percentage van toepassing op de prijs.'
  }
})
