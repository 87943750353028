export default {
  name: 'VueFormField_File',
  props: {
    fieldID: String,
    value: String,
    inline: Boolean,
    disabled: Boolean
  },
  render (h) {
    return h(
      'input',
      {
        staticClass: this.inline ? 'inline' : '',
        attrs: {
          id: this.fieldID,
          type: 'file',
          value: this.value,
          disabled: this.disabled
        },
        on: {
          change: (evt) => this.onChange(evt)
        }
      }
    )
  },
  methods: {
    onChange (evt) {
      let value = evt.target.value

      // Remove "fake path" (or any path).
      if (typeof value === 'string' && value.length > 0) {
        const parts = value.split(/[\\/]/g)
        value = parts[parts.length - 1]
      }

      this.$emit('input', value)
      this.$emit('files', evt.target.files)
    }
  }
}
