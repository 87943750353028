import Local from './Local'
import FTP from './FTP'
import HTTP from './HTTP'
import SFTP from './SFTP'

export {
  Local,
  FTP,
  HTTP,
  SFTP
}
