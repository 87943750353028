import SimpleSchema from '@qiri/simpl-schema'
import AlgorithmSettings from '../algorithm/AlgorithmSettings'
import IPAddress from './IPAddress'

export default new SimpleSchema({
  'tokenID': {
    type: String,
    label: 'API-token ID',
    description: 'De unieke waarde waarmee de API-token geïdentificeerd kan worden',
    primaryKey: true
  },
  'userID': {
    type: String,
    label: 'User ID',
    description: 'Indien de API-token bedoeld is voor gebruik door een gebruiker, de ID van deze gebruiker',
    optional: true,
    foreignKey: 'User'
  },
  'token': {
    type: String,
    label: 'API-token',
    description: 'De token die gebruikt moet worden in API-requests',
    optional: true
  },
  'description': {
    type: String,
    label: 'Omschrijving gebruik en gebruiker(s)',
    description: 'De omschrijving over het gebruik van de API-token en wie de gebruikers zijn van de API-token',
    multiline: true
  },
  'expiryDate': {
    type: Date,
    label: 'Verloopdatum',
    description: 'Timestamp, of oneindig geldig',
    optional: true
  },
  'expired': {
    type: Boolean,
    label: 'Verlopen',
    description: 'Geeft aan of de token is verlopen',
    optional: true
  },
  'revokedAt': {
    type: Date,
    label: 'Ingetrokken op',
    description: 'Datum wanneer token is ingetrokken.',
    optional: true
  },
  'algorithmSettings': {
    type: Array,
    label: 'Toegang tot algoritmes',
    description: 'De algoritmes/API\'s waar de API-token toegang tot heeft',
    defaultValue: []
  },
  'algorithmSettings.$': {
    type: AlgorithmSettings,
    label: 'Toegang tot algoritme',
    description: 'De algoritme/API waar de API-token toegang tot heeft'
  },
  'whitelist': {
    type: Array,
    label: 'IP adressen',
    description: 'IP adressen welke gebruik mogen maken van de API-token',
    minCount: 1,
    defaultValue: []
  },
  'whitelist.$': {
    type: IPAddress,
    label: 'IP adres',
    description: 'IP adres welke gebruik mag maken van de API-token'
  }
})
