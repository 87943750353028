<template>
    <section v-if="loading" class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>{{ title }}</h1></template>
                    <template slot="content">
                        <p v-if="loading">Bezig met laden...</p>
                    </template>
                </block>
            </div>
        </div>
    </section>
    <vue-form v-else tag="section" :state="form" ref="form" :schema="schema" v-model="snippet" class="padding" @submit="doSubmit()">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>{{ title }}</h1></template>
                    <template slot="content">
                        <vue-form-field field="name"></vue-form-field>
                        <vue-form-field field="description" type="quill"></vue-form-field>
                        <vue-form-field field="apiTokenID" :options="apiTokenOptions"></vue-form-field>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h2>API-scripts</h2></template>
                    <template slot="content">
                        <div class="block--sub--content">
                            Hier kun je de API-scripts selecteren waarvan je de output wilt tonen op je website.
                            Je kunt kiezen welke velden (die je in je website gebruikt) je mee wilt sturen als parameters naar de API-scripts.
                            Als laatste kun je opgeven in welke containers (in je website) je de output van de API-scripts wilt tonen.
                        </div>
                        <div
                            v-for="(container, containerIndex) of snippet.containers"
                            :key="tag(container)"
                            class="block--sub--content"
                        >
                            <btn
                                icon="trash"
                                type="button"
                                title="API-script selectie verwijderen"
                                class="icon sticky-add-container"
                                @click.prevent="removeContainer(containerIndex)"
                            />
                            <vue-form-group>
                                <vue-form-field :column="75" :field="`containers.${containerIndex}.scriptID`" :options="scriptOptions"></vue-form-field>
                                <vue-form-field :column="25" :field="`containers.${containerIndex}.scriptVersion`" :options="getScriptVersions(container.scriptID)"></vue-form-field>
                            </vue-form-group>
                            <button
                                v-if="container.context.length === 0 && container.scriptID && getParameterOptions(container.scriptID).length > 1"
                                type="button"
                                class="button-margin"
                                title="API-script parameter toevoegen"
                                @click.prevent="addContext(containerIndex)"
                            >
                                API-script parameter toevoegen
                            </button>
                            <template v-if="container.context.length > 0">
                                <label>API-script parameters:</label>
                                <table class="table form-padding">
                                    <tbody>
                                        <tr v-for="(context, contextIndex) of container.context" :key="tag(context)">
                                            <td style="width: 25%;">
                                                <vue-form-field
                                                    :field="`containers.${containerIndex}.context.${contextIndex}.source`"
                                                    :bare="true"
                                                    null-label="Selecteer het webshop-onderdeel waarin de waarde staat"
                                                />
                                            </td>
                                            <td style="width: 50%;">
                                                <vue-form-field
                                                    :field="`containers.${containerIndex}.context.${contextIndex}.selector`"
                                                    :bare="true"
                                                />
                                            </td>
                                            <td style="width: 25%;">
                                                <vue-form-field
                                                    :field="`containers.${containerIndex}.context.${contextIndex}.parameter`"
                                                    :bare="true"
                                                    :options="getParameterOptions(container.scriptID)"
                                                />
                                            </td>
                                            <td class="whitespace--nowrap">
                                                <vue-form-field :field="`containers.${containerIndex}.context.${contextIndex}.persistent`" />
                                            </td>
                                            <td class="icon">
                                                <btn icon="trash" title="Verwijderen" class="icon" @click.prevent="removeContext(containerIndex, contextIndex)"></btn>
                                            </td>
                                        </tr>
                                        <tr v-if="container.context.length > 0">
                                            <td colspan="5" class="icon align--top whitespace--nowrap">
                                                <btn
                                                    icon="plus"
                                                    type="button"
                                                    title="API-script parameter toevoegen"
                                                    class='icon'
                                                    @click.prevent="addContext(containerIndex)"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </template>
                            <vue-form-field :field="`containers.${containerIndex}.selector`"></vue-form-field>
                        </div>
                        <div class="block--sub--content">
                            <button type="button" title="API-script selecteren" @click.prevent="addContainer()">API-script selecteren</button>
                        </div>
                    </template>
                </block>
            </div>
        </div>

        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h2>Toegestane domeinen</h2></template>
                    <template slot="content">
                        <div class="block--sub--content">
                            Hier kun je de domeinen opgeven waarop de API-script snippet geplaatst mag worden, bijvoorbeeld jullie webshop-domein.
                            Je stelt hier dus een whitelist in. Als je geen domeinen opgeeft, mag de API-script snippet vanaf elk domein gebruikt worden.
                        </div>
                        <div class="block--sub--content">
                            <table class="table form-padding">
                                <tbody>
                                    <tr v-for="(domain, index) of domains" :key="tag(domain)">
                                        <td>
                                            <vue-form-field v-if="index < domains.length - 1" :field="`domains.${index}.name`" :bare="true"></vue-form-field>
                                            <vue-form-field v-else :field="`domains.$.name`" :bare="true" v-model="newDomain.name"></vue-form-field>
                                        </td>
                                        <td class="icon">
                                            <btn v-if="index < domains.length - 1" icon="trash" title="Verwijderen" class="icon" @click.prevent="removeDomain(index)"></btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                </block>
            </div>
        </div>

        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="content">
                        <button type="button" class="full" title="Opslaan" :disabled="saving" @click.prevent="$refs.form.submit()">
                            <template v-if="saving"><icon name="refresh" :spin="true" /></template>
                            <template v-else>{{ submitText }}</template>
                        </button>
                        <ul class="errors" v-if="formErrors.length > 0 || submitErrors.length > 0">
                            <li v-for="(error, index) of formErrors" :key="index">
                                <icon name="exclamation-circle"></icon> {{ error }}
                            </li>
                            <li v-for="(error, index) of submitErrors" :key="index">
                                <icon name="exclamation-circle"></icon> <vue-markdown :source="error"></vue-markdown>
                            </li>
                        </ul>
                    </template>
                </block>
            </div>
        </div>
    </vue-form>
</template>

<script>
    import uuidv4 from 'uuid/v4'
    import omit from 'lodash/omit'
    import cloneDeep from 'lodash/cloneDeep'
    import SimpleSchema from '@qiri/simpl-schema'
    import {Snippet} from '@qiri/models/api-script-snippets'

    const TAG = Symbol('tag')

    export default {
        name: 'page-script-snippets-Form',
        components: {},
        data () {
            return {
                form: {},
                scripts: [],
                scriptVersions: [],
                snippet: null,
                newDomain: {
                    name: ''
                },
                loading: true,
                saving: false,
                submitErrors: []
            }
        },
        async mounted () {
            if (this.formMode === 'edit') {
                if (!this.snippetID) {
                    throw new Error(`Missing property "snippetID".`)
                }

                this.snippet = await this.$model.get(`/env/${this.$environment}/api-script-snippets/Snippet`, this.snippetID)
                if (!this.snippet) {
                    throw new Error(`Script "${this.snippetID}" not found.`)
                }

                // Normalize snippet.
                this.snippet.domains = this.snippet.domains || []
            } else {
                this.snippet = this.schema.clean({
                    snippetID: uuidv4(),
                    name: '',
                    description: '',
                    containers: [],
                    domains: []
                })
            }

            // Load scripts.
            this.scripts = await this.$model.fetch(`/env/${this.$environment}/api-template/Script/list`)
            this.scriptVersions = await this.$model.fetch(`/env/${this.$environment}/api-template/Script/listVersions`, {
                recordIDs: this.scripts.map(x => x.scriptID)
            })
            this.apiTokens = await this.$model.fetch(`/env/${this.$environment}/api/APIToken/listValid`)

            this.loading = false

            // Watch new domain record.
            this.$watch(
                'newDomain',
                async (domain) => {
                    if (domain.name !== '') {
                        this.snippet.domains.push(domain)
                        this.newDomain = {
                            name: ''
                        }
                    }
                },
                {deep: true}
            )
        },
        computed: {
            schema () {
                return Snippet
            },
            snippetID () {
                return this.$route.params.snippetID
            },
            title () {
                return this.$route.meta.title
            },
            submitText () {
                if (this.$route.meta.submitText) {
                    return this.$route.meta.submitText
                } else {
                    return this.title
                }
            },
            formMode () {
                return this.$route.meta.form.mode
            },
            formErrors () {
                let messages = []
                if (this.form.errors && this.form.errors.length > 0) {
                    for (const error of this.form.errors) {
                        let message = `${error.message}.`
                        // Don't show duplicate messages.
                        const duplicateMessage = messages.find(x => x === message)
                        if (!duplicateMessage) {
                            messages.push(message)
                        }
                    }
                }
                return messages
            },
            scriptOptions () {
                return this.scripts
                    .filter(script => script.htmlEnabled)
                    .map(script => [script.scriptID, script.name])
                    .sort((a, b) => a[1] < b[1] ? -1 : a[1] === b[1] ? 0 : 1)
            },
            apiTokenOptions () {
                return this.apiTokens.map(token => [token.tokenID, token.description])
            },
            domains () {
                return [
                    ...this.snippet.domains,
                    this.newDomain
                ]
            }
        },
        methods: {
            addContainer () {
                this.snippet.containers.push({
                    selector: '',
                    context: [],
                    scriptID: null,
                    scriptVersion: 'active'
                })
            },
            removeContainer (containerIndex) {
                this.snippet.containers.splice(containerIndex, 1)
            },
            addContext (containerIndex) {
                this.snippet.containers[containerIndex].context.push({
                    selector: '',
                    parameter: '',
                    persistent: false
                })
            },
            removeContext (containerIndex, contextIndex) {
                this.snippet.containers[containerIndex].context.splice(contextIndex, 1)
            },
            removeDomain (index) {
                this.snippet.domains.splice(index, 1)
            },
            getScriptVersions (scriptID) {
                const versions =  this.scriptVersions
                    .filter(x => x.id === scriptID)
                    .map(x => [x.version, `Versie ${x.version}`])

                return [
                    ['active', 'Actief'],
                    ['latest', 'Laatste'],
                    ...versions
                ]
            },
            getParameterOptions (scriptID) {
                const script = this.scripts.find(x => x.scriptID === scriptID)
                if (!script) {
                    return []
                }
                const parameters = (script.parameters || [])
                    .map(parameter => [parameter.name, parameter.name])
                    .sort((a, b) => a[1] < b[1] ? -1 : a[1] === b[1] ? 0 : 1)

                return [
                    [null, 'Selecteer de parameter in het API-script waar naartoe de waarde overgenomen moet worden'],
                    ...parameters
                ]
            },
            async doSubmit () {
                this.saving = true
                this.submitErrors = []
                try {
                    const snippet = omit(this.schema.clean(this.snippet), 'snippetID')

                    if (this.formMode === 'create') {
                        await this.$model.create(`/env/${this.$environment}/api-script-snippets/Snippet`, this.snippet.snippetID, snippet)
                        this.$router.replace({
                            name: 'api-script-snippets.snippets.overview',
                            params: {},
                            query: this.$route.query
                        })
                    } else {
                        await this.$model.update(`/env/${this.$environment}/api-script-snippets/Snippet`, this.snippet.snippetID, { $set: snippet })
                        this.$router.replace({
                            name: 'api-script-snippets.snippets.overview',
                            params: {},
                            query: this.$route.query
                        })
                    }
                } catch (err) {
                    if (err.error === 'validation-error') {
                        this.submitErrors = err.details.map(error => error.message)
                    } else {
                        this.submitErrors = [err.message]
                    }
                } finally {
                    this.saving = false
                }
            },
            tag (obj) {
                if (obj && !obj[TAG]) {
                    obj[TAG] = uuidv4()
                }
                return obj && obj[TAG]
            }
        }
    }
</script>

<style lang="scss" scoped>
.block--sub--content {
    border-top: 1px solid #c1c1c1;
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px;
    position: relative;

    &:first-child {
        border-top: 0;
        padding-top: 0;
    }
    &:last-child {
        padding-bottom: 0;
    }

    .sticky-add-container {
        position: absolute;
        top: 5px;
        right: 20px;
    }
    &:first-child .sticky-add-container {
        top: -15px;
    }

    button.button-margin {
        margin-bottom: 10px;
    }
}
</style>
