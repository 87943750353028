<template>
    <section class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>Foutmeldingen</h1></template>
                    <template slot="content">
                        <ModelSelect v-model="modelFilter" :counts="modelCounts"></ModelSelect>
                        <ButtonSelect label="Type" v-model="typeFilter" :options="typeOptions"></ButtonSelect>
                        <p v-if="false">
                            <router-link :to="{ name: 'data.status.config', query: $route.query }">
                                Configuratie voor foutmeldingen
                            </router-link>
                        </p>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block :table="true">
                    <template slot="content">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Datum &amp; tijd</th>
                                    <th>Type</th>
                                    <th>Bericht</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="message in displayMessages" :key="message.messageID">
                                    <td class="whitespace--nowrap">{{ message.date }}</td>
                                    <td class="whitespace--nowrap">{{ message.type }} </td>
                                    <td>
                                        <component :is="message.component" :message="message"></component>
                                    </td>
                                    <td class="icon">
                                        <btn v-if="!message.acknowledged" icon="check" title="Bevestigen" class="icon" @click.prevent="ackMessage(message)"></btn>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </block>
            </div>
        </div>
    </section>
</template>

<script>
    import uuidv4 from 'uuid/v4'
    import omit from 'lodash/omit'
    import moment from 'moment'
    import models from '@qiri/models/data'
    import ModelSelect from '../../Partials/ModelSelect'
    import ButtonSelect from '@/components/Partials/ButtonSelect'
    import * as components from './types'

    const TYPE_LABELS = {
        'validation-error': 'Validatie fout',
        'import-error': 'Import fout',
        'export-error': 'Export fout',
        'missing-data': 'Missende gegevens',
        'import-count-warning': 'Geen of te weinig geïmporteerde regels'
    }
    const TYPE_COMPONENTS = {
        'validation-error': components.ValidationError,
        'import-error': components.ImportError,
        'export-error': components.ExportError,
        'missing-data': components.MissingData,
        'import-count-warning': components.ImportCountWarning,
        'data.query-import:import-error': components.QueryImportError,
    }

    export default {
        name: 'page-status-Overview',
        components: {
            ModelSelect,
            ButtonSelect
        },
        data () {
            return {
                modelFilter: null,
                typeFilter: Object.keys(TYPE_LABELS),
                messages: []
            }
        },
        mounted () {
            this.$model.list(`/env/${this.environmentID}/data/Log/list`, 'messages', {
                priority: 50
            })
        },
        computed: {
            environmentID () {
                return this.$environment || this.$route.query.environment
            },
            modelCounts () {
                let counts = {}
                this.messages
                    .filter(message => message.data && message.data.model && !message.acknowledged)
                    .forEach(message => {
                        let count = counts[message.data.model] || 0
                        counts[message.data.model] = ++count
                    })
                return counts
            },
            displayMessages () {
                const modelFilter = this.modelFilter
                const typeFilter = this.typeFilter
                return this.messages
                    .filter(message => message.data && modelFilter === message.data.model)
                    .filter(message => typeFilter.indexOf(message.type) >= 0)
                    .sort((a, b) => moment(b.timestamp).diff(a.timestamp))
                    .map(message => ({
                        messageID: message.sequence,
                        acknowledged: message.acknowledged,
                        date: moment(message.timestamp).format('DD-MM-YYYY HH:mm:ss'),
                        type: TYPE_LABELS[message.type],
                        content: message.data,
                        component: TYPE_COMPONENTS[`${message.module}:${message.type}`] || TYPE_COMPONENTS[message.type]
                    }))
            },
            typeOptions () {
                return TYPE_LABELS
            }
        },
        methods: {
            async ackMessage ({messageID}) {
                await this.$model.update(`/env/${this.$environment}/data/Log`, messageID, {$set: {
                    acknowledged: true
                }})
            }
        }
    }
</script>
