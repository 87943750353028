<template>
  <section v-if="loading" class="padding">
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h1>{{ title }}</h1></template>
          <template slot="content">
            <p v-if="loading">Bezig met laden...</p>
          </template>
        </block>
      </div>
    </div>
  </section>
  <vue-form v-else tag="section" :state="form" ref="form" :schema="schema" v-model="dataExport" class="padding" @submit="doSubmit()">
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h1>{{ title }}</h1></template>
          <template slot="content">
            <vue-form-field field="name"></vue-form-field>
            <vue-form-field field="description"></vue-form-field>
          </template>
        </block>
      </div>
    </div>
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h2>Voorwaarden</h2></template>
          <template slot="content">
            <table class="table form-padding">
              <tbody>
                <tr v-for="(filter, index) of filters" :key="tag(filter)">
                  <td :colspan="getColspanForFilter(filter)">
                    <vue-form-field v-if="index < filters.length - 1" :field="`filters.${index}.select`" :bare="true"></vue-form-field>
                    <vue-form-field v-else :field="`filters.$.select`" :bare="true" null-label="Nieuwe voorwaarde toevoegen" v-model="newFilter.select"></vue-form-field>
                  </td>
                  <template v-if="filter.select === 'field'">
                    <td><vue-form-field :field="`filters.${index}.name`" :bare="true" :options="getFieldsForModelAsOptions(dataExport.model)"></vue-form-field></td>
                    <td><vue-form-field :field="`filters.${index}.operator`" :bare="true" :options="getOperatorsForFilter(filter)"></vue-form-field></td>
                    <td><vue-form-field :field="`filters.${index}.value`" :bare="true"></vue-form-field></td>
                  </template>
                  <template v-else-if="filter.select === 'insert-date'">
                    <td><vue-form-field :field="`filters.${index}.operator`" :bare="true" :options="getOperatorsForFilter(filter)"></vue-form-field></td>
                    <td><vue-form-field :field="`filters.${index}.value`" :bare="true"></vue-form-field></td>
                  </template>
                  <td class="icon">
                    <btn v-if="index < filters.length - 1" icon="trash" title="Verwijderen" class="icon" @click.prevent="removeFilter(index)"></btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </block>
      </div>
    </div>
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h2>Opties</h2></template>
          <template slot="content">
            <vue-form-field field="onlySinceLastExport">{{ onlySinceLastExportLabel }}</vue-form-field>
          </template>
        </block>
      </div>
    </div>
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h2>Bestandsformaat van het export bestand</h2></template>
          <template slot="content">
            <vue-form-field field="format.type" :options="formatOptions"></vue-form-field>
            <component :is="formatForm"></component>
          </template>
        </block>
      </div>
    </div>
    <div class="grid" v-if="!dataExport.format || !dataExport.format.type">
      <div class="column size--100">
        <block>
          <template slot="title"><h2>Toewijzen van velden aan export bestand</h2></template>
          <template slot="content">
            <p>Kies eerst het bestandsformaat van het export bestand.</p>
          </template>
        </block>
      </div>
    </div>
    <div class="grid" v-else>
      <div class="column size--100">
        <block :table="true">
          <template slot="title"><h2>Toewijzen van velden aan export bestand</h2></template>
          <template slot="content">
            <MappingTable v-model="dataExport.mappings" :models="models" :source="tableSource"></MappingTable>
          </template>
        </block>
      </div>
    </div>
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h2>Optioneel: export bestand automatisch uploaden</h2></template>
          <template slot="content">
            <div class="grid">
              <div class="column size--50">
                <vue-form-field v-model="protocolType" field="protocol.type" :options="protocolOptions"></vue-form-field>
                <component :is="protocolForm"></component>
              </div>
              <div class="column size--50">
                <vue-form-field field="compression.type"></vue-form-field>
                <vue-form-field v-if="dataExport.compression && dataExport.compression.type === 'zip'" field="compression.name"></vue-form-field>
              </div>
            </div>
          </template>
        </block>
      </div>
    </div>
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="content">
            <button type="button" class="full" title="Opslaan" :disabled="saving" @click.prevent="$refs.form.submit()">
              <template v-if="saving"><icon name="refresh" :spin="true" /></template>
              <template v-else>{{ title }}</template>
            </button>
            <ul class="errors" v-if="formErrors.length > 0 || submitErrors.length > 0">
              <li v-for="(error, index) of formErrors" :key="index">
                <icon name="exclamation-circle"></icon> <vue-markdown :source="error"></vue-markdown>
              </li>
              <li v-for="(error, index) of submitErrors" :key="index">
                <icon name="exclamation-circle"></icon> <vue-markdown :source="error"></vue-markdown>
              </li>
            </ul>
          </template>
        </block>
      </div>
    </div>
  </vue-form>
</template>

<script>
  import uuidv4 from 'uuid/v4'
  import omit from 'lodash/omit'
  import moment from 'moment-timezone'
  import VueMarkdown from '@qiri/vue-markdown'
  import SimpleSchema from '@qiri/simpl-schema'
  import models, {MODELS, Export} from '@qiri/models/data'
  import {getFieldsFor, fieldTypeToRealType} from '@qiri/models/data/util'
  import TableSource from '@qiri/models/aggregate/sources/table'

  import MappingTable from '@/modules/aggregate/components/MappingTable'

  import {pull} from '@qiri/stream/pull'
  import {iterate} from '@qiri/stream/sources/iterate'
  import {empty} from '@qiri/stream/sources/empty'

  import * as protocols from '../feeds/protocols'
  import * as formats from './formats'

  /**
   * @private
   */
  const TAG = Symbol('tag')

  /**
   * @private
   */
  const OPERATOR = Symbol('operator')

  export default {
    name: 'page-data-exports-Form',
    components: {
      VueMarkdown,
      MappingTable
    },
    data () {
      return {
        form: {},
        dataExport: {},
        remoteServers: [],
        newFilter: {
          select: null
        },
        customFields: [],
        loading: true,
        saving: false,
        submitErrors: []
      }
    },
    async mounted () {
      // List remote servers for protocol configuration.
      this.$model.list(`/env/${this.$environment}/data/RemoteServer/list`, 'remoteServers')

      // Load the user, if any.
      if (this.formMode === 'edit') {
        // Ensure a record ID was given.
        if (!this.exportID) {
          throw new Error(`Missing property "exportID".`)
        }

        // Load the record from the backend.
        this.dataExport = await this.$model.get(`/env/${this.$environment}/data-export/Export`, this.exportID)

        // Ensure that we loaded the record.
        if (!this.dataExport) {
          throw new Error(`Export "${this.exportID}" not found.`)
        }

        // Normalize existing record.
        this.$set(this.dataExport, 'filters', this.dataExport.filters || [])
        this.$set(this.dataExport, 'mappings', this.dataExport.mappings || [])
      } else {
        // Create a new record.
        this.dataExport = this.schema.clean({
          exportID: uuidv4(),
          model: this.$route.query.model,
          filters: [],
          mappings: []
        })
      }

      // Watch for new filters.
      this.$watch(
        'newFilter',
        (filter) => {
          if (filter.select) {
            this.dataExport.filters.push(filter)
            this.newFilter = {
              select: null
            }
          }
        },
        {deep: true}
      )

      // Load the custom fields.
      this.customFields = await this.$model.fetch(`/env/${this.$environment}/data/CustomField/list`)

      // Finished loading.
      this.loading = false
    },
    computed: {
      schema () {
        return Export
      },
      models () {
        let results = {}

        // Define data models.
        for (const modelName of MODELS) {
          const modelSchema = models.schema(modelName)
          const customFields = this.customFields.filter(x => x.model === modelName)

          const customSchema = {}
          for (const customField of customFields) {
            customSchema[customField.name] = {
              type: fieldTypeToRealType(customField.type),
              label: customField.description,
              optional: true
            }
          }

          const mergedSchema = new SimpleSchema(modelSchema.type.singleType)
          mergedSchema.extend(customSchema)

          results[modelName] = {
            type: mergedSchema,
            label: modelSchema.label,
            table: modelSchema.table,
            store: modelSchema.store
          }
        }

        return new SimpleSchema(results)
      },
      exportID () {
        return this.$route.params.exportID
      },
      title () {
        return this.$route.meta.title
      },
      filters () {
        return [
          ...this.dataExport.filters,
          this.newFilter
        ]
      },
      tableSource () {
        // Get the table source used by the condition and mapping UI stages.
        return TableSource(
          {},
          {
            table: this.dataExport.model
          },
          {model: this.getModel, store: this.getStore}
        )
      },
      onlySinceLastExportLabel () {
        let label = 'Exporteer alleen nieuwe regels die aan Qiri zijn toegevoegd na de laatste keer uitvoeren van deze export'
        if (this.dataExport.lastExport && this.dataExport.lastExport.startDate) {
          label += ` op ${moment(this.dataExport.lastExport.startDate).format('YYYY-MM-DD HH:mm:ss')}`
        }
        return label
      },
      formatOptions () {
        // TODO: Get from metadata schema.
        return {
          'CSV': 'CSV'
        }
      },
      formatForm () {
        const formatType = this.dataExport && this.dataExport.format && this.dataExport.format.type
        if (!formatType) {
          return null
        }
        return formats[formatType]
      },
      protocolType: {
        get () {
          if (this.dataExport.protocol) {
            return this.dataExport.protocol.type === 'RemoteServer'
              ? `RemoteServer:${this.dataExport.protocol.settings.remoteServerID}`
              : this.dataExport.protocol.type
          } else {
            return null
          }
        },
        set (protocolType) {
          if (protocolType) {
            const parts = protocolType.split(':')
            this.$set(this.dataExport, 'protocol', this.dataExport.protocol || {})
            this.$set(this.dataExport.protocol, 'type', parts[0])
            this.$set(this.dataExport.protocol, 'settings', this.dataExport.protocol.settings || {})
            this.$set(this.dataExport.protocol.settings, 'remoteServerID', parts.length === 1 ? null : parts[1])
          } else {
            this.$set(this.dataExport, 'protocol', null)
          }
        }
      },
      protocolOptions () {
        // TODO: Get from metadata schema.
        let options = [
          [null, 'Niet van toepassing'],
          ['HTTP', 'HTTP(S)'],
          ['FTP', 'FTP(S)'],
          ['SFTP', 'SFTP']
        ]
        if (process.env.NODE_ENV !== 'production') {
          options.push(['Local', 'Lokaal (development)'])
        }

        if (this.remoteServers.length > 0) {
          const remoteServers = this.remoteServers.sort((a, b) => a.name < b.name ? -1 : a.name === b.name ? 0 : 1)
          options.push([undefined, 'Overnemen van:'])
          for (const remoteServer of remoteServers) {
            options.push([
              `RemoteServer:${remoteServer.remoteServerID}`,
              remoteServer.name
            ])
          }
        }

        return options
      },
      protocolForm () {
        const protocolType = this.dataExport && this.dataExport.protocol && this.dataExport.protocol.type
        if (!protocolType) {
          return null
        }
        return protocols[protocolType]
      },
      formMode () {
        return this.$route.meta.form.mode
      },
      formErrors () {
        let messages = []
        if (this.form.errors && this.form.errors.length > 0) {
          for (const error of this.form.errors) {
            let message = `${error.message}.`
            // Don't show duplicate messages.
            const duplicateMessage = messages.find(x => x === message)
            if (!duplicateMessage) {
              messages.push(message)
            }
          }
        }
        return messages
      }
    },
    methods: {
      tag (obj) {
        if (obj && !obj[TAG]) {
          obj[TAG] = uuidv4()
        }
        return obj && obj[TAG]
      },
      getFieldsForModelAsOptions (modelName) {
        return Object.entries(this.models.schema(modelName).type.singleType.mergedSchema())
          .filter(([propertyName, property]) =>
            propertyName.indexOf('$') === -1 &&
            property.type.singleType !== Array
          )
          .sort((a, b) => a[0] < b[0] ? -1 : a[0] === b[0] ? 0 : 1)
          .map(([propertyName, property]) => [
            propertyName,
            property.label ? `${propertyName} (${property.label})` : propertyName
          ])
      },
      getOperatorsForFilter (filter) {
        if (filter.select === 'insert-date') {
          return [
            ['bigger-than', 'Is groter dan'],
            ['bigger-than-or-equal', 'Is groter dan of gelijk aan'],
            ['smaller-than', 'Is kleiner dan'],
            ['smaller-than-or-equal', 'Is kleiner dan of gelijk aan']
          ]
        } else {
          return [
            ['equal', 'Is gelijk aan'],
            ['not-equal', 'Is ongelijk aan'],
            ['bigger-than', 'Is groter dan'],
            ['bigger-than-or-equal', 'Is groter dan of gelijk aan'],
            ['smaller-than', 'Is kleiner dan'],
            ['smaller-than-or-equal', 'Is kleiner dan of gelijk aan'],
            ['in', 'Komt voor in (komma-gescheiden) lijst'],
            ['not-in', 'Komt niet voor in (komma-gescheiden) lijst']
          ]
        }
      },
      getModel (modelName) {
        return this.models.schema(modelName)
      },
      getStore () {
        return empty()
      },
      getColspanForFilter (filter) {
        const columns = 5
        switch (filter.select) {
          case null:
          case '':
            return columns

          case 'field':
            return 1

          case 'insert-date':
            return 2
        }
      },
      removeFilter (index) {
        this.dataExport.filters.splice(index, 1)
      },
      removeMapping (index) {
        this.dataExport.mappings.splice(index, 1)
      },
      async doSubmit () {
        this.saving = true
        this.submitErrors = []
        try {
          const dataExport = omit(this.schema.clean(this.dataExport), 'exportID')
          dataExport.timezone = moment.tz.guess()

          if (this.formMode === 'create') {
            await this.$model.create(`/env/${this.$environment}/data-export/Export`, this.dataExport.exportID, dataExport)
            this.$router.replace({ name: 'data.exports', query: this.$route.query })
          } else {
            await this.$model.update(`/env/${this.$environment}/data-export/Export`, this.dataExport.exportID, { $set: dataExport })
            this.$router.push({ name: 'data.exports', query: this.$route.query })
          }
        } catch (err) {
          if (err.error === 'validation-error') {
            this.submitErrors = err.details.map(error => error.message)
          } else {
            this.submitErrors = [err.message]
          }
          this.saving = false
        }
      }
    }
  }
</script>
