import Stream from '../Stream'
import {iterate} from './iterate'

/**
 * @todo
 */
export const infinite = (generate = Math.random) => (start, sink) => {
  if (start !== 0) {
    return
  }

  let ended = false
  let inLoop = false
  let got1 = false

  const loop = () => {
    inLoop = true
    while (got1 && !ended) {
      got1 = false
      const v = generate()
      if (v === undefined) {
        ended = true
        sink(2)
      } else if (v && typeof v.then === 'function' && !(Stream.source in v)) {
        v.then(
          d => {
            if (d === undefined) {
              ended = true
              sink(2)
            } else {
              sink(1, d)
            }
          },
          err => {
            ended = true
            sink(2, err)
          }
        )
      } else {
        sink(1, v)
      }
    }
    inLoop = false
  }

  sink(0, (t, d) => {
    if (ended) {
      return
    }
    if (t === 1) {
      got1 = true
      if (!inLoop && !ended) {
        loop()
      }
    } else if (t === 2) {
      ended = true
    }
  })
}
