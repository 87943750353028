<template>
    <table class="table">
        <thead>
            <tr>
                <th>Module naam</th>
                <th class="align--center">Gebruik toestaan</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="moduleSetting of moduleSettings" :key="moduleSetting.moduleID">
                <td>{{ getModuleName(moduleSetting.moduleID) }}</td>
                <td class="align--center">
                    <vue-form-field :field="`${field}.${getIndex(moduleSetting)}.enabled`" :bare="true" :disabled="role === 'superadministrator'"></vue-form-field>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    import get from 'lodash/get'
    import SimpleSchema from '@qiri/simpl-schema'
    import {ModuleSettings} from '@qiri/models/environment'
    import {getSystemModules} from '@qiri/models/environment/Module'
    import {VueFormContext} from '@qiri/vue-form/providers'

    export default {
        name: 'partial-modules-AccessTable',
        inject: {
            form: {
              from: VueFormContext
            }
        },
        props: {
            field: String,
            role: {
                type: String,
                default: 'user'
            },
            modules: {
                type: Array,
                default () {
                    return getSystemModules().map(x => x.moduleID)
                }
            }
        },
        computed: {
            modulesByRole () {
                return getSystemModules()
                    .filter(x => x.roles.indexOf(this.role) !== -1)
                    .filter(x => this.modules.indexOf(x.moduleID) !== -1)
            },
            moduleSettings () {
                const settings = get(this.form.value, this.field)
                if (!settings) {
                    return []
                }
                
                let result = []
                for (const moduleID of this.modulesByRole.map(x => x.moduleID)) {
                    let moduleSetting = settings.find(x => x.moduleID === moduleID)
                    if (!moduleSetting) {
                        moduleSetting = {
                            moduleID,
                            enabled: false
                        }
                        settings.push(moduleSetting)
                    }
                    result.push(moduleSetting)
                }
                return result
            }
        },
        methods: {
            getModuleName (moduleID) {
                const module = getSystemModules().find(x => x.moduleID === moduleID)
                return module ? module.name : ''
            },
            getIndex (moduleSetting) {
                const settings = get(this.form.value, this.field)
                return settings.indexOf(moduleSetting)
            }
        }
    }
</script>
