export default {
  title: 'Browser (JavaScript)',
  language: 'javascript',
  supports: () => true,
  code: ({url, html, token = '{TOKEN}'}) => `var options = {
  method: 'GET',
  headers: {
    'Authorization': 'Bearer ${token}'
  },
  mode: 'cors',
  cache: 'no-store'
};
fetch('${url}', options)
  .then(function (response) {
    return response.${html ? 'text' : 'json'}();
  })
  .then(function (result) {
    // Toon het resultaat in de console.
    console.log(result);
  });`
}
