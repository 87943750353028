/**
 * @todo
 */
export function checkAudience (audience, patterns) {
  if (!audience) {
    return false
  }
  audience = Array.isArray(audience) ? audience : [audience]
  patterns = Array.isArray(patterns) ? patterns : [patterns]
  for (const pattern of patterns) {
    const [scope, path] = pattern.split(':')
    let current = '$'
    for (const name of path.substr(1).split('/').reverse()) {
      if (name === '*') {
        current = `\\/(\\*\\*$|[^\/]+${current})`
      } else {
        current = `\\/(\\*\\*$|(\\*|${name})${current})`
      }
    }
    const regExp = new RegExp(`^${scope}:${current}`, 'i')
    if (!audience.some(aud => regExp.test(aud))) {
      return false
    }
  }
  return true
}
