<template>
    <section class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>404 - Pagina niet gevonden</h1></template>
                    <template slot="content">
                        <p>De opgevraagde pagina kon niet worden gevonden.</p>
                    </template>
                </block>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'page-404',
        computed: {
            routeJSON () {
                return JSON.stringify(
                    {
                        path: this.$route.path,
                        params: this.$route.params,
                        query: this.$route.query,
                        hash: this.$route.hash,
                        fullPath: this.$route.fullPath
                    },
                    null,
                    '  '
                )
            }
        }
    }
</script>
