<template>
    <div class="grid">
        <div class="column size--20 zero--padding"></div>
        <div class="column size--60 zero--padding align--center">
            <a href="/" class="logo" alt="logo" title="Qiri: personalizing consumers shopping experience">
                <img src="/static/assets/images/logo-banner.jpg" height="35" alt="Qiri" />
            </a>
        </div>
        <div class="column size--20 zero--padding align--right"></div>
    </div>
</template>

<script>
    export default {
        name: 'header-simple'
    }
</script>
