import get from 'lodash/get'
import SimpleSchema from '@qiri/simpl-schema'

import Stream, {pull} from '@qiri/stream'
import {iterate} from '@qiri/stream/sources/iterate'
import {map} from '@qiri/stream/operators/map'

/**
 * @todo
 */
 export const meta = {
  name: 'property',
  label: 'JSON waarde uitlezen'
}

/**
 * @todo
 */
export default function property ({schema}, configuration, {}) {
  const single = schema.schema('output').type.singleType !== Array

  const elementSchema = schema.schema(single ? 'output' : 'output.$')
  if (elementSchema.type.singleType !== Object) {
    return false
  }

  // Create the configuration's schema.
  const configurationSchema = new SimpleSchema({
    propertyName: {
      type: String,
      label: 'JSON property',
      description: 'Naam van de JSON property.'
    }
  })

  // Just provide the configuration's schema if no configuration was actually provided.
  if (!configuration) {
    return {
      meta,
      configurationSchema
    }
  }

  const {propertyName} = configuration
  const transform = map(record => get(record, propertyName))

  let sink
  if (single) {
    sink = source => pull(
      source,
      transform
    )
  } else {
    sink = source => pull(
      source,
      map(list => pull(
        iterate(list),
        transform
      ))
    )
  }

  // Provide new schema based on the property. For now we assume its type is always a string.
  if (single) {
    return {
      meta,
      configurationSchema,
      schema: new SimpleSchema({
        'output': {
          type: String,
          name: propertyName,
          label: propertyName
        }
      }),
      get [Stream.sink] () {
        return sink
      }
    }
  } else {
    return {
      meta,
      configurationSchema,
      schema: new SimpleSchema({
        'output': {
          type: Array,
          name: propertyName,
          label: propertyName
        },
        'output.$': {
          type: String
        }
      }),
      get [Stream.sink] () {
        return sink
      }
    }
  }
}
