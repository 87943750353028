<template>
    <section class="padding">
        <div class="grid">
            <div class="column size--30"></div>
            <div class="column size--40">
                <block>
                    <template slot="title"><h1>Uitloggen</h1></template>
                    <template slot="content">
                        Je wordt nu uitgelogd...
                    </template>
                </block>
            </div>
            <div class="column size--30"></div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'page-logout',
        mounted () {
            this.$security.clear()
            this.$router.replace({ name: 'auth.login' })
        }
    }
</script>
