import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  unavailable: {
    type: Boolean,
    label: 'Niet meer beschikbaar',
    description: 'Geeft aan of het product niet meer beschikbaar is.',
    optional: true
  },
  availableInPhysicalStore: {
    type: Boolean,
    label: 'Beschikbaar in winkels',
    description: 'Geeft aan of het product beschikbaar is in een fysieke winkel.',
    optional: true
  },
  availableInPhysicalStoreIDs: {
    type: Array,
    label: 'Beschikbaar in winkel IDs',
    description: 'De IDs van de fysieke winkels waar het product beschikbaar is.',
    optional: true
  },
  'availableInPhysicalStoreIDs.$': {
    type: SimpleSchema.Integer,
    label: 'Winkel ID',
    description: 'De ID van de fysieke winkels waar het product beschikbaar is.'
  },
  availableInWebshop: {
    type: Boolean,
    label: 'Beschikbaar in webshop',
    description: 'Geeft aan of het product beschikbaar is in de webshop.',
    optional: true
  }
})
