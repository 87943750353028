import SimpleSchema from '@qiri/simpl-schema'
import XRegExp from 'xregexp'
import Address from './Address'
import IPAddress from './IPAddress'
import User from './User'
import {
  correctPhone,
  validatePhone
} from './util'

SimpleSchema.setDefaultMessages({
  messages: {
    nl: {
      invalidPhone: `{{{label}}} is geen geldig telefoonnummer`,
      invalidCCRegistrationNumber: 'Geen geldig KvK registratienummer',
      invalidVATNumber: `Geen geldig BTW nummer`
    }
  }
})

/**
 * @private
 */
const VATNumberRegExpPerCountry = {
  'Austria': '(AT)U[0-9]{8}',
  'Belgium': '(BE)0[0-9]{9}',
  'Bulgaria': '(BG)[0-9]{9,10}',
  'Cyprus': '(CY)[0-9]{8}L',
  'Czech Republic': '(CZ)[0-9]{8,10}',
  'Germany': '(DE)[0-9]{9}',
  'Denmark': '(DK)[0-9]{8}',
  'Estonia': '(EE)[0-9]{9}',
  'Greece': '(EL|GR)[0-9]{9}',
  'Spain': '(ES)[0-9A-Z][0-9]{7}[0-9A-Z]',
  'Finland': '(FI)[0-9]{8}',
  'France': '(FR)[0-9A-Z]{2}[0-9]{9}',
  'United Kingdom': '(GB)([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})',
  'Hungary': '(HU)[0-9]{8}',
  'Ireland': '(IE)[0-9]S[0-9]{5}L',
  'Italy': '(IT)[0-9]{11}',
  'Lithuania': '(LT)([0-9]{9}|[0-9]{12})',
  'Luxembourg': '(LU)[0-9]{8}',
  'Latvia': '(LV)[0-9]{11}',
  'Malta': '(MT)[0-9]{8}',
  'Netherlands': '(NL)[0-9]{9}B[0-9]{2}',
  'Poland': '(PL)[0-9]{10}',
  'Portugal': '(PT)[0-9]{9}',
  'Romania': '(RO)[0-9]{2,10}',
  'Sweden': '(SE)[0-9]{12}',
  'Slovenia': '(SI)[0-9]{8}',
  'Slovakia': '(SK)[0-9]{10}'
}
const VATNumberExp = {
  validate: XRegExp.build(`^(${Object.values(VATNumberRegExpPerCountry).join('|')})$`)
}

export default new SimpleSchema({
  'companyID': {
    type: String,
    label: 'Bedrijf ID',
    description: 'De unieke waarde waarmee het bedrijf geïdentificeerd kan worden',
    primaryKey: true
  },
  'name': {
    type: String,
    label: 'Bedrijfsnaam',
    description: 'De naam van het bedrijf'
  },
  'type': {
    type: String,
    label: 'Bedrijfstype',
    description: 'Het type van het bedrijf',
    defaultValue: 'customer',
    allowedValues: [
      'owner',
      'customer'
    ]
  },
  'ccRegistrationNumber': {
    type: String,
    label: 'KvK registratienummer',
    description: 'Het nummer van de registratie van het bedrijf bij de KvK',
    autoValue () {
      if (this.isSet && this.value) {
        return this.value.replace(/[\s\.]/g, '')
      }
    },
    custom () {
      if (this.isSet && this.value && !/^\d+$/.test(this.value)) {
        return 'invalidCCRegistrationNumber'
      }
    }
  },
  'vatNumber': {
    type: String,
    label: 'BTW nummer',
    description: 'Het BTW nummer van het bedrijf',
    autoValue () {
      if (this.isSet && this.value) {
        return this.value.replace(/[\s\.]/g, '').toUpperCase()
      }
    },
    custom () {
      if (this.isSet && this.value && !VATNumberExp.validate.test(this.value)) {
        return 'invalidVATNumber'
      }
    }
  },
  'visitAddress': {
    type: Address,
    label: 'Vestigingsadres',
    description: 'Het bezoek- of vestigingsadres van het bedrijf'
  },
  'postAddress': {
    type: Address,
    label: 'Postadres',
    description: 'Het postadres van het bedrijf',
    optional: true
  },
  'generalPhone': {
    type: String,
    label: 'Algemeen telefoonnummer',
    description: 'Het algemene telefoonnummer van het bedrijf',
    autoValue () {
      if (this.isSet && this.value) {
        return correctPhone(this.value)
      }
    },
    custom () {
      if (this.isSet && this.value && !validatePhone(this.value)) {
        return 'invalidPhone'
      }
    }
  },
  'websiteURL': {
    type: String,
    label: 'Website URL',
    description: 'De URL van de website van het bedrijf',
    regEx: SimpleSchema.RegEx.WebsiteUrl
  },
  'whitelist': {
    type: Array,
    label: 'Vestiging IP adressen',
    description: 'IP adressen van de vestigingen met toegang tot Qiri (de whitelist)',
    defaultValue: []
  },
  'whitelist.$': {
    type: IPAddress,
    label: 'Vestiging IP adres',
    description: 'IP adress van een vestiging met toegang tot Qiri'
  }
})
