import extend from 'lodash/extend'
import SimpleSchema from '@qiri/simpl-schema'
import ProductModel from '../data/Product'
import ProductPromotionModel from '../data/ProductPromotion'
import PersonModel from '../data/Person'
import OrderBy from './OrderBy'

export const Configuration = new SimpleSchema({

})

export const Input = {
  Order: ['personID', 'timestamp'],
  OrderLine: ['orderID', 'productID', 'quantity'],
  ProductPromotion: ['products.$']
}

export const Output = ({model} = {}) => new SimpleSchema({
  'person': {
    type: model ? model('Person').type : PersonModel,
    label: 'Persoon',
    table: 'Personen'
  }
})

export const Preview = ({customFields}) => new SimpleSchema(extend(
  customFields
    .filter(customField => customField.model === 'Person' && customField.type === 'foreignKey')
    .reduce(
      (acc, cur) => {
        acc[cur.name] = {
          type: String,
          label: cur.name,
          render (record) {
            return record.person[cur.name]
          }
        }
        return acc
      },
      {}
    ),
  {

  }
))

export const Request = new SimpleSchema({
  'productPromotion': {
    type: String,
    label: 'Productpromotie',
    description: 'De productpromotie waarvan relevante personen getoond moeten worden.'
  },
  'minOrderDate': {
    type: Date,
    label: 'Laatste aankoopmoment niet eerder dan',
    description: 'Indien ingevuld, mag het laatste aankoopmoment niet eerder zijn dan de opgegeven datum.',
    optional: true
  },
  'maxOrderDate': {
    type: Date,
    label: 'Laatste aankoopmoment niet later dan',
    description: 'Indien ingevuld, mag het laatste aankoopmoment niet later zijn dan de opgegeven datum.',
    optional: true
  },
  'useCategory': {
    type: Boolean,
    label: 'Gebruik product categorieën',
    description: 'Zoek personen op basis van product categorieën i.p.v. exacte producten.',
    optional: true,
    options: {
      'Ja': 'true',
      'Nee': 'false'
    }
  },
  'filter': {
    type: Array,
    label: 'Filters',
    optional: true
  },
  'filter.$': {
    type: String
  },
  'limit': {
    type: SimpleSchema.Integer,
    label: 'Limiet',
    description: 'Maximaal aantal personen dat wordt getoond.',
    min: 1,
    optional: true
  }/*,
  'orderBy': OrderBy.arraySchema({
    defaultValue: [{ field: 'factor', order: 'desc' }]
  }),
  'orderBy.$': OrderBy.elementSchema({
    indexFields: Output
  })*/
})

export const RequestConfiguration = new SimpleSchema({
  'maxLimit': {
    type: SimpleSchema.Integer,
    label: 'Limiet',
    description: 'Maximaal aantal suggesties in een enkele API-response',
    min: 1,
    defaultValue: 50
  }
})
