<template>
    <table class="table form-padding">
        <tbody>
            <tr v-for="(record, index) of orderBy" :key="tag(record)">
                <td>
                    <vue-form-field v-if="record === newRecord" :field="`${field}.$.field`" :bare="true" v-model="newRecord.field" :options="getFieldOptions()"></vue-form-field>
                    <vue-form-field v-else :field="`${field}.${index}.field`" :bare="true" :options="getFieldOptions(record.field)"></vue-form-field>
                </td>
                <td>
                    <vue-form-field v-if="record === newRecord" :field="`${field}.$.order`" :bare="true" v-model="newRecord.order"></vue-form-field>
                    <vue-form-field v-else :field="`${field}.${index}.order`" :bare="true"></vue-form-field>
                </td>
                <td class="icon">
                    <btn v-if="record !== newRecord" icon="trash" title="Verwijderen" class="icon" @click.prevent="remove(index)"></btn>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    import get from 'lodash/get'
    import uuidv4 from 'uuid/v4'
    import SimpleSchema from '@qiri/simpl-schema'
    import {VueFormContext} from '@qiri/vue-form/providers'

    const TAG = Symbol('tag')

    export default {
        name: 'partial-OrderByTable',
        inject: {
            form: {
              from: VueFormContext
            }
        },
        props: {
            field: String
        },
        data () {
            return {
                fieldOptions: [],
                newRecord: {
                    field: null,
                    order: 'asc'
                }
            }
        },
        mounted () {
            this.fieldOptions = optionsFromField(this.form.schema.schema(`${this.field}.$.field`))
            this.$watch(
                'newRecord',
                async (record) => {
                    if (record.field && record.order) {
                        let orderBy = get(this.form.value, this.field)
                        if (orderBy) {
                            orderBy.push(this.newRecord)
                            this.newRecord = {
                                field: null,
                                order: 'asc'
                            }
                        }
                    }
                },
                { deep: true }
            )
        },
        computed: {
            orderBy () {
                let results = [].concat(get(this.form.value, this.field, []))
                if (results.length < this.fieldOptions.length) {
                    results.push(this.newRecord)
                }
                return results
            }
        },
        methods: {
            tag (obj) {
                if (obj && !obj[TAG]) {
                    obj[TAG] = uuidv4()
                }
                return obj && obj[TAG]
            },
            getFieldOptions (include) {
                const exclude = this.orderBy.filter(x => x.field).map(x => x.field)
                return this.fieldOptions.filter(([value, label]) => value === include || exclude.indexOf(value) === -1)
            },
            remove (index) {
                let orderBy = get(this.form.value, this.field)
                if (orderBy) {
                    orderBy.splice(index, 1)
                }
            }
        }
    }

    /**
     * @private
     */
    function optionsFromField (field) {
        const allowedValues = field.type.definitions[0].allowedValues
        let options
        if (Array.isArray(allowedValues)) {
            options = allowedValues.map(value => [value, value])
        } else {
            options = Object.entries(allowedValues).map(([label, value]) => [value, label])
        }
        return options
    }
</script>
