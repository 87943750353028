<template>
    <section v-if="loading" class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>{{ title }}</h1></template>
                    <template slot="content">
                        <p v-if="loading">Bezig met laden...</p>
                    </template>
                </block>
            </div>
        </div>
    </section>
    <vue-form v-else tag="section" :state="form" ref="form" :schema="schema" v-model="token" class="padding" @submit="doSubmit()">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>{{ title }}</h1></template>
                    <template slot="content">
                        <vue-form-field field="description"></vue-form-field>
                        <vue-form-field v-if="formMode === 'create'" field="expiryDate"></vue-form-field>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block :table="true">
                    <template slot="title"><h2>API's die de aangesproken mogen worden met de API-token</h2></template>
                    <template slot="content">
                        <AlgorithmAccessTable field="algorithmSettings" :algorithms="availableAlgorithms"></AlgorithmAccessTable>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h2>Geautoriseerde IP-adres(-sen) die de API's mogen aanspreken</h2></template>
                    <template slot="content">
                        <WhitelistTable field="whitelist"></WhitelistTable>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="content">
                        <button type="button" class="full" title="Opslaan" :disabled="saving" @click.prevent="$refs.form.submit()">
                            <template v-if="saving"><icon name="refresh" :spin="true" /></template>
                            <template v-else>{{ title }}</template>
                        </button>
                        <ul class="errors" v-if="formErrors.length > 0 || submitErrors.length > 0">
                            <li v-for="error of formErrors">
                                <icon name="exclamation-circle"></icon> {{ error }}
                            </li>
                            <li v-for="error of submitErrors">
                                <icon name="exclamation-circle"></icon> <vue-markdown :source="error"></vue-markdown>
                            </li>
                        </ul>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid" v-if="false">
            <div class="column size--100">
                <block>
                    <template slot="content">
                        <pre>{{ debugJSON }}</pre>
                    </template>
                </block>
            </div>
        </div>
    </vue-form>
</template>

<script>
    import uuidv4 from 'uuid/v4'
    import omit from 'lodash/omit'
    import SimpleSchema from '@qiri/simpl-schema'
    import {APIToken} from '@qiri/models/environment'
    import {getAlgorithms} from '@qiri/models/algorithm/Algorithm'
    import AlgorithmAccessTable from '@/components/Partials/AlgorithmAccessTable'
    import WhitelistTable from '@/components/Partials/WhitelistTable'

    export default {
        name: 'page-api-TokenForm',
        components: {
            AlgorithmAccessTable,
            WhitelistTable
        },
        data () {
            return {
                form: {},
                availableAlgorithms: [],
                token: {},
                loading: true,
                saving: false,
                submitErrors: []
            }
        },
        async mounted () {
            const settings = await this.$api.call(`/env/${this.$environment}/Settings/get`)
            if (!settings) {
                throw new Error(`Unable to retrieve settings.`)
            }
            this.availableAlgorithms = settings.algorithmSettings
                .filter(x => x.enabled)
                .map(x => x.algorithmID)

            if (this.formMode === 'edit') {
                if (!this.tokenID) {
                    throw new Error(`Missing property "tokenID".`)
                }

                this.token = await this.$model.get(`/env/${this.$environment}/api/APIToken`, this.tokenID)

                if (!this.token) {
                    throw new Error(`API-token "${this.tokenID}" not found.`)
                }
            } else {
                this.token = this.schema.clean({
                    tokenID: uuidv4(),
                    algorithmSettings: this.availableAlgorithms.map(algorithmID => ({
                        algorithmID,
                        enabled: true
                    }))
                })
            }

            this.loading = false
        },
        computed: {
            schema () {
                return APIToken
            },
            tokenID () {
                return this.$route.params.tokenID
            },
            title () {
                return this.$route.meta.title
            },
            formMode () {
                return this.$route.meta.form.mode
            },
            formErrors () {
                let messages = []
                if (this.form.errors && this.form.errors.length > 0) {
                    for (const error of this.form.errors) {
                        let message = `${error.message}.`
                        // Don't show duplicate messages.
                        const duplicateMessage = messages.find(x => x === message)
                        if (!duplicateMessage) {
                            messages.push(message)
                        }
                    }
                }
                return messages
            },
            debugJSON () {
                return JSON.stringify(this.token, null, '  ')
            }
        },
        methods: {
            async doSubmit () {
                this.saving = true
                this.submitErrors = []
                try {
                    const token = omit(this.schema.clean(this.token), 'tokenID')
                    if (this.formMode === 'create') {
                        await this.$model.create(`/env/${this.$environment}/api/APIToken`, this.token.tokenID, token)
                        this.$router.replace({ name: this.formAction, query: this.$route.query })
                    } else {
                        await this.$model.update(`/env/${this.$environment}/api/APIToken`, this.token.tokenID, { $set: token })
                        this.$router.push({ name: this.formAction, query: this.$route.query })
                    }
                    this.$router.replace({ name: 'api.tokens.overview', query: this.$route.query })
                } catch (err) {
                    if (err.error === 'validation-error') {
                        this.submitErrors = err.details.map(error => error.message)
                    } else {
                        this.submitErrors = [err.message]
                    }
                    this.saving = false
                }
            }
        }
    }
</script>
