import SimpleSchema from '@qiri/simpl-schema'

/**
 * @todo
 */
export default new SimpleSchema({
  'select': {
    type: String,
    label: 'Selectie',
    allowedValues: {
      'Qiri-veld': 'field',
      'Qiri-systeemveld: datum toegevoegd': 'insert-date'
    }
  },
  'name': {
    type: String,
    label: 'Naam',
    optional: true,
    regEx: /^[a-z_]+[a-z_0-9\.]*$/i
  },
  'operator': {
    type: String,
    label: 'Type',
    optional: true,
    allowedValues: {
      'Is gelijk aan': 'equal',
      'Is ongelijk aan': 'not-equal',
      'Is groter dan': 'bigger-than',
      'Is groter dan of gelijk aan': 'bigger-than-or-equal',
      'Is kleiner dan': 'smaller-than',
      'Is kleiner dan of gelijk aan': 'smaller-than-or-equal',
      'Komt voor in (komma-gescheiden) lijst': 'in',
      'Komt niet voor in (komma-gescheiden) lijst': 'not-in'
    }
  },
  'value': {
    type: String,
    label: 'Waarde',
    optional: true
  }
})
