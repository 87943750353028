<template>
    <section v-if="loading" class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>API-script snippets</h1></template>
                    <template slot="content">
                        <p>Bezig met laden...</p>
                    </template>
                </block>
            </div>
        </div>
    </section>
    <section v-else class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>API-script snippets</h1></template>
                    <template slot="content">
                        <p>Hier kun je de API-script snippets beheren. Met deze snippets kun je snel Qiri-suggesties tonen binnen je website of webshop met behulp van API-scripts.</p>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block :table="true">
                    <template slot="content">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Naam</th>
                                    <th>Omschrijving</th>
                                    <th class="icon">
                                        <router-link :to="{ name: 'api-script-snippets.snippets.add', query: $route.query }" title="API-script snippet toevoegen" class="btn icon">
                                            <icon name="plus" />
                                        </router-link>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="snippet of sortedSnippets" :key="snippet.snippetID">
                                    <td class="align--top whitespace--nowrap icon-inline">
                                        <router-link
                                            :to="{ name: 'api-script-snippets.snippets.update', params: { snippetID: snippet.snippetID }, query: $route.query }"
                                            title="API-script snippet wijzigen"
                                            class="btn icon"
                                        >
                                            <icon name="pencil" />
                                        </router-link>
                                        {{ snippet.name }}
                                    </td>
                                    <td v-html="snippet.description"></td>
                                    <td class="icon align--top whitespace--nowrap">
                                        <btn icon="eye" title="API-script snippet bekijken" class="icon" @click.prevent="viewSnippetCode(snippet)"></btn>
                                        <btn icon="trash" title="API-script snippet verwijderen" class="icon" @click.prevent="removeRecord(snippet)"></btn>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </block>
            </div>
        </div>
        <modal name="view-snippet" title="Snippet">
            <template v-if="selectedRecord">
                <p>
                    Om de API-script snippet "{{ selectedRecord.name }}" te gebruiken op je website, plaats je de onderstaande code
                    zo laag mogelijk in de &lt;body&gt; van je website.
                </p>
                <textarea :value="getSnippetCode(selectedRecord)" rows="12" @click="selectCode"></textarea>
                <button type="button" @click="$root.$emit('modal', 'view-snippet', false)">Sluiten</button>
            </template>
        </modal>
        <modal name="delete" title="Weet je zeker dat je de API-script snippet wilt verwijderen?">
            <p>Wil je de API-script snippet "{{ selectedRecord && selectedRecord.name }}" verwijderen?</p>
            <p>
                <strong>Let op!</strong>
                Alle webpagina's die gebruik maken van deze API-script snippet stoppen hierdoor met het tonen van Qiri-suggesties.
                Zorg er dus voor dat deze API-script snippet niet meer in gebruik is.
            </p>
            <button type="button" @click="removeRecord(selectedRecord, true)">Ja, verwijderen</button>
            <button type="button" @click="$root.$emit('modal', 'delete', false)">Nee, annuleren</button>
        </modal>
    </section>
</template>

<script>
    import Modal from '@/components/Partials/Modal'

    export default {
        name: 'page-script-snippets-Overview',
        components: {
            Modal
        },
        data () {
            return {
                snippets: [],
                selectedRecord: null,
                loading: true
            }
        },
        async mounted () {
            this.loading = true

            // Default mode is just an overview of API-script snippets.
            this.$model.list(`/env/${this.$environment}/api-script-snippets/Snippet/list`, 'snippets')

            this.loading = false
        },
        watch: {
            overviewMode () {
                this.refresh()
            }
        },
        computed: {
            sortedSnippets () {
                return this.snippets.sort((a, b) => a.name > b.name ? 1 : a.name === b.name ? 0 : -1)
            },
            overviewMode () {
                return this.$route.meta.overview.mode
            }
        },
        methods: {
            getSnippetCode (record) {
                const urlHost = this.$api.host('http', 'snippets')
                return [
                    `<script>`,
                    `(function (w, d, s, t) {`,
                    `  var f = d.getElementsByTagName(s)[0];`,
                    `  var j = d.createElement(s);`,
                    `  j.async = true;`,
                    `  j.src = '${urlHost}/snippet.js?token=' + t;`,
                    `  j.referrerpolicy = 'origin';`,
                    `  f.parentNode.insertBefore(j, f);`,
                    `})(window, document, 'script', '${record.token}');`,
                    `<${'/'}script>`
                ].join('\n')
            },
            viewSnippetCode (record) {
                this.selectedRecord = record
                this.$root.$emit('modal', 'view-snippet', true)
            },
            selectCode (evt) {
                evt.target.focus()
                evt.target.select()
            },
            async removeRecord (record, confirmed) {
                if (!confirmed) {
                    this.selectedRecord = record
                    this.$root.$emit('modal', 'delete', true)
                } else {
                    this.selectedRecord = null
                    this.$root.$emit('modal', 'delete', false)
                    await this.$model.remove(`/env/${this.$environment}/api-script-snippets/Snippet`, record.snippetID)
                }
            }
        }
    }
</script>
