import moment from 'moment'
import SimpleSchema from '@qiri/simpl-schema'
import PersonModel from '../data/Person'
import OrderModel from '../data/Order'

export const Configuration = new SimpleSchema({

})

const PeriodStats = new SimpleSchema({
  'firstDate': {
    type: Date
  },
  'orderCount': {
    type: SimpleSchema.Integer
  },
  'valueSum': {
    type: Number
  },
  'valueAverage': {
    type: Number
  },
  'valueMedian': {
    type: Number
  },
  'dayValue': {
    type: Number
  }
})

export const Input = {
  Order: ['personID', 'timestamp', 'totalOrderValue']
}

export const Output = ({model} = {}) => new SimpleSchema({
  'person': {
    type: model ? model('Person').type : PersonModel,
    label: 'Persoon',
    table: 'Personen'
  },
  'details': {
    type: Object
  },
  'details.lastDate': {
    type: Date
  },
  'details.lifetime': {
    type: PeriodStats
  },
  'details.yearly': {
    type: PeriodStats
  },
  'details.quarterly': {
    type: PeriodStats
  }
})

const getPeriodPreviewFields = (period, periodLabel) => ({
  /*[`${period}FirstDate`]: {
    type: String,
    label: `${periodLabel}: eerste order datum`,
    render (record) {
      return record.details[period].firstDate ? moment(record.details[period].firstDate).format('D-M-YYYY') : 'N.v.t.'
    }
  },*/
  [`${period}OrderCount`]: {
    type: String,
    //label: 'Aantal orders',
    label: `${periodLabel}: Aantal orders`,
    render (record) {
      return record.details[period].orderCount || '0'
    }
  },
  [`${period}ValueSum`]: {
    type: String,
    label: `${periodLabel}: Waarde orders`,
    render (record) {
      return ((record.details[period].valueSum || 0) / 100).toFixed(2)
    }
  }/*,
  [`${period}ValueAverage`]: {
    type: String,
    label: 'Gemiddelde',
    render (record) {
      return ((record.details[period].valueAverage || 0) / 100).toFixed(2)
    }
  },
  [`${period}ValueMedian`]: {
    type: String,
    label: 'Mediaan',
    render (record) {
      return ((record.details[period].valueMedian || 0) / 100).toFixed(2)
    }
  },
  [`${period}DayValue`]: {
    type: String,
    label: 'Gemiddelde dagwaarde',
    render (record) {
      return ((record.details[period].dayValue || 0) / 100).toFixed(2)
    }
  }*/
})

export const Preview = ({customFields}) => new SimpleSchema({
  /*...customFields
    .filter(customField => customField.model === 'Person' && customField.type === 'foreignKey')
    .reduce(
      (acc, cur) => {
        acc[cur.name] = {
          type: String,
          label: cur.name,
          render (record) {
            return record.person[cur.name]
          }
        }
        return acc
      },
      {}
    ),*/
  'lifetimeFirstDate': {
    type: String,
    label: `Eerste order datum (lifetime)`,
    render (record) {
      return record.details.lifetime.firstDate ? moment(record.details.lifetime.firstDate).format('D-M-YYYY') : 'N.v.t.'
    }
  },
  'lastDate': {
    type: String,
    label: 'Laatste order datum',
    render (record) {
      return record.details.lastDate ? moment(record.details.lastDate).format('D-M-YYYY') : 'N.v.t.'
    }
  },
  ...getPeriodPreviewFields('lifetime', 'Lifetime'/*, ['orderCount', 'valueSum']*/),
  //...getPeriodPreviewFields('yearly', 'Jaar'),
  ...getPeriodPreviewFields('quarterly', 'Kwartaal')
})

const getPeriodRequestFields = (period, periodLabel) => ({
  [`${period}MinFirstDate`]: {
    type: Date,
    label: `${periodLabel}: Eerste order datum niet eerder dan`,
    description: `Indien ingevuld, mag de eerste ${periodLabel.toLowerCase()} order datum niet eerder zijn dan de opgegeven datum.`,
    optional: true
  },
  [`${period}MaxFirstDate`]: {
    type: Date,
    label: `${periodLabel}: Eerste order datum niet later dan`,
    description: `Indien ingevuld, mag de eerste ${periodLabel.toLowerCase()} order datum niet later zijn dan de opgegeven datum.`,
    optional: true
  },
  [`${period}MinOrderCount`]: {
    type: Number,
    label: `${periodLabel}: Aantal orders niet minder dan`,
    description: `Indien ingevuld, mogen het aantal orders voor de ${periodLabel.toLowerCase()} periode niet minder zijn dat het opgegeven aantal.`,
    optional: true
  },
  [`${period}MaxOrderCount`]: {
    type: Number,
    label: `${periodLabel}: Aantal orders niet meer dan`,
    description: `Indien ingevuld, mogen het aantal orders voor de ${periodLabel.toLowerCase()} periode niet meer zijn dat het opgegeven aantal.`,
    optional: true
  },
  [`${period}MinValueSum`]: {
    type: Number,
    label: `${periodLabel}: Som niet minder dan`,
    description: `Indien ingevuld, mag de som waarde van alle orders in de ${periodLabel.toLowerCase()} periode niet minder zijn dat de opgegeven waarde.`,
    optional: true
  },
  [`${period}MaxValueSum`]: {
    type: Number,
    label: `${periodLabel}: Som niet meer dan`,
    description: `Indien ingevuld, mag de som waarde van alle orders in de ${periodLabel.toLowerCase()} periode niet meer zijn dat de opgegeven waarde.`,
    optional: true
  },
  [`${period}MinValueAverage`]: {
    type: Number,
    label: `${periodLabel}: Gemiddelde niet minder dan`,
    description: `Indien ingevuld, mag de gemiddelde waarde van alle orders in de ${periodLabel.toLowerCase()} periode niet minder zijn dat de opgegeven waarde.`,
    optional: true
  },
  [`${period}MaxValueAverage`]: {
    type: Number,
    label: `${periodLabel}: Gemiddelde niet meer dan`,
    description: `Indien ingevuld, mag de gemiddelde waarde van alle orders in de ${periodLabel.toLowerCase()} periode niet meer zijn dat de opgegeven waarde.`,
    optional: true
  },
  [`${period}MinValueMedian`]: {
    type: Number,
    label: `${periodLabel}: Mediaan niet minder dan`,
    description: `Indien ingevuld, mag de mediaan waarde van alle orders in de ${periodLabel.toLowerCase()} periode niet minder zijn dat de opgegeven waarde.`,
    optional: true
  },
  [`${period}MaxValueMedian`]: {
    type: Number,
    label: `${periodLabel}: Mediaan niet meer dan`,
    description: `Indien ingevuld, mag de mediaan waarde van alle orders in de ${periodLabel.toLowerCase()} periode niet meer zijn dat de opgegeven waarde.`,
    optional: true
  },
  [`${period}MinDayValue`]: {
    type: Number,
    label: `${periodLabel}: Gemiddelde dagwaarde niet minder dan`,
    description: `Indien ingevuld, mag de gemiddelde dagwaarde waarde van alle orders in de ${periodLabel.toLowerCase()} periode niet minder zijn dat de opgegeven waarde.`,
    optional: true
  },
  [`${period}MaxDayValue`]: {
    type: Number,
    label: `${periodLabel}: Gemiddelde dagwaarde niet meer dan`,
    description: `Indien ingevuld, mag de gemiddelde dagwaarde waarde van alle orders in de ${periodLabel.toLowerCase()} periode niet meer zijn dat de opgegeven waarde.`,
    optional: true
  }
})

const getPeriodSortFields = (period, periodLabel) => ({
  [`${periodLabel}: Eerste order datum`]: `${period}_first_date`,
  [`${periodLabel}: Aantal orders`]: `${period}_order_count`,
  [`${periodLabel}: Som`]: `${period}_value_sum`,
  [`${periodLabel}: Gemiddelde`]: `${period}_value_average`,
  [`${periodLabel}: Mediaan`]: `${period}_value_median`,
  [`${periodLabel}: Gemiddelde dagwaarde`]: `${period}_day_value`
})

export const Request = new SimpleSchema({
  'person': {
    type: String,
    label: 'Persoon',
    description: 'De persoon voor wie de orderwaarde wordt opgevraagd.',
    optional: true
  },
  'minLastDate': {
    type: Date,
    label: `Laatste order datum niet eerder dan`,
    description: `Indien ingevuld, mag de laatste order datum niet eerder zijn dan de opgegeven datum.`,
    optional: true
  },
  'maxLastDate': {
    type: Date,
    label: `Laatste order datum niet later dan`,
    description: `Indien ingevuld, mag de laatste order datum niet later zijn dan de opgegeven datum.`,
    optional: true
  },
  ...getPeriodRequestFields('lifetime', 'Lifetime'),
  ...getPeriodRequestFields('yearly', 'Jaar'),
  ...getPeriodRequestFields('quarterly', 'Kwartaal'),
  'sortField': {
    type: String,
    label: 'Sorteer op veld',
    description: 'Sorteer op het gekozen veld',
    allowedValues: {
      'Laatste order datum': 'last_date',
      ...getPeriodSortFields('lifetime', 'Lifetime'),
      ...getPeriodSortFields('yearly', 'Jaar'),
      ...getPeriodSortFields('quarterly', 'Kwartaal')
    },
    optional: true
  },
  'sortDirection': {
    type: String,
    label: 'Sorteer volgorde',
    description: 'Sorteer oplopend of aflopend op het gekozen veld',
    allowedValues: {
      'Oplopend': 'ASC',
      'Aflopend': 'DESC'
    },
    optional: true
  },
  'limit': {
    type: SimpleSchema.Integer,
    label: 'Limiet',
    description: 'Maximaal aantal resultaten dat wordt getoond.',
    min: 1,
    optional: true
  }
})

export const RequestConfiguration = new SimpleSchema({

})
