import SimpleSchema from '@qiri/simpl-schema'
import {MODELS} from '@qiri/models/data'

import Stream, {pull} from '@qiri/stream'
import {once} from '@qiri/stream/sources/once'
import {iterate} from '@qiri/stream/sources/iterate'
import {map} from '@qiri/stream/operators/map'
import {flatMap} from '@qiri/stream/operators/flatMap'
import {filter} from '@qiri/stream/operators/filter'
import {effect} from '@qiri/stream/operators/effect'
import {collect} from '@qiri/stream/sinks/collect'

import {getIDField, getForeignKeyFields} from '../../data/util'

/**
 * @todo
 */
export default function relation ({schema}, configuration, {model}) {

  const parentSingle = schema.schema('output').type.singleType !== Array

  // Input must be a SimpleSchema, or else we wouldn't be able to find relations.
  const modelSchema = schema.schema(parentSingle ? 'output' : 'output.$').type.singleType
  if (!SimpleSchema.isSimpleSchema(modelSchema)) {
    return false
  }

  const modelName = schema.schema('output').name
  let idField
  try {
    idField = getIDField(modelSchema, modelName)
  } catch (err) {
    // Not an actual model.
    return false
  }
  const relations = []

  // Get relations from current to foreign model.
  for (const foreignKeyField of getForeignKeyFields(modelSchema)) {
    const foreignModelSchema = model(foreignKeyField.foreignKey)
    relations.push([foreignModelSchema.label, foreignKeyField.name.replace('.$', '')])
  }

  // Also get relations from foreign to current model.
  // TODO: Implement this when parent is already a list.
  if (parentSingle) {
    for (const foreignModelName of MODELS) {
      if (foreignModelName !== modelName) {
        const foreignModelSchema = model(foreignModelName)
        const foreignKeyFields = getForeignKeyFields(foreignModelSchema.type.singleType).filter(field => field.foreignKey === modelName)
        if (foreignKeyFields.length > 0) {
          const foreignKeyField = foreignKeyFields[0]
          relations.push([foreignModelSchema.table, `${foreignModelName}.${foreignKeyField.name}`])
        }
      }
    }
  }

  // Create the configuration's schema.
  const configurationSchema = new SimpleSchema({
    field: {
      type: String,
      label: 'Relatie veld',
      description: 'Kies relatie veld.',
      allowedValues: relations
        .sort((a, b) => a[1] < b[1] ? -1 : a[1] === b[1] ? 0 : 1)
    }
  })

  // Just provide the configuration's schema if no configuration was actually provided.
  if (!configuration) {
    return {configurationSchema}
  }

  // Use the configured field to create the sink and output schema.
  const {field} = configuration
  if (field.indexOf('.') === -1) {
    // Relation to another model.
    const foreignKeyFieldName = field
    const foreignKeyField = modelSchema.schema(foreignKeyFieldName)
    const single = foreignKeyField.type.singleType !== Array
    const foreignModelName = single
      ? foreignKeyField.foreignKey
      : modelSchema.schema(`${foreignKeyFieldName}.$`).foreignKey
    const foreignModel = model(foreignModelName)
    const foreignSchema = foreignModel.type.singleType

    if (single && parentSingle) {
      // One-to-one: our parent is a single record and so is the relation.
      // We can just use the model as the output schema.
      return {
        configurationSchema,
        schema: new SimpleSchema({
          'output': {
            type: foreignSchema,
            name: foreignModelName,
            label: foreignModel.label
          }
        }),
        get [Stream.sink] () {
          return source => pull(
            source,
            map(record => record && record[`${foreignModelName}$`] || null)
          )
        }
      }
    } else if (single && !parentSingle) {
      // Many-to-one: our parent is already an array of records. Each record is one-to-one.
      // Preserve array in output schema.
      return {
        configurationSchema,
        schema: new SimpleSchema({
          'output': {
            type: Array,
            name: foreignModelName,
            label: foreignModel.label
          },
          'output.$': {
            type: foreignSchema
          }
        }),
        get [Stream.sink] () {
          return source => pull(
            source,
            map(list => pull(
              iterate(list),
              map(record => {
                if (!record) {
                  console.log(`ERROR: NULL record in relation (foreignModelName = ${foreignModelName}, foreignKeyFieldName = ${foreignKeyFieldName})`)
                }
                return record && record[`${foreignModelName}$`] || null
              })
            ))
          )
        }
      }
    } else if (!single && parentSingle) {
      // One-to-many: our parent is a single record, but we produce an array per record.
      return {
        configurationSchema,
        schema: new SimpleSchema({
          'output': {
            type: Array,
            name: foreignModelName,
            label: foreignModel.table
          },
          'output.$': {
            type: foreignSchema
          }
        }),
        get [Stream.sink] () {
          return source => pull(
            source,
            map(record => iterate(record && record[`${foreignModelName}$`] || []))
          )
        }
      }
    } else { // !single && !parentSingle
      // Many-to-many: our parent is an array of records and we also produce an array of records.
      // This causes the output to be an array of arrays of records. We flatten this automatically
      // to an array of records.
      return {
        configurationSchema,
        schema: new SimpleSchema({
          'output': {
            type: Array,
            name: foreignModelName,
            label: foreignModel.table
          },
          'output.$': {
            type: foreignSchema
          }
        }),
        get [Stream.sink] () {
          return source => pull(
            source,
            map(list => pull(
              iterate(list),
              flatMap(record => record && record[`${foreignModelName}$`] || [])
            ))
          )
        }
      }
    }
  } else {
    // TODO: One-to-many or many-to-many
    // Relation from another model.
    const [foreignModelName, foreignKeyFieldName] = field.split('.')
    const foreignModel = model(foreignModelName)
    const foreignSchema = foreignModel.type.singleType

    return {
      configurationSchema,
      schema: new SimpleSchema({
        'output': {
          type: Array,
          name: foreignModelName,
          label: foreignModel.table
        },
        'output.$': {
          type: foreignSchema
        }
      }),
      get [Stream.sink] () {
        return source => pull(
          source,
          map(record => iterate(record && record[`${foreignModelName}$`] || []))
        )
      }
    }
  }
}
