import moment from 'moment-timezone'
import SimpleSchema from '@qiri/simpl-schema'

export const RecurPatterns = {
  Minutely: new SimpleSchema({
    interval: {
      type: SimpleSchema.Integer,
      label: 'Aantal minuten',
      defaultValue: 15
    }
  }),
  Hourly: new SimpleSchema({
    interval: {
      type: SimpleSchema.Integer,
      label: 'Aantal uren',
      defaultValue: 1
    }
  }),
  Daily: new SimpleSchema({
    interval: {
      type: SimpleSchema.Integer,
      label: 'Aantal dagen',
      defaultValue: 1
    }
  }),
  WeekDay: new SimpleSchema({

  }),
  WeekEnd: new SimpleSchema({

  }),
  Weekly: new SimpleSchema({
    interval: {
      type: SimpleSchema.Integer,
      label: 'Aantal weken',
      defaultValue: 1
    }
  }),
  Monthly: new SimpleSchema({
    day: {
      type: SimpleSchema.Integer,
      label: 'Dag',
      min: 1,
      max: 31,
      defaultValue: 7
    },
    interval: {
      type: SimpleSchema.Integer,
      min: 1,
      max: 12,
      label: 'Aantal maanden',
      defaultValue: 1
    }
  }),
  Yearly: new SimpleSchema({
    day: {
      type: SimpleSchema.Integer,
      label: 'Dag',
      min: 1,
      max: 31
    },
    month: {
      type: String,
      label: 'Maand',
      allowedValues: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
    }
  }),
  YearlyOccurence: new SimpleSchema({
    occurrence: {
      type: String,
      label: 'Voorval',
      allowedValues: ['first', 'second', 'third', 'fourth', 'last']
    },
    dayOfWeek: {
      type: String,
      label: 'Dag van de week',
      allowedValues: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
    },
    month: {
      type: String,
      label: 'Maand',
      allowedValues: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
    }
  })
}

export default new SimpleSchema({
  timezone: {
    type: String,
    label: 'Tijdzone'
  },
  startDate: {
    type: Date,
    label: 'Begint op'
  },
  endType: {
    type: String,
    allowedValues: ['none', 'date', 'count'],
    defaultValue: 'none'
  },
  endDate: {
    type: Date,
    label: 'Eindigt op',
    optional: true
  },
  endAfter: {
    type: SimpleSchema.Integer,
    label: 'Aantal keer',
    optional: true
  },
  recur: {
    type: Object,
    label: 'Herhalings patroon'
  },
  'recur.type': {
    type: String,
    optional: true,
    allowedValues: Object.keys(RecurPatterns)
  },
  'recur.settings': {
    type: Object,
    optional: true,
    blackbox: true,
    label: 'Herhalings patroon instellingen'
  },
  'exclude': {
    type: Object,
    label: 'Niet uitvoeren op'
  },
  'exclude.monday': {
    type: Boolean,
    label: 'maandag',
    defaultValue: false
  },
  'exclude.tuesday': {
    type: Boolean,
    label: 'dinsdag',
    defaultValue: false
  },
  'exclude.wednesday': {
    type: Boolean,
    label: 'woensdag',
    defaultValue: false
  },
  'exclude.thursday': {
    type: Boolean,
    label: 'donderdag',
    defaultValue: false
  },
  'exclude.friday': {
    type: Boolean,
    label: 'vrijdag',
    defaultValue: false
  },
  'exclude.saturday': {
    type: Boolean,
    label: 'zaterdag',
    defaultValue: false
  },
  'exclude.sunday': {
    type: Boolean,
    label: 'zondag',
    defaultValue: false
  }
})

/**
 * @todo
 */
export const DaysOfWeek = {
    'mon': 'maandag',
    'tue': 'dinsdag',
    'wed': 'woensdag',
    'thu': 'donderdag',
    'fri': 'vrijdag',
    'sat': 'zaterdag',
    'sun': 'zondag'
}

/**
 * @todo
 */
export const DaysOfWeekFull = {
  'monday': DaysOfWeek['mon'],
  'tuesday': DaysOfWeek['tue'],
  'wednesday': DaysOfWeek['wed'],
  'thursday': DaysOfWeek['thu'],
  'friday': DaysOfWeek['fri'],
  'saturday': DaysOfWeek['sat'],
  'sunday': DaysOfWeek['sun']
}

/**
 * @todo
 */
export const Months = {
    'jan': 'januari',
    'feb': 'februari',
    'mar': 'maart',
    'apr': 'april',
    'may': 'mei',
    'jun': 'juni',
    'jul': 'juli',
    'aug': 'augustus',
    'sep': 'september',
    'oct': 'october',
    'nov': 'november',
    'dec': 'december'
}

/**
 * @todo
 */
export const Occurrences = {
    'first': 'eerste',
    'second': 'tweede',
    'third': 'derde',
    'fourth': 'vierde',
    'last': 'laatste'
}

/**
 * @todo
 */
export function scheduleToString (schedule) {
  if (!schedule || !schedule.startDate) {
    return 'Geen'
  }
  const startDate = moment.tz(schedule.startDate, schedule.timezone).locale('nl')
  const recurType = schedule.recur && schedule.recur.type
  const recurSettings = schedule.recur && schedule.recur.settings
  switch (recurType) {
    case 'Minutely':
      if (recurSettings.interval === 1) {
        return `Elke minuut`
      } else {
        return `Elke ${recurSettings.interval} minuten`
      }

    case 'Hourly':
      if (recurSettings.interval === 1) {
        return `Elk uur`
      } else {
        return `Elke ${recurSettings.interval} uren`
      }

    case 'Daily':
      if (recurSettings.interval === 1) {
        return `Elke dag om ${startDate.format('LT')}`
      } else {
        return `Elke ${recurSettings.interval} dagen`
      }

    case 'WeekDay':
      return `Elke werkdag om ${startDate.format('LT')}`

    case 'WeekEnd':
      return `Elk weekend om ${startDate.format('LT')}`

    case 'Weekly':
      if (recurSettings.interval === 1) {
        return `Elke week op ${startDate.format('dddd')} om ${startDate.format('LT')}`
      } else {
        return `Elke ${recurSettings.interval} weken op ${startDate.format('dddd')} om ${startDate.format('LT')}`
      }

    case 'Monthly':
      if (recurSettings.interval === 1) {
        return `Elke ${recurSettings.day}e dag van de maand`
      } else {
        return `Elke ${recurSettings.day}e dag van elke ${recurSettings.interval} maanden`
      }

    case 'Yearly':
      return `Elke ${recurSettings.day}e dag van ${Months[recurSettings.month]}`

    case 'YearlyOccurence':
      return `De ${Occurrences[recurSettings.occurrence]} ${DaysOfWeek[recurSettings.dayOfWeek]} van ${Months[recurSettings.month]}`

    default:
      return `Op ${startDate.format('LLLL')}`
  }
}

/**
 * @todo
 */
export function getNextDate (schedule, previousDate, notBeforeDate) {
  if (!schedule) {
    return null
  }
  const startDate = moment.tz(schedule.startDate, schedule.timezone)
  const endDate = schedule.endType === 'date' && schedule.endDate ? moment.tz(schedule.endDate, schedule.timezone) : null

  previousDate = previousDate ? moment.tz(previousDate, schedule.timezone) : null
  if (!previousDate) {
    return startDate.toDate()
  }
  if (!schedule.recur || !schedule.recur.type || !schedule.recur.settings || schedule.recur.type === 'None') {
    return false
  }

  let exclude = new Set()
  if (schedule.exclude) {
    Object.keys(DaysOfWeekFull).forEach((dayOfWeek, index) => {
      if (schedule.exclude[dayOfWeek]) {
        const day = (index + 1) % 7 // Swap sunday to index 0.
        exclude.add(day)
      }
    })
  }

  let nextDate = null

  let recurType = schedule.recur.type
  const recurSettings = schedule.recur.settings
  let recurInterval = Math.max(1, recurSettings.interval || 1)
  if (recurType ===  'WeekDay') {
      // Exclude weekend days.
    exclude.add(0).add(6)
    recurInterval = 1
    recurType = 'Daily'
  } else if (recurType ===  'WeekEnd') {
      // Exclude working days.
    exclude.add(1).add(2).add(3).add(4).add(5)
    recurInterval = 1
    recurType = 'Daily'
  }

  // All day excluded, so nothing would match.
  if (exclude.size === 7) {
    return false
  }

  switch (recurType) {
    case 'Minutely':
      nextDate = moment(previousDate).add(recurInterval, 'minutes')
      break

    case 'Hourly':
      // TODO: Should we atleast set minutes here?
      nextDate = moment(previousDate).add(recurInterval, 'hours')
      break

    case 'Daily':
      nextDate = setTime(previousDate, startDate).add(recurInterval, 'days')
      break

    case 'Weekly':
      nextDate = setTime(previousDate, startDate).add(recurInterval, 'weeks')
      break

    case 'Monthly': {
      const day = recurSettings.day || 1
      nextDate = setTime(previousDate, startDate).add(recurInterval, 'months').date(day)
      // If the month doesn't have the requested day, take the last day instead.
      if (nextDate.date() !== day) {
        nextDate.add(-1, 'month')
        nextDate.date(moment(nextDate).endOf('month').date())
      }
      break
    }

    case 'Yearly': {
      const month = Object.keys(Months).indexOf(recurSettings.month)
      nextDate = setTime(previousDate, startDate).add(recurInterval, 'years').month(month).date(recurSettings.day || 1)
      break
    }

    case 'YearlyOccurence': {
      const occurrence = Object.keys(Occurrences).indexOf(recurSettings.occurrence)
      const dayOfWeek = (Object.keys(DaysOfWeek).indexOf(recurSettings.dayOfWeek) + 1) % 7 // Swap sunday to index 0.
      const month = Object.keys(Months).indexOf(recurSettings.month)
      nextDate = setTime(previousDate, nextDate).add(1, 'year').month(month)
      nextDate = nextDate.date(moment(nextDate).startOf('month').date())

      // Find first occurrence of the given day of week.
      while (nextDate.day() !== dayOfWeek) {
        nextDate.add(1, 'day')
      }
      // Next, keep adding weeks until we get the correct occurrence.
      for (let i = 0; i < occurrence && moment(nextDate).add(1, 'week').month() === month; i++) {
        nextDate.add(1, 'week')
      }

      break
    }
  }

  if (!nextDate) {
    return false
  }

  // Force start date if next date is before it.
  if (nextDate.isBefore(startDate)) {
    return startDate.toDate()
  }

  // Try next date if current day is excluded.
  if (exclude.has(nextDate.day())) {
    return getNextDate(schedule, nextDate)
  }

  // Try next date if current one is too early.
  if (notBeforeDate && nextDate.isSameOrBefore(moment.tz(notBeforeDate, schedule.timezone))) {
    return getNextDate(schedule, nextDate, notBeforeDate)
  }

  // Return next date, or false if there is none or it is after the end date.
  return (!endDate || nextDate.isBefore(endDate)) ? nextDate.toDate() : false
}

/**
 * @private
 */
function setTime (date, time) {
  time = moment(time)
  return moment(date).set({
    'hour': time.get('hour'),
    'minute': time.get('minute'),
    'second': time.get('second'),
    'millisecond': time.get('millisecond')
  })
}
