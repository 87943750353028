import {sort as timSort} from 'timsort'
import {pull} from '../pull'
import {empty} from '../sources/empty'
import {iterate} from '../sources/iterate'
import {take} from './take'
import {merge, defaultCompare} from './merge'
import {drain} from '../sinks/drain'

/**
 * @private
 */
const BUCKET_SIZE = 1000000

/**
 * @todo
 */
export const sort = (options = {}) => (outerSource) => (start, sink) => {
  if (start !== 0) {
    return
  }
  const {
    compare = defaultCompare,
    bucketSize = BUCKET_SIZE
  } = options

  let otherBuckets = []
  let currentBucket = new Array(bucketSize)
  let currentBucketLength = 0

  const onValue = x => {
    if (currentBucketLength === bucketSize) {
      timSort(currentBucket, compare)
      otherBuckets.push(currentBucket)
      currentBucket = new Array(bucketSize)
      currentBucketLength = 0
    }
    currentBucket[currentBucketLength++] = x
  }

  const onDrained = () => {
    let innerSource
    if (currentBucketLength === 0) {
      innerSource = empty()
    } else {
      timSort(currentBucket, compare, 0, currentBucketLength)
      if (otherBuckets.length === 0) {
        innerSource = pull(
          iterate(currentBucket),
          take(currentBucketLength)
        )
      } else {
        innerSource = merge(
          otherBuckets.map(x => iterate(x)).concat([pull(
            iterate(currentBucket),
            take(currentBucketLength)
          )]),
          {compare}
        )
      }
    }
    innerSource(0, sink)
  }

  const result = drain(onValue)(outerSource)
  if (result && typeof result.then === 'function') {
    result.then(onDrained, err => {
      sink(0, () => {})
      sink(2, err)
    })
  } else {
    onDrained()
  }
}
