import SimpleSchema from '@qiri/simpl-schema'
import Company from './Company'

const Owner = new SimpleSchema({
  'termsAndConditionsURL': {
    type: String,
    label: 'Algemene voorwaarden URL',
    description: 'De URL naar de algemene voorwaarden van het bedrijf',
    regEx: SimpleSchema.RegEx.Url
  }
})
Owner.extend(Company)
export default Owner
