import SimpleSchema from '@qiri/simpl-schema'

/**
 * @todo
 */
const ContextSelector = new SimpleSchema({
  'source': {
    type: String,
    label: 'API-script parameter bron',
    description: 'De bron waarin de waarde gevonden kan worden',
    allowedValues: {
      'Pagina HTML': 'page',
      'Pagina URL': 'url',
      'Lokale- of sessieopslag': 'storage',
      'Cookies': 'cookie',
      'Google Tag Manager': 'gtm'
    }
  },
  'selector': {
    type: String,
    label: 'API-script parameter selector',
    description: 'De selector waarmee de waarde gevonden kan worden'
  },
  'parameter': {
    type: String,
    label: 'API-script parameter',
    description: 'De API-script parameter waarmee de waarde naar het API-script gestuurd wordt'
  },
  'persistent': {
    type: Boolean,
    label: 'Onthouden',
    description: 'Gebruik de laatst bekende waarde indien "selector" niet voorkomt op de huidige pagina',
    defaultValue: false
  }
})

/**
 * @todo
 */
const Container = new SimpleSchema({
  'selector': {
    type: String,
    label: 'Container CSS-selector',
    description: 'De CSS-selector waarmee de container gevonden kan worden op de website om het resultaat van het API-script te tonen'
  },
  'context': {
    type: Array,
    label: 'API-script parameters'
  },
  'context.$': {
    type: ContextSelector,
    label: 'API-script parameters CSS-selectors'
  },
  'scriptID': {
    type: String,
    label: 'API-script',
    description: 'Het API-script dat waarvan het resultaat op de website getoond moet worden'
  },
  'scriptVersion': {
    type: String,
    label: 'Versie',
    description: 'De versie van het API-script'
  }
})

/**
 * @todo
 */
const Domain = new SimpleSchema({
  'name': {
    type: String,
    label: 'Domein zoals jullie-webshop.nl',
    regEx: SimpleSchema.RegEx.Domain
  }
})

/**
 * @todo
 */
export default new SimpleSchema({
  'snippetID': {
    type: String,
    label: 'Snippet ID',
    description: 'De unieke waarde waarmee het document geïdentificeerd kan worden',
    primaryKey: true
  },
  'name': {
    type: String,
    label: 'Naam',
    description: 'De naam van de API-script snippet'
  },
  'description': {
    type: String,
    label: 'Omschrijving',
    description: 'De omschrijving van de API-script snippet',
    optional: true
  },
  'apiTokenID': {
    type: String,
    label: 'API-token',
    description: 'De API-token waarmee de API-scripts aangeroepen zullen worden'
  },
  'containers': {
    type: Array,
    label: 'Containers',
    optional: true
  },
  'containers.$': {
    type: Container,
    label: 'Container'
  },
  'token': {
    type: String,
    label: 'Token',
    description: 'De token waarmee de API-script snippet aangeroepen kan worden',
    optional: true
  },
  'domains': {
    type: Array,
    label: 'Toegestane domeinen',
    optional: true
  },
  'domains.$': {
    type: Domain,
    label: 'Domein'
  }
})
