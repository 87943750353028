import SimpleSchema from '@qiri/simpl-schema'
import EnvironmentSettings from './EnvironmentSettings'

export default new SimpleSchema({
  'environmentID': {
    type: SimpleSchema.Integer,
    label: 'Omgeving ID',
    description: 'De unieke waarde waarmee de omgeving geïdentificeerd kan worden',
    primaryKey: true
  },
  'companyID': {
    type: String,
    label: 'Bedrijf ID',
    description: 'De klant aan wie de omgeving is toegewezen',
    foreignKey: 'Customer'
  },
  'name': {
    type: String,
    label: 'Omgeving naam',
    description: 'De naam van de omgeving'
  },
  'settings': {
    type: EnvironmentSettings,
    label: 'Instellingen',
    description: 'De instellingen van de omgeving',
    optional: true
  },
  'active': {
    type: Boolean,
    label: 'Is actief',
    description: 'Markeerd de omgeving als actief of inactief'
  }
})
