<template>
    <span>
        Geen velden toegewezen aan "{{ fieldLabel }}" in "{{ modelLabel }}".
        Hierdoor kan API "{{ content.projection }}" geen resultaten tonen.
    </span>
</template>

<script>
    import models from '@qiri/models/data'

    export default {
        name: 'page-status-type-MissingData',
        props: {
            message: Object
        },
        computed: {
            content () {
                return this.message.content
            },
            modelLabel () {
                const schema = models.schema(`${this.message.content.model}`)
                return schema.label
            },
            fieldLabel () {
                const schema = models.schema(`${this.message.content.model}.${this.message.content.field}`)
                return schema.label
            }
        }
    }
</script>
