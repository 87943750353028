import SimpleSchema from '@qiri/simpl-schema'

/**
 * @todo
 */
export default new SimpleSchema({
  model: {
    type: String,
    label: 'Tabel'
  },
  days: {
    type: SimpleSchema.Integer,
    label: 'Aantal dagen om te bewaren',
    optional: true
  }
})
