<template>
    <section class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>Externe servers</h1></template>
                    <template slot="content">
                        <p>
                            Hier kunnen de gegevens van externe servers worden beheerd. Met een externe server wordt bedoeld:
                            bijvoorbeeld een FTP(S) of HTTPS server waar bronbestanden vanaf gedownload worden of exports naar toe geupload worden.
                        </p>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block :table="true">
                    <template slot="content">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Naam</th>
                                    <th>Omschrijving</th>
                                    <th>Host</th>
                                    <th class="icon">
                                        <router-link :to="{ name: `${routePrefix}.add`, query: $route.query }" title="Externe server toevoegen" class="btn icon">
                                            <icon name="plus" />
                                        </router-link>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="remoteServer in displayRemoteServers" :key="remoteServer.remoteServerID">
                                    <td class="icon-inline">
                                        <router-link :to="{ name: `${routePrefix}.update`, params: { remoteServerID: remoteServer.remoteServerID }, query: $route.query }" title="Externe server wijzigen" class="btn icon">
                                            <icon name="pencil" />
                                        </router-link>
                                        {{ remoteServer.name }}
                                    </td>
                                    <td>{{ remoteServer.description }}</td>
                                    <td>{{ getHost(remoteServer) }}</td>
                                    <td class="icon">
                                        <btn icon="trash" title="Verwijderen" class="icon" @click.prevent="removeRecord(remoteServer)"></btn>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </block>
            </div>
        </div>
        <modal name="delete" title="Weet je zeker dat je deze externe server wilt verwijderen?">
            <p>Wil je de externe server "{{ selectedRecord && selectedRecord.name }}" verwijderen?</p>
            <button type="button" @click="removeRecord(selectedRecord, true)">Ja, verwijderen</button>
            <button type="button" @click="$root.$emit('modal', 'delete', false)">Nee, annuleren</button>
        </modal>
    </section>
</template>

<script>
    import url from 'url'
    import Modal from '@/components/Partials/Modal'

    const ProtocolGetHost = {
        'FTP': (settings) => settings.host,
        'HTTP': (settings) => url.parse(settings.url).hostname,
        'SFTP': (settings) => settings.host
    }

    export default {
        name: 'page-externalservers-Overview',
        components: {
            Modal
        },
        data () {
            return {
                selectedModel: null,
                selectedRecord: null,
                remoteServers: []
            }
        },
        mounted () {
            this.$model.list(`/env/${this.$environment}/data/RemoteServer/list`, 'remoteServers')
        },
        computed: {
            displayRemoteServers () {
                return this.remoteServers.sort((a, b) => a.name < b.name ? -1 : a.name === b.name ? 0 : 1)
            },
            routePrefix () {
                return this.$route.name.split('.').slice(0, -1).join('.')
            }
        },
        methods: {
            getHost (remoteServer) {
                if (remoteServer && remoteServer.protocol && remoteServer.protocol.type) {
                    const getFn = ProtocolGetHost[remoteServer.protocol.type]
                    return getFn ? getFn(remoteServer.protocol.settings) : ''
                } else {
                    return ''
                }
            },
            async removeRecord (record, confirmed) {
                if (!confirmed) {
                    this.selectedRecord = record
                    this.$root.$emit('modal', 'delete', true)
                } else {
                    this.selectedRecord = null
                    this.$root.$emit('modal', 'delete', false)
                    await this.$model.remove(`/env/${this.$environment}/data/RemoteServer`, record.remoteServerID)
                }
            }
        }
    }
</script>
