<template>
    <section v-if="loading" class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>Data bewaartermijn</h1></template>
                    <template slot="content">
                        <p v-if="loading">Bezig met laden...</p>
                    </template>
                </block>
            </div>
        </div>
    </section>
    <vue-form v-else tag="section" :state="form" ref="form" :schema="schema" v-model="settings" class="padding" @submit="doSubmit()">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>Data bewaartermijn</h1></template>
                    <template slot="content">
                        <p>
                            Hier kun je de instellen hoe lang bepaalde gegevens bewaard moeten worden.
                            Na het ingestelde aantal dagen, berekend vanaf het laatste moment dat het gegeven werd geïmporteerd of aangepast, zullen de gegevens automatisch verwijderd worden.
                            Als het veld "Maximale leeftijd (in dagen)" niet ingevuld wordt, zullen de gegevens niet automatisch verwijderd worden.
                        </p>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block :table="true">
                    <template slot="content">
                        <DataRetentionTable field="dataRetention"></DataRetentionTable>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="content">
                        <button type="button" class="full" title="Opslaan" :disabled="form.invalid || saving" @click.prevent="$refs.form.submit()">
                            <template v-if="saving"><icon name="refresh" :spin="true" /></template>
                            <template v-else>Instellingen opslaan</template>
                        </button>
                        <ul class="errors" v-if="formErrors.length > 0">
                            <li v-for="error of formErrors">
                                <icon name="exclamation-circle"></icon> {{ error }}
                            </li>
                        </ul>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid" v-if="false">
            <div class="column size--100">
                <block>
                    <template slot="content">
                        <pre>{{ debugJSON }}</pre>
                    </template>
                </block>
            </div>
        </div>
    </vue-form>
</template>

<script>
    import pick from 'lodash/pick'
    import omit from 'lodash/omit'
    import {Settings} from '@qiri/models/qiri-settings'
    import {MODELS} from '@qiri/models/data'
    import DataRetentionTable from '@/components/Partials/DataRetentionTable'
    import {getSystemModules} from '@qiri/models/environment/Module'

    export default {
        name: 'page-DataRetention',
        components: {
            DataRetentionTable
        },
        data () {
            return {
                form: {},
                settings: null,
                loading: true,
                saving: false
            }
        },
        async mounted () {
            this.settings = await this.$model
                .get('/qiri-settings/Settings', 'global')
                .then(settings =>  settings ? pick(settings, 'settingsID', 'dataRetention') : null)

            if (!this.settings) {
                this.settings = {
                    settingsID: 'global',
                    dataRetention: []
                }
            }
            if (!this.settings.dataRetention) {
                this.$set(this.settings, 'dataRetention', [])
            }

            for (const modelName of ['Order', 'OrderLine', 'ApiLog']) {
                let setting = this.settings.dataRetention.find(x => x.model === modelName)
                if (!setting) {
                    setting = {
                        model: modelName,
                        days: null
                    }
                    this.settings.dataRetention.push(setting)
                }
            }

            // Finished loading.
            this.loading = false
        },
        computed: {
            schema () {
                return Settings.pick('settingsID', 'dataRetention')
            },
            formErrors () {
                let messages = []
                if (this.form.errors && this.form.errors.length > 0) {
                    for (const error of this.form.errors) {
                        let message = `${error.message}.`
                        // Don't show duplicate messages.
                        const duplicateMessage = messages.find(x => x === message)
                        if (!duplicateMessage) {
                            messages.push(message)
                        }
                    }
                }
                return messages
            },
            debugJSON () {
                return JSON.stringify(this.settings, null, '  ')
            }
        },
        methods: {
            async doSubmit () {
                this.saving = true
                await this.$model.upsert('/qiri-settings/Settings', this.settings.settingsID, { $set: omit(this.settings, 'settingsID') })
                this.$router.push({ name: 'dashboard', query: this.$route.query })
            }
        }
    }
</script>
