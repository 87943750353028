<template>
  <section v-if="loading" class="padding">
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h1>Data-exports vanuit tabellen en velden</h1></template>
          <template slot="content">
            <p v-if="loadingError" class="error">{{ loadingError }}</p>
            <p v-else>Bezig met laden...</p>
          </template>
        </block>
      </div>
    </div>
  </section>
  <section v-else class="padding">
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h1>Data-exports vanuit tabellen en velden</h1></template>
          <template slot="content">
            <ModelSelect v-model="selectedModel" :models="modelOptions"></ModelSelect>
            <p v-if="selectedModel">
              <router-link :to="{ name: 'data.exports.create', query: $route.query }" class="btn">Data-export vanuit tabel {{ modelLabel }} toevoegen</router-link>
            </p>
          </template>
        </block>
      </div>
    </div>
    <div class="grid">
      <div class="column size--100">
        <block :table="true">
          <template slot="content">
            <table class="table">
              <thead>
                <tr>
                  <th>Naam</th>
                  <th>Omschrijving</th>
                  <th>Planning</th>
                  <th>Status</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="dataExport in displayExports" :key="dataExport.exportID">
                  <td class="icon-inline">
                    <router-link :to="{ name: 'data.exports.update', params: { exportID: dataExport.exportID }, query: $route.query }" title="Data-export wijzigen" class="btn icon">
                      <icon name="pencil" />
                    </router-link>
                    {{ dataExport.name }}
                  </td>
                  <td>{{ dataExport.description }}</td>
                  <template v-if="dataExport.protocol">
                    <td class="icon-inline whitespace--nowrap">
                      <router-link :to="{ name: 'data.exports.schedule', params: { exportID: dataExport.exportID }, query: $route.query }" title="Planning wijzigen" class="btn icon">
                        <icon name="pencil" />
                      </router-link>
                      <ScheduleLabel :schedule="dataExport.schedule"></ScheduleLabel>
                    </td>
                    <td>{{ getStatusLabel(dataExport) }}</td>
                  </template>
                  <template v-else>
                    <td><i>N.v.t.</i></td>
                    <td><i>N.v.t.</i></td>
                  </template>
                  <td class="icon">
                    <template v-if="dataExport.state !== 'disabled' && getStatus(dataExport) !== 'executing' && getStatus(dataExport) !== 'queued'">
                      <btn v-if="dataExport.protocol" @click.prevent="executeTask(dataExport)" icon="play" title="Uitvoeren" class="icon"></btn>
                      <a :href="getDownloadURL(dataExport)" title="Downloaden" class="btn icon">
                        <icon name="cloud-download" />
                      </a>
                    </template>
                    <btn v-if="getStatus(dataExport) === 'queued' || getStatus(dataExport) === 'executing'" @click.prevent="cancelTask(dataExport)" icon="stop" title="Annuleren" class="icon"></btn>
                    <router-link :to="{ name: 'data.exports.log', params: { exportID: dataExport.exportID }, query: $route.query }" title="Logboek" class="btn icon">
                      <icon name="eye" />
                    </router-link>
                    <btn v-if="dataExport.state === 'enabled'" @click.prevent="disableExport(dataExport)" icon="toggle-on" title="Uitschakelen" class="icon"></btn>
                    <btn v-else @click.prevent="enableExport(dataExport)" icon="toggle-off" title="Inschakelen" class="icon"></btn>
                    <btn icon="trash" title="Verwijderen" class="icon" @click.prevent="removeRecord(dataExport)"></btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </block>
      </div>
    </div>
    <modal name="delete" title="Weet je zeker dat je deze data-export wilt verwijderen?">
      <p>Wil je de data-export "{{ selectedRecord && selectedRecord.name }}" verwijderen?</p>
      <button type="button" @click="removeRecord(selectedRecord, true)">Ja, verwijderen</button>
      <button type="button" @click="$root.$emit('modal', 'delete', false)">Nee, annuleren</button>
    </modal>
  </section>
</template>

<script>
  import uuidv4 from 'uuid/v4'
  import get from 'lodash/get'
  import last from 'lodash/last'
  import pick from 'lodash/pick'
  import models from '@qiri/models/data'
  import ModelSelect from '../../Partials/ModelSelect'
  import Modal from '@/components/Partials/Modal'
  import ScheduleLabel from '@/modules/task-scheduler/components/Partials/ScheduleLabel'

  const statusLabels = {
    'initial': 'Initieel',
    'idle': 'Actief',
    'queued': 'In wachtrij',
    'executing': 'Bezig met uitvoeren',
    'canceled': 'Geannuleerd',
    'executed': 'Uitgevoerd'
  }

  export default {
    name: 'page-exports-Overview',
    components: {
      ModelSelect,
      Modal,
      ScheduleLabel
    },
    data () {
      return {
        selectedModel: null,
        selectedRecord: null,
        exports: [],
        tasks: [],
        taskProgress: [],
        statusLabels,
        exportToken: null,
        loading: true,
        loadingError : null
      }
    },
    async mounted () {
      // Gain access to the export scope.
      this.exportToken = await this.$security.access('export')
      if (!this.exportToken) {
          this.loadingError = 'Geen toegang'
          return
      }

      this.$model.list(`/env/${this.$environment}/data-export/Export/list`, 'exports')
      this.$model.list(`/env/${this.$environment}/task-scheduler/Task/list`, 'tasks', {module: 'data.export'})
      this.$model.list(`/env/${this.$environment}/task-scheduler/Task/progress`, 'taskProgress', {module: 'data.export'})

      this.loading = false
    },
    computed: {
      modelLabel () {
        const modelName = this.selectedModel
        if (!modelName) {
          return ''
        }
        const model = models.schema(modelName)
        return model.table
      },
      displayExports () {
        if (!this.selectedModel) {
          return []
        }
        return this.exports
          .filter(dataExport => dataExport.model === this.selectedModel)
          .sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
      },
      modelOptions () {
        return {
            'Person': 'Personen',
            'Store': 'Winkels',
            'Product': 'Producten',
            'ProductCategory': 'Product categorieën',
            'ProductPromotion': 'Productpromoties',
            'Order': 'Orders',
            'OrderLine': 'Orderregels',
            'Event': 'Events'
        }
      }
    },
    methods: {
      async removeRecord (record, confirmed) {
        if (!confirmed) {
          this.selectedRecord = record
          this.$root.$emit('modal', 'delete', true)
        } else {
          this.selectedRecord = null
          this.$root.$emit('modal', 'delete', false)
          await this.$model.remove(`/env/${this.$environment}/data-export/Export`, record.exportID)
        }
      },
      async enableExport (dataExport) {
        await this.$model.update(`/env/${this.$environment}/data-export/Export`, dataExport.exportID, { $set: {
          state: 'enabled'
        }})
      },
      async disableExport (dataExport) {
        await this.$model.update(`/env/${this.$environment}/data-export/Export`, dataExport.exportID, { $set: {
          state: 'disabled'
        }})
      },
      async executeTask (dataExport) {
        if (dataExport.taskID) {
          await this.$api.dispatch(`/env/${this.$environment}/task-scheduler/Task/execute`, {
            taskID: dataExport.taskID
          })
        }
      },
      async cancelTask (dataExport) {
        const task = this.getTaskFor(dataExport)
        if (!task) {
          return
        }
        this.$api.dispatch(`/env/${this.$environment}/data-export/ExportTask/cancel`, {
          taskID: task.taskID
        })
      },
      getTaskFor (dataExport) {
        return dataExport.taskID ? this.tasks.find(x => x.taskID === dataExport.taskID) : null
      },
      getTaskProgressFor (dataExport) {
        return dataExport.taskID ? this.taskProgress.find(x => x.taskID === dataExport.taskID) : null
      },
      getStatus (dataExport) {
        return get(this.getTaskFor(dataExport), 'state', 'initial')
      },
      getStatusLabel (dataExport) {
        if (dataExport.state === 'disabled') {
          return 'Uitgeschakeld'
        } else {
          const task = this.getTaskFor(dataExport)
          const progress = this.getTaskProgressFor(dataExport)
          const status = get(task, 'state', 'initial')
          let label = statusLabels[status]
          let suffix = ''
          if (status === 'executing' && progress) {
            suffix = ` (${(progress.current / progress.total * 100).toFixed(2).replace('.', ',')}%)`
          } else if (status === 'executed' && task.result && task.result.code !== 0) {
            label = 'Foutmelding'
            const error = task.result.data
            if (error) {
              suffix = ` (${error.message})`
            }
          }
          return `${label}${suffix}`
        }
      },
      getDownloadURL (dataExport) {
        return `${this.$api.host()}/export?module=data-export&exportID=${dataExport.exportID}&token=${this.exportToken}`
      }
    }
  }
</script>
