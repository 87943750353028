<template>
  <section v-if="loading" class="padding">
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h1>Query-imports</h1></template>
          <template slot="content">
            <p v-if="loadingError" class="error">{{ loadingError }}</p>
            <p v-else>Bezig met laden...</p>
          </template>
        </block>
      </div>
    </div>
  </section>
  <section v-else class="padding">
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h1>Query-imports</h1></template>
          <template slot="content">
            <ModelSelect v-model="selectedModel"></ModelSelect>
            <p v-if="selectedModel">
              <router-link :to="{ name: 'data.query-imports.create', query: $route.query }" class="btn">Query-import naar tabel {{ modelLabel }} toevoegen</router-link>
            </p>
          </template>
        </block>
      </div>
    </div>
    <div class="grid">
      <div class="column size--100">
        <block :table="true">
          <template slot="content">
            <table class="table">
              <thead>
                <tr>
                  <th>Naam</th>
                  <th>Omschrijving</th>
                  <th>Planning</th>
                  <th>Status</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="queryImport in displayRecords" :key="queryImport.queryImportID">
                  <td class="icon-inline">
                    <router-link :to="{ name: 'data.query-imports.update', params: { queryImportID: queryImport.queryImportID }, query: $route.query }" title="Query-import wijzigen" class="btn icon">
                      <icon name="pencil" />
                    </router-link>
                    {{ queryImport.name }}
                  </td>
                  <td>{{ queryImport.description }}</td>
                  <td class="icon-inline whitespace--nowrap">
                    <router-link :to="{ name: 'data.query-imports.schedule', params: { queryImportID: queryImport.queryImportID }, query: $route.query }" title="Planning wijzigen" class="btn icon">
                      <icon name="pencil" />
                    </router-link>
                    <ScheduleLabel :schedule="queryImport.schedule"></ScheduleLabel>
                  </td>
                  <td>{{ getStatusLabel(queryImport) }}</td>
                  <td class="icon">
                    <template v-if="queryImport.state !== 'disabled' && getStatus(queryImport) !== 'executing' && getStatus(queryImport) !== 'queued'">
                      <btn @click.prevent="executeTask(queryImport)" icon="play" title="Uitvoeren" class="icon"></btn>
                    </template>
                    <btn v-if="getStatus(queryImport) === 'queued' || getStatus(queryImport) === 'executing'" @click.prevent="cancelTask(queryImport)" icon="stop" title="Annuleren" class="icon"></btn>
                    <router-link :to="{ name: 'data.query-imports.log', params: { queryImportID: queryImport.queryImportID }, query: $route.query }" title="Logboek" class="btn icon">
                      <icon name="eye" />
                    </router-link>
                    <btn v-if="queryImport.state === 'enabled'" @click.prevent="disableRecord(queryImport)" icon="toggle-on" title="Uitschakelen" class="icon"></btn>
                    <btn v-else @click.prevent="enableRecord(queryImport)" icon="toggle-off" title="Inschakelen" class="icon"></btn>
                    <btn icon="trash" title="Verwijderen" class="icon" @click.prevent="removeRecord(queryImport)"></btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </block>
      </div>
    </div>
    <modal name="delete" title="Weet je zeker dat je deze query-import wilt verwijderen?">
      <p>Wil je de query-import "{{ selectedRecord && selectedRecord.name }}" verwijderen?</p>
      <button type="button" @click="removeRecord(selectedRecord, true)">Ja, verwijderen</button>
      <button type="button" @click="$root.$emit('modal', 'delete', false)">Nee, annuleren</button>
    </modal>
  </section>
</template>

<script>
  import get from 'lodash/get'
  import models from '@qiri/models/data'
  import ModelSelect from '../../Partials/ModelSelect'
  import Modal from '@/components/Partials/Modal'
  import ScheduleLabel from '@/modules/task-scheduler/components/Partials/ScheduleLabel'

  const statusLabels = {
    'initial': 'Initieel',
    'idle': 'Actief',
    'queued': 'In wachtrij',
    'executing': 'Bezig met uitvoeren',
    'canceled': 'Geannuleerd',
    'executed': 'Uitgevoerd'
  }

  export default {
    name: 'page-query-import-Overview',
    components: {
      ModelSelect,
      Modal,
      ScheduleLabel
    },
    data () {
      return {
        selectedModel: null,
        selectedRecord: null,
        queryImports: [],
        tasks: [],
        taskProgress: [],
        statusLabels,
        loading: true,
        loadingError : null
      }
    },
    async mounted () {
      this.$model.list(`/env/${this.$environment}/data/QueryImport/list`, 'queryImports')
      this.$model.list(`/env/${this.$environment}/task-scheduler/Task/list`, 'tasks', {module: 'data.query-import'})
      this.$model.list(`/env/${this.$environment}/task-scheduler/Task/progress`, 'taskProgress', {module: 'data.query-import'})

      this.loading = false
    },
    computed: {
      modelLabel () {
        const modelName = this.selectedModel
        if (!modelName) {
          return ''
        }
        const model = models.schema(modelName)
        return model.table
      },
      displayRecords () {
        if (!this.selectedModel) {
          return []
        }
        return this.queryImports
          .filter(queryImport => queryImport.model === this.selectedModel)
          .sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
      }
    },
    methods: {
      async removeRecord (record, confirmed) {
        if (!confirmed) {
          this.selectedRecord = record
          this.$root.$emit('modal', 'delete', true)
        } else {
          this.selectedRecord = null
          this.$root.$emit('modal', 'delete', false)
          await this.$model.remove(`/env/${this.$environment}/data/QueryImport`, record.queryImportID)
        }
      },
      async enableRecord (queryImport) {
        await this.$model.update(`/env/${this.$environment}/data/QueryImport`, queryImport.queryImportID, { $set: {
          state: 'enabled'
        }})
      },
      async disableRecord (queryImport) {
        await this.$model.update(`/env/${this.$environment}/data/QueryImport`, queryImport.queryImportID, { $set: {
          state: 'disabled'
        }})
      },
      async executeTask (queryImport) {
        if (queryImport.taskID) {
          await this.$api.dispatch(`/env/${this.$environment}/task-scheduler/Task/execute`, {
            taskID: queryImport.taskID
          })
        }
      },
      async cancelTask (queryImport) {
        const task = this.getTaskFor(queryImport)
        if (!task) {
          return
        }
        this.$api.dispatch(`/env/${this.$environment}/data/QueryImportTask/cancel`, {
          taskID: task.taskID
        })
      },
      getTaskFor (queryImport) {
        return queryImport.taskID ? this.tasks.find(x => x.taskID === queryImport.taskID) : null
      },
      getTaskProgressFor (queryImport) {
        return queryImport.taskID ? this.taskProgress.find(x => x.taskID === queryImport.taskID) : null
      },
      getStatus (queryImport) {
        return get(this.getTaskFor(queryImport), 'state', 'initial')
      },
      getStatusLabel (queryImport) {
        if (queryImport.state === 'disabled') {
          return 'Uitgeschakeld'
        } else {
          const task = this.getTaskFor(queryImport)
          const progress = this.getTaskProgressFor(queryImport)
          const status = get(task, 'state', 'initial')
          let label = statusLabels[status]
          let suffix = ''
          if (status === 'executing' && progress) {
            suffix = ` (${(progress.current / progress.total * 100).toFixed(2).replace('.', ',')}%)`
          } else if (status === 'executed' && task.result && task.result.code !== 0) {
            label = 'Foutmelding'
            const error = task.result.data
            if (error) {
              suffix = ` (${error.message})`
            }
          }
          return `${label}${suffix}`
        }
      }
    }
  }
</script>
