import SimpleSchema from '@qiri/simpl-schema'
import ProductModel from '../data/Product'
import {renderProductName} from './util'

export const Configuration = new SimpleSchema({

})

export const Output = ({model} = {}) => new SimpleSchema({
  'product': {
    type: model ? model('Product').type : ProductModel,
    label: 'Product'
  }
})

export const Preview = ({ customFields }) => new SimpleSchema({
  'name': {
    type: String,
    label: 'Productnaam',
    render: renderProductName
  },
  ...customFields
    .filter(customField => customField.model === 'Product' && customField.type === 'foreignKey')
    .reduce(
      (acc, cur) => {
        acc[cur.name] = {
          type: String,
          label: cur.name,
          render (record) {
            return record.product[cur.name]
          }
        }
        return acc
      },
      {}
    )
})

export const Request = new SimpleSchema({
  'product': {
    type: String,
    label: 'Product',
    description: 'Het product om op te zoeken'
  },
  'date': {
    type: Date,
    label: 'Datum',
    description: 'De datum waarvoor de voorspelling gemaakt moet worden. Indien leeg wordt ‘vandaag’ gebruikt.',
    optional: true
  },
  'includeProductPromotions': {
    type: Boolean,
    label: 'Productpromoties opzoeken',
    description: 'Indien het product in een productpromotie gevonden wordt, deze ook teruggeven in het resultaat?',
    optional: true,
    options: {
      'Ja': 'true',
      'Nee': 'false'
    }
  },
  'limit': {
    type: SimpleSchema.Integer,
    label: 'Limiet',
    description: 'Maximaal aantal resultaten',
    min: 1,
    defaultValue: 20
  }
})

export const RequestConfiguration = new SimpleSchema({

})
