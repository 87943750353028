import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  'interval': {
    type: String,
    label: 'Interval',
    description: 'Interval van de vergoeding'
  },
  'unit': {
    type: String,
    label: 'Eenheid',
    description: 'De eenheid die bepalend is voor het berekenen van de "aantallen" per interval'
  },
  'price': {
    type: SimpleSchema.Integer,
    label: 'Prijs',
    description: 'De prijs per interval en eenheid',
    currency: true
  },
  'factor': {
    type: SimpleSchema.Integer,
    label: 'Factor',
    description: 'Aantal decimalen dat ingevoerd kan worden met de prijs'
  }
})
