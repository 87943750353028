<template>
    <section v-if="loading" class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>{{ title }}</h1></template>
                    <template slot="content">
                        <p v-if="loading">Bezig met laden...</p>
                    </template>
                </block>
            </div>
        </div>
    </section>
    <vue-form v-else tag="section" :state="form" ref="form" :schema="schema" v-model="remoteServer" class="padding" @submit="doSubmit()">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>{{ title }}</h1></template>
                    <template slot="content">
                        <vue-form-field field="name"></vue-form-field>
                        <vue-form-field field="description"></vue-form-field>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h2>Stel de externe server in</h2></template>
                    <template slot="content">
                        <div class="grid">
                            <div class="column size--100">
                                <vue-form-field field="protocol.type" :options="protocolOptions"></vue-form-field>
                                <component :is="protocolForm"></component>
                            </div>
                        </div>
                        <div class="grid" v-if="false">
                            <div class="column size--100">
                                <button type="button" class="full" :disabled="!canCheckServer || checkingServer" @click.prevent="checkServer()">
                                    <template v-if="checkingServer"><icon name="refresh" :spin="true" /></template>
                                    <template v-else>Verbinding controleren</template>
                                </button>
                            </div>
                        </div>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="content">
                        <button type="button" class="full" title="Opslaan" :disabled="saving" @click.prevent="$refs.form.submit()">
                            <template v-if="saving"><icon name="refresh" :spin="true" /></template>
                            <template v-else>{{ title }}</template>
                        </button>
                        <ul class="errors" v-if="formErrors.length > 0 || submitErrors.length > 0">
                            <li v-for="error of formErrors">
                                <icon name="exclamation-circle"></icon> <vue-markdown :source="error"></vue-markdown>
                            </li>
                            <li v-for="error of submitErrors">
                                <icon name="exclamation-circle"></icon> <vue-markdown :source="error"></vue-markdown>
                            </li>
                        </ul>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid" v-if="false">
            <div class="column size--100">
                <block>
                    <template slot="content">
                        <pre>{{ debugJSON }}</pre>
                    </template>
                </block>
            </div>
        </div>
    </vue-form>
</template>

<script>
    import uuidv4 from 'uuid/v4'
    import omit from 'lodash/omit'
    import VueMarkdown from '@qiri/vue-markdown'
    import SimpleSchema from '@qiri/simpl-schema'
    import RemoteServer from '@qiri/models/data/RemoteServer'
    import * as protocols from './protocols'

    export default {
        name: 'page-remoteservers-Add',
        components: {
            VueMarkdown
        },
        data () {
            return {
                form: {},
                remoteServer: {},
                loading: true,
                saving: false,
                submitErrors: [],
                checkingServer: false
            }
        },
        async mounted () {
            // Load the user, if any.
            if (this.formMode === 'edit') {
                if (!this.remoteServerID) {
                    throw new Error(`Missing property "remoteServerID".`)
                }

                this.remoteServer = await this.$model.get(`/env/${this.$environment}/data/RemoteServer`, this.remoteServerID)

                if (!this.remoteServer) {
                    throw new Error(`Remote server "${this.remoteServerID}" not found.`)
                }
            } else {
                this.remoteServer = this.schema.clean({
                    remoteServerID: uuidv4()
                })
            }

            this.loading = false
        },
        computed: {
            schema () {
                return RemoteServer
            },
            remoteServerID () {
                return this.$route.params.remoteServerID
            },
            protocolOptions () {
                // TODO: Get from metadata schema.
                return {
                    'HTTP': 'HTTP(S)',
                    'FTP': 'FTP(S)',
                    'SFTP': 'SFTP'
                }
            },
            protocolForm () {
                const protocolType = this.remoteServer && this.remoteServer.protocol && this.remoteServer.protocol.type
                if (!protocolType) {
                    return null
                }
                return protocols[protocolType]
            },
            canCheckServer () {
                return false
            },
            title () {
                return this.$route.meta.title
            },
            formMode () {
                return this.$route.meta.form.mode
            },
            formAction () {
                return this.$route.meta.form.action
            },
            formErrors () {
                let messages = []
                if (this.form.errors && this.form.errors.length > 0) {
                    for (const error of this.form.errors) {
                        let message = `${error.message}.`
                        // Don't show duplicate messages.
                        const duplicateMessage = messages.find(x => x === message)
                        if (!duplicateMessage) {
                            messages.push(message)
                        }
                    }
                }
                return messages
            },
            debugJSON () {
                return JSON.stringify(this.remoteServer, null, '  ')
            }
        },
        methods: {
            async checkServer () {

            },
            async doSubmit () {
                this.saving = true
                this.submitErrors = []
                try {
                    const remoteServer = omit(this.schema.clean(this.remoteServer), 'remoteServerID')
                    if (this.formMode === 'create') {
                        await this.$model.create(`/env/${this.$environment}/data/RemoteServer`, this.remoteServer.remoteServerID, remoteServer)
                        this.$router.replace({ name: this.formAction, query: this.$route.query })
                    } else {
                        await this.$model.update(`/env/${this.$environment}/data/RemoteServer`, this.remoteServer.remoteServerID, { $set: remoteServer })
                        this.$router.push({ name: this.formAction, query: this.$route.query })
                    }
                } catch (err) {
                    if (err.error === 'validation-error') {
                        this.submitErrors = err.details.map(error => error.message)
                    } else {
                        this.submitErrors = [err.message]
                    }
                    this.saving = false
                }
            }
        }
    }
</script>
