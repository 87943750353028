import SimpleSchema from '@qiri/simpl-schema'
import Pipeline from './Pipeline'

/**
 * @todo
 */
export default new SimpleSchema({
  'pipeline': {
    type: Pipeline
  },
  'targetField': {
    type: String,
    label: 'Toewijzen aan'
  }
})
