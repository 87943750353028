import Stream from '../Stream'
import {isIterable} from '../util'

export const iterate = (iterable) => (start, sink) => {
  if (start !== 0 || !isIterable(iterable)) {
    return
  }
  if (Stream.source in iterable || typeof iterable === 'function') {
    // Normal source, so just pass along the sink.
    return iterable(start, sink)
  }

  const iterator = iterable[Symbol.iterator]()
  let inLoop = false
  let got1 = false
  let completed = false
  let res
  const loop = () => {
    inLoop = true
    while (got1 && !completed) {
      got1 = false
      res = iterator.next()
      if (res.done) {
        completed = true
        sink(2)
        break
      } else {
        sink(1, res.value)
      }
    }
    inLoop = false
  }
  sink(0, (t, d) => {
    if (completed) {
      return
    } else if (t === 1) {
      got1 = true
      if (!inLoop && !(res && res.done)) {
        loop()
      }
    } else if (t === 2) {
      if (iterator.return && !d) {
        iterator.return(d)
      } else if (iterator.throw && d) {
        iterator.throw(d)
      }
      completed = true
    }
  })
}
