<template>
    <vue-nested-form :schema="schema" field="format.settings">
        <vue-form-field field="path"></vue-form-field>
    </vue-nested-form>
</template>

<script>
    import _JSON from '@qiri/models/formats/JSON'

    export default {
        name: 'page-feeds-format-JSON',
        computed: {
            schema () {
                return _JSON
            }
        }
    }
</script>
