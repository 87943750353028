import SimpleSchema from '@qiri/simpl-schema'
import Schedule from '../task-scheduler/Schedule'

/**
 * @todo
 */
export default new SimpleSchema({
  'queryImportID': {
    type: String,
    primaryKey: true
  },
  'name': {
    type: String,
    label: 'Naam'
  },
  'description': {
    type: String,
    multiline: true,
    label: 'Omschrijving',
    optional: true
  },
  'schedule': {
    type: Schedule,
    label: 'Planning',
    optional: true
  },
  'state': {
    type: String,
    label: 'Staat',
    defaultValue: 'enabled',
    allowedValues: [
      'enabled',
      'disabled'
    ]
  },
  'taskID': {
    type: String,
    label: 'Taak ID',
    optional: true
  },
  'model': {
    type: String,
    label: 'Model'
  },
  'query': {
    type: String,
    label: 'Query'
  },
  // Import rules
  'importMode': {
    type: String,
    label: 'Welke regels verwerken',
    defaultValue: 'all',
    allowedValues: [
      'all',
      'update-only',
      'insert-only'
    ]
  },
  'duplicationMode': {
    type: String,
    label: 'Als er aan meerdere regels gekoppeld kan worden',
    optional: true,
    allowedValues: [
      'update-first',
      'update-all'
    ]
  }
})
