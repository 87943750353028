import get from 'lodash/get'
import SimpleSchema from '@qiri/simpl-schema'

import {VueFormContext, VueFormGroup, VueFormField} from '../providers'
import {randomID, vueSet, propertyToPath} from '../util'

import VueFormField_String from './fields/String'
import VueFormField_Password from './fields/Password'
import VueFormField_Text from './fields/Text'
import VueFormField_Integer from './fields/Integer'
import VueFormField_Currency from './fields/Currency'
import VueFormField_Date from './fields/Date'
import VueFormField_Checkbox from './fields/Checkbox'
import VueFormField_Radio from './fields/Radio'
import VueFormField_Select from './fields/Select'
import VueFormField_File from './fields/File'

const FIELD_TYPES = {
  'string': VueFormField_String,
  'password': VueFormField_Password,
  'text': VueFormField_Text,
  'integer': VueFormField_Integer,
  'currency': VueFormField_Currency,
  'date': VueFormField_Date,
  'checkbox': VueFormField_Checkbox,
  'radio': VueFormField_Radio,
  'select': VueFormField_Select,
  'file': VueFormField_File
}

export default (Vue) => ({
  name: 'VueFormField',
  provide() {
    return {
      [VueFormField]: this
    }
  },
  inject: {
    form: {
      from: VueFormContext
    },
    formGroup: {
      from: VueFormGroup,
      default: false
    },
    parentField: {
      from: VueFormField,
      default: null
    }
  },
  props: {
    type: String,
    field: String,
    fieldSchema: Object,
    group: String,
    value: {},
    options: [Array, Object],
    option: {},
    nullLabel: String,
    label: {
      type: [Boolean, String],
      default () {
        const { label = true } = this.form.defaults
        return label
      }
    },
    bare: {
      type: Boolean,
      default () {
        const { bare = false } = this.form.defaults
        return bare
      }
    },
    inline: {
      type: Boolean,
      default () {
        const { inline = false } = this.form.defaults
        return inline
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    column: {
      type: [Number, String],
      default: 100
    },
    time: {
      type: Boolean,
      default: false
    },
    factor: {
      type: Number,
      default: 100
    }
  },
  data () {
    return {
      fieldID: `${this.field}-${randomID()}`.replace(/\./g, '-')
    }
  },
  render (h) {
    if (!this.form.schema) {
      throw new Error(`Form has no schema.`)
    }

    // Determine the schema of the field.
    let field
    if (this.fieldSchema) {
      field = this.fieldSchema
    } else {
      field = this.form.schema.schema(propertyToPath(this.field))
      if (!field) {
        throw new Error(`Field "${this.field}" not found in schema.`)
      }
    }
    const fieldType = field.type.singleType

    // Determine if we read the value from the form or from our own props.
    if (this.value === undefined) {
      this.valueSource = 'form'
    } else {
      this.valueSource = 'props'
    }

    // Determine which (form) component to render.
    let componentType
    let props = {}
    let labelPosition = 'before'
    if (this.type) {
      componentType = this.type
    } else if (this.options) {
      componentType = 'select'
      props.options = this.options
    } else if (field.options || field.type.definitions[0].allowedValues) {
      componentType = 'select'
      props.options = optionsFromField(field)
    } else if (fieldType === Number || fieldType === SimpleSchema.Integer) {
      componentType = field.currency ? 'currency' : 'integer'
      props.factor = this.factor
    } else if (fieldType === Date) {
      componentType = 'date'
      props.time = this.time
    } else if (fieldType === Boolean) {
      componentType = 'checkbox'
    } else if (fieldType === String && field.encrypted) {
      componentType = 'password'
    } else {
      componentType = field.multiline ? 'text' : 'string'
    }
    if (componentType === 'checkbox' || componentType === 'radio') {
      labelPosition = 'after'
      props.option = this.option
      props.group = this.group
    } else if (componentType === 'select') {
      props.nullLabel = this.nullLabel
    }
    this.fieldType = componentType

    let component = FIELD_TYPES[componentType]
    if (!component) {
      // Find as an component in Vue.
      component = Vue.component(`vue-form-field-${componentType}`)
      if (!component) {
        throw new Error(`Unknown field type "${componentType}".`)
      }
    }

    // Create the label (text).
    let label
    if (this.$slots.default) {
      label = h('label', { attrs: { for: this.fieldID } }, this.$slots.default)
    } else {
      label = []
      if (this.$slots.label) {
        label.push(this.$slots.label)
      } else if (this.label) {
        label.push(typeof this.label === 'string' ? this.label : field.label)
      }
      if (labelPosition === 'before') {
        label.push(':')
      }
      if (this.error) {
        label.push(' ')
        label.push(h('i', {
          staticClass: 'fa fa-exclamation-circle',
          attrs: {
            title: this.error
          }
        }))
      }
      label = h('label', { attrs: { for: this.fieldID } }, label)
    }

    // Create the input component.
    const input = h(component, {
      attrs: {
        placeholder: field.description || field.label
      },
      props: {
        field: this.field,
        fieldID: this.fieldID,
        bare: this.bare,
        inline: this.inline,
        disabled: this.disabled,
        value: this.valueSource === 'form'
          ? this.form.value ? get(this.form.value, this.field) : null
          : this.value,
        ...props
      },
      on: {
        input: (value) => this.onChange(value),
        files: (files) => this.$emit('files', files)
      }
    })

    if (this.bare) {
      return input
    } else if (this.formGroup) {
      return h(
        'div',
        {
          staticClass: `column size--${this.column} ${this.error ? 'form-invalid--border' : ''}`
        },
        labelPosition === 'before' ? [ label, input ] : [ input, label ]
      )
    } else {
      return h(
        'div', {
          staticClass: 'grid form-group'
        },
        [
          h(
            'div',
            {
              staticClass: `column size--100 ${this.error ? 'form-invalid--border' : ''}`
            },
            labelPosition === 'before' ? [ label, input ] : [ input, label ]
          )
        ]
      )
    }
  },
  computed: {
    error () {
      if (this.valueSource === 'form') {
        const field = this.field
        const errors = (this.form.state.errors || []).filter(error => error.name === field)
        return errors.length > 0 ? errors[0].message : null
      } else {
        return null
      }
    }
  },
  methods: {
    onChange (value) {
      if (this.valueSource === 'form') {
        this.form.markFieldDirty(this.field)
        vueSet(this, this.form.value, this.field, value)
      } else {
        this.$emit('input', value)
      }

      // Special case: parent radio button, make sure it's active.
      if (this.parentField && this.parentField.fieldType === 'radio') {
        //this.parentField.onChange(this.parentField.option)
        const el = document.querySelector('#' + this.parentField.fieldID)
        if (el) {
          let evt
          if (document.createEvent) {
            evt = document.createEvent('MouseEvents');
            evt.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
          }
          if (evt) {
            el.dispatchEvent(evt)
          } else if (el.click) {
            el.click()
          }
        }
      }
    }
  }
})

/**
 * @private
 */
function optionsFromField (field) {
  const options = field.options || field.type.definitions[0].allowedValues
  if (Array.isArray(options)) {
    return options.map(value => [value, value])
  } else {
    // TODO: Reverse in options field instead.
    //       Key as label makes more sense (it's called key/value anyway).
    return Object.entries(options).map(([label, value]) => [value, label])
  }
}
