<template>
    <vue-nested-form :schema="schema" field="format.settings">

    </vue-nested-form>
</template>

<script>
    import NDJSON from '@qiri/models/formats/NDJSON'

    export default {
        name: 'page-feeds-format-NDJSON',
        computed: {
            schema () {
                return NDJSON
            }
        }
    }
</script>
