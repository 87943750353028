<template>
    <div :class="{'clock': true, 'warning-mode': this.warningMode && this.mode === 'timeLeft'}">
        <template v-if="mode === 'clock'">
          <icon name="clock-o" @click="toggleMode()"></icon>
          <span>{{ clock.hours[0] ? clock.hours[0] : '&nbsp;' }}</span><span>{{ clock.hours[1] }}</span> : <span>{{ clock.minutes[0] ? clock.minutes[0] : '&nbsp;' }}</span><span>{{ clock.minutes[1] }}</span> : <span>{{ clock.seconds[0] ? clock.seconds[0] : '&nbsp;' }}</span><span>{{ clock.seconds[1] }}</span>
        </template>
        <template v-if="mode === 'timeLeft'">
          <icon name="lock" @click="toggleMode()"></icon>
          <span>{{ timeLeft.hours[0] ? timeLeft.hours[0] : '&nbsp;' }}</span><span>{{ timeLeft.hours[1] }}</span> : <span>{{ timeLeft.minutes[0] ? timeLeft.minutes[0] : '&nbsp;' }}</span><span>{{ timeLeft.minutes[1] }}</span> : <span>{{ timeLeft.seconds[0] ? timeLeft.seconds[0] : '&nbsp;' }}</span><span>{{ timeLeft.seconds[1] }}</span>
        </template>
    </div>
</template>

<script>
    export default {
        name: 'clock',
        data: () => ({
            mode: 'clock',
            warningMode: false,
            clock: {
                hours: '00',
                minutes: '00',
                seconds: '00'
            },
            timeLeft: {
                hours: '00',
                minutes: '00',
                seconds: '00'
            }
        }),
        mounted () {
            this.updateDateTime()

            const now = Date.now()
            const delta = (Math.ceil(now / 1000) * 1000) - now
            setTimeout(this.updateDateTime, delta)

            this.$watch(() => this.$security.expiryTimeLeft, expiryTimeLeft => this.updateTimeLeft(expiryTimeLeft), {immediate: true})

            // Register event
            this.$root.$on('security.tokenExpiring', () => {
                this.mode = 'timeLeft'
            })
        },
        methods: {
            toggleMode () {
                this.mode = this.mode === 'clock' ? 'timeLeft' : 'clock'
            },
            updateDateTime () {
                let now = new Date()
                this.clock.hours = ('0' + now.getHours()).slice(-2).split('')
                this.clock.minutes = ('0' + now.getMinutes()).slice(-2).split('')
                this.clock.seconds = ('0' + now.getSeconds()).slice(-2).split('')

                const delta = (Math.ceil(now.getTime() / 1000) * 1000) - now.getTime()
                setTimeout(this.updateDateTime, delta)
            },
            updateTimeLeft (timeLeft) {
                timeLeft = Math.max(timeLeft, 0)
                const seconds = timeLeft % 60
                const minutes = Math.floor(timeLeft / 60) % 60
                const hours = Math.floor(timeLeft / 3600)

                this.timeLeft.hours = ('0' + hours).slice(-2).split('')
                this.timeLeft.minutes = ('0' + minutes).slice(-2).split('')
                this.timeLeft.seconds = ('0' + seconds).slice(-2).split('')

                if (timeLeft <= 600) {
                    this.warningMode = true
                } else {
                    this.warningMode = false
                }
            }
        }
    }
</script>
