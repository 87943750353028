import CompanyOverview from '@/components/Pages/companies/Overview'
import CompanyForm from '@/components/Pages/companies/Form'
import UserOverview from '@/components/Pages/users/Overview'
import UserForm from '@/components/Pages/users/Form'
import {Owner} from '@qiri/models/environment'

const CompanyMeta = {
    type: 'owner',
    model: Owner,
    actorPrefix: '/owner'
}

export default {
    'owner.overview': {
        path: '/owner/overview',
        component: CompanyOverview,
        meta: {
            audience: 'actor:/owner/Company',
            title: 'Eigenaar overzicht',
            company: CompanyMeta
        }
    },
    'owner.add': {
        path: '/owner/add',
        component: CompanyForm,
        meta: {
            audience: 'actor:/owner/Company',
            title: 'Eigenaar toevoegen',
            company: CompanyMeta,
            form: {
                mode: 'create',
                action: 'owner.overview'
            }
        }
    },
    'owner.edit': {
        path: '/owner/:companyID/edit',
        component: CompanyForm,
        meta: {
            audience: 'actor:/owner/Company',
            title: 'Eigenaar bewerken',
            company: CompanyMeta,
            form: {
                mode: 'edit',
                action: 'owner.overview'
            }
        }
    },
    'owner.users.overview': {
        path: '/owner/:companyID/users',
        component: UserOverview,
        meta: {
            audience: 'actor:/owner/User',
            title: '(Super-)administrators overzicht',
            company: CompanyMeta
        }
    },
    'owner.users.add': {
        path: '/owner/:companyID/users/add',
        component: UserForm,
        meta: {
            audience: 'actor:/owner/User',
            title: '(Super-)administrator toevoegen',
            company: CompanyMeta,
            form: {
                mode: 'create',
                action: 'owner.users.overview'
            }
        }
    },
    'owner.users.edit': {
        path: '/owner/:companyID/users/:userID/edit',
        component: UserForm,
        meta: {
            audience: 'actor:/owner/User',
            title: '(Super-)administrator bewerken',
            company: CompanyMeta,
            form: {
                mode: 'edit',
                action: 'owner.users.overview'
            }
        }
    }
}
