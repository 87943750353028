import SimpleSchema from '@qiri/simpl-schema'
import ProductModel from '../data/Product'
import OrderModel from '../data/Order'
import OrderLineModel from '../data/OrderLine'
import OrderBy from './OrderBy'
import {applicableIf} from '../util'
import {renderProductName} from './util'

export const Configuration = new SimpleSchema({
  'factorThreshold': {
    type: SimpleSchema.Integer,
    label: 'Minimaal percentage kopers',
    description: 'Het minimaal percentage personen die meer dan twee orders met het desbetreffende product hebben (bijvoorbeeld 10%). Dit is de ondergrens om een product als typisch herhalingsaankoopproduct aan te merken.',
    min: 0,
    max: 100,
    defaultValue: 10
  },
  'intervalFormula': {
    type: String,
    label: 'Formule',
    description: 'De formule waarmee het globale tijdsinterval berekend wordt. Meestal wordt gekozen voor de mediaan minus de standaard deviatie.',
    defaultValue: 'AVERAGE-DEVIATION',
    allowedValues: {
      'Gemiddelde': 'AVERAGE',
      'Gemiddelde - Standaarddeviatie * deviatiefactor': 'AVERAGE-DEVIATION',
      'Mediaan': 'MEDIAN',
      'Mediaan - Standaarddeviatie * deviatiefactor': 'MEDIAN-DEVIATION'
    }
  },
  'deviationFactor': {
    type: Number,
    label: 'Deviatiefactor',
    description: 'Is onderdeel van de formule. Met deze factor (standaard 1) wordt de standaarddeviatie vermenigvuldigd. Het resultaat wordt opgeteld bij het gemiddelde of de mediaan.',
    min: 0,
    defaultValue: 1,
    render (value, record) {
      // TODO: Don't "abuse" render for this, but implement "applicable" below.
      return record && ['AVERAGE-DEVIATION', 'MEDIAN-DEVIATION'].indexOf(record.intervalFormula) !== -1
    }
    //applicable: applicableIf('intervalFormula', v => ['AVERAGE-DEVIATION', 'MEDIAN-DEVIATION'].indexOf(v) !== -1)
  }
})

export const Input = {
  Order: ['personID', 'timestamp'],
  OrderLine: ['orderID', 'productID', 'quantity']
}

export const Output = ({model} = {}) => new SimpleSchema({
  'product': {
    type: model ? model('Product').type : ProductModel,
    label: 'Product',
    table: 'Producten'
  },
  'details': {
    type: Object
  },
  'details.intervalType': {
    type: String
  },
  'details.interval': {
    type: Number,
    label: 'Tijdsinterval',
    index: true
  }
})

export const Preview = new SimpleSchema({
  'name': {
    type: String,
    label: 'Productnaam',
    render: renderProductName
  },
  'interval': {
    type: String,
    label: 'Globaal tijdsinterval (dagen)',
    render (record) {
      return record.details.interval
    }
  }
})

export const Request = new SimpleSchema({
  'product': {
    type: String,
    label: 'Product',
    description: 'Het product op basis waarvan suggesties worden opgevraagd. Alleen voor dit product worden de gegevens opgevraagd.',
    optional: true
  },
  'date': {
    type: Date,
    label: 'Datum',
    description: 'De datum waarvoor de voorspelling gemaakt moet worden. Indien leeg wordt ‘vandaag’ gebruikt.',
    optional: true
  },
  'includeProductPromotions': {
    type: Boolean,
    label: 'Productpromoties opzoeken',
    description: 'Indien het product in een productpromotie gevonden wordt, deze ook teruggeven in het resultaat?',
    optional: true,
    options: {
      'Ja': 'true',
      'Nee': 'false'
    }
  },
  'filter': {
    type: Array,
    label: 'Filters',
    optional: true
  },
  'filter.$': {
    type: String
  },
  'limit': {
    type: SimpleSchema.Integer,
    label: 'Limiet',
    description: 'Maximaal aantal suggesties dat wordt getoond.',
    min: 1,
    defaultValue: 20
  }/*,
  'orderBy': OrderBy.arraySchema({
    defaultValue: [{ field: 'product.name', order: 'asc' }]
  }),
  'orderBy.$': OrderBy.elementSchema({
    indexFields: Output
  })*/
})

export const RequestConfiguration = new SimpleSchema({
  'maxLimit': {
    type: SimpleSchema.Integer,
    label: 'Limiet',
    description: 'Maximaal aantal suggesties in een enkele API-response',
    min: 1,
    defaultValue: 50
  }
})
