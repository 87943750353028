import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  period: {
    type: SimpleSchema.Integer,
    label: 'Periode in dagen'
  },
  quantity: {
    type: SimpleSchema.Integer,
    label: 'Aantal'
  },
  timestamp: {
    type: Date,
    label: 'Datum & tijd'
  }
})
