import SimpleSchema from '@qiri/simpl-schema'
import Company from './Company'
import EnvironmentSettings from './EnvironmentSettings'

const Customer = new SimpleSchema({
  'invoicingEmailAddress': {
    type: String,
    label: 'E-mailadres voor facturatie',
    description: 'Het e-mailadres van het bedrijf dat gebruikt wordt voor de facturatie',
    regEx: SimpleSchema.RegEx.EmailWithTLD
  },
  'expiryDate': {
    type: Date,
    label: 'Verloopt op datum',
    description: 'Nu oneindig. Indien ingevuld, de datum waarop toegang tot Qiri verloopt.',
    optional: true
  }
})
Customer.extend(Company)
Customer.extend(EnvironmentSettings)

export default Customer
