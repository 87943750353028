<template>
  <section v-if="loading" class="padding">
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h1>{{ title }}</h1></template>
          <template slot="content">
            <p v-if="loading">Bezig met laden...</p>
          </template>
        </block>
      </div>
    </div>
  </section>
  <vue-form v-else tag="section" :state="form" ref="form" :schema="schema" v-model="feed" class="padding" @submit="saveFeed()">
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h1>{{ title }}</h1></template>
          <template slot="content">
            <vue-form-group>
              <vue-form-field column="50" field="name"></vue-form-field>
              <vue-form-field column="50" field="description"></vue-form-field>
            </vue-form-group>
          </template>
        </block>
      </div>
    </div>
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h2>Stel de data-import in</h2></template>
          <template slot="content">
            <div class="grid">
              <div class="column size--50">
                <vue-form-field v-model="protocolType" field="protocol.type" :options="protocolOptions"></vue-form-field>
                <component :is="protocolForm"></component>
                <vue-form-field v-if="protocolHasWildcard" field="protocol.multiMode" :options="multiModeOptions"></vue-form-field>
                <vue-form-field field="protocol.ignoreAlreadyProcessed"></vue-form-field>
              </div>
              <div class="column size--50">
                <vue-form-field field="format.encoding" :options="encodingOptions"></vue-form-field>
                <vue-form-field field="format.type" :options="formatOptions"></vue-form-field>
                <component :is="formatForm"></component>
              </div>
            </div>
            <div class="grid">
              <div class="column size--100">
                <button type="button" class="full" :disabled="!canFetchFeed || sourceFieldsFetching" @click.prevent="fetchFeed()">
                  <template v-if="sourceFieldsFetching"><icon name="refresh" :spin="true" /></template>
                  <template v-else>Bronbestand ophalen</template>
                </button>
              </div>
            </div>
          </template>
        </block>
      </div>
    </div>
    <div class="grid">
      <div class="column size--100">
        <block :table="sourceFields.length > 0 && !sourceFieldsFetching && !sourceFieldsError">
          <template slot="title"><h2>Toewijzen van velden uit bronbestand aan Qiri-velden in {{ getModelTable(this.feed.model) }}</h2></template>
          <template slot="content">
            <p v-if="sourceFieldsError" class="error">
              Sorry, maar er is iets fout gegaan. Dit is de foutmelding die ik ontvangen heb:<br />
              <em>{{ sourceFieldsError }}</em>
            </p>
            <p v-else-if="sourceFieldsFetching"><icon name="refresh" :spin="true" /></p>
            <p v-else-if="sourceFields.length === 0">
              Je moet eerst het bronbestand instellen en ophalen voordat je velden kunt toewijzen.
            </p>
            <table class="table" v-else>
              <thead>
                <tr>
                  <th>Qiri tabel</th>
                  <th>Qiri-veld naam</th>
                  <th>Qiri-veld omschrijving</th>
                  <th>Qiri-veld type</th>
                  <th>Veld uit bronbestand</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="targetField in targetFields" :key="targetField.name" :class="getFieldClass(targetField)">
                  <td>{{ getModelTable(targetField.model) }}</td>
                  <td>{{ targetField.name }}</td>
                  <td>{{ targetField.description }}</td>
                  <td :title="getFieldTypeHelpTitle(targetField)" :style="getFieldTypeHelpStyle(targetField)">{{ getFieldTypeLabel(targetField) }}</td>
                  <td>
                    <vue-form-field field="mappings.$.source" v-model="getMappingFor(targetField).source" :options="sourceFields" :bare="true"></vue-form-field>
                  </td>
                  <td class="icon">
                    <btn icon="pencil" title="Correctieregels" class="icon" @click.prevent="openCorrectionModal(getMappingFor(targetField))"></btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </block>
      </div>
    </div>
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h2>Verwerking van regels uit bronbestand</h2></template>
          <template slot="content">
            <vue-form-field v-if="hasForeignKeysMappedForCurrentModel" field="importMode" :options="importModeOptions">
              Regels met lege waarden in {{ foreignKeySourceLabel }} zullen worden genegeerd. Wat wil je met de overige regels uit het bronbestand doen?
            </vue-form-field>
            <p v-else class="warnings">
              <icon name="exclamation-triangle"></icon> {{ noMappedForeignKeysForCurrentModelLabel }}
            </p>
            <vue-form-field v-if="feed.model === 'Product'" field="actionWhenMissing" :options="actionWhenMissingOptions">
              Wat wil je doen met producten die al eerder in Qiri geplaatst zijn, maar nu niet (meer) voorkomen in het te importeren bronbestand?
            </vue-form-field>
            <vue-form-field field="mergeMode" :options="mergeModeOptions">
              Wat wil je doen met Qiri-velden die meerdere waardes kunnen bevatten?
            </vue-form-field>
            <vue-form-field field="importCountWarningThreshold"></vue-form-field>
          </template>
        </block>
      </div>
    </div>
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h2>Bewaartermijn van bronbestanden</h2></template>
          <template slot="content">
            <div class="grid">
              <div class="column size--50">
                <vue-form-field field="retention.keepForDays"></vue-form-field>
              </div>
              <div class="column size--50">
                <vue-form-field field="retention.deleteFromRemote" :options="deleteFromRemoteOptions">
                  Na verwerking van het bronbestand:
                </vue-form-field>
              </div>
            </div>
          </template>
        </block>
      </div>
    </div>
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="content">
            <button type="button" class="full" title="Opslaan" :disabled="saving" @click.prevent="$refs.form.submit()">
              <template v-if="saving"><icon name="refresh" :spin="true" /></template>
              <template v-else>{{ title }}</template>
            </button>
            <ul class="errors" v-if="formErrors.length > 0">
              <li v-for="error of formErrors">
                <icon name="exclamation-circle"></icon> {{ error }}
              </li>
            </ul>
          </template>
        </block>
      </div>
    </div>
    <div class="grid" v-if="mappingWarnings.length > 0">
      <div class="column size--100">
        <block>
          <template slot="content">
            <ul class="warnings">
              <li v-for="warning of mappingWarnings">
                <icon name="exclamation-triangle"></icon> {{ warning }}
              </li>
            </ul>
          </template>
        </block>
      </div>
    </div>
    <div class="grid" v-if="false">
      <div class="column size--100">
        <block>
          <template slot="content">
            <pre>{{ formJSON }}</pre>
            <pre>{{ feedJSON }}</pre>
          </template>
        </block>
      </div>
    </div>
    <modal name="correction" title="Correctie regel" @close="cancelCorrectionModal">
      <vue-form-field field="mappings.$.correctionTemplate" v-model="selectedMapping.correctionTemplate" :key="selectedMapping.source"></vue-form-field>
      <button type="button" @click.prevent="submitCorrectionModal()">Opslaan</button>
      <button type="button" @click.prevent="$root.$emit('modal', 'correction', false)">Annuleren</button>
    </modal>
    <modal name="confirmation" title="Weet u het zeker?">
      <ul class="warnings">
        <li v-for="warning of formWarnings">
          <icon name="exclamation-triangle"></icon> {{ warning }}
        </li>
      </ul>
      <button type="button" @click.prevent="answerConfirmationModal(true)">Ja</button>
      <button type="button" @click.prevent="answerConfirmationModal(false)">Nee</button>
    </modal>
  </vue-form>
</template>

<script>
  import uuidv4 from 'uuid/v4'
  import omit from 'lodash/omit'
  import last from 'lodash/last'
  import slugify from 'slugify'
  import SimpleSchema from '@qiri/simpl-schema'
  import {propertyToType, getFullLabel, mergeLabelAndDescription} from '@qiri/models/util'
  import models, {Feed} from '@qiri/models/data'
  import {getRelatedModels} from '@qiri/models/data/util'
  import {FieldTypes} from '@qiri/models/data/Field'
  import {FeedValidator} from '@qiri/models/data/Feed'
  import encodings from '@qiri/models/formats/encodings'
  import Modal from '@/components/Partials/Modal'

  import {pull} from '@qiri/stream/pull'
  import {map} from '@qiri/stream/operators/map'
  import {collect} from '@qiri/stream/sinks/collect'

  import * as protocols from './protocols'
  import * as formats from './formats'

  /**
   * @private
   */
  const simplifyName = (name) => slugify(name, { remove: /[\W_]/g, lower: true })

  /**
   * @private
   */
  const joinLabels = (labels, sep, lastSep = sep) => {
    if (labels.length === 0) {
      return ''
    } else if (labels.length === 1) {
      return labels[0]
    } else if (labels.length === 2) {
      return `${labels[0]}${lastSep}${labels[1]}`
    } else {
      return `${labels.slice(0, -1).join(sep)}${lastSep}${last(labels)}`
    }
  }

  /**
   * @todo
   */
  export default {
    name: 'page-feeds-Form',
    components: {
      Modal
    },
    props: {
      mode: String,
      title: String,
      feedID: String
    },
    data () {
      return {
        form: {},
        allFeeds: [],
        remoteServers: [],
        feed: {
          model: this.$route.query.model,
          actionWhenMissing: this.$route.query.model === 'Product' ? 'mark-as-unavailable' : 'ignore'
        },
        mappingHistory: [],
        loading: true,
        saving: false,
        targetFields: [],
        selectedMapping: {},
        originalMapping: {},
        sourceFieldsFetching: false,
        sourceFieldsError: null
      }
    },
    async mounted () {
      // Keep a list of all (other) feeds, so we can determine missing mappings globally.
      this.$model.list(`/env/${this.$environment}/data/Feed/list`, 'allFeeds')

      // List remote servers for protocol configuration.
      this.$model.list(`/env/${this.$environment}/data/RemoteServer/list`, 'remoteServers')

      // Load the feed, if any.
      if (this.mode === 'update') {
        if (!this.feedID) {
          throw new Error(`Missing property "feedID".`)
        }
        this.feed = omit(await this.$model.get(`/env/${this.$environment}/data/Feed`, this.feedID), 'state')
      }

      // Determine the model of the feed.
      const feedModel = this.mode === 'update' ? this.feed.model : this.$route.query.model

      // Load the fields from the model.
      let fields = []

      // Get system fields from the models.
      const showHiddenFields = this.$token.role === 'administrator' || this.$token.role === 'superadministrator'
      const model = models.schema(feedModel)
      const schema = model.type.singleType
      for (const [propertyName, property] of Object.entries(schema.mergedSchema())) {
        const propertyType = propertyToType(property)
        const isHiddenField = property.primaryKey || property.foreignKey
        const isSimpleField = propertyName.indexOf('$') === -1 && FieldTypes[propertyType]

        if ((showHiddenFields && (isHiddenField || isSimpleField)) ||
          (!showHiddenFields && !isHiddenField && isSimpleField)) {
          fields.push({
            model: feedModel,
            name: propertyName,
            description: mergeLabelAndDescription(getFullLabel(propertyName, schema), property.description),
            primaryKey: property.primaryKey,
            foreignKey: property.foreignKey,
            type: propertyType,
            owner: 'system'
          })
        }
      }

      // Get the custom fields.
      const customFields = await this.$model.fetch(`/env/${this.$environment}/data/CustomField/list`)
      for (const customField of customFields) {
        if (customField.model === feedModel) {
          const existingField = fields.find(x => x.name === customField.name)
          if (existingField) {
            existingField.fieldID = customField.fieldID
            existingField.validationTemplate = customField.validationTemplate
          } else {
            fields.push({ ...customField })
          }
        }
      }

      // Get related fields.
      for (const relatedModelName of getRelatedModels(feedModel)) {
        for (const customField of customFields) {
          if (customField.model === relatedModelName && customField.type === 'foreignKey') {
            fields.push({ ...customField })
          }
        }
      }

      this.targetFields = fields.sort((a, b) => {
        if (a.primaryKey) {
          return -1
        } else if (b.primaryKey) {
          return 1
        } else if (a.foreignKey && !b.foreignKey) {
          return -1
        } else if (!a.foreignKey && b.foreignKey) {
          return 1
        } else if (a.type === 'foreignKey' && b.type === 'foreignKey' && a.model !== b.model) {
          return a.model === feedModel ? -1 : 1
        } else if (a.type === 'foreignKey' && b.type !== 'foreignKey') {
          return -1
        } else if (a.type !== 'foreignKey' && b.type === 'foreignKey') {
          return 1
        } else {
          return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
        }
      })

      // Remove mappings that don't have a field (anymore).
      if (this.feed.mappings) {
        this.feed.mappings = this.feed.mappings.filter(mapping =>
          fields.find(field => field.model === mapping.model && field.name === mapping.target)
        )
      }

      // Get mapping history for better suggestions.
      this.mappingHistory = await pull(
        await this.$api.call(`/env/${this.$environment}/data/FeedMappingHistory/list`, {}, {sync: false}),
        map(kv => ({
          target: kv.key,
          sources: kv.value.mappings.filter(Boolean)
        })),
        collect()
      )

      // Finished loading.
      this.loading = false
    },
    computed: {
      fieldTypeLabels () {
        return FieldTypes
      },
      selectedModel () {
        if (this.mode === 'create') {
          return this.$route.query.model
        } else if (this.feed) {
          return this.feed.model
        }
      },
      modelLabel () {
        const modelName = this.selectedModel
        if (!modelName) {
          return ''
        }
        const model = models.schema(modelName)
        return model.table.toLowerCase()
      },
      otherFeeds () {
        return this.allFeeds
          .filter(f => f.feedID !== this.feed.feedID)
          .filter(f => f.model === this.selectedModel)
      },
      schema () {
        const schema = this.mode === 'create' ? Feed.omit('feedID', 'state') : Feed.omit('state')

        // Add validation to ensure the name is unique.
        schema.addDocValidator(doc => {
          let errors = []
          if (doc.name) {
          const name = simplifyName(doc.name)
          if (this.allFeeds.some(feed => feed.feedID !== doc.feedID && simplifyName(feed.name) === name)) {
            errors.push({
              name: schema.label('name'),
              type: 'mustBeUnique',
              value: doc.name
            })
          }
          }
          return errors
        })

        return schema
      },
      protocolType: {
        get () {
          if (this.feed.protocol) {
            return this.feed.protocol.type === 'RemoteServer'
              ? `RemoteServer:${this.feed.protocol.settings.remoteServerID}`
              : this.feed.protocol.type
          } else {
            return null
          }
        },
        set (protocolType) {
          const parts = protocolType.split(':')
          this.$set(this.feed, 'protocol', this.feed.protocol || {})
          this.$set(this.feed.protocol, 'type', parts[0])
          this.$set(this.feed.protocol, 'settings', this.feed.protocol.settings || {})
          this.$set(this.feed.protocol.settings, 'remoteServerID', parts.length === 1 ? null : parts[1])
        }
      },
      protocolOptions () {
        // TODO: Get from metadata schema.
        let options = [
          ['HTTP', 'HTTP(S)'],
          ['FTP', 'FTP(S)'],
          ['SFTP', 'SFTP']
        ]
        if (process.env.NODE_ENV !== 'production') {
          options.push(['Local', 'Lokaal (development)'])
        }

        if (this.remoteServers.length > 0) {
          const remoteServers = this.remoteServers.sort((a, b) => a.name < b.name ? -1 : a.name === b.name ? 0 : 1)
          options.push([undefined, 'Overnemen van:'])
          for (const remoteServer of remoteServers) {
            options.push([
              `RemoteServer:${remoteServer.remoteServerID}`,
              remoteServer.name
            ])
          }
        }

        return options
      },
      protocolForm () {
        const protocolType = this.feed && this.feed.protocol && this.feed.protocol.type
        if (!protocolType) {
          return null
        }
        return protocols[protocolType]
      },
      protocolHasWildcard () {
        return this.feed &&
          this.feed.protocol &&
          this.feed.protocol.settings &&
          (this.feed.protocol.settings.path || this.feed.protocol.settings.location || '').indexOf('*') >= 0
      },
      multiModeOptions () {
        return [
          ['byDate-asc', 'Sorteer op wijzigingsdatum, importeer van oud naar nieuw'],
          ['byDate-desc', 'Sorteer op wijzigingsdatum, importeer van nieuw naar oud '],
          ['byDate-last', 'Sorteer op wijzigingsdatum, importeer alleen het nieuwste bestand'],
          ['byDate-first', 'Sorteer op wijzigingsdatum, importeer alleen het oudste bestand '],
          ['byName-asc', 'Sorteer op bestandsnaam (niet hoofdlettergevoelig), importeer van A-Z'],
          ['byName-desc', 'Sorteer op bestandsnaam (niet hoofdlettergevoelig), importeer van Z-A'],
          ['byName-last', 'Sorteer op bestandsnaam (niet hoofdlettergevoelig), importeer alleen het onderste bestand'],
          ['byName-first', 'Sorteer op bestandsnaam (niet hoofdlettergevoelig), importeer alleen het bovenste bestand '],
          ['asc', 'Niet sorteren, importeer van boven naar onderen'],
          ['desc', 'Niet sorteren, importeer van onderen naar boven '],
          ['last', 'Niet sorteren, importeer alleen het onderste bestand'],
          ['first', 'Niet sorteren, importeer alleen het bovenste bestand']
        ]
      },
      encodingOptions () {
        let options = [].concat(encodings).sort((a, b) => a < b ? -1 : a === b ? 0 : 1)
        options = options.map(x => [x, x])
        options.splice(0, 0, [null, 'Automatisch detecteren'])
        return options
      },
      formatOptions () {
        // TODO: Get from metadata schema.
        return {
          'CSV': 'CSV',
          'XML': 'XML',
          'JSON': 'JSON',
          'NDJSON': 'ND-JSON'
        }
      },
      actionWhenMissingOptions () {
        return {
          'mark-as-unavailable': 'Product in Qiri markeren als niet meer beschikbaar',
          'ignore': 'Product in Qiri niet aanpassen'
        }
      },
      deleteFromRemoteOptions () {
        return {
          'false': 'Behoud het bronbestand op de externe server',
          'true': 'Verwijder het bronbestand van de externe server'
        }
      },
      mergeModeOptions () {
        return {
          'override': 'Bestaande waardes overschrijven met nieuwe uit het bronbestand',
          'concat': 'Bestaande waardes bewaren en nieuwe daaraan toevoegen vanuit het bronbestand'
        }
      },
      formatForm () {
        const formatType = this.feed && this.feed.format && this.feed.format.type
        if (!formatType) {
          return null
        }
        return formats[formatType]
      },
      canFetchFeed () {
        return this.form.ready
          && this.$refs.form.fieldIsValid('protocol.type') && this.$refs.form.isValid('protocol.settings')
          && this.$refs.form.fieldIsValid('format.type') && this.$refs.form.isValid('format.settings')
      },
      sourceFields () {
        if (this.feed && this.feed.sourceFields) {
          return [
            [ null, '(Overslaan)' ],
            ...this.feed.sourceFields.map(field => [field, field])
          ]
        } else {
          return []
        }
      },
      importModeOptions () {
        return {
          'all': 'Verwerk alle regels uit het bronbestand.',
          'insert-only': `Verwerk alleen de regels die tot nieuwe records in de ${this.modelLabel}-tabel leiden.`,
          'update-only': `Verwerk alleen de regels die bestaande records in de ${this.modelLabel}-tabel bijwerken.`
        }
      },
      hasForeignKeysMappedForCurrentModel () {
        const mappings = this.getForeignKeyMappingsFor(this.selectedModel)
        return mappings.filter(m => !!m.source).length > 0
      },
      noMappedForeignKeysForCurrentModelLabel () {
        const tableLabel = `${this.getModelTable(this.selectedModel).toLowerCase()}-tabel`
        const fields = this.getForeignKeysFor(this.selectedModel)
        const prefix = fields.length === 1 ? 'het Qiri-veld' : 'de Qiri-velden'
        const fieldLabels = joinLabels(fields.map(f => `"${f.name}"`), ', ', ' of ')
        return `U heeft geen velden uit het bronbestand toegewezen aan ${prefix} ${fieldLabels} van de ${tableLabel}. Hierdoor zullen de regels uit het bronbestand altijd tot nieuwe records in de ${tableLabel} leiden.`
      },
      foreignKeySourceLabel () {
        let sources = []
        if (this.feed.mappings) {
          sources = this.targetFields
            .filter(targetField => targetField.type === 'foreignKey')
            .map(targetField => ({
              targetField,
              mapping: this.feed.mappings.find(m => m.model === targetField.model && m.target === targetField.name)
            }))
            .filter(({ mapping }) => mapping && mapping.source)
            .map(({ mapping }) => mapping.source)
            .reduce(
              (acc, cur) => {
                if (acc.indexOf(cur) < 0) {
                  acc.push(cur)
                }
                return acc
              },
              []
            )
        }
        return joinLabels(sources.map(s => `"${s}"`), ', ', ' of ')
      },
      mappingWarnings () {
        let allMappings = []
        if (this.feed.mappings) {
          this.feed.mappings
            .filter(m => !!m.source)
            .forEach(m => allMappings.push(m))
        }
        this.otherFeeds.forEach(f =>
          f.mappings
            .filter(m => !!m.source)
            .forEach(m => allMappings.push(m))
        )

        // Determine foreign keys that need to be mapped.
        const foreignKeyFields = new Map()
        for (const targetField of this.targetFields) {
          if (targetField.foreignKey) {
            foreignKeyFields.set(targetField.foreignKey, false)
          }
        }

        // Flag mappings for the given foreign model.
        for (const mapping of allMappings) {
          const targetField = this.targetFields.find(f => f.model === mapping.model && f.name === mapping.target)
          if (targetField && targetField.type === 'foreignKey') {
            foreignKeyFields.set(targetField.model, true)
          }
        }

        let messages = []
        for (const [modelName, isMapped] of foreignKeyFields.entries()) {
          if (!isMapped) {
            const targetFields = this.getForeignKeysFor(modelName)
            const prefix = targetFields.length === 1 ? 'het Qiri-veld' : 'de Qiri-velden'
            const targetsLabel = joinLabels(targetFields.map(f => `"${f.name}"`), ', ', ' of ')
            messages.push(`Let op: u heeft nog geen velden uit een bronbestand toegewezen aan ${prefix} ${targetsLabel} in de ${this.getModelTable(modelName).toLowerCase()}-tabel`)
          }
        }
        return messages
      },
      formWarnings () {
        let messages = []
        if (!this.hasForeignKeysMappedForCurrentModel) {
          messages.push(`${this.noMappedForeignKeysForCurrentModelLabel} Is dat wat u wilt?`)
        }
        return messages
      },
      formErrors () {
        let messages = []
        if (this.form.errors && this.form.errors.length > 0) {
          for (const error of this.form.errors) {
            let message = error.message
            /*if (error.type === 'requiredAtleastOnce') {
              const prefix = error.targets.length === 1 ? 'het Qiri-veld' : 'de Qiri-velden'
              const targetLabels = error.targets.map(target => {
                const targetField = this.targetFields.find(t => t.model === error.model && t.name === target)
                return `"${targetField ? targetField.name : target}"`
              })
              const targetsLabel = joinLabels(targetLabels, ', ', ' of ')
              message = `Let op: u heeft nog geen velden uit het bronbestand toegewezen aan ${prefix} ${targetsLabel} in de ${this.getModelTable(error.model).toLowerCase()}-tabel`
            } else {
              message = error.message
            }*/
            message = `${message}.`
            // Don't show duplicate messages.
            const duplicateMessage = messages.find(x => x === message)
            if (!duplicateMessage) {
              messages.push(message)
            }
          }
        }
        return messages
      },
      formJSON () {
        return JSON.stringify(this.form, null, '  ')
      },
      feedJSON () {
        return JSON.stringify(this.feed, null, '  ')
      }
    },
    methods: {
      getMappingFor (field) {
        let mappings = this.feed.mappings
        if (!mappings) {
          mappings = []
          this.$set(this.feed, 'mappings', mappings)
        }
        let mapping = mappings.find(x => x.model === field.model && x.target === field.name)
        if (!mapping) {
          const targetField = this.targetFields.find(x => x.model === field.model && x.name === field.name)
          mapping = {
            source: null,
            target: field.name,
            model: field.model,
            correctionTemplate: null
          }
          mappings.push(mapping)
        }
        return mapping
      },
      getModelTable (modelName) {
        const model = models.schema(modelName)
        return model.table
      },
      getFieldClass (field) {
        let classes = {}

        let fieldType = field.type || 'unknown'
        if (field.primaryKey) {
          fieldType = 'primaryKey'
        } else if (field.foreignKey) {
          fieldType = 'foreignKey'
        }

        return {
          [`${field.owner}-field`]: true,
          [`${fieldType}-field`]: true
        }
      },
      getFieldTypeLabel (field) {
        if (field.primaryKey) {
          return 'Primary key'
        } else if (field.foreignKey) {
          return 'Foreign key'
        } else {
          return FieldTypes[field.type]
        }
      },
      getFieldTypeHelpTitle (field) {
        if (field.type === 'bool') {
          return `Formaat: 1, 0, true of false`
        } else if (field.type === 'date') {
          return `Formaat: ISO 8601`
        } else if (field.type === 'decimal') {
          return `Decimaalteken: . (punt)`
        } else {
          return null
        }
      },
      getFieldTypeHelpStyle (field) {
        return this.getFieldTypeHelpTitle(field) ? 'cursor: help;' : ''
      },
      getForeignKeysFor (modelName) {
        return this.targetFields
          .filter(targetField => targetField.model === modelName)
          .filter(targetField => targetField.type === 'foreignKey')
      },
      getForeignKeyMappingsFor (modelName) {
        if (this.feed.mappings) {
          return this.targetFields
            .filter(targetField => targetField.model === modelName)
            .filter(targetField => targetField.type === 'foreignKey')
            .map(targetField => this.feed.mappings.find(m => m.model === targetField.model && m.target === targetField.name))
            .filter(mapping => !!mapping)
        } else {
          return []
        }
      },
      openCorrectionModal (mapping) {
        this.selectedMapping = mapping
        this.originalMapping = JSON.parse(JSON.stringify(mapping))
        this.$root.$emit('modal', 'correction', true)
      },
      submitCorrectionModal () {
        this.selectedMapping = {}
        this.originalMapping = {}
        this.$root.$emit('modal', 'correction', false)
      },
      cancelCorrectionModal () {
        this.selectedMapping.correctionTemplate = this.originalMapping.correctionTemplate
        this.selectedMapping = {};
        this.originalMapping = {};
      },
      openConfirmationModal () {
        this.$root.$emit('modal', 'confirmation', true)
      },
      answerConfirmationModal (confirmed) {
        this.$root.$emit('modal', 'confirmation', false)
        const afterModal = () => {
          if (confirmed === true) {
            this.saveFeed(confirmed)
          }
        }
        setTimeout(afterModal, 200)
      },
      async fetchFeed () {
        this.sourceFieldsError = null
        this.sourceFieldsFetching = true

        try {
          // Get the source fields from the feed.
          const api = await this.$rpc.access()
          const {fields: sourceFields, encoding} = await api.rootActor.call(`/env/${this.$environment}/data/FieldsFromSource/get`, {
            protocol: this.feed.protocol,
            format: this.feed.format
          })

          // For each target field (that doesn't have a mapping yet), try and "pre-match" a source field.
          let mappings = this.feed.mappings || []
          for (const targetField of this.targetFields) {
            // Find existing mapping. Skip the field if it has one.
            const mapping = mappings.find(m => m.target === targetField.name)
            if (mapping && mapping.source) {
              continue
            }

            // First try to match a mapping that was done before.
            const {sources: potentialSources = []} = this.mappingHistory.find(x => x.target === targetField.name) || {}
            let sourceField = potentialSources.find(x => sourceFields.indexOf(x) !== -1)

            // If nothing was found, try and match a source field that looks similar to the target field.
            if (!sourceField) {
              sourceField = sourceFields.find(x => simplifyName(targetField.name) === simplifyName(x))
            }

            // If a source field was found, add it to the mapping.
            if (sourceField) {
              if (mapping) {
                mapping.source = sourceField
              } else {
                mappings.push({
                  source: sourceField,
                  target: targetField.name,
                  model: targetField.model,
                  correctionTemplate: null
                })
              }
            }
          }

          // Put the results on the feed.
          this.$set(this.feed, 'sourceFields', sourceFields)
          this.$set(this.feed, 'mappings', mappings)
          if (encoding) {
            this.$set(this.feed.format, 'encoding', encoding)
          }
        } catch (err) {
          this.sourceFieldsError = err.message
        } finally {
          this.sourceFieldsFetching = false
        }
      },
      async saveFeed (confirmed) {
        if (this.formWarnings.length > 0 && confirmed !== true) {
          this.openConfirmationModal()
        } else {
          this.saving = true

          // Cleanup unused mappings.
          const feed = {
            ...this.feed,
            mappings: this.feed.mappings.filter(x => x.source)
          }

          if (this.mode === 'create') {
            await this.$model.create(`/env/${this.$environment}/data/Feed`, uuidv4(), feed)
            this.$router.replace({ name: 'data.feeds', query: this.$route.query })
          } else {
            await this.$model.update(`/env/${this.$environment}/data/Feed`, this.feedID, { $set: feed })
            this.$router.push({ name: 'data.feeds', query: this.$route.query })
          }
        }
      }
    }
  }
</script>
