<template>
  <section v-if="loading" class="padding">
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h1>Data-export logboek</h1></template>
          <template slot="content">
            <p v-if="loadingError" class="error">{{ loadingError }}</p>
            <p v-else>Bezig met laden...</p>
          </template>
        </block>
      </div>
    </div>
  </section>
  <section v-else class="padding">
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h1>Data-export logboek</h1></template>
          <template slot="content">
            <ButtonSelect label="Type" v-model="typeFilter" :options="typeOptions"></ButtonSelect>
          </template>
        </block>
      </div>
    </div>
    <div class="grid">
      <div class="column size--100">
        <block :table="true">
          <template slot="content">
            <table class="table">
              <thead>
                <tr>
                  <th>Datum &amp; tijd</th>
                  <th>Type</th>
                  <th>Gebruiker</th>
                  <th>Bericht</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="entry in displayEvents" :key="entry.id">
                  <td class="align--top">{{ entry.date }}</td>
                  <td class="align--top">{{ entry.type }}</td>
                  <td class="align--top" v-if="entry.user">{{ entry.user }}</td>
                  <td class="align--top" v-else><em>Systeem</em></td>
                  <td>{{ entry.message }}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </block>
      </div>
    </div>
  </section>
</template>

<script>
  import moment from 'moment'
  import {scheduleToString} from '@qiri/models/task-scheduler/Schedule'
  import ButtonSelect from '@/components/Partials/ButtonSelect'

  import {pull} from '@qiri/stream'
  import {iterate} from '@qiri/stream/sources/iterate'
  import {filter} from '@qiri/stream/operators/filter'
  import {map} from '@qiri/stream/operators/map'
  import {sort} from '@qiri/stream/operators/sort'
  import {collect} from '@qiri/stream/sinks/collect'

  const TYPES = {
    'executed': 'Verwerkt',
    'modified': 'Aangepast'
  }
  const TYPE_MAPPING = {
    'created': 'modified',
    'updated': 'modified',
    'enabled': 'modified',
    'disabled': 'modified',
    'scheduled': 'modified',
    'executed': 'executed',
    'removed': 'modified'
  }

  export default {
    name: 'page-exports-Log',
    components: {
      ButtonSelect
    },
    data () {
      return {
        events: [],
        typeFilter: Object.keys(TYPES),
        loading: true,
        loadingError : null
      }
    },
    async mounted () {
      // Gain access to the export scope.
      this.downloadToken = await this.$security.access('feed-storage')
      if (!this.downloadToken) {
          this.loadingError = 'Geen toegang'
          return
      }

      this.$model.list(`/env/${this.$environment}/data/Log/list`, 'events', {
        module: 'data.export',
        recordID: this.exportID
      })

      this.loading = false
    },
    computed: {
      exportID () {
        return this.$route.params.exportID
      },
      displayEvents () {
        const typeFilter = this.typeFilter
        return pull(
          iterate(this.events),
          filter(evt => typeFilter.indexOf(TYPE_MAPPING[evt.type]) >= 0),
          map(evt => {
            let type = evt.type
            let message
            let attachments

            switch (type) {
              case 'created':
                type = 'Aangepast'
                message = 'De data-export is aangemaakt.'
                break

              case 'updated':
                type = 'Aangepast'
                message = `De data-export is aangepast.`
                break

              case 'scheduled':
                type = 'Aangepast'
                message = `De planning voor verwerking is aangepast naar: ${scheduleToString(evt.data.schedule)}`
                break

              case 'executed':
                type = 'Uitgevoerd'
                if (evt.data) {
                  const {task, files} = evt.data

                  const totalSeconds = moment(task.finishedAt).unix() - moment(task.executedAt).unix()
                  const seconds = `${totalSeconds % 60}`
                  const minutes = `${Math.floor(totalSeconds / 60) % 60}`
                  const hours = `${Math.floor(totalSeconds / 60 / 60)}`

                  message = [
                    `Gestart: ${moment(task.executedAt).format('DD-MM-YYYY HH:mm:ss')}, `,
                    `beëindigd: ${moment(task.finishedAt).format('DD-MM-YYYY HH:mm:ss')}; `,
                    `duur: ${padLeft(hours)}:${padLeft(minutes)}:${padLeft(seconds)}.`
                  ].join('')

                  attachments = (files || []).filter(file => file.hash)
                } else {
                  message = `De data-export is uitgevoerd`
                }
                break

              case 'enabled':
                type = 'Aangepast'
                message = `De data-export is ingeschakeld`
                break

              case 'disabled':
                type = 'Aangepast'
                message = `De data-export is uitgeschakeld`
                break

              case 'removed':
                type = 'Aangepast'
                message = `De data-export is verwijderd`
                break

              default:
                return false
            }

            return {
              id: evt.sequence,
              date: moment(evt.timestamp).format('DD-MM-YYYY HH:mm:ss'),
              type,
              message,
              attachments: attachments && attachments.length > 0 ? attachments : null,
              user: evt.audit ? evt.audit.displayName : null
            }
          }),
          filter(x => x !== false),
          sort({
            compare: (a, b) => a.id > b.id ? -1 : a.id === b.id ? 0 : 1
          }),
          collect()
        )
      },
      typeOptions () {
        return TYPES
      }
    },
    methods: {}
  }

  /**
   * @private
   */
  function padLeft (str) {
    while (str.length < 2) {
      str = `0${str}`
    }
    return str
  }
</script>
