import {noop, identity} from '../util'

/**
 * @todo
 */
export const drain = (eachFn, doneFn = identity) => (source) => {
  let talkback
  let finished = false
  let failed = false
  let result
  let resolve, reject
  let next

  if (eachFn.length >= 2) {
    next = (err, result) => {
      if (talkback) {
        if (err || result === false) {
          talkback(2, err)
        } else {
          talkback(1)
        }
      }
    }
  }

  source(0, (t, d) => {
    if (t === 0) {
      talkback = d
      talkback(1)
    } else if (t === 1) {
      result = eachFn(d, next)
      if (next) {
        // Do nothing here, wait for "eachFn" to call "next" instead.
      } else if (result === false) {
        talkback(2)
      } else if (result && typeof result.then === 'function') {
        result.then(
          r => {
            result = r
            talkback(1)
          },
          err => {
            talkback(2, err)
            finished = true
            failed = true
            result = err
            reject && reject(err)
          }
        )
      } else {
        talkback(1)
      }
    } else if (t === 2) {
      if (d) {
        finished = true
        failed = true
        result = d
        reject && reject(d)
      } else if (doneFn.length >= 2) {
        doneFn(result, (err, r) => {
          finished = true
          if (err) {
            failed = true
            result = err
            reject && reject(err)
          } else {
            result = r
            resolve && resolve(r)
          }
        })
      } else {
        const r = doneFn(result)
        if (r && typeof r.then === 'function') {
          r.then(
            r => {
              finished = true
              result = r
              resolve && resolve(r)
            },
            err => {
              finished = true
              failed = true
              result = err
              reject && reject(err)
            }
          )
        } else {
          finished = true
          result = r
          resolve && resolve(r)
        }
      }
    }
  })

  if (finished) {
    if (failed) {
      throw result
    } else {
      return result
    }
  } else {
    return new Promise((_resolve, _reject) => {
      resolve = _resolve
      reject = _reject
    })
  }
}
