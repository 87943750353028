<template>
    <transition name="notification">
        <div class="notification" v-if="visible">
            <img :src="IMAGE_NOTIFY_ICON" />
            <a href="#" @click.prevent="visible = false"><icon name="times"></icon></a>
            <h4>{{ title }}</h4>
            <p>{{ body }}</p>
        </div>
    </transition>
</template>

<script>
    import Notify from 'notifyjs'
    //import {Config} from '@/config/config'

    export default {
        name: 'notification',
        data () {
            return {
                visible: false,
                title: '',
                body: '',
                timeout: 5,
                native: true,
                //IMAGE_NOTIFY_ICON: Config.IMAGE_NOTIFY_ICON
                IMAGE_NOTIFY_ICON: '/static/assets/images/logo.png'
            }
        },
        mounted () {
            // Request notification permission
            if (Notify.needsPermission && Notify.isSupported()) {
                Notify.requestPermission()
            }
            // Native support?
            this.native = !Notify.needsPermission
            // Register event
            this.$root.$on('notification', (title, body) => {
                this.title = title
                this.body = body
                this.show()
            })
        },
        methods: {
            show (title, body) {
                if (this.native) {
                    new Notify(this.title, {
                        body: this.body,
                        icon: this.IMAGE_NOTIFY_ICON,
                        timeout: this.timeout,
                        closeOnClick: true
                    }).show()
                } else {
                    this.visible = true
                    setTimeout(() => { this.visible = false }, this.timeout * 1000)
                }
            }
        }
    }
</script>
