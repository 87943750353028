var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"padding"},[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"column size--100"},[_c('block',{attrs:{"table":true}},[_c('template',{slot:"title"},[_c('h1',[_vm._v(_vm._s(_vm.title))])]),_vm._v(" "),_c('template',{slot:"content"},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Bedrijfsnaam")]),_vm._v(" "),_c('th',[_vm._v(" ")]),_vm._v(" "),_c('th',{staticClass:"icon"},[(_vm.companies.length === 0 || _vm.companyType !== 'owner')?_c('router-link',{staticClass:"btn icon",attrs:{"to":{
                                            name: (_vm.routePrefix + ".add"),
                                            query: _vm.$route.query
                                        },"title":_vm.getTitleForRoute({ name: (_vm.routePrefix + ".add") })}},[_c('icon',{attrs:{"name":"plus"}})],1):_vm._e()],1)])]),_vm._v(" "),_c('tbody',_vm._l((_vm.companies),function(company){return _c('tr',{key:company.companyID},[_c('td',[_c('router-link',{staticClass:"btn icon",attrs:{"to":{
                                            name: (_vm.routePrefix + ".edit"),
                                            params: { companyID: company.companyID },
                                            query: _vm.$route.query
                                        },"title":"Bedrijf bewerken"}},[_c('icon',{attrs:{"name":"pencil"}})],1),_vm._v("\n                                    "+_vm._s(company.name)+"\n                                ")],1),_vm._v(" "),_c('td',[_c('router-link',{attrs:{"to":{
                                            name: (_vm.routePrefix + ".users.overview"),
                                            params: { companyID: company.companyID },
                                            query: _vm.$route.query
                                        },"title":_vm.usersTitle}},[_vm._v("\n                                        "+_vm._s(_vm.usersTitle)+"\n                                    ")])],1),_vm._v(" "),_c('td',{staticClass:"icon"},[(_vm.companyType !== 'owner')?_c('btn',{staticClass:"icon",attrs:{"icon":"trash","title":"Bedrijf verwijderen"},on:{"click":function($event){$event.preventDefault();return _vm.removeRecord(company)}}}):_vm._e()],1)])}),0)])])],2)],1)]),_vm._v(" "),_c('modal',{attrs:{"name":"delete","title":"Weet je zeker dat je deze klant wilt verwijderen?"}},[_c('p',[_vm._v("Wil je de klant \""+_vm._s(_vm.selectedRecord && _vm.selectedRecord.name)+"\" verwijderen?")]),_vm._v(" "),_c('p',[_c('strong',[_vm._v("Let op!")]),_vm._v(" Dit verwijdert ook alle data in de omgeving van de klant! Om te bevestigen, type \""+_vm._s(_vm.deleteConfirmCheck)+"\" in het onderstaande veld.")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.deleteConfirmInput),expression:"deleteConfirmInput"}],attrs:{"type":"text","placeholder":("Type \"" + _vm.deleteConfirmCheck + "\" om te bevestigen")},domProps:{"value":(_vm.deleteConfirmInput)},on:{"input":function($event){if($event.target.composing){ return; }_vm.deleteConfirmInput=$event.target.value}}}),_vm._v(" "),_c('br'),_c('br'),_vm._v(" "),_c('button',{attrs:{"type":"button","disabled":_vm.deleteConfirmInput !== _vm.deleteConfirmCheck},on:{"click":function($event){return _vm.removeRecord(_vm.selectedRecord, true)}}},[_vm._v("Ja, verwijderen")]),_vm._v(" "),_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.$root.$emit('modal', 'delete', false)}}},[_vm._v("Nee, annuleren")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }