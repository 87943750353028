import {drain} from './drain'

/**
 * @todo
 */
export const collect = () => {
  let values = []
  return drain(
    (v) => {
      values.push(v)
    },
    () => values
  )
}
