<template>
    <div id="app">
        <header class="no-nav">
            <layout-header-simple></layout-header-simple>
        </header>
        <div id="content" class="no-nav">
            <div class="grid wrapper">
                <router-view :key="$route.fullPath"></router-view>
            </div>
            <footer>
                <layout-footer></layout-footer>
            </footer>
        </div>
        <notification></notification>
    </div>
</template>

<script>
    import LayoutHeaderSimple from '@/components/Layout/HeaderSimple'
    import LayoutFooter from '@/components/Layout/Footer'
    import Notification from '@/components/Partials/Notification'

    export default {
        name: 'layout-Center',
        components: {
            LayoutHeaderSimple,
            LayoutFooter,
            Notification
        }
    }
</script>
