export default {
  name: 'VueFormField_String',
  props: {
    fieldID: String,
    value: String,
    inline: Boolean,
    disabled: Boolean
  },
  render (h) {
    return h(
      'input',
      {
        staticClass: this.inline ? 'inline' : '',
        attrs: {
          id: this.fieldID,
          type: 'text',
          value: this.value,
          disabled: this.disabled
        },
        on: {
          change: (evt) => this.onChange(evt),
          input: (evt) => this.onChange(evt)
        }
      }
    )
  },
  methods: {
    onChange (evt) {
      const value = evt.target.value
      this.$emit('input', value)
    }
  }
}
