import Stream from './Stream'
import {pull} from './pull'
import {iterate} from './sources/iterate'

export {pull}

/**
 * @todo
 */
export const noop = () => {}

/**
 * @todo
 */
export const identity = (x) => x

/**
 * @todo
 */
export const isIterable = (obj) => obj && (typeof obj[Symbol.iterator] === 'function' || (typeof obj === 'object' && Stream.source in obj) || typeof obj === 'function')

/**
 * @todo
 */
export const asIterator = (it) => {
  if (!it) {
    return null
  } else if (it[Symbol.iterator]) {
    return iterate(it)
  } else if (typeof it === 'object' && Stream.source in it) {
    return asIterator(it[Stream.source])
  } else if (typeof it === 'function') {
    return it
  } else {
    return null
  }
}
