import Stream from './Stream'
import {abortable} from './operators/abortable'

/**
 * @todo
 */
export const pull = (...streams) => {
  streams = streams.filter(stream => !!stream)

  let source = streams.shift()

  const length = streams.length
  if (length < 1) {
    throw new Error(`Expected atleast 2 streams.`)
  }

  let result
  for (let i = 0; i < length; i++) {
    const sink = streams[i]
    while (source && typeof source === 'object' && Stream.source in source) {
      source = source[Stream.source]
    }

    if (result && typeof result.catch === 'function') {
      // Link promise error to next pipeline.
      const abortHandle = abortable()
      source = abortHandle(source)
      result.catch(err => {
        abortHandle.abort(err)
      })
    }

    if (typeof sink === 'function') {
      source = result = sink(source)
    } else if (sink && typeof sink === 'object' && Stream.sink in sink) {
      result = sink[Stream.sink](source)
      source = Stream.source in sink ? sink : result
    }
  }

  if (result && result !== source) {
    result[Stream.source] = source
  }
  return result
}
