import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  isOrganic: {
    type: Boolean,
    label: 'Product is biologisch?',
    description: 'Geeft aan of het product biologisch is.',
    optional: true
  },
  isEcological: {
    type: Boolean,
    label: 'Product is ecologisch?',
    description: 'Geeft aan of het product ecologisch is.',
    optional: true
  },
  isPrivateLabel: {
    type: Boolean,
    label: 'Product is huismerk?',
    description: 'Geeft aan of het product een huismerk is.',
    optional: true
  },
  isFairtrade: {
    type: Boolean,
    label: 'Product is Fairtrade?',
    description: 'Geeft aan of het product een Fairtrade product is.',
    optional: true
  }
})
