/**
 * @todo
 */
export function getIndexFields (schema) {
  let fields = []
  for (const [propertyName, property] of Object.entries(schema.mergedSchema())) {
    if (property.index) {
      fields.push({
        name: propertyName,
        ...property
      })
    }
  }
  return fields
}

/**
 * @todo
 */
export function renderProductName (record) {
  let icon
  if (record.productPromotions && record.productPromotions.length > 0) {
    icon = {
      name: 'exclamation-circle',
      title: `Heeft een productpromotie`
    }
  }
  if (record.product.webshopURL) {
    return {
      href: record.product.webshopURL,
      text: record.product.name,
      icon
    }
  } else if (icon) {
    return {
      text: record.product.name,
      icon
    }
  } else {
    return record.product.name
  }
}
