<template>
    <component :is="layout"></component>
</template>

<script>
    // Layout
    import CenterLayout from '@/components/Layout/CenterLayout'
    import DefaultLayout from '@/components/Layout/DefaultLayout'

    // Icons
    require("font-awesome-loader")

    // Helpers
    import {checkAudience} from '@qiri/jwt/util'

    export default {
        name: 'app',
        components: {},
        data: () => ({
            tokenExpiryNotified: false
        }),
        mounted () {
            this.$watch('$route', (route) => this.onRouteChanged(route), { immediate: true })
            this.$watch(() => this.$security.expiryTimeLeft, (newValue, oldValue) => this.checkTokenExpiry(newValue, oldValue))
        },
        computed: {
            layout () {
                return this.$route.meta.layout || DefaultLayout
            }
        },
        methods: {
            checkTokenExpiry (timeLeft, oldTimeLeft) {
                // Check if the security token expired.
                if (timeLeft === 0 && oldTimeLeft > 0) {
                    this.$security.clear()
                    this.$router.push({ name: 'auth.login' })
                }

                // Check if we should warn the user that the security token is about the expire.
                if (timeLeft <= 600 && !this.tokenExpiryNotified) {
                    this.$root.$emit('notification', 'Waarschuwing', 'Je sessie verloopt binnen 10 minuten, log a.u.b. opnieuw in.')
                    this.$root.$emit('security.tokenExpiring')
                    this.tokenExpiryNotified = true
                } else if (timeLeft > 600) {
                    this.tokenExpiryNotified = false
                }
            },
            onRouteChanged (route) {
                const token = this.$token
                const meta = route.meta || {}
                if (meta.public === true) {
                    return
                } else if (!token.aud) {
                    this.$router.replace({ name: 'auth.login' })
                } else if (meta.audience && !checkAudience(token.aud, meta.audience)) {
                    this.$router.replace({ name: 'unauthorized' })
                }
            }
        }
    }
</script>

<style lang="scss">
    @import './assets/scss/layout.scss';
</style>
