<template>
    <div class="grid">
        <div class="column size-100 align--right small">
            Qiri&reg; is een artificial intelligence-product van <a href="https://www.sellvation.nl/" target="_blank">Sellvation marketing BV</a> | Qiri&reg; en Sellvation&reg; zijn geregistreerde merken | &copy; {{ '' | date('Y') }} <a href="https://www.sellvation.nl/" target="_blank">Sellvation marketing BV</a> | <a href="https://cdn01.ccmprofessional.com/a140025/files/documenten/ALGEMENE-VOORWAARDEN-Sellvation-marketing-BV.pdf" target="_blank">Algemene voorwaarden</a> | Versie: {{ version }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'footer-default',
        computed: {
          version () {
            return VERSION
          }
        }
    }
</script>
