<template>
    <section class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>Unauthorized</h1></template>
                    <template slot="content">
                        <p>Je hebt geen toegang tot deze pagina.</p>
                    </template>
                </block>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'page-401'
    }
</script>
