import SimpleSchema from '@qiri/simpl-schema'
import * as product from './Product'
import * as person from './Person'
import * as store from './Store'
import * as bestseller from './Bestseller'
import * as hype from './Hype'
import * as upselling from './Upselling'
import * as repeatproduct from './RepeatProduct'
import * as repeatpurchase from './RepeatPurchase'
import * as seasonal from './Seasonal'
import * as shoppingcart from './ShoppingCart'
import * as shoppingvisit from './ShoppingVisit'
import * as ordervalue from './OrderValue'
import * as personsforproduct from './PersonsForProduct'
import * as relatedproducts from './RelatedProducts'
import * as productpromotions from './ProductPromotions'
import * as personalproductpromotions from './PersonalProductPromotions'
import * as personsforproductpromotions from './PersonsForProductPromotions'

/**
 * @todo
 */
export const getAlgorithms = (context = {}) => [
  {
    algorithmID: 'feed-storage',
    internal: true,
    name: 'Data-import bronbestand download',
    audience: [
      'uri:/feed-storage/download'
    ]
  },
  {
    algorithmID: 'export',
    internal: true,
    name: 'Exports',
    audience: [
      'uri:/export'
    ]
  },
  {
    algorithmID: 'api-template',
    internal: true,
    name: 'API-scripts',
    audience: [
      'uri:/script'
    ]
  },
  {
    algorithmID: 'events',
    internal: true,
    name: 'Events',
    audience: [
      'uri:/event'
    ]
  },
  {
    algorithmID: 'products',
    internal: true,
    name: 'Producten',
    audience: [
      'uri:/query/products/list',
      'uri:/query'
    ],
    configurationSchema: product.Configuration,
    requestConfigurationSchema: product.RequestConfiguration,
    requestSchema: product.Request,
    //inputFields: product.Input,
    outputSchema: product.Output(context),
    previewSchema: product.Preview
  },
  {
    algorithmID: 'persons',
    internal: true,
    name: 'Personen',
    audience: [
      'uri:/query/persons/list',
      'uri:/query'
    ],
    configurationSchema: person.Configuration,
    requestConfigurationSchema: person.RequestConfiguration,
    requestSchema: person.Request,
    //inputFields: person.Input,
    outputSchema: person.Output(context),
    previewSchema: person.Preview
  },
  {
    algorithmID: 'stores',
    internal: true,
    name: 'Winkels',
    audience: [
      'uri:/query/stores/list',
      'uri:/query'
    ],
    configurationSchema: store.Configuration,
    requestConfigurationSchema: store.RequestConfiguration,
    requestSchema: store.Request,
    //inputFields: store.Input,
    outputSchema: store.Output(context),
    previewSchema: store.Preview
  },
  {
    algorithmID: 'bestseller',
    name: 'Bestseller-producten',
    audience: [
      'uri:/query/bestsellers/list',
      'uri:/query'
    ],
    configurationSchema: bestseller.Configuration,
    requestConfigurationSchema: bestseller.RequestConfiguration,
    requestSchema: bestseller.Request,
    inputFields: bestseller.Input,
    outputSchema: bestseller.Output(context),
    previewSchema: bestseller.Preview
  },
  {
    algorithmID: 'hype',
    name: 'Hype-producten',
    audience: [
      'uri:/query/hypes/list',
      'uri:/query'
    ],
    configurationSchema: hype.Configuration,
    requestConfigurationSchema: hype.RequestConfiguration,
    requestSchema: hype.Request,
    inputFields: hype.Input,
    outputSchema: hype.Output(context),
    previewSchema: hype.Preview
  },
  {
    algorithmID: 'seasonal',
    name: 'Seizoens- of momentgebonden aankoopmomenten van producten',
    audience: [
      'uri:/query/seasonal/list',
      'uri:/query'
    ],
    configurationSchema: seasonal.Configuration,
    requestConfigurationSchema: seasonal.RequestConfiguration,
    requestSchema: seasonal.Request,
    inputFields: seasonal.Input,
    outputSchema: seasonal.Output(context),
    previewSchema: seasonal.Preview
  },
  {
    algorithmID: 'upselling',
    name: 'Alternatieve producten',
    audience: [
      'uri:/query/upselling/list',
      'uri:/query'
    ],
    configurationSchema: upselling.Configuration(context),
    requestConfigurationSchema: upselling.RequestConfiguration,
    requestSchema: upselling.Request,
    inputFields: upselling.Input,
    outputSchema: upselling.Output(context),
    previewSchema: upselling.Preview
  },
  {
    algorithmID: 'relatedproducts',
    name: 'Gerelateerde producten',
    audience: [
      'uri:/query/relatedproducts/list',
      'uri:/query'
    ],
    configurationSchema: relatedproducts.Configuration,
    requestConfigurationSchema: relatedproducts.RequestConfiguration,
    requestSchema: relatedproducts.Request,
    inputFields: relatedproducts.Input,
    outputSchema: relatedproducts.Output(context),
    previewSchema: relatedproducts.Preview
  },
  {
    algorithmID: 'repeatproduct',
    name: 'Typische herhalingsaankoopproducten',
    audience: [
      'uri:/query/repeat/products',
      'uri:/query'
    ],
    configurationSchema: repeatproduct.Configuration,
    requestConfigurationSchema: repeatproduct.RequestConfiguration,
    requestSchema: repeatproduct.Request,
    inputFields: repeatproduct.Input,
    outputSchema: repeatproduct.Output(context),
    previewSchema: repeatproduct.Preview
  },
  {
    algorithmID: 'repeatpurchase',
    name: 'Herhalingsaankoopproducten van de persoon',
    audience: [
      'uri:/query/repeat/purchases',
      'uri:/query'
    ],
    configurationSchema: repeatpurchase.Configuration,
    requestConfigurationSchema: repeatpurchase.RequestConfiguration,
    requestSchema: repeatpurchase.Request,
    inputFields: repeatpurchase.Input,
    outputSchema: repeatpurchase.Output(context),
    previewSchema: repeatpurchase.Preview
  },
  {
    algorithmID: 'shoppingvisit',
    name: 'Aankoopmomenten van de persoon',
    audience: [
      'uri:/query/shoppingvisit/list',
      'uri:/query'
    ],
    configurationSchema: shoppingvisit.Configuration,
    requestConfigurationSchema: shoppingvisit.RequestConfiguration,
    requestSchema: shoppingvisit.Request,
    inputFields: shoppingvisit.Input,
    outputSchema: shoppingvisit.Output(context),
    previewSchema: shoppingvisit.Preview
  },
  {
    algorithmID: 'ordervalue',
    name: 'Orderwaarden van de persoon',
    audience: [
      'uri:/query/ordervalue/list',
      'uri:/query'
    ],
    configurationSchema: ordervalue.Configuration,
    requestConfigurationSchema: ordervalue.RequestConfiguration,
    requestSchema: ordervalue.Request,
    inputFields: ordervalue.Input,
    outputSchema: ordervalue.Output(context),
    previewSchema: ordervalue.Preview
  },
  {
    algorithmID: 'shoppingcart',
    name: 'Winkelwagen-voorspelling voor de persoon',
    audience: [
      'uri:/query/shoppingcart/list',
      'uri:/query'
    ],
    configurationSchema: shoppingcart.Configuration,
    requestConfigurationSchema: shoppingcart.RequestConfiguration,
    requestSchema: shoppingcart.Request,
    inputFields: shoppingcart.Input,
    outputSchema: shoppingcart.Output(context),
    previewSchema: shoppingcart.Preview
  },
  {
    algorithmID: 'personsforproduct',
    name: 'Personen voor opgegeven product',
    audience: [
      'uri:/query/personsforproduct/list',
      'uri:/query'
    ],
    configurationSchema: personsforproduct.Configuration,
    requestConfigurationSchema: personsforproduct.RequestConfiguration,
    requestSchema: personsforproduct.Request,
    inputFields: personsforproduct.Input,
    outputSchema: personsforproduct.Output(context),
    previewSchema: personsforproduct.Preview
  },
  {
    algorithmID: 'productpromotions',
    name: 'Productpromoties',
    audience: [
      'uri:/query/productpromotions/list',
      'uri:/query'
    ],
    configurationSchema: productpromotions.Configuration,
    requestConfigurationSchema: productpromotions.RequestConfiguration,
    requestSchema: productpromotions.Request,
    //inputFields: productpromotions.Input,
    outputSchema: productpromotions.Output(context),
    previewSchema: productpromotions.Preview
  },
  {
    algorithmID: 'personalproductpromotions',
    name: 'Productpromoties voor de persoon',
    audience: [
      'uri:/query/personalproductpromotions/list',
      'uri:/query'
    ],
    configurationSchema: personalproductpromotions.Configuration,
    requestConfigurationSchema: personalproductpromotions.RequestConfiguration,
    requestSchema: personalproductpromotions.Request,
    inputFields: personalproductpromotions.Input,
    outputSchema: personalproductpromotions.Output(context),
    previewSchema: personalproductpromotions.Preview
  },
  {
    algorithmID: 'personsforproductpromotions',
    name: 'Personen voor opgegeven productpromotie',
    audience: [
      'uri:/query/personsforproductpromotions/list',
      'uri:/query'
    ],
    configurationSchema: personsforproductpromotions.Configuration,
    requestConfigurationSchema: personsforproductpromotions.RequestConfiguration,
    requestSchema: personsforproductpromotions.Request,
    inputFields: personsforproductpromotions.Input,
    outputSchema: personsforproductpromotions.Output(context),
    previewSchema: personsforproductpromotions.Preview
  }
]

/**
 * @todo
 */
export const getAlgorithm = (algorithmID, context) => getAlgorithms(context).find(x => x.algorithmID === algorithmID)

/**
 * @todo
 */
export const factory = ({algorithmID} = {}, context) => new SimpleSchema({
  'algorithmID': {
    type: String,
    label: 'Qiri algoritme ID',
    description: 'De unieke waarde waarmee het algoritme geïdentificeerd kan worden',
    primaryKey: true
  },
  'name': {
    type: String,
    label: 'Naam',
    description: 'De naam van het algoritme',
    optional: true,
    autoValue () {
      if (algorithmID) {
        return getAlgorithm(algorithmID, context).name
      }
    }
  },
  'description': {
    type: String,
    label: 'Werking',
    description: 'Omschrijving van de werking van het algoritme',
    multiline: true,
    autoValue () {
      // TODO: Strip/sanitize HTML (on server only, using "sanitize-html").
    }
  },
  'configuration': {
    type: algorithmID ? getAlgorithm(algorithmID, context).configurationSchema : Object,
    blackbox: !algorithmID,
    label: 'Configuratie',
    defaultValue: {}
  },
  'suggestions': {
    type: Object,
    defaultValue: {}
  },
  'suggestions.description': {
    type: String,
    label: 'Omschrijving in GUI',
    description: 'Omschrijving van de suggesties die het algoritme kan geven',
    multiline: true,
    optional: true,
    autoValue () {
      // TODO: Strip/sanitize HTML (on server only, using "sanitize-html").
    }
  },
  'suggestions.configuration': {
    type: algorithmID ? overrideSchemaForConfiguration(getAlgorithm(algorithmID, context).requestSchema) : Object,
    blackbox: !algorithmID,
    label: 'Configuratie',
    defaultValue: {}
  },
  'api': {
    type: Object,
    defaultValue: {}
  },
  'api.configuration': {
    type: algorithmID ? getAlgorithm(algorithmID, context).requestConfigurationSchema : Object,
    blackbox: !algorithmID,
    label: 'Configuratie',
    defaultValue: {}
  }
})

/**
 * @todo
 */
const Algorithm = factory()
export default Algorithm

/**
 * @static
 */
Algorithm.getSchemaFor = (doc, context) => factory({algorithmID: doc && doc.algorithmID}, context)

/**
 * @private
 */
function overrideSchemaForConfiguration (s) {
  const schema = new SimpleSchema(s)
  if (schema.allowsKey('person')) {
    schema.extend({
      'person': {
        optional: true,
        custom: null
      }
    })
  }
  if (schema.allowsKey('product')) {
    schema.extend({
      'product': {
        optional: true,
        custom: null
      }
    })
  }
  if (schema.allowsKey('productPromotion')) {
    schema.extend({
      'productPromotion': {
        optional: true,
        custom: null
      }
    })
  }
  return schema
}
