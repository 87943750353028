import {VueFormGroup} from '../providers'
import {randomID} from '../util'

import VueFormField_String from './fields/String'
import VueFormField_Text from './fields/Text'
import VueFormField_Checkbox from './fields/Checkbox'
import VueFormField_Select from './fields/Select'

export default {
  name: 'VueFormGroup',
  provide() {
    return { 
      [VueFormGroup]: this
    }
  },
  props: {},
  render (h) {
    return h('div', { staticClass: 'grid form-group' }, this.$slots.default)
  }
}
