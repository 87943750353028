import SimpleSchema from '@qiri/simpl-schema'
import ProductModel from '../data/Product'
import OrderLineModel from '../data/OrderLine'
import OrderBy from './OrderBy'
import {renderProductName} from './util'

export const Configuration = new SimpleSchema({
  'maxOrderSize': {
    type: SimpleSchema.Integer,
    label: 'Maximale order grootte',
    description: 'Het maximale aantal producten in een order. Indien een order meer dan het opgegeven aantal producten heeft, zal deze worden genegeerd.',
    min: 0,
    defaultValue: 40
  },
  'intersectionThreshold': {
    type: SimpleSchema.Integer,
    label: 'Minimaal aantal orders',
    description: 'Het minimale aantal orders waarin product A en B gecombineerd voorkomen.',
    min: 0,
    defaultValue: 100
  },
  'jaccardSimilarityThreshold': {
    type: Number,
    label: 'Ondergrens \'Jaccard Similarity\'-coëfficiënt',
    description: 'Het minimale percentage waarin product A en B gecombineerd voorkomen ten opzichte van alle waarnemingen van de producten A en B (al dan niet gecombineerd).',
    min: 0,
    max: 100,
    defaultValue: 1
  }
})

export const Input = {
  OrderLine: ['orderID', 'productID']
}

export const Output = ({model} = {}) => new SimpleSchema({
  'product': {
    type: model ? model('Product').type : ProductModel,
    label: 'Product',
    table: 'Producten'
  },
  'jaccardSimilarity': {
    type: Number,
    label: 'Jaccard Similarity coëfficiënt'
  }
})

export const Preview = new SimpleSchema({
  'name': {
    type: String,
    label: 'Productnaam',
    render: renderProductName
  },
  'jaccardSimilarity': {
    type: String,
    label: '\'Jaccard similarity\'-coëfficiënt',
    render (record) {
      return `${Math.round(record.jaccardSimilarity * 100)}%`
    }
  }
})

export const Request = new SimpleSchema({
  'product': {
    type: String,
    label: 'Product',
    description: 'Het product op basis waarvan suggesties worden opgevraagd.'
  },
  'date': {
    type: Date,
    label: 'Datum',
    description: 'De datum waarvoor de voorspelling gemaakt moet worden. Indien leeg wordt ‘vandaag’ gebruikt.',
    optional: true
  },
  'includeProductPromotions': {
    type: Boolean,
    label: 'Productpromoties opzoeken',
    description: 'Indien het product in een productpromotie gevonden wordt, deze ook teruggeven in het resultaat?',
    optional: true,
    options: {
      'Ja': 'true',
      'Nee': 'false'
    }
  },
  'filter': {
    type: Array,
    label: 'Filters',
    optional: true
  },
  'filter.$': {
    type: String
  },
  'limit': {
    type: SimpleSchema.Integer,
    label: 'Limiet',
    description: 'Maximaal aantal gerelateerde producten dat wordt getoond.',
    min: 1,
    defaultValue: 3
  }/*,
  'orderBy': OrderBy.arraySchema({
    defaultValue: [{ field: 'factor', order: 'desc' }]
  }),
  'orderBy.$': OrderBy.elementSchema({
    indexFields: Output
  })*/
})

export const RequestConfiguration = new SimpleSchema({
  'maxLimit': {
    type: SimpleSchema.Integer,
    label: 'Limiet',
    description: 'Maximaal aantal suggesties in een enkele API-response',
    min: 1,
    defaultValue: 50
  }
})
