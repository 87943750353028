import extend from 'lodash/extend'
import SimpleSchema from '@qiri/simpl-schema'
import {getIndexFields} from './util'

/**
 * @todo
 */
export default {
  arraySchema: ({ defaultValue, ...def }) => extend(def, {
    type: Array,
    autoValue () {
      if (!this.isSet) {
        if (this.operator === null) {
          return defaultValue//[{ field: 'factor', order: 'desc' }]
        } else {
          return {
            $setOnInsert: defaultValue
          }
        }
      } else if (Array.isArray(this.value)) {
        return this.value.map(value => {
          if (typeof value === 'string') {
            const [field, order] = value.split(':')
            return {field, order}
          } else {
            return value
          }
        })
      }
    }
  }),
  elementSchema: ({indexFields}) => ({
    type: new SimpleSchema({
      'field': {
        type: String,
        label: 'Veld',
        description: 'Veld om op te sorteren',
        allowedValues: getIndexFields(indexFields).reduce((acc, cur) => extend(acc, {[cur.name]: cur.name}), {})
      },
      'order': {
        type: String,
        label: 'Volgorde',
        description: 'Volgorde van sortering',
        allowedValues: {
          'Oplopend': 'asc',
          'Aflopend': 'desc'
        },
        defaultValue: 'asc'
      }
    })
  })
}
