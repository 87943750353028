import invert from 'lodash/invert'
import isNumber from 'lodash/isNumber'
import SimpleSchema from '@qiri/simpl-schema'
import ProductModel from '../data/Product'
import ClassificationModel from '../data/Classification'
import NutritionalValueModel, {normalize as normalizeNutritionalValue} from '../data/NutritionalValue'
import {requiredIf} from '../util'
import OrderBy from './OrderBy'
import {renderProductName} from './util'

const TYPE_LABELS = {
  'promoted': 'Aanbieding',
  'organic': 'Biologisch',
  'moreExpensive': 'Duurder',
  'ecological': 'Ecologisch',
  'fairtrade': 'Fairtrade',
  'healthier': 'Gezonder',
  'privateLabel': 'Huismerk',
  'morePopular': 'Populair',
  'cheaper': 'Voordeliger',
  'moreProfitable': 'Winstgevender'
}
const TYPE_OPTIONS = {
  'Geen: toon alle typen': null,
  ...invert(TYPE_LABELS)
}

const HealthierConfiguration = new SimpleSchema({
  'weightOfKilocalorie': {
    type: Number,
    label: 'Kilocalorieën gezondheidsvoordeel- of nadeel',
    description: 'Het gezondheidsvoordeel- of nadeel van kilocalorieën in verhouding tot andere voedingswaarden',
    min: -1,
    max: 1,
    defaultValue: 0
  },
  'weightOfFat': {
    type: Number,
    label: 'Vet gezondheidsvoordeel- of nadeel',
    description: 'Het gezondheidsvoordeel- of nadeel van vet in verhouding tot andere voedingswaarden',
    min: -1,
    max: 1,
    defaultValue: 0
  },
  'weightOfSaturatedFat': {
    type: Number,
    label: 'Verzadigd vet gezondheidsvoordeel- of nadeel',
    description: 'Het gezondheidsvoordeel- of nadeel van verzadigd vet in verhouding tot andere voedingswaarden',
    min: -1,
    max: 1,
    defaultValue: 0
  },
  'weightOfMonounsaturatedFat': {
    type: Number,
    label: 'Enkelvoudig onverzadigd vet gezondheidsvoordeel- of nadeel',
    description: 'Het gezondheidsvoordeel- of nadeel van enkelvoudig onverzadigd vet in verhouding tot andere voedingswaarden',
    min: -1,
    max: 1,
    defaultValue: 0
  },
  'weightOfPolyunsaturatedFat': {
    type: Number,
    label: 'Meervoudig onverzadigd vet gezondheidsvoordeel- of nadeel',
    description: 'Het gezondheidsvoordeel- of nadeel van meervoudig onverzadigd vet in verhouding tot andere voedingswaarden',
    min: -1,
    max: 1,
    defaultValue: 0
  },
  'weightOfCarbohydrates': {
    type: Number,
    label: 'Koolhydraten gezondheidsvoordeel- of nadeel',
    description: 'Het gezondheidsvoordeel- of nadeel van koolhydraten in verhouding tot andere voedingswaarden',
    min: -1,
    max: 1,
    defaultValue: 0
  },
  'weightOfSugars': {
    type: Number,
    label: 'Suikers gezondheidsvoordeel- of nadeel',
    description: 'Het gezondheidsvoordeel- of nadeel van suikers in verhouding tot andere voedingswaarden',
    min: -1,
    max: 1,
    defaultValue: 0
  },
  'weightOfPolyols': {
    type: Number,
    label: 'Polyolen gezondheidsvoordeel- of nadeel',
    description: 'Het gezondheidsvoordeel- of nadeel van polyolen in verhouding tot andere voedingswaarden',
    min: -1,
    max: 1,
    defaultValue: 0
  },
  'weightOfStarch': {
    type: Number,
    label: 'Zetmeel gezondheidsvoordeel- of nadeel',
    description: 'Het gezondheidsvoordeel- of nadeel van zetmeel in verhouding tot andere voedingswaarden',
    min: -1,
    max: 1,
    defaultValue: 0
  },
  'weightOfFibers': {
    type: Number,
    label: 'Vezels gezondheidsvoordeel- of nadeel',
    description: 'Het gezondheidsvoordeel- of nadeel van vezels in verhouding tot andere voedingswaarden',
    min: -1,
    max: 1,
    defaultValue: 0
  },
  'weightOfProtein': {
    type: Number,
    label: 'Eiwitten gezondheidsvoordeel- of nadeel',
    description: 'Het gezondheidsvoordeel- of nadeel van eiwitten in verhouding tot andere voedingswaarden',
    min: -1,
    max: 1,
    defaultValue: 0
  },
  'weightOfSalt': {
    type: Number,
    label: 'Zout gezondheidsvoordeel- of nadeel',
    description: 'Het gezondheidsvoordeel- of nadeel van zout in verhouding tot andere voedingswaarden',
    min: -1,
    max: 1,
    defaultValue: 0
  },
  'weightOfTransFat': {
    type: Number,
    label: 'Transvet gezondheidsvoordeel- of nadeel',
    description: 'Het gezondheidsvoordeel- of nadeel van transvet in verhouding tot andere voedingswaarden',
    min: -1,
    max: 1,
    defaultValue: 0
  }
})

export const Configuration = ({customFields}) => new SimpleSchema({
  'flaggedCategoryType': {
    type: String,
    label: 'In- of uitsluiten van product categorieën',
    description: 'Geeft aan of gemarkeerde product categorieën in- of uitgesloten worden van vergelijkingen.',
    optional: true,
    allowedValues: {
      'Niet van toepassing': null,
      'Product categorieën insluiten': 'inclusive',
      'Product categorieën uitsluiten': 'exclusive'
    }
  },
  'flaggedCategoryField': {
    type: String,
    label: 'Product categorie markeringsveld',
    description: 'Het veld  dat aangeeft of een product categorie is gemarkeerd.',
    optional: true,
    custom: requiredIf('flaggedCategoryType', flaggedCategoryType => !!flaggedCategoryType),
    render (value, record) {
      // TODO: Don't "abuse" render for this, but implement "applicable".
      return record && !!record.flaggedCategoryType
    },
    allowedValues: !customFields ? undefined : customFields
      .filter(customField => customField.model === 'ProductCategory' && customField.type === 'bool')
      .reduce(
        (acc, cur) => {
          acc[cur.name] = cur.name
          return acc
        },
        {}
      )
  },
  'healthierConfiguration': {
    type: HealthierConfiguration,
    label: 'Gezondere producten'
  }
})

export const Input = {
  Product: [
    'name',
    'abstractProduct.$',
    'pricing.sellingPrice',
    'pricing.margin',
    'unitType',
    'unitQuantity',
    ...Object.keys(ClassificationModel.schema()).map(x => `classification.${x}`),
    ...Object.keys(NutritionalValueModel.schema()).map(x => `nutritionalValue.${x}`)
  ]
}

export const Output = ({model} = {}) => new SimpleSchema({
  'product': {
    type: model ? model('Product').type : ProductModel,
    label: 'Product',
    table: 'Producten'
  },
  'alternative': {
    type: model ? model('Product').type : ProductModel,
    label: 'Alternatief product',
    table: 'Producten'
  },
  'details': {
    type: Object
  },
  'details.type': {
    type: String,
    label: 'Type alternatief'
  },
  'details.reason': {
    type: String,
    label: 'Reden voor alternatief'
  },
  'details.similarity': {
    type: Number,
    label: 'Gelijkenis van producten'
  },
  'details.product': {
    type: Object
  },
  'details.product.unitType': {
    type: String,
    label: 'Eenheid type'
  },
  'details.product.unitQuantity': {
    type: Number,
    label: 'Eenheid aantal'
  },
  'details.product.unitPrice': {
    type: SimpleSchema.Integer,
    label: 'Prijs per eenheid'
  },
  'details.alternative': {
    type: Object
  },
  'details.alternative.unitType': {
    type: String,
    label: 'Eenheid type'
  },
  'details.alternative.unitQuantity': {
    type: Number,
    label: 'Eenheid aantal'
  },
  'details.alternative.unitPrice': {
    type: SimpleSchema.Integer,
    label: 'Prijs per eenheid'
  }
})

export const Preview = new SimpleSchema({
  'productName': {
    type: String,
    label: 'Gekocht product',
    render: renderProductName
  },
  'alternativeName': {
    type: String,
    label: 'Alternatief product',
    render (record) {
      let icon
      if (record.alternativeProductPromotions && record.alternativeProductPromotions.length > 0) {
        icon = {
          name: 'exclamation-circle',
          title: `Heeft een productpromotie`
        }
      }
      if (record.alternative.webshopURL) {
        return {
          href: record.alternative.webshopURL,
          text: record.alternative.name,
          icon
        }
      } else if (icon) {
        return {
          text: record.alternative.name,
          icon
        }
      } else {
        return record.alternative.name
      }
    }
  },
  'alternativeReason': {
    type: String,
    label: 'Reden alternatief',
    render (record) {
      if (Array.isArray(record.details.reason) && record.details.type === 'healthier') {
        const labels = record.details.reason.map(x => {
          const label = NutritionalValueModel.label(x.item)

          const productNutritionalValue = normalizeNutritionalValue(record.product.nutritionalValue)
          const alternativeNutritionalValue = normalizeNutritionalValue(record.alternative.nutritionalValue)

          if (x.healthImpact <= 0) {
            return false
          } else if (alternativeNutritionalValue[x.item] > productNutritionalValue[x.item]) {
            return `meer ${label.toLowerCase()}`
          } else if (alternativeNutritionalValue[x.item] < productNutritionalValue[x.item]) {
            return `minder ${label.toLowerCase()}`
          }
        })
        return `Gezonder (${labels.filter(Boolean).join(', ')})`
      } else {
        return TYPE_LABELS[record.details.type] || record.details.type
      }
    }
  },
  'consumerPriceDifference': {
    type: String,
    label: 'Prijsverschil voor consument',
    render (record) {
      const sellingDiff = record.alternative.pricing.sellingPrice - record.product.pricing.sellingPrice
      const unitDiff = record.details.alternative.unitPrice - record.details.product.unitPrice
      return `${(sellingDiff / 100).toFixed(2)} (per ${record.details.product.unitType}: ${(unitDiff / 100).toFixed(2)})`
    }
  },
  'retailerPriceDifference': {
    type: String,
    label: 'Margeverschil voor retailer',
    render (record) {
      const alternativeMargin = record.alternative.pricing.margin ? Number(record.alternative.pricing.margin) : null
      const productMargin = record.product.pricing.margin ? Number(record.product.pricing.margin) : null

      if (isNumber(alternativeMargin) && isNumber(productMargin)) {
        const diff = alternativeMargin - productMargin
        return `${(diff / 100).toFixed(2)}`
      } else {
        return 'Onbekend'
      }
    }
  }
})

export const Request = new SimpleSchema({
  'person': {
    type: String,
    label: 'Persoon',
    description: 'De persoon voor wie de suggesties opgevraagd worden. Alleen alternatieven voor producten die eerder door de persoon zijn gekocht, worden in de lijst getoond.',
    optional: true,
    custom: requiredIf('product', product => !product)
  },
  'product': {
    type: String,
    label: 'Product',
    description: 'Het product op basis waarvan suggesties worden opgevraagd. Alleen alternatieven voor dit product worden in de lijst getoond.',
    optional: true,
    custom: requiredIf('person', person => !person)
  },
  'date': {
    type: Date,
    label: 'Datum',
    description: 'De datum waarvoor de voorspelling gemaakt moet worden. Indien leeg wordt ‘vandaag’ gebruikt.',
    optional: true
  },
  'includeProductPromotions': {
    type: Boolean,
    label: 'Productpromoties opzoeken',
    description: 'Indien het product in een productpromotie gevonden wordt, deze ook teruggeven in het resultaat?',
    optional: true,
    options: {
      'Ja': 'true',
      'Nee': 'false'
    }
  },
  'filter': {
    type: Array,
    label: 'Filters',
    optional: true
  },
  'filter.$': {
    type: String
  },
  'limit': {
    type: SimpleSchema.Integer,
    label: 'Limiet totaal',
    description: 'Maximaal totaal aantal suggesties dat wordt getoond (zoals maximaal 20 suggesties).',
    min: 1,
    defaultValue: 20
  },
  'limitPerType': {
    type: SimpleSchema.Integer,
    label: 'Limiet per type alternatief',
    description: 'Maximaal aantal suggesties voor elk type alternatief (zoals maximaal 3 bio-alternatieven).',
    min: 1,
    defaultValue: 3
  },
  'limitPerProduct': {
    type: SimpleSchema.Integer,
    label: 'Limiet aantal suggesties per product',
    description: 'Maximaal aantal suggesties voor elk gekocht product (zoals maximaal 1 alternatief).',
    min: 1,
    defaultValue: 1
  },
  'filterType': {
    type: String,
    label: 'Filter op type alternatief',
    description: 'Laat alleen de alternatieven zien van het opgegeven soort.',
    allowedValues: TYPE_OPTIONS,
    optional: true
  }/*,
  'orderBy': OrderBy.arraySchema({
    defaultValue: [{ field: 'product.name', order: 'asc' }]
  }),
  'orderBy.$': OrderBy.elementSchema({
    indexFields: Output
  })*/
})

export const RequestConfiguration = new SimpleSchema({
  'maxLimit': {
    type: SimpleSchema.Integer,
    label: 'Limiet',
    description: 'Maximaal aantal suggesties in een enkele API-response',
    min: 1,
    defaultValue: 50
  }
})
