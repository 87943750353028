<template>
    <section v-if="loading" class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>{{ title }}</h1></template>
                    <template slot="content">
                        <p v-if="loading">Bezig met laden...</p>
                    </template>
                </block>
            </div>
        </div>
    </section>
    <vue-form v-else tag="section" :state="form" ref="form" :schema="schema" v-model="script" class="padding" @submit="doSubmit()">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>{{ title }}</h1></template>
                    <template slot="content">
                        <vue-form-field field="name"></vue-form-field>
                        <vue-form-field field="description" type="quill"></vue-form-field>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100" @mouseenter="editorMouseEnter" @mouseleave="editorMouseLeave">
                <block :collapsed="!showCodeEditor" @toggle="showCodeEditor = !showCodeEditor">
                    <template slot="title"><h2>JavaScript</h2></template>
                    <template slot="content">
                        <p>Geef hier het JavaScript op. Deze wordt uitgevoerd als het script aangeroepen wordt via een API-request.</p>
                        <MonacoEditor
                            ref="codeEditor"
                            class="editor"
                            v-model="script.code"
                            theme="vs-dark"
                            language="javascript"
                            :options="editorOptions"
                            @editorDidMount="codeEditorDidMount"
                        />
                        <br />
                        <vue-form-field field="htmlEnabled">
                            HTML-wrapper gebruiken om de JSON output van het JavaScript te transformeren. Als je een HTML-wrapper hebt gemaakt, dan kun je de API-script output ook via een API-script snippet opvragen.
                        </vue-form-field>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid" v-if="script.htmlEnabled">
            <div class="column size--100">
                <block :collapsed="!showHtmlEditor" @toggle="showHtmlEditor = !showHtmlEditor">
                    <template slot="title"><h2>HTML-wrapper</h2></template>
                    <template slot="content">
                        <p>Geef hier een HTML template op. Deze wordt gebruikt om de JSON output van het JavaScript te transformeren.</p>
                        <MonacoEditor
                            ref="htmlEditor"
                            class="editor"
                            v-model="script.htmlContent"
                            theme="vs-dark"
                            language="handlebars"
                            :options="editorOptions"
                            @editorDidMount="htmlEditorDidMount"
                        />
                    </template>
                </block>
            </div>
        </div>

        <div class="grid">
            <div class="column size--100">
                <block :collapsed="!showExamples" @toggle="showExamples = !showExamples">
                    <template slot="title"><h2>Code voorbeeld API-request</h2></template>
                    <template slot="content" v-if="scriptID && script">
                        <vue-form :schema="exampleOptionsSchema" v-model="exampleOptions">
                            <vue-form-group>
                                <vue-form-field column="25" field="version"></vue-form-field>
                                <vue-form-field column="25" field="language"></vue-form-field>
                                <vue-form-field column="50" field="token"></vue-form-field>
                            </vue-form-group>
                            <vue-form-field v-for="parameter of this.script.parameters" :key="parameter.name" :field="`parameters.${parameter.name}`">
                                {{ parameter.name }}: <em>{{ parameter.description }}</em>
                            </vue-form-field>
                            <br />

                            <MonacoEditor v-if="selectedExample"
                                ref="exampleEditor"
                                class="example-editor"
                                :value="selectedExampleCode"
                                theme="vs-dark"
                                :language="selectedExample.language"
                                :options="exampleEditorOptions"
                            />

                            <br />
                            <vue-form-field field="showParameters">
                                Extra parameters voor API-request opgeven.
                            </vue-form-field>
                        </vue-form>
                        <table class="table" v-if="exampleOptions.showParameters">
                            <thead>
                                <tr>
                                    <th>Naam</th>
                                    <th>Omschrijving</th>
                                    <th>Is verplicht</th>
                                    <th class="icon">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(parameter, index) of parameters" :key="tag(parameter)">
                                    <td>
                                        <vue-form-field v-if="index < parameters.length - 1" :field="`parameters.${index}.name`" :bare="true"></vue-form-field>
                                        <vue-form-field v-else :field="`parameters.$.name`" :bare="true" v-model="newParameter.name"></vue-form-field>
                                    </td>
                                    <td>
                                        <vue-form-field v-if="index < parameters.length - 1" :field="`parameters.${index}.description`" :bare="true"></vue-form-field>
                                        <vue-form-field v-else :field="`parameters.$.description`" :bare="true" v-model="newParameter.description"></vue-form-field>
                                    </td>
                                    <td>
                                        <vue-form-field v-if="index < parameters.length - 1" :field="`parameters.${index}.isRequired`" :bare="true"></vue-form-field>
                                        <vue-form-field v-else :field="`parameters.$.isRequired`" :bare="true" v-model="newParameter.isRequired"></vue-form-field>
                                    </td>
                                    <td class="icon">
                                        <btn v-if="index < parameters.length - 1" icon="trash" title="Verwijderen" class="icon" @click.prevent="removeParameter(index)"></btn>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                    <template slot="content" v-else>
                        <p>Klik eerst op de knop "Script toevoegen" om het code voorbeeld te kunnen inzien.</p>
                    </template>
                </block>
            </div>
        </div>

        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="content">
                        <button type="button" class="full" title="Opslaan" :disabled="saving" @click.prevent="$refs.form.submit()">
                            <template v-if="saving"><icon name="refresh" :spin="true" /></template>
                            <template v-else>{{ submitText }}</template>
                        </button>
                        <ul class="errors" v-if="formErrors.length > 0 || submitErrors.length > 0">
                            <li v-for="(error, index) of formErrors" :key="index">
                                <icon name="exclamation-circle"></icon> {{ error }}
                            </li>
                            <li v-for="(error, index) of submitErrors" :key="index">
                                <icon name="exclamation-circle"></icon> <vue-markdown :source="error"></vue-markdown>
                            </li>
                        </ul>
                    </template>
                </block>
            </div>
        </div>
    </vue-form>
</template>

<script>
    import uuidv4 from 'uuid/v4'
    import omit from 'lodash/omit'
    import cloneDeep from 'lodash/cloneDeep'
    import MonacoEditor from 'vue-monaco'
    import {enableBodyScroll, disableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock'
    import SimpleSchema from '@qiri/simpl-schema'
    import VueMarkdown from '@qiri/vue-markdown'
    import {Script} from '@qiri/models/api-template'

    import ButtonSelect from '@/components/Partials/ButtonSelect'
    import * as examples from './examples'

    const TAG = Symbol('tag')

    export default {
        name: 'page-scripts-Form',
        components: {
            MonacoEditor,
            VueMarkdown,
            ButtonSelect
        },
        data () {
            return {
                form: {},
                tokens: [],
                script: {},
                headScript: null,
                loading: true,
                saving: false,
                submitErrors: [],
                editorOptions: {
                    minimap: {
                        enabled: false
                    }
                },
                exampleOptions: {},
                exampleEditorOptions: {
                    minimap: {
                        enabled: false
                    },
                    readOnly: true
                },
                showHtmlEditor: true,
                showCodeEditor: true,
                showParameters: false,
                showExamples: false,
                newParameter: {
                    name: '',
                    description: '',
                    isRequired: false
                }
            }
        },
        async mounted () {
            if (this.formMode === 'new-version') {
                if (!this.scriptID) {
                    throw new Error(`Missing property "scriptID".`)
                }

                this.script = await this.$model.get(`/env/${this.$environment}/api-template/Script`, this.scriptID)
                if (!this.script) {
                    throw new Error(`Script "${this.scriptID}" not found.`)
                }

                this.headScript = cloneDeep(this.script)
            } else if (this.formMode === 'view-version' || this.formMode === 'edit-version') {
                // Check route arguments.
                if (!this.scriptID) {
                    throw new Error(`Missing property "scriptID".`)
                }
                if (!this.version) {
                    throw new Error(`Missing property "version".`)
                }

                // Get the current script's version, so we know what we're going to override.
                this.headScript = await this.$model.get(`/env/${this.$environment}/api-template/Script`, this.scriptID)
                if (!this.headScript) {
                    throw new Error(`Script "${this.scriptID}" not found.`)
                }

                // Get the script's version to view.
                if (this.headScript.version === this.version) {
                    this.script = cloneDeep(this.headScript)
                } else {
                    this.script = await this.$model.get(`/env/${this.$environment}/api-template/Script`, this.scriptID, {
                        version: this.version
                    })
                }
                if (!this.script) {
                    throw new Error(`Script "${this.scriptID}" version ${this.version} not found.`)
                }
            } else {
                this.script = this.schema.clean({
                    scriptID: uuidv4(),
                    version: 1,
                    activeVersion: 1,
                    code: `/**
 * Process API-request.
 *
 * @param {Request} req The request.
 * @param {Function} waitUntil Tell Qiri to wait until the given promise handle resolves before finishing execution (does not delay the response).
 */
 async function handleRequest (req, waitUntil) {
  const result = {
    // ...
  }
  return new Response(JSON.stringify(result), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * Call the Qiri API.
 *
 * @param {string} url The (relative) URL of the API.
 * @param {object} params The parameters to include.
 */
async function callAPI (url, params) {
  const urlParams = new URLSearchParams()
  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      for (const entry of value) {
        urlParams.append(key, \`\${entry}\`)
      }
    } else {
      urlParams.set(key, \`\${value}\`)
    }
  }

  const response = await fetch(\`/query/\${url}?\${urlParams}\`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-store'
  })
  const result = await response.json()
  return result && result.results || []
}

/**
 * Posts the given event.
 *
 * @param {string} recordID The ID of the record / subject of the event.
 * @param {string} type The type of the event.
 * @param {object} payload The event payload.
 * @returns {object} The created event including the assigned event ID.
 */
async function postEvent (recordID, type, payload) {
  const response = await fetch('/event', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      recordID,
      type,
      payload
    })
  })
  return await response.json()
}

/**
 * Get events.
 *
 * @param {string} recordID The ID of the record / subject of the event.
 * @param {string} type The type of the event.
 */
async function getEvents (recordID, type, {start, end} = {}) {
  const urlParams = new URLSearchParams()
  urlParams.set('recordID', recordID)
  urlParams.set('type', type)

  if (start) {
    urlParams.set('start', start instanceof Date ? start.toISOString() : start)
  }
  if (end) {
    urlParams.set('end', end instanceof Date ? end.toISOString() : end)
  }

  const response = await fetch(\`/event?\${urlParams}\`)
  if (response.status !== 200) {
    return []
  }

  return await response.json()
}

// Start handling requests.
addEventListener('fetch', event => {
  event.respondWith(handleRequest(event.request, (handle) => event.waitUntil(handle)))
})
                    `,
                    htmlEnabled: false,
                    htmlContent: '<p></p>'
                })
            }

            // Load tokens for example.
            this.tokens = await this.$model.fetch(`/env/${this.$environment}/api/APIToken/listValid`)

            this.loading = false

            // Watch "newParameter" to automatically add parameter to list once a name is entered.
            this.$watch(
                'newParameter',
                async (record) => {
                    if (record.name !== '') {
                        this.script.parameters = this.script.parameters || []
                        this.script.parameters.push(record)
                        this.newParameter = {
                            name: '',
                            description: '',
                            isRequired: false
                        }
                    }
                },
                {deep: true}
            )
        },
        beforeDestroy () {
            clearAllBodyScrollLocks()
        },
        computed: {
            schema () {
                return Script
            },
            scriptID () {
                return this.$route.params.scriptID
            },
            version () {
                if (this.formMode === 'view-version' || this.formMode === 'edit-version') {
                    return Number(this.$route.params.version)
                } else if (this.formMode === 'new-version') {
                    return this.script.version + 1
                } else {
                    return this.script.version
                }
            },
            title () {
                return this.$route.meta.title.replace('{version}', `${this.version}`)
            },
            submitText () {
                if (this.$route.meta.submitText) {
                    return this.$route.meta.submitText
                        .replace('{version}', `${this.version}`)
                        .replace('{nextVersion}', `${(this.headScript ? this.headScript.version : this.version) + 1}`)
                } else {
                    return this.title
                }
            },
            formMode () {
                return this.$route.meta.form.mode
            },
            formErrors () {
                let messages = []
                if (this.form.errors && this.form.errors.length > 0) {
                    for (const error of this.form.errors) {
                        let message = `${error.message}.`
                        // Don't show duplicate messages.
                        const duplicateMessage = messages.find(x => x === message)
                        if (!duplicateMessage) {
                            messages.push(message)
                        }
                    }
                }
                return messages
            },
            parameters () {
                return [
                    ...(this.script.parameters || []),
                    this.newParameter
                ]
            },
            parameterSchema () {
                const schema = {}
                const parameters = this.script.parameters || []
                for (const parameter of parameters) {
                    schema[parameter.name] = {
                        type: String,
                        label: parameter.name,
                        description: parameter.description,
                        optional: !parameter.isRequired
                    }
                }
                return new SimpleSchema(schema)
            },
            exampleOptionsSchema () {
                return new SimpleSchema({
                    version: {
                        type: String,
                        label: 'Versie',
                        allowedValues: {
                            'Actief': 'active',
                            'Laatste': 'latest',
                            [`Huidige versie (versie ${this.version})`]: `${this.version}`
                        },
                        defaultValue: `${this.version}`
                    },
                    language: {
                        type: String,
                        label: 'Omgeving',
                        allowedValues: Object.entries(examples)
                            .filter(([name, example]) => example.supports({html: this.script.htmlEnabled}))
                            .reduce(
                                (result, [name, example]) => Object.assign({}, result, {
                                    [example.title]: name
                                }),
                                {}
                            ),
                        defaultValue: 'url'
                    },
                    token: {
                        type: String,
                        label: 'API-token',
                        allowedValues: this.tokens.reduce(
                            (result, token) => Object.assign({}, result, {
                                [token.description]: token.token
                            }),
                            {
                                '{TOKEN}': null
                            }
                        ),
                        optional: true
                    },
                    showParameters: {
                        type: Boolean,
                        optional: true
                    },
                    parameters: {
                        type: this.parameterSchema,
                        optional: true
                    }
                })
            },
            selectedExample () {
                return this.exampleOptions.language ? examples[this.exampleOptions.language] : null
            },
            selectedExampleCode () {
                if (!this.selectedExample) {
                    return ''
                }
                const urlHost = this.$api.host('http', 'api')

                const allSearchParams = new URLSearchParams()
                const baseSearchParams = new URLSearchParams()
                const parameterSearchParams = new URLSearchParams()

                allSearchParams.append('id', this.scriptID)
                baseSearchParams.append('id', this.scriptID)

                if (this.exampleOptions.version !== 'active') {
                    allSearchParams.append('version', this.exampleOptions.version)
                    baseSearchParams.append('version', this.exampleOptions.version)
                }

                if (this.exampleOptions.parameters) {
                    for (const [key, value] of Object.entries(this.exampleOptions.parameters)) {
                        if (value) {
                            allSearchParams.append(key, value)
                            parameterSearchParams.append(key, value)
                        }
                    }
                }

                return this.selectedExample.code({
                    url: `${urlHost}/script?${allSearchParams}`,
                    baseUrl: `${urlHost}/script?${baseSearchParams}`,
                    parameterSearchParams,
                    parameters: this.exampleOptions.parameters || {},
                    token: this.exampleOptions.token ? this.exampleOptions.token : undefined,
                    html: this.script.htmlEnabled
                })
            }
        },
        methods: {
            htmlEditorDidMount (editor) {
                editor.getModel().updateOptions({
                    tabSize: 2
                })
                // this._scrollLockTarget = editor.getDomNode()
            },
            codeEditorDidMount (editor) {
                editor.getModel().updateOptions({
                    tabSize: 2
                })
                // this._scrollLockTarget = editor.getDomNode()
            },
            editorMouseEnter () {
                /* if (this._scrollLockTarget) {
                    disableBodyScroll(this._scrollLockTarget)
                } */
            },
            editorMouseLeave () {
                /* if (this._scrollLockTarget) {
                    enableBodyScroll(this._scrollLockTarget)
                } */
            },
            removeParameter (index) {
                this.script.parameters.splice(index, 1)
            },
            async doSubmit () {
                this.saving = true
                this.submitErrors = []
                try {
                    const script = omit(this.schema.clean(this.script), 'scriptID')


                    if (this.formMode === 'create') {
                        await this.$model.create(`/env/${this.$environment}/api-template/Script`, this.script.scriptID, script)
                        this.$router.replace({
                            name: 'api-template.scripts.view-version',
                            params: {
                                scriptID: this.script.scriptID,
                                version: 1
                            },
                            query: this.$route.query
                        })
                    } else {
                        if (this.formMode === 'new-version' || this.formMode === 'view-version') {
                            script.version = this.headScript.version + 1
                            script.activeVersion = this.headScript.activeVersion
                        }

                        await this.$model.update(`/env/${this.$environment}/api-template/Script`, this.script.scriptID, { $set: script })
                        this.$router.replace({
                            name: 'api-template.scripts.history',
                            params: {
                                scriptID: this.script.scriptID
                            },
                            query: this.$route.query
                        })
                    }
                } catch (err) {
                    if (err.error === 'validation-error') {
                        this.submitErrors = err.details.map(error => error.message)
                    } else {
                        this.submitErrors = [err.message]
                    }
                } finally {
                    this.saving = false
                }
            },
            tag (obj) {
                if (obj && !obj[TAG]) {
                    obj[TAG] = uuidv4()
                }
                return obj && obj[TAG]
            }
        }
    }
</script>

<style>
.editor {
  height: 600px;
}
.example-editor {
  height: 350px;
}
</style>
