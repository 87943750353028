<template>
    <vue-nested-form :schema="schema" field="protocol.settings">
        <vue-form-field field="path"></vue-form-field>
    </vue-nested-form>
</template>

<script>
    import Local from '@qiri/models/protocols/Local'

    export default {
        name: 'page-feeds-protocol-Local',
        computed: {
            schema () {
                return Local
            }
        }
    }
</script>
\