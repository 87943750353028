import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  median: {
    type: Number,
    label: 'Mediaan'
  },
  average: {
    type: Number,
    label: 'Gemiddelde'
  },
  deviation: {
    type: Number,
    label: 'Standaarddeviatie'
  }
})
