<template>
    <vue-nested-form :schema="schema" field="protocol.settings">
        <vue-form-field field="host"></vue-form-field>
        <vue-form-field field="port"></vue-form-field>
        <vue-form-field field="username"></vue-form-field>
        <vue-form-field field="password"></vue-form-field>
        <vue-form-field field="location"></vue-form-field>
    </vue-nested-form>
</template>

<script>
    import SFTP from '@qiri/models/protocols/SFTP'

    export default {
        name: 'page-feeds-protocol-SFTP',
        computed: {
            schema () {
                return SFTP
            }
        }
    }
</script>
