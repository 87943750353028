import SimpleSchema from '@qiri/simpl-schema'
import models from './index'
import {getModelTable} from './util'
import * as protocols from '../protocols'
import * as formats from '../formats'
import encodings from '../formats/encodings'
import Schedule from '../task-scheduler/Schedule'

SimpleSchema.setDefaultMessages({
  messages: {
    nl: {
      requiredAtleastOnce ({ model, targets }) {
        return `Minstens één veld van de tabel ${getModelTable(model)} moet zijn toegewezen`
      }
    }
  }
})

/**
 * @todo
 */
export const factory = ({ protocolType, formatType } = {}) => new SimpleSchema({
  'feedID': {
    type: String,
    primaryKey: true
  },
  'name': {
    type: String,
    label: 'Naam'
  },
  'description': {
    type: String,
    multiline: true,
    label: 'Omschrijving',
    optional: true
  },
  'schedule': {
    type: Schedule,
    label: 'Planning',
    optional: true
  },
  'state': {
    type: String,
    label: 'Staat',
    defaultValue: 'enabled',
    allowedValues: [
      'enabled',
      'disabled'
    ]
  },
  'taskID': {
    type: String,
    label: 'Taak ID',
    optional: true
  },
  // Protocol
  'protocol': {
    type: Object,
    optional: true
  },
  'protocol.type': {
    type: String,
    label: 'Protocol',
    allowedValues: Object.keys(protocols)
  },
  'protocol.settings': {
    type: protocolType ? protocols[protocolType] : Object,
    blackbox: !protocolType,
    label: 'Protocol instellingen'
  },
  'protocol.location': {
    type: String,
    label: 'Bestandsnaam en locatie',
    optional: true
  },
  'protocol.ignoreAlreadyProcessed': {
    type: Boolean,
    label: 'Negeer bestanden die in het verleden al zijn verwerkt',
    optional: true
  },
  'protocol.multiMode': {
    type: String,
    label: 'Indien er meerdere bestanden gevonden zijn',
    optional: true,
    defaultValue: 'byDate-asc',
    allowedValues: [
      'byDate-asc',
      'byDate-desc',
      'byDate-last',
      'byDate-first',
      'byName-asc',
      'byName-desc',
      'byName-last',
      'byName-first',
      'asc',
      'desc',
      'last',
      'first'
    ]
  },
  // File format
  'format': {
    type: Object
  },
  'format.encoding': {
    type: String,
    label: 'Encoding',
    allowedValues: [].concat(encodings).sort((a, b) => a < b ? -1 : a === b ? 0 : 1),
    optional: true
  },
  'format.type': {
    type: String,
    label: 'Bestandsformaat',
    allowedValues: Object.keys(formats)
  },
  'format.settings': {
    type: formatType ? formats[formatType] : Object,
    blackbox: !formatType,
    label: 'Bestandsformaat instellingen'
  },
  // File retention
  'retention': {
    type: Object,
    optional: true
  },
  'retention.deleteFromRemote': {
    type: Boolean,
    label: 'Verwijder bronbestand na verwerking',
    optional: true
  },
  'retention.keepForDays': {
    type: SimpleSchema.Integer,
    label: 'Aantal dagen om een kopie in Qiri van het bronbestand te bewaren',
    optional: true
  },
  // Mappings
  'model': {
    type: String,
    label: 'Model'
  },
  'sourceFields': {
    type: Array,
    label: 'Velden uit het bronbestand'
  },
  'sourceFields.$': {
    type: String,
    label: 'Veld uit het bronbestand'
  },
  'mappings': {
    type: Array,
    label: 'Mappings'
  },
  'mappings.$': {
    type: Object,
    label: 'Mapping'
  },
  'mappings.$.source': {
    type: String,
    label: 'Veld uit bronbestand',
    optional: true
  },
  'mappings.$.target': {
    type: String,
    label: 'Qiri-veld naam'
  },
  'mappings.$.model': {
    type: String,
    label: 'Qiri-veld tabel'
  },
  'mappings.$.correctionTemplate': {
    type: String,
    label: 'Correctie template',
    optional: true
  },
  // Import rules
  'importMode': {
    type: String,
    label: 'Welke regels verwerken',
    defaultValue: 'all',
    allowedValues: [
      'all',
      'update-only',
      'insert-only'
    ]
  },
  'duplicationMode': {
    type: String,
    label: 'Als er aan meerdere regels gekoppeld kan worden',
    optional: true,
    allowedValues: [
      'update-first',
      'update-all'
    ]
  },
  'actionWhenMissing': {
    type: String,
    label: 'Actie als een regel bekend is, maar niet meer voorkomt in het bronbestand',
    optional: true,
    allowedValues: [
      'ignore',
      'mark-as-unavailable'
    ]
  },
  'mergeMode': {
    type: String,
    label: 'Bij relaties van 1 naar meerdere records',
    optional: true,
    allowedValues: [
      'override',
      'concat'
    ]
  },
  'importCountWarningThreshold': {
    type: Number,
    label: 'Als het aantal geïmporteerde regels minder is dan het opgegeven percentage ten opzichte van de vorige data-import, genereer dan een foutmelding',
    optional: true,
    min: 1,
    max: 100
  }
})

/**
 * @todo
 */
const Feed = factory()
export default Feed

/**
 * @static
 */
Feed.getSchemaFor = (doc) => factory({
  protocolType: doc && doc.protocol && doc.protocol.type,
  formatType: doc && doc.format && doc.format.type
})
