import get from 'lodash/get'
import debounce from 'lodash/debounce'
import {VueFormContext} from '../providers'
import {getFormSchema, randomID} from '../util'

export default {
  name: 'VueForm',
  provide() {
    return {
      [VueFormContext]: this
    }
  },
  props: {
    tag: {
      type: String,
      default: 'form'
    },
    state: {
      type: Object,
      default () {
        return {}
      }
    },
    schema: {
      type: Object
    },
    validationMode: {
      type: String,
      default: 'field'
    },
    value: {
      type: Object,
      default: null
    },
    defaults: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      childForms: {},
      dirtyFields: [],
      unvalidatedFields: [],
      formID: randomID()
    }
  },
  render (h) {
    if (this.tag === 'form') {
      return h(
        'form',
        {
          attrs: {
            id: this.formID
          },
          on: {
            submit: (evt) => this.onSubmit(evt)
          }
        },
        this.$slots.default
      )
    } else {
      return h(
        this.tag,
        {},
        this.$slots.default
      )
    }
  },
  mounted () {
    this.resetState()

    if (this.schema) {
      this.updateSchema(this.schema)
    }
    this.$watch('schema', (schema) => this.updateSchema(schema))
    this.$watch('value', debounce(() => this.validate('watch'), 100), { deep: true })
  },
  methods: {
    addChildForm (childForm) {
      this.$set(this.childForms, childForm._uid, childForm)
      //this.validate()
    },
    removeChildForm (childForm) {
      this.$delete(this.childForms, childForm._uid, childForm)
      //this.validate()
    },
    resetState () {
      this.$set(this.state, 'valid', false)
      this.$set(this.state, 'invalid', true)
      this.$set(this.state, 'errors', [])
      this.$set(this.state, 'ready', false)
      this.$set(this.state, 'submitted', false)
    },
    updateSchema (schema) {
      this.resetState()
      if (schema) {
        this.validationContext = schema.newContext()

        // Set defaults from the schema.
        const record = this.validationContext.clean(this.value || {})
        this.$emit('input', record)

        //this.validate('initial')
      } else {
        this.validationContext = null
      }
    },
    isValid (field) {
      if (!this.schema) {
        return
      }
      // Check if we know of any child forms with the given field.
      const childForm = Object.values(this.childForms).find(childForm => childForm.field === field)
      if (childForm) {
        const childState = childForm.validate()
        return childState.valid
      } else {
        return false
      }
    },
    fieldIsValid (field) {
      return !this.state.errors.find(x => x.name === field)
    },
    markFieldDirty (field) {
      if (this.dirtyFields.indexOf(field) === -1) {
        this.dirtyFields.push(field)
      }
      if (this.unvalidatedFields.indexOf(field) === -1) {
        this.unvalidatedFields.push(field)
      }
    },
    validate (context) {
      if (!this.validationContext) {
        return
      }
      const record = this.validationContext.clean(this.value || {})

      let keys
      if (context === 'watch' && this.validationMode === 'field') {
        // Try and figure out the keys that got changed.
        keys = this.unvalidatedFields
        this.unvalidatedFields = []
      }

      let isValid = this.validationContext.validate(record, {
        keys
      })
      let errors = this.validationContext.validationErrors().map(error => ({
        ...error,
        message: this.validationContext.keyErrorMessage(error.name)
      }))

      // Validate child forms.
      Object.values(this.childForms).forEach(childForm => {
        const childState = childForm.validate(context)
        if (childState.invalid) {
          isValid = false
        }
        childState.errors.forEach(error => errors.push({
          ...error,
          name: `${childForm.field}.${error.name}`
        }))
      })

      // Update the state.
      this.state.valid = isValid
      this.state.invalid = !isValid
      this.state.errors = errors
      this.state.ready = true
    },
    submit () {
      if (this.tag === 'form') {
        const formEl = document.querySelector('#' + this.formID)
        formEl.submit()
      } else {
        this.onSubmit()
      }
    },
    onSubmit (evt) {
      this.validate('submit')
      if (evt) {
        evt.preventDefault()
      }
      if (this.state.valid === true) {
        this.$emit('submit', evt)
      }
      this.state.submitted = true
    }
  }
}
