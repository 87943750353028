import SimpleSchema from '@qiri/simpl-schema'

import Stream, {pull} from '@qiri/stream'
import {iterate} from '@qiri/stream/sources/iterate'
import {map} from '@qiri/stream/operators/map'
import {flatMap} from '@qiri/stream/operators/flatMap'
import {reduce} from '@qiri/stream/operators/reduce'

/**
 * @todo
 */
export const meta = {
  name: 'sum',
  label: 'Waarden samenvoegen'
}

/**
 * @todo
 */
export default function sum ({schema}) {
  // Determine input from source.
  if (schema.schema('output').type.singleType !== Array) {
    return false
  }
  const elementType = schema.schema('output.$').type.singleType

  // Ensure type is supported.
  const allowedTypes = [Number, SimpleSchema.Integer]
  if (allowedTypes.indexOf(elementType) === -1) {
    return false
  }

  return {
    meta,
    schema: new SimpleSchema({
      'output': {
        type: elementType
      }
    }),
    get [Stream.sink] () {
      return source => pull(
        source,
        flatMap(list => pull(
          iterate(list),
          map(x => Number(x)),
          reduce((acc, cur) => acc + cur, 0)
        ))
      )
    }
  }
}
