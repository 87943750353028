<template>
    <section v-if="loading" class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>API-log rapportage</h1></template>
                    <template slot="content">
                        <p>Bezig met laden...</p>
                    </template>
                </block>
            </div>
        </div>
    </section>
    <section v-else class="padding">
        <div class="grid">
            <div class="column size--100">
                <block :table="true">
                    <template slot="title"><h1>API-log rapportage</h1></template>
                    <template slot="content">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Maand</th>
                                    <th>Omschrijving gebruik en gebruiker(s)</th>
                                    <th>Aantal API-verzoeken</th>
                                    <th>Aantal API-script-verzoeken</th>
                                    <th>Aantal geëxporteerd record in CSV-bestand</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="month of months">
                                    <tr :key="month.period">
                                        <td>
                                            <btn v-if="isExpanded(month)" icon="minus" title="Inklappen" class="icon" @click.prevent="toggleMonth(month, false)"></btn>
                                            <btn v-else icon="plus" title="Uitklappen" class="icon" @click.prevent="toggleMonth(month, true)"></btn>
                                            <b>{{ month.period }}</b>
                                        </td>
                                        <td>&nbsp;</td>
                                        <td><b>{{ formatNumber(month.count) }}</b></td>
                                        <td><b>{{ formatNumber(month.apiScriptCount) }}</b></td>
                                        <td><b>{{ formatNumber(month.csvRecordCount) }}</b></td>
                                    </tr>
                                    <template v-if="isExpanded(month)">
                                        <tr v-for="entry of getForMonth(month)" :key="entry.id">
                                            <td>&nbsp;</td>
                                            <td>{{ entry.description }}</td>
                                            <td>{{ formatNumber(entry.count) }}</td>
                                            <td>{{ formatNumber(entry.apiScriptCount) }}</td>
                                            <td>{{ formatNumber(entry.csvRecordCount) }}</td>
                                        </tr>
                                    </template>
                                </template>
                            </tbody>
                        </table>
                    </template>
                </block>
            </div>
        </div>
    </section>
</template>

<script>
    import moment from 'moment'

    export default {
        name: 'page-api-reporting-Overview',
        data () {
            return {
                allMonths: [],
                periods: {},
                states: {},
                loading: true
            }
        },
        async mounted () {
            this.$model.list(`/env/${this.$environment}/api-reporting/APIReport/list`, 'allMonths')
            this.loading = false
        },
        computed: {
            months () {
                const months = [].concat(this.allMonths).sort((a, b) => {
                    if (a.period < b.period) {
                        return 1
                    } else if (a.period > b.period) {
                        return -1
                    } else {
                        return 0
                    }
                })
                return months
            }
        },
        methods: {
            formatDate (d) {
                return d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : ''
            },
            formatNumber (num, decimals = 0) {
                let [major, minor] = num.toFixed(decimals).split('.')
                major = major.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                return minor ? `${major},${minor}` : major
            },
            toggleMonth (month, expanded) {
                let state = this.states[month.period]
                if (state && expanded !== true) {
                    this.$model.stop(state)
                    this.$delete(this.states, month.period)
                } else if (!state && expanded !== false) {
                    state = this.$model.list(
                        `/env/${this.$environment}/api-reporting/APIReport/listByMonth`,
                        `periods.${month.period}`,
                        {
                            period: month.period
                        }
                    )
                    this.$set(this.states, month.period, state)
                }
            },
            isExpanded (month) {
                return !!this.states[month.period]
            },
            getForMonth (month) {
                return this.periods[month.period] || []
            }
        }
    }
</script>
