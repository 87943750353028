// Pages
import TokenOverview from './components/token/Overview'
import TokenForm from './components/token/Form'

export default {
    'api.tokens.overview': {
        path: '/api/tokens/overview',
        component: TokenOverview,
        meta: {
            audience: 'actor:/env/*/api/APIToken',
        }
    },
    'api.tokens.create': {
        path: '/api/tokens/create',
        component: TokenForm,
        meta: {
            audience: 'actor:/env/*/api/APIToken',
            title: 'API-token toevoegen',
            form: {
                mode: 'create'
            }
        }
    },
    'api.tokens.update': {
        path: '/api/tokens/:tokenID/edit',
        component: TokenForm,
        meta: {
            audience: 'actor:/env/*/api/APIToken',
            title: 'API-token aanpassen',
            form: {
                mode: 'edit'
            }
        }
    }
}
