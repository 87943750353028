import Flatpickr from 'flatpickr'
import {Dutch} from 'flatpickr/dist/l10n/nl'
import moment from 'moment'

export default {
  name: 'VueFormField_Date',
  props: {
    fieldID: String,
    value: [String, Date],
    inline: Boolean,
    disabled: Boolean,
    time: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fp: null,
      valueCopy: this.value
    }
  },
  render (h) {
    return h(
      'input',
      {
        staticClass: this.inline ? 'inline' : '',
        attrs: {
          id: this.fieldID,
          type: 'text',
          value: this.value,
          disabled: this.disabled
        },
        on: {
          change: (evt) => this.onInputChange(evt)
        }
      }
    )
  },
  mounted () {
    if (!this.fp) {
      this.fp = new Flatpickr(this.$el, {
        locale: Dutch,
        dateFormat: 'Y-m-dTH:i:S.000Z',
        altFormat: this.time ? 'j-n-Y H:i' : 'j-n-Y',
        altInput: true,
        enableTime: this.time,
        weekNumbers: true,
        time_24hr: true,
        onChange: (v, s) => this.onChange(v, s)
      })
    }
  },
  beforeDestroy () {
    if (this.fp) {
      this.fp.destroy()
      this.fp = null
    }
  },
  watch: {
    'value' (value) {
      value = value ? moment(value).toDate() : null
      if (this.valueCopy !== value) {
        this.fp && this.fp.setDate(value, true)
      }
    }
  },
  methods: {
    onInputChange (evt) {
      // Ignore for now.
    },
    onChange (dates, str) {
      const value = dates.length > 0 ? moment(dates[0]).format() : null
      this.valueCopy = value
      this.$emit('input', value)
    }
  }
}
