import SimpleSchema from '@qiri/simpl-schema'

/**
 * @todo
 */
export const Stage = new SimpleSchema({
  'operator': {
    type: String
  },
  'configuration': {
    type: Object,
    blackbox: true,
    optional: true
  }
})

/**
 * @todo
 */
export default new SimpleSchema({
  'stages': {
    type: Array
  },
  'stages.$': {
    type: Stage
  }
})
