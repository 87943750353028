import SimpleSchema from '@qiri/simpl-schema'

/**
 * @todo
 */
export default new SimpleSchema({
  'scriptID': {
    type: String,
    label: 'Script ID',
    description: 'De unieke waarde waarmee het document geïdentificeerd kan worden',
    primaryKey: true
  },
  'version': {
    type: SimpleSchema.Integer,
    label: 'Script versie',
    description: 'De versie van het script'
  },
  'activeVersion': {
    type: SimpleSchema.Integer,
    label: 'Script actieve versie',
    description: 'De actieve versie van het script',
    optional: true
  },
  'name': {
    type: String,
    label: 'Naam',
    description: 'De naam van het script'
  },
  'description': {
    type: String,
    label: 'Omschrijving',
    description: 'De omschrijving van het script',
    optional: true
  },
  'code': {
    type: String,
    label: 'Code',
    description: 'De JavaScript code'
  },
  'htmlEnabled': {
    type: Boolean,
    label: 'HTML template gebruiken',
    description: 'Indien ingeschakeld, transformeer een JSON output uit het script met een HTML template',
    optional: true
  },
  'htmlContent': {
    type: String,
    label: 'HTML template content',
    description: 'De HTML template content',
    optional: true
  },
  'parameters': {
    type: Array,
    label: 'URL parameters',
    optional: true
  },
  'parameters.$': {
    type: Object,
    label: 'URL parameter'
  },
  'parameters.$.name': {
    type: String,
    label: 'Naam'
  },
  'parameters.$.description': {
    type: String,
    label: 'Omschrijving',
    optional: true
  },
  'parameters.$.isRequired': {
    type: Boolean,
    label: 'Is verplicht'
  }
})
