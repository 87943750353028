<template>
    <vue-nested-form :schema="schema" :field="field">
        <vue-form-field field="address"></vue-form-field>
        <vue-form-field field="postalCode"></vue-form-field>
        <vue-form-field field="city"></vue-form-field>
        <vue-form-field field="country" :options="countries"></vue-form-field>
    </vue-nested-form>
</template>

<script>
    import {Address} from '@qiri/models/environment'
    import countryList from '@qiri/models/environment/data/countries'

    export default {
        name: 'page-companies-AddressForm',
        props: {
            field: String
        },
        data () {
            return {}
        },
        computed: {
            schema () {
                return Address
            },
            countries () {
                let countries = [
                    [ null, 'Geen' ],
                    ...Object.entries(countryList).sort((a, b) => a[1] > b[1] ? 1 : a[1] < b[1] ? -1 : 0)
                ]
                return countries
            }
        },
        methods: {

        }
    }
</script>
