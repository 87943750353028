import SimpleSchema from '@qiri/simpl-schema'
import {MODELS} from '@qiri/models/data'

import Stream, {pull} from '@qiri/stream'
import {once} from '@qiri/stream/sources/once'
import {map} from '@qiri/stream/operators/map'
import {filter} from '@qiri/stream/operators/filter'
import {collect} from '@qiri/stream/sinks/collect'

import {fieldTypeToRealType} from '../../data/util'

/**
 * @todo
 */
export default function table ({}, configuration, {model, store}) {
  // TODO: Use "store" to determine available "tables"?

  // Create the configuration's schema.
  const configurationSchema = new SimpleSchema({
    table: {
      type: String,
      label: 'Tabel',
      description: 'Kies een tabel.',
      allowedValues: MODELS.map(modelName => [model(modelName).table, modelName])
    }
  })

  // Just provide the configuration's schema if no configuration was actually provided.
  if (!configuration) {
    return {configurationSchema}
  }

  const {
    table: modelName
  } = configuration
  const modelSchema = model(modelName)
  const modelType = modelSchema.type.singleType

  // Get the store of the table.
  const tableStore = store(modelName)

  // Return schema and source.
  return {
    configurationSchema,
    schema: new SimpleSchema({
      'output': {
        type: modelType,
        name: modelName,
        label: modelSchema.label
      }
    }),
    store: tableStore,
    get [Stream.source] () {
      return Stream.source in tableStore ? tableStore[Stream.source] : tableStore
    }
  }
}
