import SimpleSchema from '@qiri/simpl-schema'

/**
 * @todo
 */
export function normalize (obj) {
  return {
    kilocalorie: (obj.kilocalorie || 0) / obj.unitQuantity,
    fat: (obj.fat || 0) / obj.unitQuantity,
    saturatedFat: (obj.saturatedFat || 0) / obj.unitQuantity,
    monounsaturatedFat: (obj.monounsaturatedFat || 0) / obj.unitQuantity,
    polyunsaturatedFat: (obj.polyunsaturatedFat || 0) / obj.unitQuantity,
    carbohydrates: (obj.carbohydrates || 0) / obj.unitQuantity,
    sugars: (obj.sugars || 0) / obj.unitQuantity,
    polyols: (obj.polyols || 0) / obj.unitQuantity,
    starch: (obj.starch || 0) / obj.unitQuantity,
    fibers: (obj.fibers || 0) / obj.unitQuantity,
    protein: (obj.protein || 0) / obj.unitQuantity,
    salt: (obj.salt || 0) / obj.unitQuantity,
    transFat: (obj.transFat || 0) / obj.unitQuantity
  }
}

/**
 * @todo
 */
export function compare (a, b, weights) {
  const properties = [
    'kilocalorie',
    'fat',
    'saturatedFat',
    'monounsaturatedFat',
    'polyunsaturatedFat',
    'carbohydrates',
    'sugars',
    'polyols',
    'starch',
    'fibers',
    'protein',
    'salt',
    'transFat'
  ]

  a = normalize(a)
  b = normalize(b)

  let results = []
  for (const prop of properties) {
    const av = a[prop]
    const bv = b[prop]
    const weight = weights[prop]

    if (weight !== 0 && av !== bv) {
      results.push({
        item: prop,
        healthImpact: av > bv ? weight : -weight
      })
    }
  }

  return {
    healthImpact: results.reduce((acc, cur) => acc + cur.healthImpact, 0),
    details: results
  }
}

export default new SimpleSchema({
  unitType: {
    type: String,
    label: 'Eenheid type',
    description: 'De type eenheid van het product (bijv. stuks, milliliters, gram).'
  },
  unitQuantity: {
    type: Number,
    label: 'Eenheid aantal',
    description: 'Het aantal van de eenheid (bijv. 1 stuks of 100 gram).'
  },
  kilocalorie: {
    type: Number,
    label: 'Kilocalorieën',
    description: 'Aantal kilocalorieën.',
    optional: true
  },
  fat: {
    type: Number,
    label: 'Vet',
    description: 'Hoeveelheid vet (in gram).',
    optional: true
  },
  saturatedFat: {
    type: Number,
    label: 'Verzadigd vet',
    description: 'Hoeveelheid verzadigd vet (in gram).',
    optional: true
  },
  monounsaturatedFat: {
    type: Number,
    label: 'Enkelvoudig onverzadigd vet',
    description: 'Hoeveelheid enkelvoudig onverzadigd vet (in gram).',
    optional: true
  },
  polyunsaturatedFat: {
    type: Number,
    label: 'Meervoudig onverzadigd vet',
    description: 'Hoeveelheid meervoudig onverzadigd vet (in gram).',
    optional: true
  },
  carbohydrates: {
    type: Number,
    label: 'Koolhydraten',
    description: 'Hoeveelheid koolhydraten (in gram).',
    optional: true
  },
  sugars: {
    type: Number,
    label: 'Suikers',
    description: 'Hoeveelheid suikers (in gram).',
    optional: true
  },
  polyols: {
    type: Number,
    label: 'Polyolen',
    description: 'Hoeveelheid polyolen (in gram).',
    optional: true
  },
  starch: {
    type: Number,
    label: 'Zetmeel',
    description: 'Hoeveelheid zetmeel (in gram).',
    optional: true
  },
  fibers: {
    type: Number,
    label: 'Vezels',
    description: 'Hoeveelheid vezels (in gram).',
    optional: true
  },
  protein: {
    type: Number,
    label: 'Eiwitten',
    description: 'Hoeveelheid eiwitten (in gram).',
    optional: true
  },
  salt: {
    type: Number,
    label: 'Zout',// Natrium / Sodium
    description: 'Hoeveelheid zout (in gram).',
    optional: true
  },
  transFat: {
    type: Number,
    label: 'Transvet',
    description: 'Hoeveelheid transvet (in gram).',
    optional: true
  }
})
