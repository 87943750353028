<template>
    <span>
        Kon query-import "<router-link :to="{ name: 'data.query-imports.log', params: { queryImportID: content.queryImportID }, query: $route.query }">{{ content.queryImport }}</router-link>" niet uitvoeren. De ontvangen foutmelding was: <em>{{ content.error.message }}</em>
    </span>
</template>

<script>
    export default {
        name: 'page-status-type-QueryImportError',
        props: {
            message: Object
        },
        computed: {
            content () {
                return this.message.content
            }
        }
    }
</script>
