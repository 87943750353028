import SimpleSchema from '@qiri/simpl-schema'
import EnvironmentSettings from './EnvironmentSettings'

const Unit = new SimpleSchema({
  'name': {
    type: String,
    label: 'Naam'
  },
  'description': {
    type: String,
    label: 'Omschrijving',
    optional: true
  },
  'label': {
    type: String,
    label: 'Label',
  },
  'interval': {
    type: String,
    label: 'Interval',
    optional: true
  },
  'price': {
    type: SimpleSchema.Integer,
    label: 'Prijs',
    optional: true
  },
  'factor': {
    type: SimpleSchema.Integer,
    label: 'Factor'
  }
})

const Module = new SimpleSchema({
  'moduleID': {
    type: String,
    label: 'Module ID'
  },
  'name': {
    type: String,
    label: 'Naam'
  },
  'roles': {
    type: Array,
    label: 'Rollen'
  },
  'roles.$': {
    type: String,
    label: 'Rol'
  },
  'units': {
    type: Array,
    label: 'Eenheden'
  },
  'units.$': {
    type: Unit,
    label: 'Eenheid'
  }
})

const UsageOverview = new SimpleSchema({
  'period': {
    type: String,
    label: 'Periode',
    primaryKey: true
  },

  'modules': {
    type: Array,
    label: 'Modules'
  },
  'modules.$': {
    type: Module,
    label: 'Module'
  },

  'lines': {
    type: Array,
    label: 'Regels'
  },
  'lines.$': {
    type: Object,
    label: 'Regel'
  },
  'lines.$.description': {
    type: String,
    label: 'Regel omschrijving'
  },
  'lines.$.unit': {
    type: Unit,
    label: 'Regel eenheid'
  },
  'lines.$.quantity': {
    type: SimpleSchema.Integer,
    label: 'Regel aantal'
  },
  'lines.$.price': {
    type: SimpleSchema.Integer,
    label: 'Regel totaal'
  },

  'subTotal': {
    type: SimpleSchema.Integer,
    label: 'Totaal exclusief BTW'
  },
  'vat': {
    type: Object,
    label: 'BTW (21%)'
  },
  'vat.percentage': {
    type: SimpleSchema.Integer,
    label: 'BTW percentage'
  },
  'vat.total': {
    type: SimpleSchema.Integer,
    label: 'BTW totaal'
  },
  'total': {
    type: SimpleSchema.Integer,
    label: 'Totaal inclusief BTW'
  },

  'updatedAt': {
    type: Date,
    label: 'Laatst bijgewerkt op'
  }
})
UsageOverview.extend(EnvironmentSettings)

export default UsageOverview
