import SimpleSchema from '@qiri/simpl-schema'

import Snippet from './Snippet'

export {
  Snippet
}

export default new SimpleSchema({
  'Snippet': {
    type: Snippet,
    label: 'Snippet',
    table: 'Snippets'
  }
})
