import Overview from './components/Overview'

export default {
    'usage.overview': {
        path: '/usage/overview',
        component: Overview,
        meta: {
            audience: 'actor:/env/*/usage/Overview',
        }
    }
}
