import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  eventID: {
    type: String,
    label: 'Qiri event ID',
    description: 'De unieke waarde waarmee de event geïdentificeerd kan worden.',
    primaryKey: true
  },
  recordID: {
    type: String,
    label: 'Qiri record ID',
    description: 'Het ID van de record waar de event betrekking op heeft.'
    //index: 1
  },
  type: {
    type: String,
    label: 'Event type',
    description: 'Geeft aan of de persoon opt-out is voor opslag en verwerking van persoongegevens in Qiri.'
  },
  payload: {
    type: Object,
    label: 'Payload',
    description: 'De data die is meegestuurd met de event.',
    blackbox: true
  }
})
