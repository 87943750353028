export default {
  title: 'PHP',
  language: 'php',
  supports: () => true,
  code: ({url, html, token = '{TOKEN}'}) => `<?php
  $ch = curl_init();
  curl_setopt($ch, CURLOPT_URL, '${url}');
  curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
  curl_setopt($ch, CURLOPT_HTTPHEADER, array(
    'Authorization: Bearer ${token}',
    'Cache-Control: no-cache'
  ));
  $result = curl_exec($ch);
  ${html ? '' : '$result = json_decode($result);'}
  curl_close($ch);

  // Toon het resultaat in de console.
  var_dump($result);
?>`
}
