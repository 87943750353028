import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  categoryID: {
    type: String,
    label: 'Qiri product categorie ID',
    description: 'De unieke waarde waarmee de product categorie geïdentificeerd kan worden.',
    primaryKey: true
  },
  name: {
    type: String,
    label: 'Naam',
    description: 'De naam van de categorie.'
  }
})
