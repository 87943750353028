<template>
    <section class="padding">
        <vue-form :state="form" ref="form" :schema="schema" v-model="credentials" class="grid" @submit="doSubmit()">
            <div class="column size--30"></div>
            <div class="column size--40">
                <block>
                    <template slot="title"><h1>Wachtwoord wijzigen</h1></template>
                    <template slot="content">
                        <p>
                            <template v-if="isExpired">De geldigheidsduur van je wachtwoord is verlopen. Kies hieronder s.v.p. een nieuw wachtwoord.</template>
                            <template v-else>Hier kun je een nieuw wachtwoord kiezen.</template>
                            Het wachtwoord moet aan de volgende voorwaarden voldoen:
                            <ul>
                                <li>Niet gelijk zijn aan je huidige wachtwoord.</li>
                                <li>Minimaal 8 karakters lang zijn.</li>
                                <li>Minimaal 1 kleine letter bevatten.</li>
                                <li>Minimaal 1 hoofdletter bevatten.</li>
                                <li>Minimaal 1 cijfer bevatten.</li>
                                <li>Minimaal 1 speciaal karakter bevatten, anders dan een cijfer of letter.</li>
                            </ul>
                        </p>
                        <vue-form-field field="password"></vue-form-field>
                        <vue-form-field field="passwordRepeat"></vue-form-field>
                        <button type="submit" title="Wachtwoord wijzigen" :class="{'full': true, 'disabled': saving}">
                            <template v-if="saving"><icon name="refresh" :spin="true" /></template>
                            <template v-else>Wachtwoord wijzigen</template>
                        </button>
                        <router-link v-if="!isExpired" :to="{ name: 'dashboard' }" class="btn full white">Annuleren</router-link>
                        <ul class="errors" v-if="submitError || formErrors.length > 0">
                            <li v-if="submitError">
                                <icon name="exclamation-circle"></icon> <vue-markdown :source="submitError"></vue-markdown>
                            </li>
                            <li v-for="error of formErrors">
                                <icon name="exclamation-circle"></icon> <vue-markdown :source="error"></vue-markdown>
                            </li>
                        </ul>
                    </template>
                </block>
            </div>
            <div class="column size--30"></div>
        </vue-form>
    </section>
</template>

<script>
    import {createHmac} from 'crypto'
    import VueMarkdown from '@qiri/vue-markdown'
    import SimpleSchema from '@qiri/simpl-schema'
    import {Credentials} from '@qiri/models/security'

    export default {
        name: 'page-change-password',
        components: {
            VueMarkdown
        },
        data () {
            return {
                form: {},
                credentials: {},
                saving: false,
                submitError: null
            }
        },
        computed: {
            isExpired () {
                return this.$token.action === 'ChangePassword'
            },
            schema () {
                const schema = new SimpleSchema({
                    'passwordRepeat': {
                        type: String,
                        label: 'Nieuw wachtwoord herhalen',
                        encrypted: true,
                        custom () {
                            if (this.isSet && this.siblingField('password').value !== this.value) {
                                return 'passwordsMustMatch'
                            }
                        }
                    },
                    'passwordHash': {
                        type: String,
                        autoValue () {
                            const passwordField = this.siblingField('password')
                            if (passwordField.isSet && passwordField.value) {
                                // TODO: Make salt configurable (or atleast not static).
                                const hash = createHmac('sha256', 'qiri')
                                hash.update(passwordField.value, 'utf8')
                                return hash.digest('hex')
                            }
                        }
                    }
                })
                schema.extend(Credentials.pick('password'))
                schema.extend({
                    'password': {
                        type: String,
                        label: 'Nieuw wachtwoord'
                    }
                })
                return schema
            },
            formErrors () {
                let messages = []
                if (this.form.errors && this.form.errors.length > 0) {
                    for (const error of this.form.errors) {
                        if (error.type !== 'required') {
                            let message = `${error.message}.`
                            // Don't show duplicate messages.
                            const duplicateMessage = messages.find(x => x === message)
                            if (!duplicateMessage) {
                                messages.push(message)
                            }
                        }
                    }
                }
                return messages
            }
        },
        methods: {
            async doSubmit () {
                this.saving = true
                this.submitError = null

                const credentials = this.schema.clean(this.credentials)
                try {
                    // Gain access to the API using the supplied token.
                    const api = await this.$rpc.access()

                    // Submit the request.
                    await api.rootActor.dispatch(`/security/ChangePassword/submit`, {
                        passwordHash: credentials.passwordHash
                    })

                    if (this.isExpired) {
                        // Everything went well. Try to login again.
                        const encodedToken = await api.rootActor.dispatch(`/security/Authenticate/continue`)
                        if (!encodedToken) {
                            throw new Error(`No token received`)
                        }

                        // Pass encoded token to plugin, read back decoded token.
                        this.$encodedToken = encodedToken
                        const token = this.$token

                        // Determine next action based on token type.
                        let routeName = 'dashboard'
                        if (token.action === 'TermsAndConditions') {
                            routeName = 'auth.terms-and-conditions'
                        }
                        this.$router.replace({ name: routeName, query: this.$route.query })
                    } else {
                        this.$router.push({ name: 'dashboard', query: this.$route.query })
                    }
                } catch (err) {
                    // TODO: Show custom error if submitted password is same as "old" or if token has expired/is already used.
                    console.error(err)
                    this.submitError = 'Er is iets fout gegaan.'
                    this.saving = false
                }
            }
        }
    }
</script>
