import SimpleSchema from '@qiri/simpl-schema'

import Stream, {pull} from '@qiri/stream'
import {map} from '@qiri/stream/operators/map'
import {flatMap} from '@qiri/stream/operators/flatMap'
import {distinct as distinctOp} from '@qiri/stream/operators/distinct'

/**
 * @todo
 */
export const meta = {
  name: 'distinct',
  label: 'Waarden ontdubbelen'
}

/**
 * @todo
 */
export default function distinct ({schema}, configuration) {
  if (schema.schema('output').type.singleType !== Array) {
    return false
  }

  const allowedTypes = [String, Number, SimpleSchema.Integer, Boolean, Date]
  const elementSchema = schema.schema('output.$')
  const elementType = elementSchema.type.singleType

  if (SimpleSchema.isSimpleSchema(elementType) || (elementType === Object && elementSchema.blackbox !== true)) {
    // Create the configuration's schema.
    const configurationSchema = new SimpleSchema({
      field: {
        type: String,
        optional: true,
        label: 'Veld',
        description: 'Veld om te ontdubbelen.',
        allowedValues: Object.entries(SimpleSchema.isSimpleSchema(elementType) ? elementType.schema() : schema.getObjectSchema('output.$').schema())
          .filter(([propertyName, property]) => allowedTypes.indexOf(property.type.singleType) !== -1)
          .map(([propertyName, property]) => [propertyName, propertyName])
      }
    })

    // Just provide the configuration's schema if no configuration was actually provided.
    if (!configuration) {
      return {
        meta,
        configurationSchema
      }
    }

    const {field} = configuration
    return {
      meta,
      configurationSchema,
      schema: new SimpleSchema({
        'output': {
          type: Array
        },
        'output.$': {
          type: schema.schema('output.$').type.singleType
        }
      }),
      // TODO: Implement using "sort" for better memory usage on large datasets?
      get [Stream.sink] () {
        return source => pull(
          source,
          map(list => distinctOp(x => x[field])(list))
        )
      }
    }
  } else if (allowedTypes.indexOf(elementType) !== -1) {
    return {
      meta,
      schema: new SimpleSchema({
        'output': {
          type: Array
        },
        'output.$': {
          type: elementType
        }
      }),
      // TODO: Implement using "sort" for better memory usage on large datasets?
      get [Stream.sink] () {
        return source => pull(
          source,
          map(list => distinctOp()(list))
        )
      }
    }
  } else {
    return false
  }
}
