export default {
  title: 'Node.js (JavaScript)',
  language: 'javascript',
  supports: () => true,
  code: ({url, html, token = '{TOKEN}'}) => `const fetch = require('node-fetch');

async function execute() {
  const response = await fetch('${url}', {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ${token}'
    },
    mode: 'cors',
    cache: 'no-store'
  });
  const result = await response.${html ? 'text' : 'json'}();

  // Toon het resultaat in de console.
  console.log(result);
}
execute();`
}
