import {quillEditor} from 'vue-quill-editor'

export default {
  name: 'VueFormField_Quill',
  props: {
    fieldID: String,
    value: String,
    disabled: Boolean
  },
  render (h) {
    return h(
      quillEditor,
      {
        attrs: {
          id: this.fieldID,
          disabled: this.disabled
        },
        props: {
          options: {
            theme: 'snow',
            modules: {
              toolbar: ['bold', 'italic', 'underline', {list: 'ordered'}, {list: 'bullet'}],
              clipboard: {matchVisual: false}
            },
            placeholder: ' '
          },
          value: this.value
        },
        on: {
          blur: (evt) => this.onEditorBlur(evt),
          focus: (evt) => this.onEditorFocus(evt),
          input: (value) => this.onInput(value)
        }
      }
    )
  },
  methods: {
    onEditorBlur (evt) {
      evt.container.parentNode.classList.value = 'quill-editor'
    },
    onEditorFocus (evt) {
      evt.container.parentNode.classList.value = 'quill-editor focus'
      evt.enable(true)
    },
    onInput (value) {
      this.$emit('input', value)
    }
  }
}
