export default {
  name: 'VueFormField_Radio',
  inheritAttrs: false,
  props: {
    field: String,
    fieldID: String,
    group: String,
    value: {},
    option: {},
    bare: Boolean,
    disabled: Boolean
  },
  data () {
    return {
      checked: this.value === this.option
    }
  },
  watch: {
    'value' (value) {
      this.checked = value === this.option
    }
  },
  render (h) {
    //console.log(`RADIO ${this.field}: ${this.value} ${this.option} ${this.checked}`)
    const radio = h(
      'input',
      {
        //staticClass: this.checked ? 'checked' : '',
        attrs: {
          id: this.fieldID,
          name: this.group || this.field,
          type: 'radio',
          checked: this.checked,
          disabled: this.disabled
        },
        on: {
          change: (evt) => this.onChange(evt)
        }
      }
    )
    if (this.bare) {
      // Need a label for styling the radio.
      return h('span', {}, [
        radio,
        h('label', { attrs: { for: this.fieldID }})
      ])
    } else {
      return radio
    }
  },
  methods: {
    onChange (evt) {
      if (evt.target.checked) {
        const value = this.option
        this.$emit('input', value)
      }
    }
  }
}
