import SimpleSchema from '@qiri/simpl-schema'

export default new SimpleSchema({
  personID: {
    type: String,
    label: 'Qiri persoon ID',
    description: 'De unieke waarde waarmee de persoon geïdentificeerd kan worden.',
    primaryKey: true
  },
  qiriOptout: {
    type: Boolean,
    label: 'Qiri opt-out',
    description: 'Geeft aan of de persoon opt-out is voor opslag en verwerking van persoongegevens in Qiri.',
    optional: true,
    index: 1
  }
})
