<template>
  <section v-if="loading" class="padding">
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h1>API-script logboek</h1></template>
          <template slot="content">
            <p v-if="loadingError" class="error">{{ loadingError }}</p>
            <p v-else>Bezig met laden...</p>
          </template>
        </block>
      </div>
    </div>
  </section>
  <section v-else class="padding">
    <div class="grid">
      <div class="column size--100">
        <block>
          <template slot="title"><h1>API-script logboek</h1></template>
          <template slot="content">

          </template>
        </block>
      </div>
    </div>
    <div class="grid">
      <div class="column size--100">
        <block :table="true">
          <template slot="content">
            <table class="table">
              <thead>
                <tr>
                  <th>Datum &amp; tijd</th>
                  <th>Gebruiker</th>
                  <th>Bericht</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="entry in displayEvents" :key="entry.id">
                  <td class="align--top">{{ entry.date }}</td>
                  <td class="align--top" v-if="entry.user">{{ entry.user }}</td>
                  <td class="align--top" v-else><em>Systeem</em></td>
                  <td>{{ entry.message }}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </block>
      </div>
    </div>
  </section>
</template>

<script>
  import moment from 'moment'

  import {pull} from '@qiri/stream'
  import {iterate} from '@qiri/stream/sources/iterate'
  import {filter} from '@qiri/stream/operators/filter'
  import {map} from '@qiri/stream/operators/map'
  import {sort} from '@qiri/stream/operators/sort'
  import {collect} from '@qiri/stream/sinks/collect'

  export default {
    name: 'page-scripts-Log',
    data () {
      return {
        events: [],
        loading: true,
        loadingError : null
      }
    },
    async mounted () {
      this.$model.list(`/env/${this.$environment}/api-template/Script/log`, 'events', {
        scriptID: this.scriptID
      })

      this.loading = false
    },
    computed: {
      scriptID () {
        return this.$route.params.scriptID
      },
      displayEvents () {
        return pull(
          iterate(this.events),
          map(evt => {
            let type = evt.type
            let message

            switch (type) {
              case 'created':
                type = 'Aangepast'
                message = 'Het API-script is aangemaakt.'
                break

              case 'activated-version':
                type = 'Versie geactiveerd'
                message = `Versie ${evt.data.version} is geactiveerd.`
                break

              case 'new-version':
                type = 'Nieuwe versie toegevoegd'
                message = `Nieuwe versie ${evt.data.version} is toegevoegd.`
                break

              case 'updated-version':
                type = 'Bestaande versie aangepast'
                message = `De bestaande versie ${evt.data.version} is aangepast`
                break

              case 'removed-version':
                type = 'Bestaande versie verwijderd'
                message = `De bestaande versie ${evt.data.version} is verwijderd`
                break

              case 'removed':
                type = 'Verwijderd'
                message = `Het API-script is verwijderd`
                break

              default:
                return false
            }

            return {
              id: evt.sequence,
              date: moment(evt.timestamp).format('DD-MM-YYYY HH:mm:ss'),
              type,
              message,
              user: evt.audit ? evt.audit.displayName : null
            }
          }),
          filter(x => x !== false),
          sort({
            compare: (a, b) => a.id > b.id ? -1 : a.id === b.id ? 0 : 1
          }),
          collect()
        )
      }
    }
  }
</script>
