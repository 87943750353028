export default function install (SimpleSchema) {
  const regExpMessages = [
    { exp: SimpleSchema.RegEx.Email, msg: 'moet een geldig e-mailadres zijn' },
    { exp: SimpleSchema.RegEx.EmailWithTLD, msg: 'moet een geldig e-mailadres zijn' },
    { exp: SimpleSchema.RegEx.Domain, msg: 'moet een geldig domein zijn' },
    { exp: SimpleSchema.RegEx.WeakDomain, msg: 'moet een geldig domein zijn' },
    { exp: SimpleSchema.RegEx.IP, msg: 'moet een geldig IPv4 of IPv6 adres zijn' },
    { exp: SimpleSchema.RegEx.IPv4, msg: 'moet een geldig IPv4 adres zijn' },
    { exp: SimpleSchema.RegEx.IPv6, msg: 'moet een geldig IPv6 adres zijn' },
    { exp: SimpleSchema.RegEx.Url, msg: 'moet een geldige URL zijn' },
    { exp: SimpleSchema.RegEx.WebsiteUrl, msg: 'moet een geldige website URL zijn' },
    { exp: SimpleSchema.RegEx.Id, msg: 'moet een geldige alfanummerieke ID zijn' },
    { exp: SimpleSchema.RegEx.ZipCode, msg: 'moet een geldige postcode zijn' },
    { exp: SimpleSchema.RegEx.Phone, msg: 'moet een geldig telefoonnummer zijn' },
  ];

  SimpleSchema.setDefaultMessages({
    initialLanguage: 'nl',
    messages: {
      nl: {
        required: '{{{label}}} is verplicht',
        minString: '{{{label}}} moet minimaal {{min}} karakters zijn',
        maxString: '{{{label}}} kan niet langer dan {{max}} karakters zijn',
        minNumber: '{{{label}}} moet minimaal {{min}} zijn',
        maxNumber: '{{{label}}} moet maximaal {{max}} zijn',
        minNumberExclusive: '{{{label}}} moet groter dan {{min}} zijn',
        maxNumberExclusive: '{{{label}}} must kleiner dan {{max}} zijn',
        minDate: '{{{label}}} moet op of voor {{min}} zijn',
        maxDate: '{{{label}}} kan niet na {{max}} zijn',
        badDate: '{{{label}}} is niet een geldige datum',
        minCount: 'U moet minimaal {{minCount}} waardes opgeven',
        maxCount: 'U moet maximaal {{maxCount}} waardes opgeven',
        noDecimal: '{{{label}}} mag geen decimalen bevatten',
        notAllowed: '{{{value}}} is een niet toegestaande waarde',
        expectedType: '{{{label}}} moet van type {{dataType}} zijn',
        regEx ({ label, regExp }) {
          // See if there's one where exp matches this expression
          let msgObj
          if (regExp) {
            msgObj = regExpMessages.find(o => o.exp && o.exp.toString() === regExp)
          }
          const regExpMessage = msgObj ? msgObj.msg : 'gefaalde reguliere expressie'
          return `${label} ${regExpMessage}`
        },
        keyNotInSchema: '{{name}} is niet toegestaan in het schema',
        mustBeUnique: '{{{label}}} is niet uniek'
      }
    }
  })
}
