import SimpleSchema from '@qiri/simpl-schema'

import Script from './Script'

export {
  Script
}

export default new SimpleSchema({
  'Script': {
    type: Script,
    label: 'Script',
    table: 'Scripts'
  }
})
