<template>
    <section v-if="loading" class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>Vergoeding Licentie voor gebruik</h1></template>
                    <template slot="content">
                        <p>Bezig met laden...</p>
                    </template>
                </block>
            </div>
        </div>
    </section>
    <section v-else class="padding">
        <div class="grid">
            <div class="column size--100">
                <block :table="true">
                    <template slot="title"><h1>Vergoeding Licentie voor gebruik</h1></template>
                    <template slot="content">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Module naam</th>
                                    <th class="align--right">Vergoeding</th>
                                    <th>Eenheid</th>
                                    <th class="align--right">Aantal eenheden</th>
                                    <th class="align--right">Totaal</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="month of months">
                                    <tr :key="month.period">
                                        <td colspan="4">
                                            <btn v-if="isExpanded(month)" icon="minus" title="Inklappen" class="icon" @click.prevent="toggleMonth(month, false)"></btn>
                                            <btn v-else icon="plus" title="Uitklappen" class="icon" @click.prevent="toggleMonth(month, true)"></btn>
                                            <b>{{ formatPeriod(month.period) }}</b>
                                        </td>
                                        <td v-if="isExpanded(month)">&nbsp;</td>
                                        <td v-else class="align--right whitespace--nowrap"><b>{{ formatNumber(month.total, 100) }} &euro;</b></td>
                                    </tr>
                                    <template v-if="isExpanded(month)">
                                        <tr>
                                            <td colspan="5"><em>Periode: {{ getFirstDay(month) }} t/m {{ getLastDay(month) }}</em></td>
                                        </tr>
                                        <tr v-for="line of month.lines" :key="line.description">
                                            <td>{{ line.description }}</td>
                                            <td class="align--right whitespace--nowrap">{{ formatNumber(line.unit.price, line.unit.factor) }}</td>
                                            <td class="whitespace--nowrap">&euro; {{ line.unit.label }}</td>
                                            <td class="align--right whitespace--nowrap">{{ formatNumber(line.quantity) }}</td>
                                            <td class="align--right whitespace--nowrap">{{ formatNumber(line.price, 100) }} &euro;</td>
                                        </tr>
                                        <tr><td colspan="5">&nbsp;</td></tr>
                                        <tr>
                                            <td colspan="4"><b>Totaal exclusief BTW</b></td>
                                            <td class="align--right whitespace--nowrap"><b>{{ formatNumber(month.subTotal, 100) }} &euro;</b></td>
                                        </tr>
                                        <tr>
                                            <td colspan="4">BTW ({{ formatNumber(month.vat.percentage) }}%)</td>
                                            <td class="align--right whitespace--nowrap">{{ formatNumber(month.vat.total, 100) }} &euro;</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4">Totaal inclusief BTW</td>
                                            <td class="align--right whitespace--nowrap">{{ formatNumber(month.total, 100) }} &euro;</td>
                                        </tr>
                                    </template>
                                </template>
                            </tbody>
                        </table>
                    </template>
                </block>
            </div>
        </div>
    </section>
</template>

<script>
    import moment from 'moment'
    import numeral from 'numeral'

    export default {
        name: 'page-usage-Overview',
        data () {
            return {
                allMonths: [],
                states: {
                    [moment().format('YYYY-MM')]: true
                },
                loading: true
            }
        },
        async mounted () {
            this.$model.list(`/env/${this.$environment}/usage/Overview/list`, 'allMonths')
            this.loading = false
        },
        computed: {
            months () {
                const months = [].concat(this.allMonths).sort((a, b) => {
                    if (a.period < b.period) {
                        return 1
                    } else if (a.period > b.period) {
                        return -1
                    } else {
                        return 0
                    }
                })
                return months
            }
        },
        methods: {
            formatDate (d) {
                return d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : ''
            },
            formatPeriod (period) {
                return period ? moment(period, 'YYYY-MM').format('MMMM YYYY') : ''
            },
            getFirstDay (month) {
                return moment(month.period, 'YYYY-MM').format('DD-MM-YYYY')
            },
            getLastDay (month) {
                const now = moment(month.updatedAt)
                const date = moment(month.period, 'YYYY-MM').endOf('month')
                return `${(now.isBefore(date) ? now : date).format('DD-MM-YYYY H:mm')} uur`
            },
            formatNumber (value, factor = 1) {
                const decimals = Math.log10(factor)
                let format = '0,0'
                for (let i = 0; i < decimals; i++) {
                    if (i === 0) {
                        format += '.'
                    }
                    format += '0'
                }
                return numeral(value / factor).format(format) 
            },
            toggleMonth (month, expanded) {
                this.$set(this.states, month.period, expanded)
            },
            isExpanded (month) {
                return this.states[month.period] === true
            }
        }
    }
</script>
