export default {
  title: 'Salesforce Marketing Cloud',
  language: 'html',
  supports: ({html}) => html,
  code: ({baseUrl, parameters, token = '{TOKEN}'}) => {
    parameters = Object.assign({}, parameters || {})
    parameters['token'] = token

    const vars = Object.keys(parameters).map(x => `@${x}`)
    const vals = Object.values(parameters)

    let varsDeclaration = ''
    // 
    return `%%[
  VAR ${vars.join(', ')}
  ${vars.map((v, i) => `SET ${v} = '${escape(vals[i])}'`).join('\n  ')}
]%%
%%=HTTPGet(Concat('${baseUrl}&${vars.map(v => `${v.substr(1)}=', ${v}`).join(`, '&`)}), 1, 0)=%%`
  }
}

function escape (str) {
  return str.replace(/'/g, `''`)
}
