/**
 * @todo
 */
export const share = ({wait = 1} = {}) => (source) => {
  let started = false
  let sinks = []
  let sinksLength = 0
  let sinksWaiting = 0
  let sourceTalkback

  return function shared (start, sink) {
    if (start !== 0) {
      return
    }
    sinks = sinks.concat([sink])
    sinksLength++

    const talkback = (t, d) => {
      if (t === 1) {
        // Only pull once all sinks requested something.
        sinksWaiting++
        if (started && sinksWaiting === sinksLength) {
          sourceTalkback(t, d)
        }
      } else if (t === 0) {
        return
      } else if (t === 2) {
        const i = sinks.indexOf(sink)
        if (i > -1) {
          sinks = sinks.filter((_, index) => index !== i)
          sinksLength--
        }
        if (!sinksLength) {
          sourceTalkback(2)
        }
      } else {
        sourceTalkback(t, d)
      }
    }

    if (sinksLength === wait) {
      started = true
      source(0, (t, d) => {
        if (t === 0) {
          sourceTalkback = d
          sink(0, talkback)
        } else if (t === 1){
          sinksWaiting = 0
          const l = sinks
          for (let s of l) {
            s(t, d)
          }
        } else {
          const l = sinks
          for (let s of l) {
            s(t, d)
          }
        }
        if (t === 2) {
          sinks = []
        }
      })
    } else {
      sink(0, talkback)
    }
  }
}
