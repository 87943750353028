<template>
    <section class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>Data-imports naar tabellen en velden</h1></template>
                    <template slot="content">
                        <ModelSelect v-model="selectedModel"></ModelSelect>
                        <p v-if="selectedModel">
                            <router-link :to="{ name: 'data.feeds.create', query: $route.query }" class="btn">Data-import naar tabel {{ modelLabel }} toevoegen</router-link>
                        </p>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid">
            <div class="column size--100">
                <block :table="true">
                    <template slot="content">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Naam</th>
                                    <th>Planning</th>
                                    <th>Status</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(feed, index) in displayFeeds" :key="feed.feedID">
                                    <td class="icon-inline">
                                        <router-link :to="{ name: 'data.feeds.update', params: { feedID: feed.feedID }, query: $route.query }" title="Data-import wijzigen" class="btn icon">
                                            <icon name="pencil" />
                                        </router-link>
                                        {{ feed.name }}
                                    </td>
                                    <td class="icon-inline whitespace--nowrap">
                                        <router-link :to="{ name: 'data.feeds.schedule', params: { feedID: feed.feedID }, query: $route.query }" title="Planning wijzigen" class="btn icon">
                                            <icon name="pencil" />
                                        </router-link>
                                        <ScheduleLabel :schedule="feed.schedule"></ScheduleLabel>
                                    </td>
                                    <td>{{ getStatusLabel(feed) }}</td>
                                    <td class="icon">
                                        <btn v-if="feed.state !== 'disabled' && getStatus(feed) !== 'executing' && getStatus(feed) !== 'queued'" @click.prevent="executeTask(feed)" icon="play" title="Uitvoeren" class="icon"></btn>
                                        <btn v-if="getStatus(feed) === 'queued' || getStatus(feed) === 'executing'" @click.prevent="cancelTask(feed)" icon="stop" title="Annuleren" class="icon"></btn>
                                        <router-link :to="{ name: 'data.feeds.log', params: { feedID: feed.feedID }, query: $route.query }" title="Logboek" class="btn icon">
                                            <icon name="eye" />
                                        </router-link>
                                        <btn v-if="feed.state === 'enabled'" @click.prevent="disableFeed(feed)" icon="toggle-on" title="Uitschakelen" class="icon"></btn>
                                        <btn v-else @click.prevent="enableFeed(feed)" icon="toggle-off" title="Inschakelen" class="icon"></btn>
                                        <btn icon="trash" title="Verwijderen" class="icon" @click.prevent="removeRecord(feed)"></btn>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </block>
            </div>
        </div>
        <modal name="delete" title="Weet je zeker dat je dit bronbestand wilt verwijderen?">
            <p>Wil je het bronbestand "{{ selectedRecord && selectedRecord.name }}" verwijderen?</p>
            <button type="button" @click="removeRecord(selectedRecord, true)">Ja, verwijderen</button>
            <button type="button" @click="$root.$emit('modal', 'delete', false)">Nee, annuleren</button>
        </modal>
    </section>
</template>

<script>
    import uuidv4 from 'uuid/v4'
    import get from 'lodash/get'
    import last from 'lodash/last'
    import pick from 'lodash/pick'
    import models from '@qiri/models/data'
    import ModelSelect from '../../Partials/ModelSelect'
    import Modal from '@/components/Partials/Modal'
    import ScheduleLabel from '@/modules/task-scheduler/components/Partials/ScheduleLabel'

    const statusLabels = {
        'initial': 'Initieel',
        'idle': 'Actief',
        'queued': 'In wachtrij',
        'executing': 'Bezig met uitvoeren',
        'canceled': 'Geannuleerd',
        'executed': 'Uitgevoerd'
    }

    export default {
        name: 'page-feeds-Overview',
        components: {
            ModelSelect,
            Modal,
            ScheduleLabel
        },
        data () {
            return {
                selectedModel: null,
                selectedRecord: null,
                feeds: [],
                tasks: [],
                taskProgress: [],
                statusLabels
            }
        },
        mounted () {
            this.$model.list(`/env/${this.$environment}/data/Feed/list`, 'feeds')
            this.$model.list(`/env/${this.$environment}/task-scheduler/Task/list`, 'tasks', {module: 'data.feed'})
            this.$model.list(`/env/${this.$environment}/task-scheduler/Task/progress`, 'taskProgress', {module: 'data.feed'})
        },
        computed: {
            modelLabel () {
                const modelName = this.selectedModel
                if (!modelName) {
                    return ''
                }
                const model = models.schema(modelName)
                return model.table
            },
            displayFeeds () {
                if (!this.selectedModel) {
                    return []
                }
                return this.feeds
                    .filter(feed => feed.model === this.selectedModel)
                    .sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
            }
        },
        methods: {
            async removeRecord (record, confirmed) {
                if (!confirmed) {
                    this.selectedRecord = record
                    this.$root.$emit('modal', 'delete', true)
                } else {
                    this.selectedRecord = null
                    this.$root.$emit('modal', 'delete', false)
                    await this.$model.remove(`/env/${this.$environment}/data/Feed`, record.feedID)
                }
            },
            async enableFeed (feed) {
                await this.$model.update(`/env/${this.$environment}/data/Feed`, feed.feedID, { $set: {
                    state: 'enabled'
                }})
            },
            async disableFeed (feed) {
                await this.$model.update(`/env/${this.$environment}/data/Feed`, feed.feedID, { $set: {
                    state: 'disabled'
                }})
            },
            async executeTask (feed) {
                if (feed.taskID) {
                    await this.$api.dispatch(`/env/${this.$environment}/task-scheduler/Task/execute`, {
                        taskID: feed.taskID
                    })
                }
            },
            async cancelTask (feed) {
                if (feed.taskID) {
                    await this.$api.dispatch(`/env/${this.$environment}/task-scheduler/Task/cancel`, {
                        taskID: feed.taskID
                    })
                }
            },
            getTaskFor (feed) {
                return feed.taskID ? this.tasks.find(x => x.taskID === feed.taskID) : null
            },
            getTaskProgressFor (feed) {
                return feed.taskID ? this.taskProgress.find(x => x.taskID === feed.taskID) : null
            },
            getStatus (feed) {
                return get(this.getTaskFor(feed), 'state', 'initial')
            },
            getStatusLabel (feed) {
                if (feed.state === 'disabled') {
                    return 'Uitgeschakeld'
                } else {
                    const task = this.getTaskFor(feed)
                    const progress = this.getTaskProgressFor(feed)
                    const status = get(task, 'state', 'initial')
                    let label = statusLabels[status]
                    let suffix = ''
                    if (status === 'executing' && progress) {
                        suffix = ` (${(progress.current / progress.total * 100).toFixed(2).replace('.', ',')}%)`
                    } else if (status === 'executed' && task.result && task.result.code !== 0) {
                        label = 'Foutmelding'
                        const error = task.result.data
                        if (error) {
                            suffix = ` (${error.message})`
                        }
                    }
                    return `${label}${suffix}`
                }
            }
        }
    }
</script>
