import SimpleSchema from '@qiri/simpl-schema'
import XRegExp from 'xregexp'
import {correctName, validateName} from './util'

SimpleSchema.setDefaultMessages({
  messages: {
    nl: {
      toManyAddressLines: `Het adres mag uit maximaal 2 regels bestaan`,
      invalidPostalCode: `{{{label}}} is geen geldige postcode`,
      invalidCity: `{{{label}}} is geen geldige plaatsnaam`
    }
  }
})

const PostalCodeRegExp = {
  correct: XRegExp.build(`^.*((?:\\s*[0-9]{1}){4}\\s*).*((?:\\s*[a-z]{1}){2}\\s*)$`, {}, 'ui'),
  validate: XRegExp.build(`^[1-9][0-9]{3}\\s*[a-z]{2}$`, {}, 'ui')
}

export default new SimpleSchema({
  'address': {
    type: String,
    label: 'Adresregel(s)',
    description: 'De adresregel(s)',
    multiline: true,
    custom () {
      const lines = (this.value || '').split(/\n/)
      if (lines.length > 2) {
        return 'toManyAddressLines'
      }
    }
  },
  'postalCode': {
    type: String,
    label: 'Postcode',
    description: 'De postcode',
    autoValue () {
      if (this.isSet && this.value) {
        const r = PostalCodeRegExp.correct.exec(this.value)
        if (r) {
          return `${r[1].trim()} ${r[2].trim().toUpperCase()}`
        }
      }
    },
    custom () {
      if (this.isSet && this.value && !PostalCodeRegExp.validate.test(this.value)) {
        return 'invalidPostalCode'
      }
    }
  },
  'city': {
    type: String,
    label: 'Plaats',
    description: 'De (woon)plaats',
    autoValue () {
      if (this.isSet && this.value) {
        return correctName(this.value)
      }
    },
    custom () {
      if (this.isSet && this.value && !validateName(this.value)) {
        return 'invalidCity'
      }
    }
  },
  'country': {
    type: String,
    label: 'Land',
    description: 'Het land',
    defaultValue: 'nl'
  }
})
