<template>
    <section class="padding">
        <div class="grid">
            <div class="column size--100">
                <block>
                    <template slot="title"><h1>Dashboard</h1></template>
                    <template slot="content">
                        <p>Welkom terug, <Profile get="name" />.</p>
                    </template>
                </block>
            </div>
        </div>
        <div class="grid" v-if="showMonitoring">
            <div class="column size--100">
                <block>
                    <template slot="title"><h2>Meldingen</h2></template>
                    <template slot="content" v-if="messages.length === 0">
                        <p>Er zijn momenteel geen meldingen.</p>
                    </template>
                    <template slot="content" v-else>
                        <p v-for="message of messages" :key="message.environmentID">
                            <template v-if="message.unacked === 1">
                                Er is 1 melding in de omgeving "<router-link :to="getEnvironmentRoute(message)">{{message.name}}</router-link>" welke aandacht nodig heeft.
                            </template>
                            <template v-else>
                                Er zijn {{message.unacked}} meldingen in de omgeving "<router-link :to="getEnvironmentRoute(message)">{{message.name}}</router-link>" welke aandacht nodig hebben.
                            </template>
                        </p>
                    </template>
                </block>
            </div>
            <div class="column size--100">
                <block :table="true">
                    <template slot="title"><h2>Ingeplande taken</h2></template>
                    <template slot="content">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Omgeving</th>
                                    <th>Module</th>
                                    <th>Tabel</th>
                                    <th>Naam</th>
                                    <th>Volgende uitvoerdatum</th>
                                    <th>Verwachte duur</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(schedule, index) in displaySchedules" :key="schedule.record.id">
                                    <td>{{schedule.environment.name}}</td>
                                    <td>{{formatModule(schedule.module)}}</td>
                                    <td>{{formatModel(schedule.record.model)}}</td>
                                    <td>{{schedule.record.name}}</td>
                                    <td v-if="schedule.state === 'queued'">
                                        In wachtrij
                                        <icon
                                            v-if="checkScheduleOverdue(schedule)"
                                            name="exclamation-triangle"
                                            class="errors"
                                            title="Deze taak is na 5 minuten nog niet uitgevoerd. Controleer de taakplanner!"
                                        />
                                    </td>
                                    <td v-else-if="schedule.state === 'executing'">
                                        Bezig met uitvoeren
                                    </td>
                                    <td v-else>
                                        {{formatDate(schedule.nextDate)}}
                                        <icon
                                            v-if="checkScheduleOverdue(schedule)"
                                            name="exclamation-triangle"
                                            class="errors"
                                            title="Deze taak is na 5 minuten nog niet uitgevoerd. Controleer the taakplanner!"
                                        />
                                        <icon
                                            v-else-if="index > 0 && checkScheduleOverlap(schedule, displaySchedules[index - 1])"
                                            name="exclamation-triangle"
                                            class="warnings"
                                            :title="displaySchedules[index - 1].module === schedule.module ? 'Deze taak wordt mogelijk uitgesteld omdat deze overlapt met de voorliggende taak' : 'Deze taak overlapt met een andere taak, controleer of er voldoende performance beschikbaar is'"
                                        />
                                    </td>
                                    <td>{{schedule.estimatedTime ? `~${formatDuration(schedule.estimatedTime)}` : 'Onbekend'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </block>
            </div>
        </div>
    </section>
</template>

<script>
    import moment from 'moment'
    import {getModelTable} from '@qiri/models/data/util'
    import Profile from '@/components/Partials/Profile'

    export default {
        name: 'page-dashboard',
        components: {
            Profile
        },
        data () {
            return {
                environments: [],
                schedules: []
            }
        },
        mounted () {
            this.refresh()
            this.$watch(() => this.$environment, this.refresh)
            this._intervalHandle = setInterval(() => this.refresh(), 30 * 1000)
        },
        destroyed () {
            if (this._intervalHandle) {
                clearInterval(this._intervalHandle)
                this._intervalHandle = null
            }
        },
        computed: {
            showMonitoring () {
                return !this.$environment && this.$token.role === 'superadministrator' || this.$token.role === 'administrator'
            },
            messages () {
                return this.environments.filter(x => x.unacked > 0)
            },
            displaySchedules () {
                return this.schedules.sort((a, b) =>
                    !a.nextDate && b.nextDate ? -1 : a.nextDate && !b.nextDate ? 1 :
                    a.nextDate < b.nextDate ? -1 : a.nextDate === b.nextDate ? 0 : 1
                )
            }
        },
        methods: {
            refresh () {
                if (this.showMonitoring) {
                    this.$model.fetch('/env/list', {unacked: true}).then(environments => {
                        this.environments = environments
                    })
                    this.$model.fetch('/task-scheduler/Scheduler/listExpected', {}).then(schedules => {
                        this.schedules = schedules
                    })
                } else {
                    this.environments = []
                    this.schedules = []
                }
            },
            getEnvironmentRoute (environment) {
                return {
                    name: 'data.status',
                    query: {
                        ...this.$route.query,
                        environment: environment ? environment.environmentID : undefined
                    }
                }
            },
            checkScheduleOverdue (schedule) {
                if (schedule.state === 'queued') {
                    return moment(schedule.updatedAt).isBefore(moment().subtract(5, 'minutes'))
                } else if (schedule.state !== 'executing') {
                    return moment(schedule.nextDate).isBefore(moment().subtract(5, 'minutes'))
                } else {
                    return false
                }
            },
            checkScheduleOverlap (schedule, previousSchedule) {
                if (previousSchedule.nextDate && previousSchedule.estimatedTime) {
                    return moment(previousSchedule.nextDate).add(previousSchedule.estimatedTime, 'seconds').isAfter(schedule.nextDate)
                } else {
                    return false
                }
            },
            formatDate (d) {
                return d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : ''
            },
            formatModule (m) {
                const mapping = {
                    'data.feed': 'Data-import',
                    'data.export': 'Data-export'
                }
                return mapping[m] || m
            },
            formatModel (m) {
                return getModelTable(m)
            },
            formatDuration (d) {
                return `${Math.ceil(d / 60)} ${d < 120 ? 'minuut' : 'minuten'}`
            }
        }
    }
</script>
