import SimpleSchema from '@qiri/simpl-schema'
import Field from './Field'

export default new SimpleSchema({
  fieldID: {
    type: String,
    label: 'Veld ID',
    primaryKey: true
  },
  validationTemplate: {
    type: String,
    label: 'Validatie template',
    optional: true
  },
  indexed: {
    type: Boolean,
    label: 'Zoekbaar',
    optional: true
  },
  'relation': {
    type: Object,
    label: 'Relatie tot',
    optional: true
  },
  'relation.name': {
    type: String,
    label: 'Naam extern systeem',
    optional: true
  },
  'relation.table': {
    type: String,
    label: 'Naam tabel',
    optional: true
  },
  'relation.field': {
    type: String,
    label: 'Naam veld',
    optional: true
  }
})
  .extend(Field)
