// Pages
import ScriptsOverview from './components/Pages/scripts/Overview'
import ScriptsLog from './components/Pages/scripts/Log'
import ScriptsHistory from './components/Pages/scripts/History'
import ScriptsForm from './components/Pages/scripts/Form'

export default {
    'api-template.scripts.overview': {
        path: '/api/scripts',
        component: ScriptsOverview,
        meta: {
            audience: 'actor:/env/*/api-template/Script/list',
            overview: {
                mode: 'overview'
            }
        }
    },
    'api-template.scripts.log': {
        path: '/api/scripts/:scriptID/log',
        component: ScriptsLog,
        meta: {
            audience: 'actor:/env/*/api-template/Script/list'
        }
    },
    'api-template.scripts.history': {
        path: '/api/scripts/:scriptID/history',
        component: ScriptsHistory,
        meta: {
            audience: 'actor:/env/*/api-template/Script/list',
            overview: {
                mode: 'history'
            }
        }
    },
    'api-template.scripts.add': {
        path: '/api/scripts/add',
        component: ScriptsForm,
        meta: {
            audience: 'actor:/env/*/api-template/Script/add',
            title: 'API-script toevoegen',
            form: {
                mode: 'create'
            }
        }
    },
    'api-template.scripts.new-version': {
        path: '/api/scripts/:scriptID/edit',
        component: ScriptsForm,
        meta: {
            audience: 'actor:/env/*/api-template/Script/update',
            title: 'API-script versie {version} toevoegen',
            form: {
                mode: 'new-version'
            }
        }
    },
    'api-template.scripts.view-version': {
        path: '/api/scripts/:scriptID/version/:version/view',
        component: ScriptsForm,
        meta: {
            audience: 'actor:/env/*/api-template/Script/update',
            title: 'API-script versie {version} bekijken',
            submitText: 'API-script opslaan als nieuwste versie (versie {nextVersion})',
            form: {
                mode: 'view-version'
            }
        }
    },
    'api-template.scripts.edit-version': {
        path: '/api/scripts/:scriptID/version/:version/update',
        component: ScriptsForm,
        meta: {
            audience: 'actor:/env/*/api-template/Script/update',
            title: 'API-script versie {version} aanpassen',
            form: {
                mode: 'edit-version'
            }
        }
    }
}
